import React, {
	useState,
	useRef,
	useMemo,
	useCallback,
	useEffect,
} from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { ReactComponent as Style } from '../../../assets/images/icons/style1.svg'
import { ReactComponent as Person } from '../../../assets/images/icons/person.svg'
import { ReactComponent as Badge } from '../../../assets/images/icons/badge2.svg'
import { ReactComponent as Home } from '../../../assets/images/icons/home.svg'
import { ReactComponent as Package } from '../../../assets/images/icons/package.svg'
import { ReactComponent as City } from '../../../assets/images/icons/location_city.svg'
import { ReactComponent as MpList } from '../../../assets/images/icons/mpList.svg'
import { ReactComponent as Send } from '../../../assets/images/icons/send.svg'
import { ReactComponent as AlertRed } from '../../../assets/images/icons/infoMessageRed.svg'
//import { ReactComponent as Unhide } from '../../../assets/images/icons/history.svg'
import { AgGridReact } from 'ag-grid-react'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-alpine.css'
import 'ag-grid-enterprise'
import CustomHeader from '../../components/Table/CustomHeader'
import TagsRenderer from '../../components/Table/TagsRenderer'
import { LicenseManager } from 'ag-grid-enterprise'
import { Input } from '@material-tailwind/react'
import AppKeycloak from '../../Keycloak'
import { GetRowIdFunc, GetRowIdParams } from 'ag-grid-community'
import AppMQTT from '../../AppMQTT'
import { dependencyContainer } from '../../store'
import EditIcon from '../EditIcon'
import { useAppSelector } from '../../hooks'
import ForecastRenderer from '../Table/ForecastRenderer'
import forecastFilterFormatter from '../Table/ForecastFilterFormatter'
import ShareMpColumn from '../Table/ShareMpColumn'
import {GetContextMenuItemsParams} from 'ag-grid-community'
import DeleteMpModal from '../Modals/DeleteMpModal'
import { MeasuringPointModel } from '../../repository/DashboardRepository'
import { OneBusinessModel } from '../../repository/BusinessRepository'
import { useMutation } from '@tanstack/react-query'
import {queryClient} from '../../../index'
import CalculatedMpModal from '../Modals/CalculatedMpModal'
import MpCellRenderWithIcon from '../Table/MpCellRenderWithIcon'
import ChangingInputModal from '../Modals/ChangingInputModal'
import AddNotificationModal from '../Modals/AddNotificationModal '
import CheckIcon from '../CheckIcon'
import CloseIcon from '../CloseIcon'
import ConflictVmpModal from '../Modals/ConflictVmpModal'

LicenseManager.setLicenseKey(
	'CompanyName=Appmodule AG,LicensedGroup=aliunid grid / power,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=2,AssetReference=AG-036901,SupportServicesEnd=31_January_2024_[v2]_MTcwNjY1OTIwMDAwMA==4c9d5d4b04b142bc6b50ed895e5b5ef3'
)

interface Props {
	businessData: MeasuringPointModel[]
	oneBusinessData: OneBusinessModel
	openCreateCalculatedMpModal: boolean
	openAddNotificationModal: boolean
	setOpenCreateCalculatedMpModal: React.Dispatch<React.SetStateAction<boolean>>
	setOpenAddNotificationModal: React.Dispatch<React.SetStateAction<boolean>>
	openConflictVmpMpModal: boolean
	setOpenConflictVmpMpModal: React.Dispatch<React.SetStateAction<boolean>>
	//hide: boolean
}

const BusinessDataTab = (
	{ businessData, 
		oneBusinessData,
		openCreateCalculatedMpModal,
		openAddNotificationModal,
		setOpenCreateCalculatedMpModal,
		setOpenAddNotificationModal,
		openConflictVmpMpModal,
		setOpenConflictVmpMpModal,
		//hide
	}: Props) => {
	
	const [inputNameActive, setInputNameActive] = useState(false)
	const [inputCeoActive, setInputCeoActive] = useState(false)
	const [accNumberActive, setInputAccNumberActive] = useState(false)
	const [streetActive, setStreetActive] = useState(false)
	const [zipActive, setZipActive] = useState(false)
	const [townActive, setTownActive] = useState(false)

	const [businessName, setBusinessName] = useState<string>('')
	const [ceoName, setCeoName] = useState<string>('')
	const [accNumber, setAccNumber] = useState<string>('')
	const [address, setAddress] = useState<string>('')
	const [zip, setZip] = useState<string>('')
	const [town, setTown] = useState<string>('')
	const [editData, setEditData] = useState<any>()

	const [mpData, setMpData] = useState<any>({})
	const [openDeleteMpModal, setOpenDeleteMpModal] = useState(false)
	const [error, setError] = useState('')

	const [showChangingInputModal, setShowChangingInputModal] = useState(false)
	const [showChangingCeoModal, setShowChangingCeoModal] = useState(false)
	const [showChangingAccNumberModal, setShowChangingAccNumberModal] = useState(false)
	const [showChangingStreetModal, setShowChangingStreetModal] = useState(false)
	const [showChangingZipModal, setShowChangingZipModal] = useState(false)
	const [showChangingTownModal, setShowChangingTownModal] = useState(false)

	const [signalForOpenCalculatedModal, setSignalForOpenCalculatedModal] = useState(false)
	const [signalForCancelConflictModal, setSignalForCancelConflictModal] = useState(false)

	const [isConnect, setIsConnect] = useState(false)

	const { id: business, subBusinessId: id } = useParams<any>()

	const { role, business: selfBusiness } = useAppSelector((state) => state.app)

	const { subBusinessId } = useParams()

	const navigate = useNavigate()

	let client = null as any

	useEffect(() => {
		if(oneBusinessData !== undefined){
			setTown(oneBusinessData.city ? oneBusinessData.city : '')
			setZip(oneBusinessData.zip ? oneBusinessData.zip : '')
			setAddress(oneBusinessData.address ? oneBusinessData.address : '')
			setAccNumber(oneBusinessData.accountNumber ? oneBusinessData.accountNumber : '')
			setCeoName(oneBusinessData.ceoName ? oneBusinessData.ceoName : '')
			setBusinessName(oneBusinessData.name ? oneBusinessData.name : '')
		}
	}, [oneBusinessData])
  
	const gridRef = useRef<AgGridReact>(null)
	const deleteMpRef = useRef(null)
	const calculatedMpRef = useRef(null)
	const changingInputRef = useRef(null)
	const conflictVmpRef = useRef(null)

	const inputNameRef = useRef<any>()
	const inputCeoNameRef = useRef<any>()
	const inputAccNumberRef = useRef<any>()
	const inputStreetRef = useRef<any>()
	const inputZipRef = useRef<any>()
	const inputTownRef = useRef<any>()

	const editBusiness = useMutation({
		mutationFn: (): any => {
			if (id === 'self') {
				return dependencyContainer.dependency.businessApi.editBusiness(business,businessName,ceoName,address,town,zip,accNumber)
			}
		},
		onSuccess: async (res) => {
			setEditData(res)
			queryClient.invalidateQueries({ queryKey: ['businessData'] })
		},
		onError: (error: any) => {
			setError(error.response?.data?.message ? error.response?.data?.message : 'Internal server error !')
		},
	})

	// const setHideMp = useMutation({
	// 	mutationFn: () => dependencyContainer.dependency.measuringPointsApi.setHideMeasuringPoint(mpData?.businessGroupId, mpData?.id, mpData?.name),
	// 	onSuccess: async () => {
	// 		queryClient.invalidateQueries({ queryKey: ['dashboardData'] })
	// 	}
	// })

	// const setShowAllMps = useMutation({
	// 	mutationFn: () => dependencyContainer.dependency.measuringPointsApi.setShowAllMeasuringPoints(businessIdParam as string),
	// 	onSuccess: async () => {
	// 		queryClient.invalidateQueries({ queryKey: ['dashboardData'] })
	// 	}
	// })

	useEffect(() => {
		if(businessName !== oneBusinessData?.name && editData?.status === 204) {
			window.location.reload()
		}
	}, [editData?.status,editBusiness])
	
	const handleChangeBusinessName = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setBusinessName(event.target.value)
	}
	const handleChangeCeoName = (event: React.ChangeEvent<HTMLInputElement>) => {
		setCeoName(event.target.value)
	}
	const handleChangeAccNumber = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setAccNumber(event.target.value)
	}
	const handleChangeStreet = (event: React.ChangeEvent<HTMLInputElement>) => {
		setAddress(event.target.value)
	}
	const handleChangeZip = (event: React.ChangeEvent<HTMLInputElement>) => {
		setZip(event.target.value)
	}
	const handleChangeTown = (event: React.ChangeEvent<HTMLInputElement>) => {
		setTown(event.target.value)
	}

	const saveNewName = () => {
		editBusiness.mutate()
		setInputNameActive(false)
	}
	const resetName = () => {
		setBusinessName(oneBusinessData.name ? oneBusinessData.name : '')
		setInputNameActive(true)
	}

	const saveCeoName = () => {
		editBusiness.mutate()
		setInputCeoActive(false)
	}
	const resetCeo = () => {
		setCeoName(oneBusinessData.ceoName ? oneBusinessData.ceoName : '')
		setInputCeoActive(true)
	}

	const saveAccNumber = () => {
		editBusiness.mutate()
		setInputAccNumberActive(false)
	}
	const resetAccNumber = () => {
		setAccNumber(oneBusinessData.accountNumber ? oneBusinessData.accountNumber : '')
		setInputAccNumberActive(true)
	}

	const saveStreet = () => {
		editBusiness.mutate()
		setStreetActive(false)
	}
	const resetStreet = () => {
		setAddress(oneBusinessData.address ? oneBusinessData.address : '')
		setStreetActive(true)
	}

	const saveZip = () => {
		editBusiness.mutate()
		setZipActive(false)
	}
	const resetZip = () => {
		setZip(oneBusinessData.zip ? oneBusinessData.zip : '')
		setZipActive(true)
	}

	const saveTown = () => {
		editBusiness.mutate()
		setTownActive(false)
	}
	const resetTown = () => {
		setTown(oneBusinessData.city ? oneBusinessData.city : '')
		setTownActive(true)
	}

	const columnDefsTwo= [
		{
			cellRenderer: (row: any) => (
				<ShareMpColumn
					data={row.data}
				/>
			),
			field: 'mp',
			filter: false,
			maxWidth: 35,
			sortable: false,
			onCellClicked: function (params: any) {
				if (params.node.data.name !== '') {
					params.api.contextMenuFactory.showMenu(
						params.node,
						params.column,
						params.value,
						params.event
					)
				}
			}
		},
		{
			field: 'checkbox',
			filter: false,
			maxWidth: 35,
			sortable: false,
			headerCheckboxSelection: true,
			checkboxSelection: (node: any) => {
				if(node.data?.name !== ''){
					return true
				} else {
					return false
				}
			},
			cellStyle: function() {
				return { display: 'flex', justifyContent: 'center',marginLeft:'5px'}
			},
		},
		{
			field: 'name',
			headerName: 'MP name',
			minWidth: 345,
			headerComponentParams: { menuIcon: 'electric_meter' },
			cellRenderer: (row: any) => (
				<MpCellRenderWithIcon
					data={row.data}
					length={70}
				/>
			),
			cellStyle: function() {
				return { wordBreak: 'break-word' }
			},
		},
		{
			field: 'consumptionSpent',
			headerName: 'kW Live',
			type: 'number',
			valueFormatter: (params: any) => {
				if (params.value === 0) return params.value
				else if (params.value === null) return null
				else if (params.value) return params.value?.toFixed(2)
				else return '-'
		
			},
			headerComponentParams: { menuIcon: 'flash_on' },
			cellClass: 'flex-right-align',
			filter: false,
			minWidth: 135,
		},
		{
			field: 'consumptionStatus',
			headerName: 'Forecast',
			filter: 'agSetColumnFilter',
			filterParams: {
				buttons: ['reset', 'apply'],
				suppressSelectAll: true,
				suppressBlanks: true,
				cellHeight: 48,
				suppressMiniFilter: true,
				cellRenderer: forecastFilterFormatter
			},
			minWidth: 185,
			headerComponentParams: { menuIcon: 'apps' },
			cellStyle: function() {
				return { display: 'flex', justifyContent: 'center'}
			},
			cellRenderer: (row: any) => (
				<ForecastRenderer
					data={row.data}
				/>
			),
		},
		{
			field: 'quota',
			headerName: 'kWh Quota',
			headerComponentParams: { menuIcon: 'dynamic_form' },
			cellClass: 'text-[#96CCFF] flex-right-align',
			minWidth: 160,
		},
		{
			field: 'quotaUsed',
			headerName: 'of Quota used',
			headerComponentParams: { menuIcon: 'percent' },
			cellClass: 'flex-right-align',
			minWidth: 170,
		},
		{
			field: 'used',
			headerName: 'kWh used',
			headerComponentParams: { menuIcon: 'flash_on' },
			cellClass: 'flex-right-align',
			minWidth: 150,
		},
		{
			field: 'tags',
			filter: 'agSetColumnFilter',
			filterParams: {
				buttons: ['reset', 'apply'],
				suppressSelectAll: true,
				cellHeight: 48,
				suppressMiniFilter: true,
			},
			cellRenderer: TagsRenderer,
			headerComponentParams: { menuIcon: 'style' },
			autoHeight: true,
			wrapText: true,
			cellStyle: { justifyContent: 'center' },
			minWidth: 220,
			maxWidth: 270,
		},
	]

	const headerHeight = 25

	const floatingFiltersHeight = 30

	const defaultColDef = useMemo(
		() => ({
			sortable: true,
			filter: 'agTextColumnFilter',
			floatingFilter: true,
			flex: 1,
			suppressMovable: true,
			wrapText: true,
			suppressMenu: true,
			floatingFilterComponentParams: {
				suppressFilterButton: true,
			},
		}),
		[]
	)

	const rowHeight = 50

	const icons = useMemo(() => {
		return {
			groupExpanded: '<i class="ag-icon ag-icon-small-down" style=""/>',
			groupContracted: '<i class="ag-icon ag-icon-small-right" style=""/>',
		}
	}, [])

	const onFirstDataRendered = useCallback(() => {
		setTimeout(() => {
			document
				.querySelectorAll('.ag-theme-alpine input[class^=ag-][type=text]')
				.forEach((obj) => {
					obj.setAttribute('placeholder', 'Search')
				})
		}, 5)
		setTimeout(() => {
			document
				.querySelectorAll(
					'.ag-theme-alpine .ag-disabled input[class^=ag-][type=text]'
				)
				.forEach((obj) => {
					obj.setAttribute('placeholder', 'Select')
					obj.removeAttribute('disabled')
				})
		}, 10)
	}, [])

	const columnTypes = useMemo(() => {
		return {
			number: {
				aggFunc: 'count',
			},
		}
	}, [])

	const getSelectedRowData = () => {
		const selectedData = gridRef.current?.api.getSelectedRows()
		selectedData?.forEach((selectedEl: any)=> {
			(businessData as MeasuringPointModel[]).forEach((element: any) => {
				if(element.id === selectedEl.id){
					element.checked = true
				}
			})
		})
		return businessData
	}

	useEffect(() => {
		if(gridRef.current !== null && gridRef.current?.api !== undefined){
			if(!openCreateCalculatedMpModal){
				gridRef.current?.api.forEachNode((node) =>
					node.setSelected(false)
				)
			}}
	}, [openCreateCalculatedMpModal, gridRef])

	useEffect(() => {
		if(gridRef.current !== null && gridRef.current?.api !== undefined){
			if(!openConflictVmpMpModal && !openCreateCalculatedMpModal && signalForCancelConflictModal){
				gridRef.current?.api.forEachNode((node) => {
					node.setSelected(false)
				})
				setSignalForCancelConflictModal(false)
			}}
	}, [openConflictVmpMpModal, gridRef])

	useEffect(() => {
		if(gridRef.current !== null && gridRef.current?.api !== undefined){
			if(!openAddNotificationModal){
				gridRef.current?.api.forEachNode((node) =>
					node.setSelected(false)
				)
			}}
	}, [openAddNotificationModal, gridRef])

	const checkVirtualMpConflicts = () => {
		setMpData({})
		const filtered = (getSelectedRowData() as any).filter((el: any) => el.checked && el.provider)
		if(filtered.length !== 0) {
			setOpenConflictVmpMpModal(true)
		} else {
			setOpenCreateCalculatedMpModal(true)
		}
	}

	useEffect(() => {
		if(signalForOpenCalculatedModal){
			setOpenCreateCalculatedMpModal(true)
		}
	}, [signalForOpenCalculatedModal])

	const rowStyle = { cursor: 'pointer' }

	const components = useMemo(() => {
		return {
			agColumnHeader: CustomHeader,
		}
	}, [])

	const groupDefaultExpanded = 1

	const cellClicked = (row: any) => {
		if (typeof row.data !== 'undefined' && row.data.name !== '' && row.column.colId !== 'mp' && row.column.colId !== 'checkbox') {
			navigate(`measuring-points/${row.data.id}`)
		} else {
			setMpData(row.data)
		}
	}

	const searchIcon = '<img border="0" width="15" height="15" src="/images/icon.svg"/>'
	const deleteIcon = '<img border="0" width="15" height="15" src="/images/delete_icon.svg"/>'
	//const hideIcon = '<img border="0" width="15" height="15" src="/images/mpShared.svg"/>'

	const getContextMenuItems = useCallback((params: GetContextMenuItemsParams | any) => {

		const data = params.node.data
		//const shared = data.businessGroup !== 'self'
		const hasActiveProvider = data.provider !== null && data.provider !== undefined && data.provider.linked 

		const result = []
		if (params.node) {
			// if(data.virtual)
			// 	result.push(
			// 		{
			// 			name: 'Edit',
			// 			icon: editIcon,
			// 			action: function () {
			// 				setOpenCreateCalculatedMpModal(true)
			// 			}
			// 		},
			// 		'separator',
			// 	)
			if(!hasActiveProvider  && role === 'admin')
				result.push(
					{
						name: 'Delete',
						icon: deleteIcon,
						action: function () {
							setOpenDeleteMpModal(true)
						}
					},
					'separator',
				)
			result.push(
				{
					name: 'Details',
					icon: searchIcon,
					action: function () {
						if (typeof params.node.data !== 'undefined' && params.node.data.name !== '') {
							navigate(`measuring-points/${params.node.data.id}`)
						}
					}
				},
				'separator',
			)
			// if(role === 'admin')
			// 	result.push(
			// 		{
			// 			name: 'Hide',
			// 			icon: hideIcon,
			// 			action: function () {
			// 			//backend call to hide mp
			// 			//setHideMp.mutate()
			// 			}
			// 		},
			// 	)
		}
		return result
	}, [])

	useEffect(() => {
		if(id === undefined) return
		if (businessData !== undefined && Object.keys(businessData).length === 0) return
		let mqtt: AppMQTT | undefined = undefined
		try {
			const map = siotIdMap(businessData as any)
			mqtt = new AppMQTT(dependencyContainer.dependency.wssUrl,AppKeycloak.getAppKeycloak())
			client = mqtt
				.connect()
				.on('connect', () => {
					setIsConnect(true)
					topics(businessData as any)?.forEach(topic => client.subscribe(topic))
				})
				.on('error', (e: any) => {
					setIsConnect(false)
					console.error(e)
				})
				.on('timeout', (isWithError: any) => {
					setIsConnect(false)
					console.error(isWithError)
				// TODO: something more?
				})
				.on('message', (_topic: any, message: any) => {
					const parts = _topic.split('/')
					const data = JSON.parse(message.toString()).value.sys.kW
					updateRow({
						id: map[parts[4]],
						consumptionSpent: data
					})
				})
		} catch (e) {
			console.error(e)
		}
		return () => {
			if(mqtt !== undefined)
				mqtt.disconnect()
		}
	}, [id, businessData])

	// const deInitMQTT = () => {
	// 	if (client) {
	// 		client.end()
	// 		//console.log("DISCONNECTED from broker");
	// 	}
	// }

	const topics = (businesses: any[]) => {
		//const businessPart = id === 'self' ?  business : id
		return businesses?.map(({ siotId }) => `s2/aliunid/${business}/adm/${siotId}`)
	}
	const siotIdMap = (businesses: any[]) => {
		const map: any = {}
		businesses?.forEach(({id ,siotId}) => map[siotId] = id )
		return map
	}

	const getRowId = useMemo<GetRowIdFunc>(() => {
		return (params: GetRowIdParams) => {
			return params.data.id
		}
	}, [])

	const updateRow = useCallback((message: any) => {
		try {
			const rowNode = gridRef.current!.api.getRowNode(message.id)!
			rowNode.setDataValue('consumptionSpent', message.consumptionSpent)
		} catch (e) {
			// console.log(e)
		}
	}, [])

	const getRowStyle = (params: any) => {
		const data = params.data
		if(data === null || data === undefined) return
		if(data.provider !== null && data.provider !== undefined && data.provider.linked) {
			return { background: '#ebebeb' }
		} else {
			return { background: 'white' }
		}
	}

	//showing info modal when input fields are changed but not saved 
	useEffect(() => {

		function handleClickOutside(event: any) {
			if (oneBusinessData?.name !== undefined && businessName !== '' && !inputNameRef.current.contains(event.target) && (businessName !== oneBusinessData?.name)) {
				setShowChangingInputModal(true)
			}
			if (oneBusinessData?.ceoName !== undefined && ceoName !== '' && !inputCeoNameRef.current.contains(event.target) && (ceoName !== oneBusinessData?.ceoName)) {
				setShowChangingCeoModal(true)
			}
			if (oneBusinessData?.accountNumber !== undefined && accNumber !== '' && !inputAccNumberRef.current.contains(event.target) && (accNumber !== oneBusinessData?.accountNumber)) {
				setShowChangingAccNumberModal(true)
			}
			if (oneBusinessData?.address !== undefined && address !== '' && !inputStreetRef.current.contains(event.target) && (address !== oneBusinessData?.address)) {
				setShowChangingStreetModal(true)
			}
			if (oneBusinessData?.zip !== undefined  && zip !== '' && !inputZipRef.current.contains(event.target) && (zip !== oneBusinessData?.zip)) {
				setShowChangingZipModal(true)
			}
			if (oneBusinessData?.city !== undefined && town !== '' && !inputTownRef.current.contains(event.target) && (town !== oneBusinessData?.city)) {
				setShowChangingTownModal(true)
			}
		}
		document.addEventListener('mousedown', handleClickOutside)
		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [
		businessName,
		ceoName,
		accNumber,
		address,
		zip,
		town,
		oneBusinessData
	])

	return (
		<div>
			<div className="bg-[#F0F0F0] p-[15px] rounded-xl mt-[20px] text-[#51606F]">
				<div className="grid grid-cols-3 gap-4">
					<div className="bg-white rounded-xl px-[10px] h-[70px]">
						<div className="flex-col ">
							<div className="flex mt-[8.5px]">
								<div className="items-center w-5 h-5">
									<Style className="w-4 h-4" />
								</div>
								<div>
									<h2 className=" leading-6 font-medium tracking-[0.15px] mt-[-2px] ml-[8px] text-[11px]">
                    Business name
									</h2>
								</div>
							</div>
							<div className="mt-[2px] w-full h-[1px] order-1 bg-[#96CCFF]"></div>
							<div className="flex justify-between">
								<div className="mt-[-12px] w-full">
									<Input
										crossOrigin=''
										ref={inputNameRef}
										id='name'
										tabIndex={-1}
										className='text-[14px] text-[#51606F]'
										style={{fontWeight: 500}}
										onClick={() => {
											setInputNameActive(true)
										}}
										value={businessName || ''}
										readOnly={role === 'admin' && subBusinessId === 'self' ? false : true}
										onChange={handleChangeBusinessName}
										variant="static"
										onBlur={()=>{
											setTimeout(function(){
												setInputNameActive(false)
											}, 150) 
										}}
										icon={
											role === 'admin' && subBusinessId === 'self' ? (inputNameActive ? (
												<div className="flex ml-[-16px] mt-[3px]">
													<CheckIcon
														onClick={() => saveNewName()}
														className='cursor-pointer top-[-5px] w-[30px] bg-[white] p-[6px] h-[30px] absolute right-[18px]'
													/>
													<CloseIcon
														className='cursor-pointer h-[13px] w-[15px] bg-[white] mt-[1px]'
														onClick={() => resetName()}
													/>
												</div>
											) : (
												<EditIcon
													className='cursor-pointer mt-[3px] w-[50px] absolute right-[-10px] z-[20] bg-[white] h-[15px]'
													onClick={() => {
														setInputNameActive(true)
														inputNameRef.current?.children[1].focus()
													}}
												/>
											))
												:
												null
										}
									/>
								</div>
							</div>
						</div>
					</div>
					<div className="bg-white rounded-xl px-[10px] h-[70px]">
						<div className="flex-col">
							<div className="flex mt-[8.5px]">
								<div className="items-center w-5 h-5">
									<Person className="w-4 h-4" />
								</div>
								<div>
									<h2 className=" leading-6 font-medium tracking-[0.15px] mt-[-2px] ml-[8px] text-[11px]">
                    CEO name
									</h2>
								</div>
							</div>
							<div className="mt-[2px] w-full h-[1px] order-1 bg-[#96CCFF]"></div>
							<div className="flex justify-between">
								<div className="mt-[-12px] w-full">
									<Input
										crossOrigin=''
										ref={inputCeoNameRef}
										tabIndex={-1}
										className='text-[14px] text-[#51606F]'
										style={{fontWeight: 500}}
										onClick={() => setInputCeoActive(true)}
										value={ceoName || ''}
										readOnly={role === 'admin' && subBusinessId === 'self' ? false : true}
										onChange={handleChangeCeoName}
										variant="static"
										onBlur={()=>{
											setTimeout(function(){setInputCeoActive(false)}, 150)
										}}
										icon={
											role === 'admin' && subBusinessId === 'self' ? (inputCeoActive ? (
												<div className="flex ml-[-16px] mt-[3px]">
													<CheckIcon
														onClick={() => saveCeoName()}
														className='cursor-pointer top-[-5px] w-[30px] bg-[white] p-[6px] h-[30px] absolute right-[18px]'
													/>
													<CloseIcon
														className='cursor-pointer h-[13px] w-[15px] bg-[white] mt-[1px]'
														onClick={() => resetCeo()}
													/>
												</div>
											) : (
												<EditIcon
													className='cursor-pointer mt-[3px] w-[50px] absolute right-[-10px] z-[20] bg-[white] h-[15px]'
													onClick={() => {
														setInputCeoActive(true)
														inputCeoNameRef.current?.children[1].focus()
													}}
												/>
											))
												:
												null
										}
									/>
								</div>
							</div>
						</div>
					</div>
					<div className="bg-white rounded-xl px-[10px] h-[70px]">
						<div className="flex-col">
							<div className="flex mt-[8.5px]">
								<div className="items-center w-5 h-5">
									<Badge className="w-4 h-4" />
								</div>
								<div>
									<h2 className=" leading-6 font-medium tracking-[0.15px] mt-[-2px] ml-[8px] text-[11px]">
                    Business account number
									</h2>
								</div>
							</div>
							<div className="mt-[2px] w-full h-[1px] order-1 bg-[#96CCFF]"></div>
							<div className="flex justify-between">
								<div className="mt-[-12px] w-full">
									<Input
										crossOrigin=''
										ref={inputAccNumberRef}
										tabIndex={-1}
										className='text-[14px] text-[#51606F]'
										style={{fontWeight: 500}}
										onClick={() => setInputAccNumberActive(true)}
										value={accNumber || ''}
										readOnly={role === 'admin' && subBusinessId === 'self' ? false : true}
										onChange={handleChangeAccNumber}
										variant="static"
										onBlur={()=>{
											setTimeout(function(){setInputAccNumberActive(false)}, 150)
										}}
										icon={
											role === 'admin' && subBusinessId === 'self' ? (accNumberActive ? (
												<div className="flex ml-[-16px] mt-[3px]">
													<CheckIcon
														onClick={() => saveAccNumber()}
														className='cursor-pointer top-[-5px] w-[30px] bg-[white] p-[6px] h-[30px] absolute right-[18px]'
													/>
													<CloseIcon
														className='cursor-pointer h-[13px] w-[15px] bg-[white] mt-[1px]'
														onClick={() => resetAccNumber()}
													/>
												</div>
											) : (
												<EditIcon
													className='cursor-pointer mt-[3px] w-[50px] absolute right-[-10px] z-[20] bg-[white] h-[15px]'
													onClick={() => {
														setInputAccNumberActive(true)
														inputAccNumberRef.current?.children[1].focus()
													}}
												/>
											))
												:
												null
										}
									/>
								</div>
							</div>
						</div>
					</div>
					<div className="bg-white rounded-xl px-[10px] h-[70px]">
						<div className="flex-col ">
							<div className="flex mt-[8.5px]">
								<div className="items-center w-5 h-5">
									<Home className="w-4 h-4" />
								</div>
								<div>
									<h2 className="leading-6 font-medium tracking-[0.15px] mt-[-2px] ml-[8px] text-[11px]">
                    Street, Nr.
									</h2>
								</div>
							</div>
							<div className="mt-[2px] w-full h-[1px] order-1 bg-[#96CCFF]"></div>
							<div className="flex justify-between">
								<div className="mt-[-12px] w-full">
									<Input
										crossOrigin=''
										ref={inputStreetRef}
										tabIndex={-1}
										className='text-[14px] text-[#51606F]'
										style={{fontWeight: 500}}
										onClick={() => setStreetActive(true)}
										value={address || ''}
										readOnly={role === 'admin' && subBusinessId === 'self' ? false : true}
										onChange={handleChangeStreet}
										variant="static"
										onBlur={()=>{
											setTimeout(function(){setStreetActive(false)}, 150) 
										}}
										icon={
											role === 'admin' && subBusinessId === 'self' ? (streetActive ? (
												<div className="flex ml-[-16px] mt-[3px]">
													<CheckIcon
														onClick={() => saveStreet()}
														className='cursor-pointer top-[-5px] w-[30px] bg-[white] p-[6px] h-[30px] absolute right-[18px]'
													/>
													<CloseIcon
														className='cursor-pointer h-[13px] w-[15px] bg-[white] mt-[1px]'
														onClick={() => resetStreet()}
													/>
												</div>
											) : (
												<EditIcon
													className='cursor-pointer mt-[3px] w-[50px] absolute right-[-10px] z-[20] bg-[white] h-[15px]'
													onClick={() => {
														setStreetActive(true)
														inputStreetRef.current?.children[1].focus()
													}}
												/>
											))
												:
												null
										}
									/>
								</div>
							</div>
						</div>
					</div>
					<div className="bg-white rounded-xl px-[10px] h-[70px]">
						<div className="flex-col">
							<div className="flex mt-[8.5px]">
								<div className="items-center w-5 h-5">
									<Package className="w-4 h-4" />
								</div>
								<div>
									<h2 className="leading-6 font-medium tracking-[0.15px] mt-[-2px] ml-[8px] text-[11px]">
                    ZIP
									</h2>
								</div>
							</div>
							<div className="mt-[2px] w-full h-[1px] order-1 bg-[#96CCFF]"></div>
							<div className="flex justify-between">
								<div className="mt-[-12px] w-full">
									<Input
										crossOrigin=''
										ref={inputZipRef}
										tabIndex={-1}
										className='text-[14px] text-[#51606F]'
										style={{fontWeight: 500}}
										onClick={() => setZipActive(true)}
										value={zip || ''}
										readOnly={role === 'admin' && subBusinessId === 'self' ? false : true}
										onChange={handleChangeZip}
										variant="static"
										onBlur={()=>{
											setTimeout(function(){setZipActive(false)}, 150)
										}}
										icon={
											role === 'admin' && subBusinessId === 'self' ? (zipActive ? (
												<div className="flex ml-[-16px] mt-[3px]">
													<CheckIcon
														onClick={() => saveZip()}
														className='cursor-pointer top-[-5px] w-[30px] bg-[white] p-[6px] h-[30px] absolute right-[18px]'
													/>
													<CloseIcon
														className='cursor-pointer h-[13px] w-[15px] bg-[white] mt-[1px]'
														onClick={() => resetZip()}
													/>
												</div>
											) : (
												<EditIcon
													className='cursor-pointer mt-[3px] w-[50px] absolute right-[-10px] z-[20] bg-[white] h-[15px]'
													onClick={() => {
														setZipActive(true)
														inputZipRef.current?.children[1].focus()
													}}
												/>
											))
												:
												null
										}
									/>
								</div>
							</div>
						</div>
					</div>
					<div className="bg-white rounded-xl px-[10px] h-[70px]">
						<div className="flex-col">
							<div className="flex mt-[8.5px]">
								<div className="items-center w-5 h-5">
									<City className="w-4 h-4" />
								</div>
								<div>
									<h2 className="leading-6 font-medium tracking-[0.15px] mt-[-2px] ml-[8px] text-[11px]">
                    Town
									</h2>
								</div>
							</div>
							<div className="mt-[2px] w-full h-[1px] order-1 bg-[#96CCFF]"></div>
							<div className="flex justify-between">
								<div className="mt-[-12px] w-full">
									<Input
										crossOrigin=''
										ref={inputTownRef}
										tabIndex={-1}
										className='text-[14px] text-[#51606F]'
										style={{fontWeight: 500}}
										onClick={() => setTownActive(true)}
										value={town || ''}
										readOnly={role === 'admin' && subBusinessId === 'self' ? false : true}
										onChange={handleChangeTown}
										variant="static"
										onBlur={()=>{
											setTimeout(function(){setTownActive(false)}, 150) 
										}}
										icon={
											role === 'admin' && subBusinessId === 'self' ? (townActive ? (
												<div className="flex ml-[-16px] mt-[3px]">
													<CheckIcon
														onClick={() => saveTown()}
														className='cursor-pointer top-[-5px] w-[30px] bg-[white] p-[6px] h-[30px] absolute right-[18px]'
													/>
													<CloseIcon
														className='cursor-pointer h-[13px] w-[15px] bg-[white] mt-[1px]'
														onClick={() => resetTown()}
													/>
												</div>
											) : (
												<EditIcon
													className='cursor-pointer mt-[3px] w-[50px] absolute right-[-10px] z-[20] bg-[white] h-[15px]'
													onClick={() => {
														setTownActive(true)
														inputTownRef.current?.children[1].focus()
													}}
												/>
											))
												:
												null
										}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{error && (
				<div className="flex justify-start mt-[10px] text-[#ff0000] text-sm">
					<AlertRed  className='mt-[1px]'/>
					<h2  className='ml-[8px]'>{error}</h2>
				</div>
			)}
			<div className="bg-white">
				<div className="bg-[#F0F0F0] p-[15px] rounded-xl mt-[20px] text-[#004368]">
					<div className={`business ag-theme-alpine ${role === 'admin' ? 'h-[calc(100vh-580px)]' : 'h-[calc(100vh-542px)]'}`}>
						<AgGridReact
							// onCellClicked={cellClickedListener}
							ref={gridRef}
							headerHeight={headerHeight}
							floatingFiltersHeight={floatingFiltersHeight}
							rowData={businessData as MeasuringPointModel[]}
							columnDefs={columnDefsTwo}
							defaultColDef={defaultColDef}
							columnTypes={columnTypes}
							groupDisplayType={'groupRows'}
							rowSelection="multiple"
							animateRows={true}
							onFirstDataRendered={onFirstDataRendered}
							groupSelectsChildren={true}
							suppressRowClickSelection={true}
							suppressAggFuncInHeader={true}
							rowHeight={rowHeight}
							icons={icons}
							components={components}
							groupDefaultExpanded={groupDefaultExpanded}
							getRowId={getRowId}
							onCellClicked={cellClicked}
							rowStyle={rowStyle}
							getRowStyle={getRowStyle}
							suppressContextMenu={true}
							getContextMenuItems={getContextMenuItems}
							pagination={true}
							paginationAutoPageSize={true}
						/>
					</div>
					<div className="w-full h-[1px] order-1 bg-white"></div>
				</div>
				{role === 'admin' &&
				<div className='flex'>
					<div
						onClick={() => {
							checkVirtualMpConflicts()
						}}
						className="ml-[-2px] mt-[15px] flex justify-center align-center px-[18px] py-[10px] rounded-3xl border-[#4D6071] border-[1px] cursor-pointer"
					>
						<MpList className="mr-[11px] mt-[2px]" />
						<h2 className="text-sm font-medium text-[#154365]">Create calculated Measuring Point</h2>
					</div>
					<div
						onClick={() => setOpenAddNotificationModal(true)}
						className="ml-[13px] mt-[15px] flex justify-center align-center px-[18px] py-[10px] rounded-3xl border-[#4D6071] border-[1px] cursor-pointer"
					>
						<Send className="mr-[11px] mt-[3px]" />
						<h2 className="text-sm font-medium text-[#154365]">Create Notification</h2>
					</div>
					{/* {hide &&
				<div
					// onClick={() => setShowAllMps()}
					className="ml-[13px] mt-[15px] flex justify-center align-center px-[18px] py-[10px] rounded-3xl border-[#4D6071] border-[1px] cursor-pointer"
				>
					<Unhide className="mr-[11px] mt-[3px]" />
					<h2 className="text-sm font-medium text-[#154365]">Show all Measuring Points</h2>
				</div>
					} */}
				</div>
				}
			</div>
			{openDeleteMpModal && (
				<DeleteMpModal
					mpData={mpData}
					setOpenDeleteMpModal={setOpenDeleteMpModal}
					openDeleteMpModal={openDeleteMpModal}
					deleteMpRef={deleteMpRef}
				/>
			)}
			{(openCreateCalculatedMpModal || signalForOpenCalculatedModal) && (
				<CalculatedMpModal
					rowData={getSelectedRowData()}
					mpData={mpData}
					setOpenCreateCalculatedMpModal={setOpenCreateCalculatedMpModal}
					openCreateCalculatedMpModal={openCreateCalculatedMpModal}
					calculatedMpRef={calculatedMpRef}
					setSignalForOpenCalculatedModal={setSignalForOpenCalculatedModal}
				/>
			)}
			{showChangingInputModal && (
				<ChangingInputModal
					setShowChangingInputModal={setShowChangingInputModal}
					showChangingInputModal={showChangingInputModal}
					changingInputRef={changingInputRef}
					resetInput={resetName}
					setInputActive={setInputNameActive}
					focusRef={inputNameRef}
				/>
			)}
			{showChangingCeoModal && (
				<ChangingInputModal
					setShowChangingInputModal={setShowChangingCeoModal}
					showChangingInputModal={showChangingCeoModal}
					changingInputRef={changingInputRef}
					resetInput={resetCeo}
					setInputActive={setInputCeoActive}
					focusRef={inputCeoNameRef}
				/>
			)}
			{showChangingAccNumberModal && (
				<ChangingInputModal
					setShowChangingInputModal={setShowChangingAccNumberModal}
					showChangingInputModal={showChangingAccNumberModal}
					changingInputRef={changingInputRef}
					resetInput={resetAccNumber}
					setInputActive={setInputAccNumberActive}
					focusRef={inputAccNumberRef}
				/>
			)}
			{showChangingStreetModal && (
				<ChangingInputModal
					setShowChangingInputModal={setShowChangingStreetModal}
					showChangingInputModal={showChangingStreetModal}
					changingInputRef={changingInputRef}
					resetInput={resetStreet}
					setInputActive={setStreetActive}
					focusRef={inputStreetRef}
				/>
			)}
			{showChangingZipModal && (
				<ChangingInputModal
					setShowChangingInputModal={setShowChangingZipModal}
					showChangingInputModal={showChangingZipModal}
					changingInputRef={changingInputRef}
					resetInput={resetZip}
					setInputActive={setZipActive}
					focusRef={inputZipRef}
				/>
			)}
			{showChangingTownModal && (
				<ChangingInputModal
					setShowChangingInputModal={setShowChangingTownModal}
					showChangingInputModal={showChangingTownModal}
					changingInputRef={changingInputRef}
					resetInput={resetTown}
					setInputActive={setTownActive}
					focusRef={inputTownRef}
				/>
			)}
			{openAddNotificationModal && (
				<AddNotificationModal
					rowData={getSelectedRowData()}
					setOpenAddNotificationModal={setOpenAddNotificationModal}
					openAddNotificationModal={openAddNotificationModal}
				/>
			)}
			{openConflictVmpMpModal && (
				<ConflictVmpModal
					rowData={businessData}
					setOpenConflictVmpMpModal={setOpenConflictVmpMpModal}
					openConflictVmpMpModal={openConflictVmpMpModal}
					conflictVmpRef={conflictVmpRef}
					setSignalForOpenCalculatedModal={setSignalForOpenCalculatedModal}
					setSignalForCancelConflictModal={setSignalForCancelConflictModal}
				/>
			)}
		</div>
	)
}
export default BusinessDataTab
