import axios from 'axios'

import AppKeycloak from '../Keycloak'
import {OneBusinessModel} from './BusinessRepository'
import SubBusinessRepository from './SubBusinessRepository'

export default class APISubBusinessRepository implements SubBusinessRepository{

	async subBusiness(rootId: string, subId: string): Promise<OneBusinessModel> {
		const settings: any = JSON.parse(localStorage.getItem('settings') || '')
		const keycloak = AppKeycloak.getAppKeycloak()

		try {
			//console.log(rootId, subId)
			const request = await axios.get(`${settings.WEBPORTAL_BACKEND_URL}/businesses/${rootId}/sub-businesses/${subId}`, {
				headers: { Authorization: `Bearer ${keycloak.token}` }
			})

			return request.data
		} catch (error) {
			console.log(error)
			return {} as any
		}
	}
	async subBusinessMeasuringPoints(rootId: string, subId: string): Promise<any> {
		const settings: any = JSON.parse(localStorage.getItem('settings') || '')
		const keycloak = AppKeycloak.getAppKeycloak()

		const response = await axios.get<any[]>(`${settings.WEBPORTAL_BACKEND_URL}/businesses/${rootId}/sub-businesses/${subId}/measuring-points`, {
			headers: { Authorization: `Bearer ${keycloak.token}` }
		})

		return response.data.map(mp => ({ ...mp, businessGroupId: subId,consumptionSpent: undefined  }))
	}

	private mpMap(data: any) {
		return data
			.map((mp: any) => {
				return {
					businessGroup: data.business,
					businessGroupId: data.businessId,
					id: mp ? mp.id : '',
					name: mp ? mp.name : '',
					consumptionForecast: mp ? mp.consumptionStatus : 0,
					//consumptionSpent: mp ? mp.consumptionSpent : 0,
					consumptionLimit: mp ? mp.consumptionLimit : 0,
					consumptionUnit: mp ? mp.consumptionUnit : '',
					siotId: mp ? mp.siotId : '',
					tags: mp ? mp.tags : [],
				}
			}).flat()
	}
}
