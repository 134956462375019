import React from 'react'
import { ReactComponent as Lock } from '../../../assets/images/icons/lock.svg'
import { MeasuringPointModel } from '../../repository/DashboardRepository'

interface Props {
  mpData: MeasuringPointModel;
}

const ProviderMessage = ({ mpData }: Props) => {
	return (
		<div>
			{mpData !== undefined && mpData.provider !== undefined && <div className='flex' >
				<div className='text-[13px] pt-[3px] pb-[1px] font-medium px-[12px] bg-[#D5E4F7] rounded-[8px] flex text-[#0E1D2A]'>
					<div className='mt-[1px]'>
						<Lock />
					</div>
					<div className='ml-[12px] mt-[1px]'>
						This Measuring Point is shared with you. The editing rights are restricted.
					</div>
				</div>
				<div className='text-[13px] font-medium px-[12px] pt-[3px] pb-[1px] bg-[#F0F0F0] rounded-[8px] flex text-[#42474E] ml-[12px] border border-[#72777F]'>
					Provider: {mpData?.provider.businessName}
				</div>
			</div>}
		</div>
	)
}

export default ProviderMessage
