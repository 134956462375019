import React, { useRef, useMemo, useCallback } from 'react'
import { ReactComponent as TocBlue } from '../../../assets/images/icons/tocB.svg'
import { ReactComponent as EventIcon } from '../../../assets/images/icons/event.svg'
import { ReactComponent as Fingerprint } from '../../../assets/images/icons/fingerprintBlue.svg'
import { ReactComponent as BalanceBlue } from '../../../assets/images/icons/balanceBlue.svg'
import { ReactComponent as History } from '../../../assets/images/icons/history.svg'
import { useAppSelector } from '../../hooks'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-alpine.css'
import 'ag-grid-enterprise'
import '../../pages/admin/ag-grid-power.css'
import { LicenseManager } from 'ag-grid-enterprise'
import { AgGridReact } from 'ag-grid-react'
import CustomHeader from '../Table/CustomHeader'
import ValidityRenderer from '../Table/ValidityRenderer'
import APIKeyIconColumn from '../Table/APIKeyIconColumn'

LicenseManager.setLicenseKey(
	'CompanyName=Appmodule AG,LicensedGroup=aliunid grid / power,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=2,AssetReference=AG-036901,SupportServicesEnd=31_January_2024_[v2]_MTcwNjY1OTIwMDAwMA==4c9d5d4b04b142bc6b50ed895e5b5ef3'
)

interface Props {
	APIKeyData: any
}

export default function APIKeyDetailsDataTab({ APIKeyData }: Props) {

	const gridRef = useRef<AgGridReact>(null)

	const { role } = useAppSelector((state) => state.app)
		
	//agGrid
	const columnDefs = [
		{
			cellRenderer: (row: any) => {
				if(role === 'admin') {
					return <APIKeyIconColumn
						data={row.data.action}
					/>
				}
			}
			,
			field: 'action',
			filter: false,
			maxWidth: role === 'admin' ? 30 : 5,
			sortable: false,
			cellClass: 'table-icon',
		},
		{
			field: 'action',
			headerName: 'Event',
			headerComponentParams: { menuIcon: 'edit_calendar' },
			cellClass: 'first-cell',
			valueFormatter: (params: any) => {
				if (params.value == 'API_KEY_CREATED') return 'Created'
				if (params.value == 'API_KEY_ROTATED') return 'Rotated'
				if (params.value == 'API_KEY_AUTHENTICATOR_TITLE_CHANGED') return 'Edited'
				if (params.value == 'API_KEY_DELETED') return 'Deleted'
				if (params.value == 'EXPIRED_API_KEYS_DELETED') return 'Expired'
				return 'Unknown'
			}
		},
		{
			field: 'timestamp',
			headerName: 'Date',
			headerComponentParams: { menuIcon: 'date' },
			valueFormatter: (params: any) => {
				const date = new Date((params.value))
				return date.getDate().toString().padStart(2, '0') + '.' + (date.getMonth() + 1).toString().padStart(2, '0') + '.' + date.getFullYear() 
			}
		},
		{
			field: 'timestamp',
			headerName: 'Time',
			headerComponentParams: { menuIcon: 'time' },
			valueFormatter: (params: any) => {
				const time = new Date(params.value)
				return time.getHours().toString().padStart(2, '0') + ':' + time.getMinutes().toString().padStart(2, '0') 
			}
		},
		{
			field: 'executor',
			headerName: 'Conducted By',
			headerComponentParams: { menuIcon: 'group' },
		},
	]

	const headerHeight = 25
	const floatingFiltersHeight = 30
	const rowHeight = 50

	const defaultColDef = useMemo(
		() => ({
			sortable: true,
			filter: 'agTextColumnFilter',
			floatingFilter: true,
			flex: 1,
			suppressMovable: true,
			wrapText: true,
			suppressMenu: true,
			floatingFilterComponentParams: {
				suppressFilterButton: true,
			},
		}),
		[]
	)

	const columnTypes = useMemo(() => {
		return {
			number: {
				aggFunc: 'count',
			},
		}
	}, [])

	const onFirstDataRendered = useCallback(() => {
		setTimeout(() => {
			document
				.querySelectorAll('.ag-theme-alpine input[class^=ag-][type=text]')
				.forEach((obj) => {
					obj.setAttribute('placeholder', 'Search')
				})
		}, 5)
		setTimeout(() => {
			document
				.querySelectorAll(
					'.ag-theme-alpine .ag-disabled input[class^=ag-][type=text]'
				)
				.forEach((obj) => {
					obj.setAttribute('placeholder', 'Select')
					obj.removeAttribute('disabled')
				})
		}, 10)
	}, [])

	const components = useMemo(() => {
		return {
			agColumnHeader: CustomHeader,
		}
	}, [])

	const getRowStyle = (params: any) => {
		if (params.data.business !== undefined) {
			return { background: '#ebebeb' }
		} else {
			return { background: 'white' }
		}
	}

	const sortedHistoryData = APIKeyData?.logs.filter((el: any) => el.executor !== 'SYSTEM').sort((valueA: any, valueB: any) => new Date(valueA.timestamp).getTime() <= new Date(valueB.timestamp).getTime() ? 1 : -1)

	const renderDate = () => {
		if (APIKeyData) {
			if (APIKeyData?.expirationDate !== undefined) {
				return new Date(APIKeyData?.expirationDate).getDate().toString().padStart(2, '0') + '.' + (new Date(APIKeyData?.expirationDate).getMonth() + 1).toString().padStart(2, '0') + '.' + new Date(APIKeyData?.expirationDate).getFullYear()
			} else {
				if (APIKeyData?.logs !== undefined && !APIKeyData?.validity) {
					const filteredLogs = APIKeyData?.logs.filter((el: any) => el.action === 'EXPIRED_API_KEYS_DELETED')
					if (filteredLogs.length !== 0) {
						return	new Date(filteredLogs[filteredLogs.length-1].timestamp).getDate().toString().padStart(2, '0') + '.' + (new Date(filteredLogs[filteredLogs.length-1].timestamp).getMonth() + 1).toString().padStart(2, '0') + '.' + new Date(filteredLogs[filteredLogs.length-1].timestamp).getFullYear()
					} else {
						return 'Does not expire'
					}
				} else {
					return 'Does not expire'
				}
			}
		} else {
			return '-'
		}
	}
		
	return (
		<div>
			<div className="bg-[#F0F0F0] p-[13px] text-[#004368] rounded-tr-xl rounded-br-xl rounded-bl-xl">
				<div className="grid grid-cols-2 gap-4  mb-[3px] ">
					<div className="bg-white rounded-xl p-[10px]">
						<div className="flex-col ">
							<div className="flex mt-[10px]">
								<div className="items-center w-5 h-5">
									<TocBlue className="w-4 h-4 text-[#004368]" />
								</div>
								<div>
									<h2 className="leading-6 font-medium tracking-[0.15px] mt-[-2px] ml-[8px] text-[11px]">
                   Title
									</h2>
								</div>
							</div>
							<div className="mt-[2px] w-full h-[1px] order-1 bg-[#96CCFF]"></div>
							<h2 className="font-medium leading-6 tracking-[0.15px] mt-[7px]">
								{APIKeyData?.title !== undefined ? APIKeyData.title : '-'}
							</h2>
						</div>
					</div>
					<div className="bg-white rounded-xl p-[10px]">
						<div className="flex-col ">
							<div className="flex mt-[10px]">
								<div className="items-center w-5 h-5">
									<Fingerprint className="w-4 h-4 text-[#004368]" />
								</div>
								<div>
									<h2 className="leading-6 font-medium tracking-[0.15px] mt-[-2px] ml-[8px] text-[11px]">
									ID
									</h2>
								</div>
							</div>
							<div className="mt-[2px] w-full h-[1px] order-1 bg-[#96CCFF]"></div>
							<h2 className="font-medium leading-6 tracking-[0.15px] mt-[7px]">
								{APIKeyData?.id !== undefined ? APIKeyData.id : '-'}
							</h2>
						</div>
					</div>
					<div className="bg-white rounded-xl p-[10px]">
						<div className="flex-col ">
							<div className="flex mt-[10px]">
								<div className="items-center w-5 h-5">
									<BalanceBlue className="w-4 h-4 text-[#004368]" />
								</div>
								<div>
									<h2 className="leading-6 font-medium tracking-[0.15px] mt-[-2px] ml-[8px] text-[11px]">
                   Validity
									</h2>
								</div>
							</div>
							<div className="mt-[2px] w-full h-[1px] order-1 bg-[#96CCFF]"></div>
							<h2 className="font-medium leading-6 tracking-[0.15px] mt-[7px]">
								{APIKeyData ? <ValidityRenderer data={APIKeyData}/> : ''}
							</h2>
						</div>
					</div>
					<div className="bg-white rounded-xl p-[10px]">
						<div className="flex-col ">
							<div className="flex mt-[10px]">
								<div className="items-center w-5 h-5">
									<EventIcon className="w-4 h-4 text-[#004368]" />
								</div>
								<div>
									<h2 className="leading-6 font-medium tracking-[0.15px] mt-[-2px] ml-[8px] text-[11px]">
									Expiration date
									</h2>
								</div>
							</div>
							<div className="mt-[2px] w-full h-[1px] order-1 bg-[#96CCFF]"></div>
							<h2 className="font-medium leading-6 tracking-[0.15px] mt-[7px]">
								{renderDate()}
							</h2>
						</div>
					</div>
					<div className="flex mt-[10px]">
						<div className="items-center w-5 h-5">
							<History className="w-4 h-4 text-[#004368]" />
						</div>
						<div>
							<h2 className="leading-6 font-medium tracking-[0.15px] mt-[-2px] ml-[5px] text-[11px]">
									History
							</h2>
						</div>
					</div>
				</div>
				<div className="mt-[10px] w-full h-[1px] bg-[#96CCFF] mb-[26px]"></div>
				<div className="bg-[#F0F0F0] rounded-xl mt-[20px]">
					<div className='vmp ag-theme-alpine h-[calc(100vh-625px)]'>
						<AgGridReact
							ref={gridRef}
							headerHeight={headerHeight}
							floatingFiltersHeight={floatingFiltersHeight}
							rowData={sortedHistoryData}
							columnDefs={columnDefs}
							defaultColDef={defaultColDef}
							columnTypes={columnTypes}
							groupDisplayType={'groupRows'}
							rowSelection="multiple"
							animateRows={true}
							onFirstDataRendered={onFirstDataRendered}
							groupSelectsChildren={true}
							suppressRowClickSelection={true}
							suppressAggFuncInHeader={true}
							rowHeight={rowHeight}
							components={components}
							pagination={true}
							paginationAutoPageSize={true}
							suppressContextMenu={true}
							getRowStyle={getRowStyle}
						//onRowSelected={onRowSelected}
						/>
						<div className="mb-[25px] w-full h-[1px] order-1 bg-white"></div>
					</div>
				</div>
			</div>
		</div>
	)
}
