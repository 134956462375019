import React, { useState } from 'react'
import { ReactComponent as Close } from '../../assets/images/icons/closeBlue.svg'

const CloseIcon = ({onClick, className}: any) => {

	const [isHovered, setValue] = useState(false)

	const icons = <div>
		<Close 
			stroke={isHovered ? '#006397' : '#DEE3EB'}
			onMouseEnter={() => setValue(true)}
			onMouseLeave={() => setValue(false)}
			onClick={onClick}
			className={className}
		/>
	</div>

	return (
		<div className='table-action-holder'>
			{icons}
		</div>
	)

}

export default CloseIcon
