import React, { useCallback, useMemo, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { ReactComponent as NotificationIcon } from '../../../../assets/images/icons/notificationsBlue.svg'
import Breadcrumb from '../../../components/Breadcrumb/Index'
import { useAppSelector } from '../../../hooks'
import { ReactComponent as Info } from '../../../../assets/images/icons/data_info_alert.svg'
import { ReactComponent as Toc } from '../../../../assets/images/icons/toc.svg'
import { ReactComponent as Event } from '../../../../assets/images/icons/event_note.svg'
import { ReactComponent as Timer } from '../../../../assets/images/icons/timer.svg'
import { ReactComponent as Mark } from '../../../../assets/images/icons/markChat.svg'
import { ReactComponent as Frame } from '../../../../assets/images/icons/forVectorBig.svg'
import { ReactComponent as Vector } from '../../../../assets/images/icons/vectorBig.svg'
import { ReactComponent as Line } from '../../../../assets/images/icons/vectorLine.svg'
import { ReactComponent as Replace } from '../../../../assets/images/icons/replaceWhite.svg'
import { ReactComponent as Delete } from '../../../../assets/images/icons/delete.svg'
import { AgGridReact } from 'ag-grid-react'
import { LicenseManager } from 'ag-grid-enterprise'
import MpCellRenderWithIcon from '../../../components/Table/MpCellRenderWithIcon'
import TagsRenderer from '../../../components/Table/TagsRenderer'
import CustomHeader from '../../../components/Table/CustomHeader'
import { dependencyContainer } from '../../../store'
import { useQuery } from '@tanstack/react-query'
import ActionButton from '../../../components/ActionButton'
import DeleteNotificationModal from '../../../components/Modals/DeleteNotificationModal'
import AddNotificationModal from '../../../components/Modals/AddNotificationModal '

LicenseManager.setLicenseKey(
	'CompanyName=Appmodule AG,LicensedGroup=aliunid grid / power,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=2,AssetReference=AG-036901,SupportServicesEnd=31_January_2024_[v2]_MTcwNjY1OTIwMDAwMA==4c9d5d4b04b142bc6b50ed895e5b5ef3'
)

const Notification = () => {
	const [openDeleteNotificationModal, setOpenDeleteNotificationModal] = useState(false)
	const [replicate, setReplicate] = useState(false)
	const [openAddNotificationModal, setOpenAddNotificationModal] = useState(false)
	const [notificationData, setNotificationData] = useState<any>()
	const [rowDataNotification, setRowDataNotification] = useState<any>([])

	const gridRef = useRef<AgGridReact>(null)
	const notificationRef = useRef(null)
	
	const { business } = useAppSelector((state) => state.app)

	const { notificationId } = useParams()

	useQuery({
		queryKey: ['notificationsData',business],
		queryFn:() =>  dependencyContainer.dependency.notificationsDataApi.notifications(business as string),
		onSuccess: (data) => {
			setNotificationData(data.filter(notification => notification.adminNotificationId === notificationId)[0])
		}
	})

	useQuery({
		queryKey: ['notificationsModal', business, notificationData],
		queryFn:() =>  dependencyContainer.dependency.dashboardApi.dashboardData(business as string),
		enabled: !!notificationData,
		onSuccess: (data) => {
			if(notificationData !== undefined){
				const filteredNotificationData = notificationData?.mpData.map((el: any)=> el.mpId)
				const filteredMpData = data.filter((mp)=> filteredNotificationData.indexOf(mp.id) > -1)
				setRowDataNotification(filteredMpData)
			}
		}
	})

	const deleteNotification = () => {
		setOpenDeleteNotificationModal(true)
	}

	const columnDefs = [
		{
			field: 'mpName',
			headerName: 'Measuring Point name',
			//minWidth: 370,
			headerComponentParams: { menuIcon: 'electric_meter' },
			cellClass: 'first-cell',
			cellRenderer: (row: any) => (
				<MpCellRenderWithIcon
					data={row.data}
					length={100}
				/>
			),
			cellStyle: function() {
				return { wordBreak: 'break-word' }
			},
		},
		{
			field: 'tags',
			filter: 'agSetColumnFilter',
			filterParams: {
				buttons: ['reset', 'apply'],
				suppressSelectAll: true,
				cellHeight: 48,
				suppressMiniFilter: true,
			},
			cellRenderer: TagsRenderer,
			headerComponentParams: { menuIcon: 'style' },
			autoHeight: true,
			wrapText: true,
			cellStyle: { justifyContent: 'center' },
		},
	]

	const headerHeight = 25
	const floatingFiltersHeight = 30
	const rowHeight = 50

	const components = useMemo(() => {
		return {
			agColumnHeader: CustomHeader,
		}
	}, [])

	const defaultColDef = useMemo(
		() => ({
			sortable: true,
			filter: 'agTextColumnFilter',
			floatingFilter: true,
			flex: 1,
			suppressMovable: true,
			wrapText: true,
			suppressMenu: true,
			floatingFilterComponentParams: {
				suppressFilterButton: true,
			},
		}),
		[]
	)

	const columnTypes = useMemo(() => {
		return {
			number: {
				aggFunc: 'count',
			},
		}
	}, [])

	const getRowStyle = (params: any) => {
		const data = params.data
		if(data === null || data === undefined) return
		if(data.provider !== null && data.provider !== undefined && data.provider.linked) {
			return { background: '#ebebeb' }
		} else {
			return { background: 'white' }
		}
	}

	const onFirstDataRendered = useCallback(() => {
		setTimeout(() => {
			document
				.querySelectorAll('.ag-theme-alpine input[class^=ag-][type=text]')
				.forEach((obj) => {
					obj.setAttribute('placeholder', 'Search')
				})
		}, 50)
		setTimeout(() => {
			document
				.querySelectorAll(
					'.ag-theme-alpine .ag-disabled input[class^=ag-][type=text]'
				)
				.forEach((obj) => {
					obj.setAttribute('placeholder', 'Select')
					obj.removeAttribute('disabled')
				})
		}, 100)
	}, [])

	const bc = [
		{
			name: 'Notifications',
			link: notificationData?.title,
			path: `/businesses/${business}/notifications`
		},
	]

	const date = notificationData?.scheduledAt ? new Date(notificationData?.scheduledAt) : '-'

	return (
		<div className="overflow-auto pl-[25px]">
			<Breadcrumb links={bc} icon={<NotificationIcon />} />
			<div className="p-[15px] bg-white w-[100%] rounded-xl mt-[23px]">
				<div className=" flex flex-row justify-between items-center w-full">
					<div className="flex items-center">
						<div className="flex items-center">
							<Info className=" text-[#004368]" />
						</div>
						<div className="pl-[10px]">
							<h2 className="text-base leading-6 font-medium tracking-[0.15px] mt-[2px] text-[#154365]">
							Notification Details
							</h2>
						</div>
					</div>
				</div>
				<div className="mt-[10px] w-full h-[1px] bg-stone-200 "></div>
				<div className="bg-[#F0F0F0] p-[15px] rounded-xl mt-[20px] text-[#51606F]">
					<div className="grid grid-cols-7 gap-4 font-medium min-h-[92px] h-auto ">
						<div className="bg-white rounded-xl px-[10px]">
							<div className="flex-col ">
								<div className="flex mt-[10px]">
									<div className="items-center w-5 h-5">
										<Toc className="w-4 h-4" />
									</div>
									<div>
										<h2 className=" leading-6 tracking-[0.15px] mt-[-2px] ml-[8px] text-[11px]">
                    Title
										</h2>
									</div>
								</div>
								<div className="mt-[2px] w-full h-[1px] order-1 bg-[#96CCFF]"></div>
								<div className="mt-[12px] w-full">
									{notificationData?.title !== undefined ? notificationData.title : '-'}
								</div>
							</div>
						</div>
						<div className="bg-white rounded-xl px-[10px]">
							<div className="flex-col ">
								<div className="flex mt-[10px]">
									<div className="items-center w-5 h-5">
										<Event className="w-4 h-4" />
									</div>
									<div>
										<h2 className=" leading-6 tracking-[0.15px] mt-[-2px] ml-[8px] text-[11px]">
                    Date
										</h2>
									</div>
								</div>
								<div className="mt-[2px] w-full h-[1px] order-1 bg-[#96CCFF]"></div>
								<div className="mt-[12px] w-full">
									{date !== '-' ? date.getDate().toString().padStart(2, '0') + '.' + (date.getMonth() + 1).toString().padStart(2, '0') + '.' + date.getFullYear() : '-'}
								</div>
							</div>
						</div>
						<div className="bg-white rounded-xl px-[10px]">
							<div className="flex-col ">
								<div className="flex mt-[10px]">
									<div className="items-center w-5 h-5">
										<Timer className="w-4 h-4" />
									</div>
									<div>
										<h2 className=" leading-6 font-medium tracking-[0.15px] mt-[-2px] ml-[8px] text-[11px]">
                    Time
										</h2>
									</div>
								</div>
								<div className="mt-[2px] w-full h-[1px] order-1 bg-[#96CCFF]"></div>
								<div className="mt-[12px] w-full">
									{date !== '-' ? date.getHours().toString().padStart(2, '0') + ':' + date.getMinutes().toString().padStart(2, '0') : '-'}
								</div>
							</div>
						</div>
						<div className="bg-white rounded-xl px-[10px] col-span-4">
							<div className="flex-col ">
								<div className="flex mt-[10px]">
									<div className="items-center w-5 h-5">
										<Mark className="w-4 h-4" />
									</div>
									<div>
										<h2 className=" leading-6 tracking-[0.15px] mt-[-2px] ml-[8px] text-[11px]">
                    Message
										</h2>
									</div>
								</div>
								<div className="mt-[2px] w-full h-[1px] order-1 bg-[#96CCFF]"></div>
								<div className="mt-[12px] w-full text-[12px] pb-[15px]">
									{notificationData?.body !== undefined ? notificationData.body : '-'}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className='pb-[18px] mt-[29px]'>
					<div className="flex">
						<div className="items-center w-5 h-5 relative">
							<Frame className="w-[18px] h-[18px]" />
							<Vector className="w-[8px] h-[8px] absolute left-[8px] bottom-[0px]" />
							<Line className="w-[10px] h-[10px] absolute left-[3px] bottom-[8px]" />
							<Line className="w-[10px] h-[10px] absolute left-[3px] bottom-[5px]" />
						</div>
						<div>
							<h2 className="text-[#154365] font-medium leading-6 tracking-[0.15px] mt-[-2px] ml-[8px] text-[11px]">
						Recipients
							</h2>
						</div>
					</div>
					<div className="mt-[2px] w-full h-[1px] order-1 bg-[#96CCFF]"></div>
				</div>
				<div className="bg-[#F0F0F0] p-[15px] rounded-xl mt-[20px]">
					<div className='vmp ag-theme-alpine h-[calc(100vh-600px)]'>
						<AgGridReact
							ref={gridRef}
							headerHeight={headerHeight}
							floatingFiltersHeight={floatingFiltersHeight}
							rowData={rowDataNotification}
							columnDefs={columnDefs}
							defaultColDef={defaultColDef}
							columnTypes={columnTypes}
							groupDisplayType={'groupRows'}
							rowSelection="multiple"
							animateRows={true}
							onFirstDataRendered={onFirstDataRendered}
							groupSelectsChildren={true}
							suppressRowClickSelection={true}
							suppressAggFuncInHeader={true}
							rowHeight={rowHeight}
							components={components}
							pagination={true}
							paginationAutoPageSize={true}
							suppressContextMenu={true}
							getRowStyle={getRowStyle}
						//onRowSelected={onRowSelected}
						/>
						<div className="mb-[25px] w-full h-[1px] order-1 bg-white"></div>
					</div>
				</div>
				<div className="mt-[30px] flex justify-end">
					<div>
						<ActionButton
							className="bg-[#154365] text-[white] font-medium py-2.5 pl-[21px] pr-[24px] rounded-full h-[40px]"
							text="Replicate"
							icon={<Replace  className='mt-[-2px]'/>}
							onClick={() => {
								setReplicate(true)
								setOpenAddNotificationModal(true)
							}}
						/>
					</div>
					<div className="ml-[20px]">
						<ActionButton
							className="bg-[white] text-[#154365] border border-[#154365] font-medium py-2.5 pl-[21px] pr-[24px] rounded-full h-[40px]"
							text="Delete"
							icon={<Delete className='mt-[-2px]'/>}
							onClick={() => deleteNotification()}
						/>
					</div>
				</div>
			</div>
			{openDeleteNotificationModal && (
				<DeleteNotificationModal
					notificationData={notificationData}
					setNotificationData={setNotificationData}
					setOpenDeleteNotificationModal={setOpenDeleteNotificationModal}
					openDeleteNotificationModal={openDeleteNotificationModal}
					notificationRef={notificationRef}
				/>
			)}
			{openAddNotificationModal && (
				<AddNotificationModal
					setOpenAddNotificationModal={setOpenAddNotificationModal}
					openAddNotificationModal={openAddNotificationModal}
					notificationData={notificationData}
					setNotificationData={setNotificationData}
					replicate={replicate}
				/>
			)}
		</div>
	)
}

export default Notification
