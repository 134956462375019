import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { ReactComponent as User } from '../../../assets/images/icons/user.svg'
import { ReactComponent as Logout } from '../../../assets/images/icons/logout.svg'
import ChangeBusiness from '../../../features/changeBusiness/ChangeBusiness'
import AppKeycloak from '../../Keycloak'
import { useAppSelector } from '../../hooks'

export interface Props {
  openSideBar: boolean;
}

export default function Header({ openSideBar }: Props) {
	const [openDropDownList, setOpenDropDownList] = useState(false)
	const [businessName, setBusinessName] = useState('')

	const { businesses } = useAppSelector((state) => state.selectBusiness)
	const { id } = useParams<any>()

	const logoutFunc = () => {
		AppKeycloak.getAppKeycloak().logout()
			.then(() => {
				console.log('LOGGED OUT')
				window.location.replace('/')
			})
			.catch((err) => {
				console.log('ERR', err)
			})
	}

	const nameOrEmail = window.localStorage.getItem('name')

	const renderNameOrEmail = () => {
		if(nameOrEmail !== undefined) {
			return nameOrEmail
		} else {
			return ''
		}
	} 
	// const renderNameOrEmail = () => {
	// 	try{
	// 		if(AppKeycloak.getAppKeycloak().parsedToken?.name !== undefined){
	// 			return AppKeycloak.getAppKeycloak().parsedToken?.name
	// 		}else{
	// 			return AppKeycloak.getAppKeycloak().parsedToken?.email
	// 		}
	// 	} catch(e) {
	// 		console.log(e)
	// 	}
	// }

	useEffect(() => {
		businesses.map((b: any) => b.id === id && setBusinessName(b.name))
	}, [businesses,id])

	return (
		<div
			className={`h-[62px] bg-[#154365] fixed " ${
				openSideBar ? 'left-[246px]' : 'left-[70px] ease-in duration-300'
			} top-[0px] right-0 z-10 shadow-[0_2px_6px_2px_rgba(0,0,0,0.15)]`}
		>
			<div className='flex justify-between'>
				<h2 className="text-[#ffffff] text-base leading-6 pt-[18px] pl-[51px] tracking-[0.15px] h-[40px]">
					{businessName? businessName : ''}
				</h2>
				<div className='flex'>
					<div className='text-[#ffffff] pt-[18px] text-base mr-[20px]'>
						{renderNameOrEmail()}
					</div>
					<div
      
						className="cursor-pointer mr-[25px] mt-[15px]"
						onMouseEnter={() => setOpenDropDownList(true)}
						onMouseLeave={() => setOpenDropDownList(false)}
					>
						{openDropDownList ? (
							<>
								<User />
								<div className="w-[170px] h-[90px] text-[#154365] text-sm absolute right-[15px] top-[5px] mt-[40px] bg-white  rounded-md shadow-sm">
									<ChangeBusiness />
									<div className=" hover:bg-[#DEE3EB] h-[45px] flex items-center pl-[10px] rounded-b-md" onClick={() => logoutFunc()}>
										<div className="flex p-[2.5]">
											<Logout />
											<h2 className="pl-[10px] pt-[5px]">Logout</h2>
										</div>
									</div>
								</div>
							</>
						) : (
							<User />
						)}
					</div>
				</div>
			</div>
			<div className="mx-[16px] pl-[10px] ">
				<div className="my-1  w-full h-[1px] order-1 bg-[#B8E9FF]"></div>
			</div>
		</div>
	)
}
