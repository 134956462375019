import React, { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { ReactComponent as Close } from '../../../assets/images/icons/close.svg'
import { ReactComponent as CloseBlack } from '../../../assets/images/icons/closeBlack.svg'
import { ReactComponent as Check } from '../../../assets/images/icons/check.svg'
import { ReactComponent as Delete } from '../../../assets/images/icons/delete.svg'
import { ReactComponent as AlertRed } from '../../../assets/images/icons/infoMessageRed.svg'
import ActionButton from '../ActionButton'
import { useParams } from 'react-router-dom'
import { dependencyContainer } from '../../store'
import { useMutation } from '@tanstack/react-query'
import {queryClient} from '../../../index'

interface Props {
  setOpenRemoveUserModal: React.Dispatch<React.SetStateAction<boolean>>;
  openRemoveUserModal: boolean;
  cancelButtonRemoveRef: any;
  userInfoData?: any;
	setUserInfoData?: any
}

const RemoveUserModal = ({
	openRemoveUserModal,
	setOpenRemoveUserModal,
	cancelButtonRemoveRef,
	userInfoData,
	setUserInfoData
}: Props) => {

	const [error, setError] = useState('')

	const { id } = useParams()

	const removeUser = useMutation({
		mutationFn: () => {
			if(userInfoData?.business === undefined){
				return dependencyContainer.dependency.usersDataApi.removeUser(id as string, userInfoData?.id)
			} else {
				return dependencyContainer.dependency.usersDataApi.deleteInvitedUser(userInfoData?.business, userInfoData?.id)
			}
		},
		onSuccess: async () => {
			setOpenRemoveUserModal(false)
			setUserInfoData()
			queryClient.invalidateQueries({ queryKey: ['usersData'] })
			queryClient.invalidateQueries({ queryKey: ['usersDataInvited'] })
		},
		onError: (error: any) => {
			setError(error.response?.data?.message ? error.response?.data?.message : 'Internal server error !')
		},
	})

	const closeModalAndResetDataState = () => {
		setOpenRemoveUserModal(false)
		setUserInfoData()
	}

	return (
		<Transition.Root show={openRemoveUserModal} as={Fragment}>
			<Dialog
				as="div"
				className="relative z-[20]"
				initialFocus={cancelButtonRemoveRef}
				onClose={()=>{
					//no action for clicking outside modal
				}}
			>
				<Transition.Child
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 bg-[#000000] bg-opacity-75 transition-opacity " />
				</Transition.Child>

				<div className="fixed inset-0 z-10 overflow-y-auto">
					<div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							enterTo="opacity-100 translate-y-0 sm:scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 translate-y-0 sm:scale-100"
							leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						>
							<Dialog.Panel
								className="relative transform overflow-hidden rounded-lg
               bg-white text-left shadow-xl transition-all sm:my-8 xl:w-[603px] lg:w-[503px]"
							>
								<div className="p-[15px]">
									<div className=" flex flex-row justify-between items-center pt-[9px]">
										<div className="flex items-center">
											<div className="flex items-center w-5 h-4 mb-[3px]">
												<Delete />
											</div>
											<div className="w-[292px] pl-[7px]">
												<h2 className="text-base font-medium tracking-[0.15px] text-[#154365]">
													{userInfoData?.business === undefined ? 'Remove user' : 'Delete invited user' }												</h2>
											</div>
										</div>
										<div
											className=""
											onClick={()=>{
												setOpenRemoveUserModal(false)
												setUserInfoData()
											}}
										>
											<Close className="text-[#4D6071] cursor-pointer" />
										</div>
									</div>
									<div className="mt-[10px] w-full h-[0.75px] order-1 bg-[#A7ACB3]"></div>
									<div className="mt-[23px] bg-[#F0F0F0] rounded-xl h-[60px] flex items-center flex-wrap pl-[13px] pr-[13px]">
										<div className="items-center text-[#154365] text-[14px] pr-[5px]">
											{userInfoData?.business === undefined ? 'Are you sure you want to remove user' : 'Are you sure you want to delete'}
										</div>
										<div className='flex'>
											<div className='items-center font-medium text-[15px] text-[#154365]'>
												{userInfoData?.email}
											</div>
											<div className='items-center text-[#154365] pr-[5px] pl-[4px]'>
											?
											</div>
										</div>
									</div>
									{error && (
										<div className="flex justify-start fixed mb-[10px] text-[#ff0000] text-sm mt-[10px]">
											<AlertRed  className='mt-[1px]'/>
											<h2  className='ml-[8px]'>{error}</h2>
										</div>
									)}
									<div className="mt-[30px] flex justify-end">
										<div ref={cancelButtonRemoveRef}>
											<ActionButton
												className="bg-[#D5E4F7] text-[#001D32] font-medium py-2.5 pl-[21px] pr-[24px] rounded-full h-[40px]"
												text="Cancel"
												icon={<CloseBlack />}
												onClick={()=>closeModalAndResetDataState()}
											/>
										</div>
										<div ref={cancelButtonRemoveRef} className="ml-[20px]">
											<ActionButton
												className="bg-[#154365] text-white font-medium py-2.5 pl-[21px] pr-[24px] rounded-full h-[40px]"
												text={userInfoData?.business ? 'Delete' : 'Remove'}
												icon={<Check />}
												onClick={() => {
													removeUser.mutate()
												}}
											/>
										</div>
									</div>
								</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition.Root>
	)
}

export default RemoveUserModal
