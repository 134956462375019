import React, { useEffect, useState } from 'react'

import { ReactComponent as ElectricMeterIcon } from '../../../assets/images/icons/electric_meter.svg'
import { ReactComponent as FlashOnIcon } from '../../../assets/images/icons/flash_on.svg'
import { ReactComponent as AppsIcon } from '../../../assets/images/icons/apps.svg'
import { ReactComponent as CalendarMonthIcon } from '../../../assets/images/icons/calendar_month.svg'
import { ReactComponent as DynamicFormIcon } from '../../../assets/images/icons/dynamic_form_white.svg'
import { ReactComponent as PercentIcon } from '../../../assets/images/icons/percent.svg'
import { ReactComponent as StyleIcon } from '../../../assets/images/icons/style.svg'
import { ReactComponent as Badge } from '../../../assets/images/icons/badge.svg'
import { ReactComponent as AvTimer } from '../../../assets/images/icons/av_timer.svg'
import { ReactComponent as MarkChatUnread1 } from '../../../assets/images/icons/mark_chat_unread1.svg'
import { ReactComponent as Group } from '../../../assets/images/icons/group1.svg'
import { ReactComponent as Mail } from '../../../assets/images/icons/mail.svg'
import { ReactComponent as Picture } from '../../../assets/images/icons/picture_in_picture.svg'
import { ReactComponent as Admin } from '../../../assets/images/icons/admin_panel_settings.svg'
import { ReactComponent as ChatBubble } from '../../../assets/images/icons/chat_bubble.svg'
import { ReactComponent as MpName } from '../../../assets/images/icons/mp_name.svg'
import { ReactComponent as Title } from '../../../assets/images/icons/title.svg'
import { ReactComponent as SortUpIcon } from '../../../assets/images/icons/sort-up.svg'
import { ReactComponent as SortDownIcon } from '../../../assets/images/icons/sort-down.svg'
import { ReactComponent as SortUpIconActive } from '../../../assets/images/icons/sort-up-active.svg'
import { ReactComponent as SortDownIconActive } from '../../../assets/images/icons/sort-down-active.svg'
import { ReactComponent as GroupAdd } from '../../../assets/images/icons/group_add_white.svg'
import { ReactComponent as Sum } from '../../../assets/images/icons/sum.svg'
import { ReactComponent as Date } from '../../../assets/images/icons/date.svg'
import { ReactComponent as Time } from '../../../assets/images/icons/time.svg'
import { ReactComponent as Message } from '../../../assets/images/icons/mark_chat_unread1.svg'
import { ReactComponent as Fingerprint } from '../../../assets/images/icons/fingerprint.svg'
import { ReactComponent as Balance } from '../../../assets/images/icons/balance.svg'
import { ReactComponent as Event } from '../../../assets/images/icons/event_white.svg'
import { ReactComponent as EditCalendar } from '../../../assets/images/icons/edit_calendar.svg'

const CustomHeader = (props: any) => {
	const mi = props.menuIcon
	let icon = null

	if (mi === 'electric_meter') {
		icon = <MpName />
	} else if (mi === 'apps') {
		icon = <AppsIcon />
	} else if (mi === 'calendar_month') {
		icon = <CalendarMonthIcon />
	} else if (mi === 'dynamic_form') {
		icon = <DynamicFormIcon />
	} else if (mi === 'percent') {
		icon = <PercentIcon />
	} else if (mi === 'style') {
		icon = <StyleIcon />
	} else if (mi === 'flash_on') {
		icon = <FlashOnIcon />
	} else if (mi === 'badge') {
		icon = <Badge />
	} else if (mi === 'avTimer') {
		icon = <AvTimer />
	} else if (mi === 'markChartUnread') {
		icon = <MarkChatUnread1 />
	} else if (mi === 'group') {
		icon = <Group />
	} else if (mi === 'mail') {
		icon = <Mail />
	} else if (mi === 'role') {
		icon = <Picture />
	} else if (mi === 'admin') {
		icon = <Admin />
	} else if (mi === 'invited') {
		icon = <GroupAdd />
	} else if (mi === 'chat_bubble') {
		icon = <ChatBubble />
	} else if (mi === 'sum') {
		icon = <Sum />
	} else if (mi === 'title') {
		icon = <Title />
	} else if (mi === 'date') {
		icon = <Date />
	} else if (mi === 'time') {
		icon = <Time />
	} else if (mi === 'message') {
		icon = <Message />
	} else if (mi === 'fingerprint') {
		icon = <Fingerprint />
	} else if (mi === 'balance') {
		icon = <Balance />
	} else if (mi === 'event') {
		icon = <Event />
	} else if (mi === 'edit_calendar') {
		icon = <EditCalendar />
	}

	const [ascSort, setAscSort] = useState('inactive')
	const [descSort, setDescSort] = useState('inactive')
	const [noSort, setNoSort] = useState('inactive')

	const onSortChanged = () => {
		setAscSort(props.column.isSortAscending() ? 'active' : 'inactive')
		setDescSort(props.column.isSortDescending() ? 'active' : 'inactive')
		setNoSort(
			!props.column.isSortAscending() && !props.column.isSortDescending()
				? 'active'
				: 'inactive'
		)
	}

	const onSortRequested = (order: any, event: any) => {
		props.setSort(order, event.shiftKey)
	}

	useEffect(() => {
		props.column.addEventListener('sortChanged', onSortChanged)
		onSortChanged()
	}, [])

	// This is for click on the whole header field
	const sortRequest = (event: any) => {
		if (props.column.isSortDescending()) {
			onSortRequested('asc', event)
		} else {
			onSortRequested('desc', event)
		}
	}

	let sort = null
	if (props.enableSorting) {
		sort = (
			<div className="sort">
				<div
					onClick={(event) => onSortRequested('desc', event)}
					onTouchEnd={(event) => onSortRequested('desc', event)}
					className={`customSortUpLabel ${descSort}`}
				>
					{descSort == 'active' && <SortUpIconActive />}
					{descSort == 'inactive' && <SortUpIcon />}
				</div>
				<div
					onClick={(event) => onSortRequested('asc', event)}
					onTouchEnd={(event) => onSortRequested('asc', event)}
					className={`customSortDownLabel ${ascSort}`}
				>
					{ascSort == 'active' && <SortDownIconActive />}
					{ascSort == 'inactive' && <SortDownIcon />}
				</div>
				<div
					onClick={(event) => onSortRequested('', event)}
					onTouchEnd={(event) => onSortRequested('', event)}
					className={`customSortRemoveLabel ${noSort}`}
				></div>
			</div>
		)
	}

	return (
		<div>
			<div
				className="customHeader"
				onClick={(event) => sortRequest(event)}
				onTouchEnd={(event) => sortRequest(event)}
			>
				<div className='mt-[0px]'>{icon}</div>
				<div>{props.displayName}</div>
			</div>
			{sort}
		</div>
	)
}

export default CustomHeader
