/* eslint-disable no-unused-vars */
import AppKeycloak from '../Keycloak'
import axios from 'axios'
import DashboardRepository, { DashboardModel } from './DashboardRepository'

export default class APIDashboardRepository implements DashboardRepository {
	constructor (private readonly keycloak: AppKeycloak, private baseUrl: string){
	}
	async dashboardData(id: string): Promise<DashboardModel[]> {	
		try {
			const data = await axios.get(
				`${this.baseUrl}/businesses/${id}/dashboard`,
				{
					headers: {
						Authorization: `Bearer ${this.keycloak.token}`,
					},
				}
			)

			return data.data.map((d: any) => this.mapToViewModel(d)).flat()
		} catch (error) {
			console.log(error)
			return []
		}
	}

	mapToViewModel = (data: any) => {
		//console.log(data)
		if (data.measuringPoints.length !== 0) {
			return data.measuringPoints
				.map((mp: any) => {
					const qoutaCalculation = mp.standing !== undefined && mp.standing.length !== 0 ? (mp?.standing[0]?.consumption.used * 100 / mp?.standing[0]?.quota.amount)?.toFixed(2) : ''
					return {
						businessGroup: data.business,
						businessGroupId: data.businessId,
						id: mp ? mp.id : '',
						name: mp ? mp.name : '',
						consumptionForecast: mp.standing.length !== 0 ? mp.standing[0]?.quota.status : 'QUOTA_NOT_SET',
						used: mp.standing.length !== 0 ? mp.standing[0]?.consumption.used : '-',
						quota: mp.standing.length !== 0 ? mp.standing[0]?.quota.amount : 'n/a',
						quotaUsed: mp.standing.length !== 0 ? qoutaCalculation : 'n/a',
						consumptionUnit: mp ? mp.consumptionUnit : '',
						siotId: mp ? mp.siotId : '',
						tags: mp ? mp.tags : [],
						provider: mp.provider ? mp.provider : null,
						virtual: mp.involvedPoints ? mp.involvedPoints : null,
						calculationFactor: mp.calculationFactor ? mp.calculationFactor : null,
						//hide: mp.hide ? mp.hide : false
						//hide: false
					}
				})
				.flat()
		} else {
			return {
				businessGroup: data.business,
				businessGroupId: data.businessId,
				id: Math.floor(Math.random() * 100000000).toString(),
				name: '',
				consumptionForecast: null,
				consumptionSpent: null,
				consumptionLimit: null,
				consumptionUnit: '',
				siotId: '',
				tags: [],
			}
		}
	}
}
