import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { BusinessModel } from '../../app/repository/BusinessesRepository'

export interface SelectBusinessState {
    businesses: BusinessModel[]
}

const initialState: SelectBusinessState = {
	businesses: []
}

export const selectBusinessSlice = createSlice({
	name: 'selectBusinessSlice',
	initialState,
	reducers: {
		businessesLoaded: (state, { payload }: PayloadAction<BusinessModel[]>) => {
			state.businesses = payload
		},
	}
})

export const { businessesLoaded } = selectBusinessSlice.actions

export default selectBusinessSlice.reducer
