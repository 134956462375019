import React, { useState, Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { ReactComponent as Close } from '../../../assets/images/icons/close.svg'
import { ReactComponent as CloseBlack } from '../../../assets/images/icons/closeBlack.svg'
import { ReactComponent as Check } from '../../../assets/images/icons/check.svg'
import { ReactComponent as Delete } from '../../../assets/images/icons/delete.svg'
import { ReactComponent as AlertRed } from '../../../assets/images/icons/infoMessageRed.svg'
import ActionButton from '../ActionButton'
import { useParams } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'
import {queryClient} from '../../../index'
import { dependencyContainer } from '../../store'

interface Props {
  setOpenDeleteAPIKeyModal: React.Dispatch<React.SetStateAction<boolean>>;
  openDeleteAPIKeyModal: boolean;
  deleteAPIKeyRef: any;
	setAPIKeyData: any;
  APIKeyData?: any;
}

const DeleteAPIKeyModal = ({
	openDeleteAPIKeyModal,
	setOpenDeleteAPIKeyModal,
	deleteAPIKeyRef,
	APIKeyData,
	setAPIKeyData
}: Props) => {

	const [error, setError] = useState('')

	const { id } = useParams()

	const deleteAPIKey = useMutation({
		mutationFn: () => dependencyContainer.dependency.apiKeysDataApi.deleteApiKey(id as string, APIKeyData?.id),
		onSuccess: async () => {
			setOpenDeleteAPIKeyModal(false)
			setAPIKeyData()
			queryClient.invalidateQueries({ queryKey: ['apiKeysData'] })
		},
		onError: (error: any) => {
			setError(error.response?.data?.message ? error.response?.data?.message : 'Internal server error !')
		},
	})

	const closeModalAndResetDataState = () => {
		setOpenDeleteAPIKeyModal(false)
		setAPIKeyData()
	}

	return (
		<Transition.Root show={openDeleteAPIKeyModal} as={Fragment}>
			<Dialog
				as="div"
				className="relative z-[20]"
				initialFocus={deleteAPIKeyRef}
				onClose={()=>{
					//no action for clicking outside modal
				}}
			>
				<Transition.Child
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 bg-[#000000] bg-opacity-75 transition-opacity " />
				</Transition.Child>

				<div className="fixed inset-0 z-10 overflow-y-auto">
					<div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							enterTo="opacity-100 translate-y-0 sm:scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 translate-y-0 sm:scale-100"
							leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						>
							<Dialog.Panel
								className="relative transform overflow-hidden rounded-lg
               bg-white text-left shadow-xl transition-all sm:my-8 xl:w-[603px] lg:w-[503px]"
							>
								<div className="p-[15px]">
									<div className=" flex flex-row justify-between items-center pt-[9px]">
										<div className="flex items-center">
											<div className="flex items-center w-5 h-4 mb-[3px]">
												<Delete />
											</div>
											<div className=" pl-[7px]">
												<h2 className="text-base font-medium tracking-[0.15px] text-[#154365]">	
													Are you sure you want to delete this API key?										
												</h2>
											</div>
										</div>
										<div
											className=""
											onClick={()=>{
												setOpenDeleteAPIKeyModal(false)
												setAPIKeyData()
											}}
										>
											<Close className="text-[#4D6071] cursor-pointer" />
										</div>
									</div>
									<div className="mt-[10px] w-full h-[0.75px] order-1 bg-[#A7ACB3]"></div>
									<div className="mt-[23px] bg-[#F0F0F0] rounded-xl flex items-center flex-wrap p-[13px]">
										<div className="items-center text-[#154365] text-[14px] flex flex-wrap">
											<p className="pb-[20px]">Warning: This action will delete the API key <span className='font-medium'>{APIKeyData?.title}</span> and the associated data permanently and cannot be undone.</p>
											<p>Your connected applications or systems using this API key will stop having access to aliunid OPERATOR.</p>
										</div>
									</div>
									{error && (
										<div className="flex justify-start fixed mt-[10px] text-[#ff0000] text-sm">
											<AlertRed  className='mt-[1px]'/>
											<h2  className='ml-[8px]'>{error}</h2>
										</div>
									)}
									<div className="mt-[30px] flex justify-end">
										<div ref={deleteAPIKeyRef}>
											<ActionButton
												className="bg-[#D5E4F7] text-[#001D32] font-medium py-2.5 pl-[21px] pr-[24px] rounded-full h-[40px]"
												text="No"
												icon={<CloseBlack />}
												onClick={()=>closeModalAndResetDataState()}
											/>
										</div>
										<div ref={deleteAPIKeyRef} className="ml-[20px]">
											<ActionButton
												className="bg-[#154365] text-white font-medium py-2.5 pl-[21px] pr-[24px] rounded-full h-[40px]"
												text="Yes"
												icon={<Check />}
												onClick={() => {
													deleteAPIKey.mutate()
												}}
											/>
										</div>
									</div>
								</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition.Root>
	)
}

export default DeleteAPIKeyModal
