import React from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'

import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { KeycloakStore } from '../../app/Keycloak'
import { loadAppCommand } from './appLoaderCommands'

// function getBusiness(location: Location) {
// 	console.log(location)
// }

interface Props {
  keycloakStore: KeycloakStore;
}

export default function AppLoader({ keycloakStore }: Props) {
	const dispatch = useAppDispatch()
	const location = useLocation()

	const { business } = useAppSelector((state) => state.app)

	const { keycloak, authenticated } = keycloakStore

	React.useEffect(() => {
		if (keycloakStore.keycloak === null) return
		if (!keycloakStore.authenticated) return

		const parts = location.pathname
			.split('/businesses')
			.filter((p) => p !== '')
			.filter((p) => p !== '/')

		if (parts.length >= 1) {
			const businessId = parts[0].substring(1, parts[0].length).split('/')[0]
			if (business !== businessId) dispatch(loadAppCommand(businessId))
		}
	}, [location, keycloakStore])

	if (keycloak !== null) {
		if (authenticated) return <Outlet /> // successful authenticated
		else return <Navigate to="/" /> // no authenticated
	}
	return <p>LOADING....</p>
}
