import { businessUnloaded } from '../../app/appSlice'
import { Command } from '../../app/store'

export const changeBusinessCommand = (): Command => async (dispatch, getState, { dependency }) => {
	try {
		dispatch(businessUnloaded())
	} catch (err) {
		console.log(err)
	}
}
