/* eslint-disable no-useless-catch */
import Keycloak, { KeycloakTokenParsed } from 'keycloak-js'

export interface KeycloakStore {
	keycloak: Keycloak | null
	authenticated: boolean
}

export default class AppKeycloak {

	private static INSTANCE: AppKeycloak | null = null

	private readonly keycloak: Keycloak
	private _store: KeycloakStore | null = null
	private authenticated = false
	private isStarted = false

	private constructor(configPath: string) {
		this.keycloak = new Keycloak(configPath)
	}

	static createAppKeycloak(configPath: string): AppKeycloak {
		if (this.INSTANCE === null)
			this.INSTANCE = new AppKeycloak(configPath)
		return this.INSTANCE
	}
	static getAppKeycloak(): AppKeycloak {
		if (this.INSTANCE === null)
			throw new Error('Keycloak is not created!')
		return this.INSTANCE
	}

	async start() {
		try {

			if (this.isStarted) return
			this.isStarted = true
			const oldToken = window.localStorage.getItem('token')
			// console.log("OLD", oldToken)

			const kcLogin = this.keycloak.login;
			(this.keycloak as any).login = (options: any) => {
				options.idpHint = 'keycloak-oidc-aliunid-identity'
				kcLogin(options)
			}

			this.authenticated = await this.keycloak.init({
				onLoad: 'login-required',
				// onLoad: "check-sso",
				// silentCheckSsoRedirectUri: window.location.origin + 'public/silent-check-sso.html',
				checkLoginIframe: false,
				token: oldToken !== null ? oldToken : undefined
			})
			// this.keycloak.login({ idpHint: "keycloak-oidc-aliunid-identity" })

			if (this.keycloak.token !== undefined){
				window.localStorage.setItem('token', this.keycloak.token)
				window.localStorage.setItem('name', this.keycloak.tokenParsed?.name !== undefined ? this.keycloak.tokenParsed.name : this.keycloak.tokenParsed?.email)
				window.localStorage.setItem('admin-email', this.keycloak?.tokenParsed?.email)

			}
			// console.log("NEW", this.keycloak.token)

			if (!this.isTokenDefined()) this.logout() //throw new Error('Token is not defined')
			this._store = {
				keycloak: this.keycloak,
				authenticated: this.authenticated
			}
			setInterval(async () => {
				// console.log("UPDATE WILL PREFORM", new Date().toLocaleString())
				await this.updateToken()
			}, (this.tokenDuration - 10) * 1000)

		} catch (error) {
			throw error
		}
	}
	store(): KeycloakStore {
		if (this._store === null) throw new Error('KeycloakStore is null')
		return this._store
	}
	async refreshToken(): Promise<boolean> {
		try {
			// console.log('OLD TOKEN', this.keycloak.token)
			const isRefreshed = await this.keycloak.updateToken(-1)
			// console.log('Token refreshed?', r)
			// console.log('NEW TOKEN', this.keycloak.token)
			if (this.keycloak.token === undefined)
				throw new Error('Token is undefined')
			window.localStorage.setItem('token', this.keycloak.token)
			return isRefreshed
		} catch (error) {
			throw error
		}
	}
	async logout() {
		await this.keycloak.logout()
	}
	get parsedToken(): KeycloakTokenParsed {
		if (!this.isTokenDefined()) this.logout() //throw new Error('Token is not defined')
		return this.keycloak.tokenParsed as KeycloakTokenParsed
	}

	get token(): string {
		if (!this.isTokenDefined()) this.logout() // throw new Error('Token is not defined')
		return this.keycloak.token as string
	}

	private async updateToken() {
		try {
			await this.keycloak.updateToken(50)
		} catch (error) {
			throw new Error('Failed to refresh the token, or the session has expired')
		}
	}
	private get tokenDuration() {
		const { tokenParsed } = this.keycloak

		const { iat, exp } = tokenParsed as KeycloakTokenParsed
		return Math.abs((iat as number) - (exp as number))
	}
	private isTokenDefined() {
		const { token, tokenParsed } = this.keycloak
		return tokenParsed !== undefined || token !== undefined
	}
}
