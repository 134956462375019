import React, { useState, Fragment, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { Input, Textarea } from '@material-tailwind/react'
import { ReactComponent as AddBusiness } from '../../../assets/images/icons/add_business.svg'
import { ReactComponent as Edit } from '../../../assets/images/icons/edit_blue.svg'
import { ReactComponent as CloseBlack } from '../../../assets/images/icons/closeBlack.svg'
import { ReactComponent as Close } from '../../../assets/images/icons/close.svg'
import { ReactComponent as MoreTime } from '../../../assets/images/icons/more_time.svg'
import { ReactComponent as Check } from '../../../assets/images/icons/check.svg'
import { ReactComponent as Calendar } from '../../../assets/images/icons/calendar_month1.svg'
import { ReactComponent as Calculate } from '../../../assets/images/icons/calculate.svg'
import { ReactComponent as CloseGray } from '../../../assets/images/icons/closeGray.svg'
import { ReactComponent as AlertRed } from '../../../assets/images/icons/infoMessageRed.svg'
import ActionButton from '../ActionButton'
import { useParams } from 'react-router-dom'
import { dependencyContainer } from '../../store'
import { useMutation } from '@tanstack/react-query'
import { queryClient } from '../../../index'
import { Select, Option } from '@material-tailwind/react'

interface Props {
	setOpenAddQuotaModal: React.Dispatch<React.SetStateAction<boolean>>;
	openAddQuotaModal: boolean;
	quotaModalRef: React.MutableRefObject<null>;
	quotaInfoData?: any;
	setQuotaInfoData?: any;
	quotasData?: any
}

const months = [
	{
		id: 0,
		name: 'January'
	},
	{
		id: 1,
		name: 'February'
	},
	{
		id: 2,
		name: 'March'
	},
	{
		id: 3,
		name: 'April'
	},
	{
		id: 4,
		name: 'May'
	},
	{
		id: 5,
		name: 'June'
	},
	{
		id: 6,
		name: 'July'
	},
	{
		id: 7,
		name: 'August'
	},
	{
		id: 8,
		name: 'September'
	},
	{
		id: 9,
		name: 'October'
	},
	{
		id: 10,
		name: 'November'
	},
	{
		id: 11,
		name: 'December'
	}
]
const monthsReference = [
	{
		id: 0,
		name: 'January'
	},
	{
		id: 1,
		name: 'February'
	},
	{
		id: 2,
		name: 'March'
	},
	{
		id: 3,
		name: 'April'
	},
	{
		id: 4,
		name: 'May'
	},
	{
		id: 5,
		name: 'June'
	},
	{
		id: 6,
		name: 'July'
	},
	{
		id: 7,
		name: 'August'
	},
	{
		id: 8,
		name: 'September'
	},
	{
		id: 9,
		name: 'October'
	},
	{
		id: 10,
		name: 'November'
	},
	{
		id: 11,
		name: 'December'
	}
]

const currentYear = new Date().getFullYear()

const years = [
	{
		id: 1,
		name: JSON.stringify(currentYear)
	},
	{
		id: 2,
		name: JSON.stringify(currentYear + 1)
	}
]

const yearsReference = [
	{
		id: 1,
		name: JSON.stringify(currentYear -4)
	},
	{
		id: 2,
		name: JSON.stringify(currentYear -3)
	},
	{
		id: 3,
		name: JSON.stringify(currentYear -2)
	},
	{
		id: 4,
		name: JSON.stringify(currentYear -1)
	},
	{
		id: 5,
		name: JSON.stringify(currentYear)
	}
]

const monthsToNumbers = (month: string) => {
	if (month === 'January') return 0
	if (month === 'February') return 1
	if (month === 'March') return 2
	if (month === 'April') return 3
	if (month === 'May') return 4
	if (month === 'June') return 5
	if (month === 'July') return 6
	if (month === 'August') return 7
	if (month === 'September') return 8
	if (month === 'October') return 9
	if (month === 'November') return 10
	else return 11
}

const AddQuotaModal = ({
	setOpenAddQuotaModal,
	openAddQuotaModal,
	quotaModalRef,
	quotaInfoData,
	setQuotaInfoData,
	quotasData
}: Props) => {
	
	const splited = quotaInfoData ? quotaInfoData.month.split(' ') : null
	const splitedReference = quotaInfoData ? quotaInfoData.referencePeriod.split(' ') : null

	const [month, setMonth] = useState(quotaInfoData ? splited[0] : '')
	const [year, setYear] = useState(quotaInfoData ? splited[1] : '')
	const [monthReference, setMonthReference] = useState(quotaInfoData ? splitedReference[0] : '')
	const [yearReference, setYearReference] = useState(quotaInfoData ? splitedReference[1] : '')

	const [referenceAmount, setReferenceAmount] = useState<any>(quotaInfoData ? quotaInfoData.referenceAmount : null)
	const [quotaRate, setQuotaRate] = useState<any>(quotaInfoData ? quotaInfoData.quotaRate : null)
	const [quota, setQuota] = useState<any>(quotaInfoData ? quotaInfoData.quota : null)

	const [message, setMessage] = useState(quotaInfoData ? quotaInfoData.comment : '')

	const [error, setError] = useState('')
	const [errorReference, setErrorReference] = useState('')
	const [errorAmount, setErrorAmount] = useState('')
	const [errorCurrent, setErrorCurrent] = useState('')
	const [counterMessage, setCounterMessage] = useState(0)

	const { id, mpId } = useParams<any>()

	const [quotaFlag, setQuotaFlag] = useState(false)
	const [quotaRateFlag, setQuotaRateFlag] = useState(false)
	const [referenceAmountFlag, setReferenceAmountFlag] = useState(false)

	const handleChangeReferenceAmount = (event: React.ChangeEvent<HTMLInputElement>) => {
		setErrorAmount('')
		const value = parseInt(event.target.value)
		setReferenceAmount(value)
		if (quotaFlag) {
			setQuota(calclateQuota(value, quotaRate))
			return
		}
		if (quotaRateFlag) {
			setQuotaRate(calclateQuotaRate(quota, value))
			return
		}
		if ((quota === null || isNaN(quota)) && (quotaRate === null || isNaN(quotaRate)))
			return
		if (quota === null || isNaN(quota)) {
			setQuota(calclateQuota(value, quotaRate))
			setQuotaFlag(true)
			return
		}
		if (quotaRate === null || isNaN(quotaRate)) {
			setQuotaRate(calclateQuotaRate(quota, value))
			setQuotaRateFlag(true)
			return
		}
		setQuota(calclateQuota(value, quotaRate))
	}

	const handleChangeQuotaRate = (event: React.ChangeEvent<HTMLInputElement>) => {
		setErrorAmount('')
		const value = parseInt(event.target.value)
		setQuotaRate(value)
		if (referenceAmountFlag) {
			setReferenceAmount(calclateReferenceAmount(value, quota))
			return
		}
		if (quotaFlag) {
			setQuota(calclateQuota(referenceAmount, value))
			return
		}
		if ((quota === null || isNaN(quota)) && (referenceAmount === null || isNaN(referenceAmount)))
			return
		if (quota === null || isNaN(quota)) {
			setQuota(calclateQuota(referenceAmount, value))
			setQuotaFlag(true)
			return
		}
		if (referenceAmount === null || isNaN(referenceAmount)) {
			setReferenceAmount(calclateReferenceAmount(value, quota))
			setReferenceAmountFlag(true)
			return
		}
		setQuota(calclateQuota(referenceAmount, value))
	}

	const handleChangeQuota = (event: React.ChangeEvent<HTMLInputElement>) => {
		setErrorAmount('')
		const value = parseInt(event.target.value)
		setQuota(value)
		if (referenceAmountFlag) {
			setReferenceAmount(calclateReferenceAmount(quotaRate, value))
			return
		}
		if (quotaRateFlag) {
			setQuotaRate(calclateQuotaRate(value, referenceAmount))
			return
		}
		if ((quotaRate === null || isNaN(quotaRate)) && (referenceAmount === null || isNaN(referenceAmount)))
			return
		if (quotaRate === null || isNaN(quotaRate)) {
			setQuotaRate(calclateQuotaRate(value, referenceAmount))
			setQuotaRateFlag(true)
			return
		}
		if (referenceAmount === null || isNaN(referenceAmount)) {
			setReferenceAmount(calclateReferenceAmount(quotaRate, value))
			setReferenceAmountFlag(true)
			return
		}
		setQuotaRate(calclateQuotaRate(value, referenceAmount))
	}

	const calclateQuota = (referenceAmount: number, quotaRate: number): any => {
		const result = quotaRate / 100 * referenceAmount
		if(result === Math.round(result)) {
			return isNaN(result) ? null : result.toFixed(0)
		} else {
			return isNaN(result) ? null : result.toFixed(2)
		}
		
	}
	const calclateQuotaRate = (quota: number, referenceAmount: number): any => {
		if (referenceAmount === 0) return 0
		const result = (quota / referenceAmount) * 100
		if(result === Math.round(result)) {
			return isNaN(result) ? null : result.toFixed(0)
		} else {
			return isNaN(result) ? null : result.toFixed(2)
		}
	}
	const calclateReferenceAmount = (quotaRate: number, quota: number): any => {
		if (quotaRate === 0) return 0
		const result = quota * 100 / quotaRate
		if(result === Math.round(result)) {
			return isNaN(result) ? null : result.toFixed(0)
		} else {
			return isNaN(result) ? null : result.toFixed(2)
		}
	}

	const handleChangeMessage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setMessage(event.target.value)
		setCounterMessage(event.target.value.length)
	}

	useEffect(() => {
		if(quotaInfoData !== undefined){
			setCounterMessage(quotaInfoData?.comment.length)
		}
	}, [quotaInfoData])

	const setMonthOnClick = (month: any) => {
		setMonth(month)
		setErrorCurrent('')
		setError('')
	}

	const setYearOnClick = (year: any) => {
		setYear(year)
		setErrorCurrent('')
	}

	const setMonthReferenceOnClick = (month: any) => {
		setMonthReference(month)
		setErrorReference('')
	}

	const setYearReferenceOnClick = (year: any) => {
		setYearReference(year)
		setErrorReference('')
	}

	const closeModalAndResetDataState = () => {
		setOpenAddQuotaModal(false)
		setQuotaInfoData()
	}

	const createTimestamp = (year: number, month: number, day: number, hour: number, min: number, sec: number) => {
		const d = new Date(year, month, day, hour, min, sec)
		//console.log(hour)
		// convert to msec
		// subtract local time zone offset
		// get UTC time in msec
		const utc = d.getTime() + (d.getTimezoneOffset() * 60000)
		//console.log(d.getTimezoneOffset(), d)
		// create new Date object for different city
		// using supplied offset
		let offset = 1
		if (month >= 3 && month <= 9) offset = 2
		//console.log(offset)

		const nd = (new Date(utc + (3600000 * (offset)))).getTime()

		// return time as a string
		return nd
	}

	const createQuota = useMutation({
		mutationFn: () => dependencyContainer.dependency.quotasDataApi.createQuota(
			id as string,
			month + ' ' + year,
			message,
			mpId,
			parseFloat(quota),
			createTimestamp(parseInt(year), monthsToNumbers(month), 1, 0, 0, 0),
			createTimestamp(parseInt(year), monthsToNumbers(month) + 1, 0, 23, 59, 59),
			createTimestamp(parseInt(yearReference), monthsToNumbers(monthReference), 1, 0, 0, 0),
			createTimestamp(parseInt(yearReference), monthsToNumbers(monthReference) + 1, 0, 23, 59, 59),
			parseFloat(referenceAmount)
		),
		onSuccess: async () => {
			setOpenAddQuotaModal(false)
			queryClient.invalidateQueries({ queryKey: ['quotasData'] })
		},
		onError: (error: any) => {
			setError(error.response?.data?.message ? error.response?.data?.message : 'Internal server error !')
		},
	})

	const changeQuota = useMutation({
		mutationFn: () => dependencyContainer.dependency.quotasDataApi.changeQuota(
			id as string,
			quotaInfoData?.id,
			month + ' ' + year,
			message,
			parseFloat(quota),
			createTimestamp(parseInt(year), monthsToNumbers(month), 1, 0, 0, 0),
			createTimestamp(parseInt(year), monthsToNumbers(month) + 1, 0, 23, 59, 59),
			createTimestamp(parseInt(yearReference), monthsToNumbers(monthReference), 1, 0, 0, 0),
			createTimestamp(parseInt(yearReference), monthsToNumbers(monthReference) + 1, 0, 23, 59, 59),
			parseFloat(referenceAmount)
		),
		onSuccess: async () => {
			setOpenAddQuotaModal(false)
			setQuotaInfoData()
			queryClient.invalidateQueries({ queryKey: ['quotasData'] })
		},
		onError: (error: any) => {
			setError(error.response?.data?.message ? error.response?.data?.message : 'Internal server error !')
		},
	})

	const checkQuotaExist = () => {
		const monthYear = month + ' ' + year

		const filtered = quotasData.filter((quota: any) => quota.month === monthYear)
		if (filtered.length > 0) {
			return true
		} else {
			return false
		}
	}

	const checkEditQuotaExist = () => {
		const monthYear = month + ' ' + year

		const filtered = quotasData.filter((quota: any) => quota.month === monthYear)

		if (filtered.length > 0) {
			if (filtered[0].id === quotaInfoData?.id) {
				return false
			} else {
				return true
			}
		} else {
			return false
		}
	}

	const submitForm = async () => {
		if (month === '') {
			setErrorCurrent('Month is required !')
		}
		else if (year === '') {
			setErrorCurrent('Year is required !')
		}
		else if (monthReference === '') {
			setErrorReference('Reference month is required !')
		}
		else if (yearReference === '') {
			setErrorReference('Reference year is required !')
		} else if (referenceAmount === null || isNaN(referenceAmount)) {
			setErrorAmount('Reference amount is required !')
		}
		else if (quota === null || isNaN(quota)) {
			setErrorAmount('Quota is required !')
		}
		else {
			if (quotaInfoData) {
				if (checkEditQuotaExist()) {
					setError('Quota already exists !')
				} else {
					changeQuota.mutate()
				}
			} else {
				if (checkQuotaExist()) {
					setError('Quota already exists !')
				} else {
					createQuota.mutate()
				}
			}
		}
	}

	return (
		<Transition.Root show={openAddQuotaModal} as={Fragment}>
			<Dialog
				as="div"
				className="relative z-[20]"
				initialFocus={quotaModalRef}
				onClose={()=>{
					//no action for clicking outside modal
				}}
			>
				<Transition.Child
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 bg-[#000000] bg-opacity-75 transition-opacity " />
				</Transition.Child>

				<div className="fixed inset-0 z-10 overflow-y-auto">
					<div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							enterTo="opacity-100 translate-y-0 sm:scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 translate-y-0 sm:scale-100"
							leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						>
							<Dialog.Panel className="relative transform overflow-hidden rounded-lg
               bg-white text-left shadow-xl transition-all sm:my-8 xl:w-[627px] lg:w-[540px] sm:w-[525px]">
								<div className="p-[15px]">
									<div className=" flex flex-row justify-between items-center pt-[9px]">
										<div className="flex items-center">
											<div className="flex items-center w-5 h-5">
												{quotaInfoData ? <Edit /> : <AddBusiness />}
											</div>
											<div className="w-[292px] pl-[7px]">
												<h2 className="text-base font-medium tracking-[0.15px] text-[#154365]">
													{quotaInfoData ? 'Edit Quota' : 'Add new Quota'}
												</h2>
											</div>
										</div>
										<div
											className=""
											onClick={() => closeModalAndResetDataState()}
										>
											<Close className="text-[#4D6071] cursor-pointer" />
										</div>
									</div>
									<div className="mt-[10px] w-full h-[0.75px] order-1 bg-[#A7ACB3]"></div>
									<div className="mt-[33px] bg-[#F0F0F0] rounded-xl">
										<div className="p-[13px]">
											<div>
												<div className='pb-[18px]'>
													<div className="flex">
														<div className="items-center w-5 h-5">
															<Calendar className="w-[18px] h-[18px]" />
														</div>
														<div>
															<h2 className="leading-6 font-medium text-[#154365] tracking-[0.15px] mt-[-2px] ml-[8px] text-[11px]">
																Month to be contingent
															</h2>
														</div>
													</div>
													<div className="mt-[2px] w-full h-[1px] order-1 bg-[#96CCFF]"></div>
												</div>
												<div className='flex gap-x-4 mb-[13px]'>
													<div className='w-full selectDiv'>
														<Select 
															placeholder=''
															label="Month"
															size='lg'
															value={month}
															onChange={setMonthOnClick}
															style={{fontSize: '16px'}}
															animate={{
																mount: { y: 0 },
																unmount: { y: 15 },
															}}
														>
															{months?.map((month: any) => (
																<Option
																	key={month?.id}
																	className="text-sm text-[#001F24] tracking-[0.5px] hover:bg-[#004368] hover:bg-opacity-[0.12] cursor-pointer h-[32px] py-[6px] pl-[16px] "
																	value={month?.name}
																>
																	{month?.name}
																</Option>
															))}
														</Select>
													</div>
													<div className='w-full selectDivAutoHeight'>
														<Select 
															placeholder=''
															label="Year"
															size='lg'
															value={year}
															onChange={setYearOnClick}
															style={{fontSize: '16px'}}
															animate={{
																mount: { y: 0 },
																unmount: { y: 15 },
															}}
														>
															{years?.map((year: any) => (
																<Option
																	key={year?.id}
																	className="text-sm text-[#001F24] tracking-[0.5px] hover:bg-[#004368] hover:bg-opacity-[0.12] cursor-pointer h-[32px] py-[6px] pl-[16px] "
																	value={year?.name}
																>
																	{year?.name}
																</Option>
															))}
														</Select>
													</div>
												</div>
												{errorCurrent && (
													<div className="flex justify-start mb-[10px] text-[#ff0000] text-sm mt-[-5px]">
														<AlertRed  className='mt-[1px]'/>
														<h2 className='ml-[8px]'>{errorCurrent}</h2>
													</div>
												)}
											</div>
											<div>
												<div className='pb-[18px]'>
													<div className="flex">
														<div className="items-center w-5 h-5">
															<MoreTime className="w-[18px] h-[18px]" />
														</div>
														<div>
															<h2 className="leading-6 font-medium text-[#154365] tracking-[0.15px] mt-[-2px] ml-[8px] text-[11px]">
																Reference period
															</h2>
														</div>
													</div>
													<div className="mt-[2px] w-full h-[1px] order-1 bg-[#96CCFF]"></div>
												</div>
												<div className='flex gap-x-4 mb-[13px]'>
													<div className='w-full selectDiv'>
														<Select 
															placeholder=''
															label="Month"
															size='lg'
															value={monthReference}
															onChange={setMonthReferenceOnClick}
															style={{fontSize: '16px'}}
															animate={{
																mount: { y: 0 },
																unmount: { y: 15 },
															}}
														>
															{monthsReference?.map((month: any) => (
																<Option
																	key={month?.id}
																	className="text-sm text-[#001F24] tracking-[0.5px] hover:bg-[#004368] hover:bg-opacity-[0.12] cursor-pointer h-[32px] py-[6px] pl-[16px] "
																	value={month?.name}
																>
																	{month?.name}
																</Option>
															))}
														</Select>
													</div>
													<div className='w-full selectDivAutoHeight'>
														<Select 
															placeholder=''
															label="Year"
															size='lg'
															value={yearReference}
															onChange={setYearReferenceOnClick}
															style={{fontSize: '16px'}}
															animate={{
																mount: { y: 0 },
																unmount: { y: 15 },
															}}
														>
															{yearsReference?.map((year: any) => (
																<Option
																	key={year?.id}
																	className="text-sm text-[#001F24] tracking-[0.5px] hover:bg-[#004368] hover:bg-opacity-[0.12] cursor-pointer h-[32px] py-[6px] pl-[16px] "
																	value={year?.name}
																>
																	{year?.name}
																</Option>
															))}
														</Select>
													</div>
												</div>
												{errorReference && (
													<div className="flex justify-start mb-[10px] text-[#ff0000] text-sm mt-[-5px]">
														<AlertRed  className='mt-[1px]'/>
														<h2 className='ml-[8px]'>{errorReference}</h2>
													</div>
												)}
											</div>
											<div className='pb-[18px]'>
												<div className="flex">
													<div className="items-center w-5 h-5">
														<Calculate className="w-[18px] h-[18px]" />
													</div>
													<div>
														<h2 className="leading-6 font-medium text-[#154365] tracking-[0.15px] mt-[-2px] ml-[8px] text-[11px]">
															Calculation
														</h2>
													</div>
												</div>
												<div className="mt-[2px] w-full h-[1px] order-1 bg-[#96CCFF]"></div>
											</div>
											<div className='flex gap-x-3'>
												<div className=" mb-[20px]" id='1'>
													<Input
														crossOrigin=''
														//ref={referenceAmountRef}
														type={'text'}
														color={errorAmount ? 'red' : 'blue'}
														size="lg"
														id='1'
														value={referenceAmount || ''}
														label="Reference amount"
														onChange={handleChangeReferenceAmount}
														onBlur={() => {
															setQuotaFlag(false)
															setQuotaRateFlag(false)
														}}
														style={{width: '200px'}}
														icon={
															<div className='w-[45px] h-[40px] bg-white absolute right-[-10px] rounded-r-[3px] bottom-[-8px]'>
																<div className='absolute left-[14px] top-[12px] text-[10px]'>
																	kWh
																</div>
															</div>
														}
													/>
												</div>
												<div className='mt-[15px]'>
													<CloseGray />
												</div>
												<div className="relative mb-[20px]" id='2'>
													<Input
														crossOrigin=''
														//ref={quotaRateRef}
														type={'text'}
														color="blue"
														size="lg"
														value={quotaRate || ''}
														label="Quota rate"
														onChange={handleChangeQuotaRate}
														onBlur={() => {
															setQuotaFlag(false)
															setReferenceAmountFlag(false)
														}}
														className="text-base"
														icon={
															<div className='w-[45px] h-[40px] bg-white absolute right-[-10px] rounded-r-[3px] bottom-[-8px]'>
																<div className='absolute left-[17px] top-[10px] text-[15px]'>
																	%
																</div>
															</div>
														}
													/>
												</div>
												<div className='mt-[8px] text-[#72777F] text-[18px]'>
													=
												</div>
												<div className="relative mb-[20px]" id='3'>
													<Input
														crossOrigin=''
														//ref={quotaRef}
														type={'text'}
														color={errorAmount ? 'red' : 'blue'}
														size="lg"
														value={quota || ''}
														label="Quota"
														onChange={handleChangeQuota}
														onBlur={() => {
															setQuotaRateFlag(false)
															setReferenceAmountFlag(false)
														}}
														className="text-base"
														icon={
															<div className='w-[45px] h-[40px] bg-white absolute right-[-10px] rounded-r-[3px] bottom-[-8px]'>
																<div className='absolute left-[14px] top-[12px] text-[10px]'>
																	kWh
																</div>
															</div>
														}
													/>
												</div>
											</div>
											{errorAmount && (
												<div className="flex justify-start mb-[10px] text-[#ff0000] text-sm mt-[-10px]">
													<AlertRed  className='mt-[1px]'/>
													<h2 className='ml-[8px]'>{errorAmount}</h2>
												</div>
											)}
											<div className="">
												<Textarea
													value={message}
													label={`Comment ${counterMessage}/250`}
													onChange={handleChangeMessage as React.ChangeEventHandler<HTMLTextAreaElement> | any}
													className="text-base "
													maxLength={250}
												/>
											</div>
										</div>
									</div>
									{error && (
										<div className="flex justify-start fixed mb-[10px] text-[#ff0000] text-sm mt-[10px]">
											<AlertRed  className='ml-[14px] mt-[1px]'/>
											<h2 className='ml-[8px]'>{error}</h2>
										</div>
									)}
									<div className="mt-[30px] flex justify-end">
										<div>
											<ActionButton
												className="bg-[#D5E4F7] text-[#001D32] font-medium py-2.5 pl-[21px] pr-[24px] rounded-full h-[40px]"
												text="Cancel"
												icon={<CloseBlack />}
												onClick={() => closeModalAndResetDataState()}
											/>
										</div>
										<div className="ml-[20px]">
											<ActionButton
												className="bg-[#154365] text-white font-medium py-2.5 pl-[21px] pr-[24px] rounded-full h-[40px]"
												text={quotaInfoData ? 'Update' : 'Save'}
												icon={<Check />}
												onClick={() => submitForm()}
											/>
										</div>
									</div>
								</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition.Root>
	)
}

export default AddQuotaModal
