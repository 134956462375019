import React, { useState } from 'react'
import { ReactComponent as APIIcon } from '../../../assets/images/icons/API.svg'
import { ReactComponent as APIIcon1 } from '../../../assets/images/icons/API1.svg'
import APIKeysTab from './APIKeysTab'
import { APIKey } from '../../repository/APIKeysRepository'

interface Props {
	apiKeys: APIKey[]
}

const SettingsTabs = ({ apiKeys }: Props) => {
	const [openTab, setOpenTab] = useState<number>(1)

	return (
		<div className="flex flex-wrap">
			<div className="w-full">
				<ul
					className="flex mb-0 list-none flex-wrap flex-row "
					role="tablist"
				>
					<li className="w-[24%] h-[93px]">
						<a
							tabIndex={-1}
							className={
								'flex flex-col pl-[13px] pr-[13px] text-xs h-[93px] leading-normal ' +
                (openTab === 1 ? 'text-[#154365] bg-[#F0F0F0] rounded-t-2xl border-l-2 border-r-2 border-t-2 border-[#F0F0F0]' : 'text-[#72777F] bg-white rounded-t-2xl border-l-2 border-r-2 border-t-2 border-[#F0F0F0]')
							}
							onClick={(e) => {
								e.preventDefault()
								setOpenTab(1)
							}}
							data-toggle="tab"
							href="#link1"
							role="tablist"
						>
							<div className="flex pt-[31.5px]">
								<div>
									{openTab === 1 ? (
										<APIIcon />
									) : (
										<APIIcon1 />
									)}
								</div>
								<div className="pl-[13px] font-[11px] mb-[13.5px]">
                  API keys
								</div>
							</div>
							<div
								className={`w-full h-[1px] order-1 
                    ${openTab === 1 ? 'bg-[#51606F]' : 'bg-[#72777F]'}
                  `}
							></div>
						</a>
					</li>
					{/* <li className="w-[24%] h-[93px] ml-[15px]">
						<a
							tabIndex={-1}
							className={
								'flex flex-col pl-[13px] pr-[13px] text-xs h-[93px] leading-normal ' +
                  (openTab === 2 ? 'text-[#154365] bg-[#F0F0F0] rounded-t-2xl border-l-2 border-r-2 border-t-2 border-[#F0F0F0]' : 'text-[#72777F] bg-white rounded-t-2xl border-l-2 border-r-2 border-t-2 border-[#F0F0F0]')
							}
							onClick={(e) => {
								e.preventDefault()
								setOpenTab(2)
							}}
							data-toggle="tab"
							href="#link2"
							role="tablist"
						>
							<div className="flex pt-[31.5px]">
								<div>
									{openTab === 2 ? <DynamicForm1 /> : <DynamicForm />}
								</div>
								<div className="pl-[13px] font-[11px] mt-[-2px] mb-[13.5px]">
                    Quotas
								</div>
							</div>
							<div
								className={`w-full h-[1px] order-1 
                    ${openTab === 2 ? 'bg-[#51606F]' : 'bg-[#72777F]'}
                  `}
							></div>
						</a>
					</li> */}
				</ul>
				<div className="relative flex flex-col min-w-0 break-words bg-white w-full rounded">
					<div className="flex-auto">
						<div className="tab-content tab-space ">
							<div className={openTab === 1 ? 'block' : 'hidden'} id="link1">
								<APIKeysTab apiKeys={apiKeys}/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
export default SettingsTabs
