import AppKeycloak from '../Keycloak'
import axios from 'axios'
import NotificationsRepository, { MpNotificationRepository, MP } from './NotificationsRepository'

export default class APINotificationsRepository implements NotificationsRepository 
{

	async notifications(id?: string): Promise<MpNotificationRepository[]> {
		const settings: any = JSON.parse(localStorage.getItem('settings') || '')
		const keycloak = AppKeycloak.getAppKeycloak()

		const data = await axios.get(`${settings.WEBPORTAL_BACKEND_URL}/businesses/${id}/notifications`, {
			headers: {
				Authorization: `Bearer ${keycloak.token}`,
			},
		})

		return data.data
	}

	async addNotification(id: string, title?: string, message?: string, date?: number, category?: string,  mp?: MP[]): Promise<void> {
		const settings: any = JSON.parse(localStorage.getItem('settings') || '')
		const keycloak = AppKeycloak.getAppKeycloak()

		const body = {
			'title': title,
			'body': message,
			'scheduledAt': date,
			'category': category,
			'mpData': mp,
		}
		await axios.post(`${settings.WEBPORTAL_BACKEND_URL}/businesses/${id}/notifications`, body,{
			headers: {
				Authorization: `Bearer ${keycloak.token}`,
			},
		})
	}

	async deleteNotification(businessId: string, id: string): Promise<void>  {
		const settings: any = JSON.parse(localStorage.getItem('settings') || '')
		const keycloak = AppKeycloak.getAppKeycloak()

		await axios.delete(`${settings.WEBPORTAL_BACKEND_URL}/businesses/${businessId}/notifications/${id}`,{
			headers: {
				Authorization: `Bearer ${keycloak.token}`,
			},
		})
	}

	async editNotification(id: string, notificationId: string, title: string, message: string, date: number, mp: MP): Promise<void> {
		const settings: any = JSON.parse(localStorage.getItem('settings') || '')
		const keycloak = AppKeycloak.getAppKeycloak()

		const body = {
			'title': title,
			'body': message,
			'scheduledAt': date,
			'mpData': mp,
		}

		await axios.post(`${settings.WEBPORTAL_BACKEND_URL}/businesses/${id}/notifications/${notificationId}/change`, body, {
			headers: {
				Authorization: `Bearer ${keycloak.token}`,
			},
		})

		//const data = oneRowData

		//return data
	}

}
