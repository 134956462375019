import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import Dialog from '@mui/material/Dialog'
import { Input, Textarea } from '@material-tailwind/react'
import { AgGridReact } from 'ag-grid-react'
import { LicenseManager } from 'ag-grid-enterprise'
import { ReactComponent as AddNotification } from '../../../assets/images/icons/editNotification.svg'
import { ReactComponent as Close } from '../../../assets/images/icons/close.svg'
import { ReactComponent as CloseBlack } from '../../../assets/images/icons/closeBlack.svg'
import { ReactComponent as IconClose } from '../../../assets/images/icons/iconClose.svg'
import { ReactComponent as Send } from '../../../assets/images/icons/sendWhite.svg'
import { ReactComponent as Frame } from '../../../assets/images/icons/forVectorBig.svg'
import { ReactComponent as Vector } from '../../../assets/images/icons/vectorBig.svg'
import { ReactComponent as Line } from '../../../assets/images/icons/vectorLine.svg'
import { ReactComponent as AlertRed } from '../../../assets/images/icons/infoMessageRed.svg'
import ActionButton from '../ActionButton'
import { dependencyContainer } from '../../store'
import { useMutation } from '@tanstack/react-query'
import {queryClient} from '../../../index'
import { useQuery } from '@tanstack/react-query'
import VirtualMpFilterFormatter from '../Table/VirtualMpFilterFormatter'
import VirtualMpFormatter from '../Table/VirtualMpFormatter'
import MpCellRenderWithIcon from '../Table/MpCellRenderWithIcon'
import TagsRenderer from '../Table/TagsRenderer'
import CustomHeader from '../Table/CustomHeader'
import type { } from '@mui/x-date-pickers/themeAugmentation'
import dayjs , { Dayjs } from 'dayjs'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { TimePicker } from '@mui/x-date-pickers/TimePicker'
import { createTheme } from '@mui/material/styles'
import ThemeProvider from '@mui/system/ThemeProvider/ThemeProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import RecipientsWarningModal from './RecipientsWarningModal'

const theme = createTheme({
	palette: {
		primary: {
			main: '#2196f3',
		},
		secondary: {
			main: '#f44336',
		},
	},
})

LicenseManager.setLicenseKey(
	'CompanyName=Appmodule AG,LicensedGroup=aliunid grid / power,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=2,AssetReference=AG-036901,SupportServicesEnd=31_January_2024_[v2]_MTcwNjY1OTIwMDAwMA==4c9d5d4b04b142bc6b50ed895e5b5ef3'
)

interface Props {
  setOpenAddNotificationModal: React.Dispatch<React.SetStateAction<boolean>>;
  openAddNotificationModal: boolean;
	rowData?: any;
	notificationData?: any;
	setNotificationData?: any;
	replicate?: any;
}

const AddNotificationModal = ({
	setOpenAddNotificationModal,
	openAddNotificationModal,
	rowData,
	notificationData,
	setNotificationData,
	replicate,
}: Props) => {
	const [date, setDate] = useState<Dayjs | any>(notificationData ? (notificationData?.status === 'HISTORY' ? dayjs() : dayjs(notificationData?.scheduledAt)) : dayjs())
	const [time, setTime] = useState<Dayjs | any>(notificationData ? (notificationData?.status === 'HISTORY' ? dayjs().add(1, 'hour') : dayjs(notificationData?.scheduledAt)) : dayjs().add(1, 'hour'))
	const [title, setTitle] = useState(notificationData ? notificationData?.title : '')
	const [message, setMessage] = useState(notificationData ? notificationData?.body : '')
	const [error, setError] = useState<any>('')
	const [errorServer, setErrorServer] = useState<any>('')
	const [counter, setCounter] = useState(0)
	const [counterMessage, setCounterMessage] = useState(0)
	const [recipients, setRecipients] = useState(0)
	const [checkboxData, setCheckboxData] = useState<any>([])
	const [dataWithChecked, setDataWithChecked] = useState<any>([])
	const [rowDataNotifications, setRowDataNotifications] = useState<any>([])
	const [openRecipientsWarningModal, setOpenRecipientsWarningModal] = useState(false)

	const navigate = useNavigate()
	const { id } = useParams<any>()
	const { notificationId } = useParams<any>()
	const { subBusinessId } = useParams<any>()
	const gridRef = useRef<AgGridReact>(null)

	const handleChangeDate = (newValue: any) => {
		if(newValue !== null)
			setDate(newValue)
		setError('')
	}

	const handleChangeTime = (newValue: any) => {
		if(newValue !== null)
			setTime(newValue)
		setError('')
	}

	useEffect(() => {
		if(checkboxData.length !== 0) {
			setError('')
		}
	}, [checkboxData])
	
	const closeModalAndResetDataState = () => {
		setOpenAddNotificationModal(false)
		if(notificationData){
			if(notificationId === undefined)
				setNotificationData()
		}
	}

	useQuery({
		queryKey: ['notifications modal', id],
		queryFn:() =>  dependencyContainer.dependency.dashboardApi.dashboardData(id as string),
		onSuccess: (data) => {
			const filteredNotificationData = notificationData?.mpData.map((el: any)=> el.mpId)
			const filteredMpData = data.map((mp)=> {
				if(filteredNotificationData?.indexOf(mp.id) > -1) {
					return ({ ... mp, checked: true})
				} else {
					return mp
				}
			})
			setRowDataNotifications(filteredMpData)
		}
	})

	const category = 'MP_SPECIFIC' 
	const dateTime = (date.set('hour', time.get('hour')).set('minute', time.get('minute')).set('second', time.get('second'))).valueOf()

	const mapedCheckBoxData = checkboxData.map((el: any) => ({
		mpId: el.id,
		mpName: el.name,
		targetBusiness: el.businessGroupId
	}))

	const addNotification = useMutation({
		mutationFn: () => dependencyContainer.dependency.notificationsDataApi.addNotification(id as string, title, message, dateTime, category, mapedCheckBoxData),
		onSuccess: async () => {
			setOpenAddNotificationModal(false)
			if(notificationData !== undefined)
				setNotificationData()
			if(location.pathname === `/businesses/${id}` || subBusinessId !== undefined || notificationId !== undefined){
				navigate(`/businesses/${id}/notifications`)
			}
			queryClient.invalidateQueries({ queryKey: ['notificationsData'] })
		},
		onError: (error: any) => {
			setErrorServer(error.response?.data?.message ? error.response?.data?.message : 'Internal server error !')
			
		},
	})

	const editNotification = useMutation({
		mutationFn: () => dependencyContainer.dependency.notificationsDataApi.editNotification(id as string, notificationData?.adminNotificationId, title, message, dateTime, mapedCheckBoxData),
		onSuccess: async () => {
			setOpenAddNotificationModal(false)
			setNotificationData()
			queryClient.invalidateQueries({ queryKey: ['notificationsData'] })
		},
		onError: (error: any) => {
			setErrorServer(error.response?.data?.message ? error.response?.data?.message : 'Internal server error !')
		},
	})

	useEffect(() => {
		if(rowData !== undefined)
			setDataWithChecked(rowData?.sort((a: any) => a.checked === true  ? -1 : 1))
	}, [])

	useEffect(() => {
		if(location.pathname === `/businesses/${id}/notifications` || notificationId !== undefined){
			setDataWithChecked(rowDataNotifications?.sort((a: any) => a.checked === true  ? -1 : 1))
		}
	}, [rowDataNotifications])

	const handleChangeTitle = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setTitle(event.target.value)
		setCounter(event.target.value.length)
		setError('')
	}

	const handleChangeMessage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setMessage(event.target.value)
		setCounterMessage(event.target.value.length)
		setError('')
	}

	useEffect(() => {
		if(notificationData !== undefined){
			setCounterMessage(notificationData?.body.length)
			setCounter(notificationData?.title.length)
			setRecipients(notificationData?.mpData.length)
		}
	}, [notificationData])

	useEffect(() => {
		setRecipients(checkboxData.length)
	}, [checkboxData])
	
	const columnDefs = [
		{
			field: 'checked',
			headerName: '',
			maxWidth: 68,
			sortable: false,
			headerCheckboxSelection: true,
			checkboxSelection: (node: any) => {
				if(node.data?.name !== ''){
					return true
				} else {
					return false
				}
			},
			cellStyle: function() {
				return { display: 'flex', justifyContent: 'center',marginLeft:'8px'}
			},
			filter: 'agSetColumnFilter',
			filterParams: {
				buttons: ['reset', 'apply'],
				suppressSelectAll: true,
				suppressBlanks: true,
				cellHeight: 48,
				suppressMiniFilter: true,
				cellRenderer: VirtualMpFilterFormatter
			},
			cellRenderer: (row: any) => (
				<VirtualMpFormatter
					data={row.data}
				/>
			),
		},
		{
			field: 'name',
			headerName: 'Measuring Point name',
			//minWidth: 370,
			headerComponentParams: { menuIcon: 'electric_meter' },
			cellClass: 'first-cell',
			cellRenderer: (row: any) => (
				<MpCellRenderWithIcon
					data={row.data}
					length={65}
				/>
			),
			cellStyle: function() {
				return { wordBreak: 'break-word' }
			},
		},
		{
			field: 'tags',
			filter: 'agSetColumnFilter',
			filterParams: {
				buttons: ['reset', 'apply'],
				suppressSelectAll: true,
				cellHeight: 48,
				suppressMiniFilter: true,
			},
			cellRenderer: TagsRenderer,
			headerComponentParams: { menuIcon: 'style' },
			autoHeight: true,
			wrapText: true,
			cellStyle: { justifyContent: 'center' },
		},
	]

	const headerHeight = 25
	const floatingFiltersHeight = 30
	const rowHeight = 50

	const components = useMemo(() => {
		return {
			agColumnHeader: CustomHeader,
		}
	}, [])

	const defaultColDef = useMemo(
		() => ({
			sortable: true,
			filter: 'agTextColumnFilter',
			floatingFilter: true,
			flex: 1,
			suppressMovable: true,
			wrapText: true,
			suppressMenu: true,
			floatingFilterComponentParams: {
				suppressFilterButton: true,
			},
		}),
		[]
	)

	const columnTypes = useMemo(() => {
		return {
			number: {
				aggFunc: 'count',
			},
		}
	}, [])

	const getRowStyle = (params: any) => {
		const data = params.data
		if(data === null || data === undefined) return
		if(data.provider !== null && data.provider !== undefined && data.provider.linked){
			return { background: '#ebebeb' }
		} else {
			return { background: 'white' }
		}
	}

	const onFirstDataRendered = useCallback(() => {
		setTimeout(() => {
			document
				.querySelectorAll('.ag-theme-alpine input[class^=ag-][type=text]')
				.forEach((obj) => {
					obj.setAttribute('placeholder', 'Search')
				})
		}, 50)
		setTimeout(() => {
			document
				.querySelectorAll(
					'.ag-theme-alpine .ag-disabled input[class^=ag-][type=text]'
				)
				.forEach((obj) => {
					obj.setAttribute('placeholder', 'Select')
					obj.removeAttribute('disabled')
				})
		}, 100)
		gridRef.current?.api.forEachNode((node) =>
			node.setSelected(node.data && node.data.checked)
		)
	}, [])

	const onRowSelected = useCallback((event: any) => {

		if (event.data) {
			setCheckboxData((oldData: any) =>{
				const filtered = oldData.filter((item: any)=> item.id === event.data.id)

				if(filtered.length === 0){
					return [...oldData,event.data] 
				} else {
					return oldData.filter((item: any)=> item.id !== event.data.id)
				}
			})
		}
	}, [])

	const filteredRowData = dataWithChecked?.filter((data: any)=> {
		return data.name !== ''
	})

	const submitForm = async () => {
		if ((dateTime === null || isNaN(dateTime))) {
			setError('Enter date and time !')
		}
		else if (title === '') {
			setError('Enter title !')
		}
		else if (message === '') {
			setError('Enter message !')
		}
		else if (checkboxData.length === 0) {
			setOpenRecipientsWarningModal(true)
		}
		else {
			if(notificationData) {
				if(replicate){
					addNotification.mutate()
				}
				else {
					editNotification.mutate()
				}
			} else {
				addNotification.mutate()
			}
		}
	}
	
	return (
		<Dialog
			onClose={closeModalAndResetDataState}
			open={openAddNotificationModal}
			className="relative z-[20] bg-[#000000] bg-opacity-50 transition-opacity"
		>
			<div className="fixed inset-0 z-10 overflow-y-auto">
				<div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
					<div className="relative transform overflow-hidden rounded-lg
               bg-white text-left shadow-xl transition-all sm:my-8 xl:w-[734px] lg:w-[634px] ">
						<div className="p-[15px]">
							<div className=" flex flex-row justify-between items-center pt-[9px]">
								<div className="flex items-center">
									<div className="flex items-center w-5 h-5 mb-[2px]">
										<AddNotification />
									</div>
									<div className="w-[292px] pl-[4px]">
										<h2 className="text-base font-medium tracking-[0.15px] text-[#154365]">
											{notificationData ? (replicate ? 'Replicate Notification' : 'Edit Notification') : 'Create Notification'}
										</h2>
									</div>
								</div>
								<div
									className=""
									onClick={() => closeModalAndResetDataState() }
								>
									<Close className="text-[#4D6071] cursor-pointer" />
								</div>
							</div>
							<div className="mt-[10px] w-full h-[0.75px] order-1 bg-[#A7ACB3]"></div>
							<div className="mt-[33px] bg-[#F0F0F0] rounded-xl">
								<div className="p-[13px]">
									<div className='flex gap-x-4 mb-[20px]'>
										<div className="w-full relative">
											<ThemeProvider theme={theme}>
												<LocalizationProvider dateAdapter={AdapterDayjs}>
													<Stack spacing={3}>
														<DatePicker
															label="Date"
															inputFormat="DD.MM.YYYY"
															value={date}
															disablePast
															onChange={handleChangeDate}
															renderInput={(params) => (
																<TextField
																	{...params}
																	sx= {
																		{ '& .MuiOutlinedInput-root': { '& > fieldset': { border: '1px solid #B0BEC5', borderRadius: '6px' } },
																			'& .MuiInputBase-root': { height: '45px',} }
																	}
																/>
															)}
															PopperProps={{
																placement: 'bottom-start',
															}}
															PaperProps={{
																sx: {marginTop: '-5px'}
															}}
														/>
													</Stack>
												</LocalizationProvider>
											</ThemeProvider>
										</div>
										<div className="w-full relative ">
											<ThemeProvider theme={theme}>
												<LocalizationProvider dateAdapter={AdapterDayjs}>
													<Stack spacing={3}>
														<TimePicker
															label="Time"
															value={time}
															ampm={false}
															onChange={handleChangeTime}
															renderInput={(params) => (
																<TextField
																	{...params}
																	sx= {
																		{ '& .MuiOutlinedInput-root': { '& > fieldset': { border: '1px solid #B0BEC5', borderRadius: '6px' } },
																			'& .MuiInputBase-root': { height: '45px',} }
																	}
																/>
															)}
															PopperProps={{
																placement: 'bottom-start',
															}}
															PaperProps={{
																sx: {marginTop: '-5px'}
															}}
														/> 
													</Stack>
												</LocalizationProvider>
											</ThemeProvider>
										</div>
									</div>
									{error === 'Enter date and time !' && (
										<div className="flex justify-start mb-[10px] text-[#ff0000] text-sm mt-[-10px]">
											<AlertRed  className='mt-[1px]'/>
											<h2  className='ml-[8px]'>{error}</h2>
										</div>
									)}
									<div className="w-full relative mb-[20px]">
										<Input
											crossOrigin=''
											type={'text'}
											color={error === 'Enter title !' ? 'red' : 'blue'}
											size="lg"
											value={title}
											label={`Title ${counter}/40`}
											onChange={handleChangeTitle}
											className="text-base"
											icon={
												<IconClose
													className="cursor-pointer"
													//onClick={() => setZip('')}
												/>
											}
											maxLength={40}
										/>
									</div>
									{error === 'Enter title !' && (
										<div className="flex justify-start mb-[10px] text-[#ff0000] text-sm mt-[-10px]">
											<AlertRed  className='mt-[1px]'/>
											<h2  className='ml-[8px]'>{error}</h2>
										</div>
									)}
									<div className="w-full relative mb-[20px]">
										<Textarea
											value={message}
											color={error === 'Enter message !' ? 'red' : 'blue'}
											label={`Message ${counterMessage}/250`}
											onChange={handleChangeMessage as React.ChangeEventHandler<HTMLTextAreaElement> | any}
											className="text-base "
											maxLength={250}
										/>
									</div>
									{error === 'Enter message !' && (
										<div className="flex justify-start mb-[10px] text-[#ff0000] text-sm mt-[-15px]">
											<AlertRed  className='mt-[1px]'/>
											<h2  className='ml-[8px]'>{error}</h2>
										</div>
									)}
									<div className='pb-[18px]'>
										<div className="flex">
											<div className="items-center w-5 h-5 relative">
												<Frame className="w-[18px] h-[18px]" />
												<Vector className="w-[8px] h-[8px] absolute left-[8px] bottom-[0px]" />
												<Line className="w-[10px] h-[10px] absolute left-[3px] bottom-[8px]" />
												<Line className="w-[10px] h-[10px] absolute left-[3px] bottom-[5px]" />
											</div>
											<div>
												<h2 className="leading-6 font-medium text-[#154365] tracking-[0.15px] mt-[-2px] ml-[8px] text-[11px]">
													{`Recipients (${recipients}/${filteredRowData?.length} selected)`}
												</h2>
											</div>
										</div>
										<div className="mt-[2px] w-full h-[1px] order-1 bg-[#96CCFF]"></div>
									</div>
									<div className='vmp ag-theme-alpine h-[calc(100vh-600px)]'>
										<AgGridReact
											ref={gridRef}
											headerHeight={headerHeight}
											floatingFiltersHeight={floatingFiltersHeight}
											rowData={filteredRowData}
											columnDefs={columnDefs}
											defaultColDef={defaultColDef}
											columnTypes={columnTypes}
											groupDisplayType={'groupRows'}
											rowSelection="multiple"
											animateRows={true}
											onFirstDataRendered={onFirstDataRendered}
											groupSelectsChildren={true}
											suppressRowClickSelection={true}
											suppressAggFuncInHeader={true}
											rowHeight={rowHeight}
											components={components}
											pagination={true}
											paginationAutoPageSize={true}
											suppressContextMenu={true}
											getRowStyle={getRowStyle}
											onRowSelected={onRowSelected}
										/>
										<div className="mb-[25px] w-full h-[1px] order-1 bg-white"></div>
										{errorServer && (
											<div className="flex justify-start text-[#ff0000] text-sm">
												<AlertRed  className='mt-[1px]'/>
												<h2  className='ml-[8px]'>{errorServer}</h2>
											</div>
										)}
									</div>
								</div>
							</div>
							<div className="mt-[30px] flex justify-end">
								<div>
									<ActionButton
										className="bg-[#D5E4F7] text-[#001D32] font-medium py-2.5 pl-[21px] pr-[24px] rounded-full h-[40px]"
										text="Cancel"
										icon={<CloseBlack />}
										onClick={() => closeModalAndResetDataState()}
									/>
								</div>
								<div className="ml-[20px]">
									<ActionButton
										className="bg-[#154365] text-white font-medium py-2.5 pl-[21px] pr-[24px] rounded-full h-[40px]"
										text="Send"
										icon={<Send />}
										onClick={() => submitForm()}
									/>
								</div>
							</div>
							{openRecipientsWarningModal && (
								<RecipientsWarningModal
									setOpenRecipientsWarningModal={setOpenRecipientsWarningModal}
									openRecipientsWarningModal={openRecipientsWarningModal}
								/>
							)}
						</div>
					</div>
				</div>
			</div>
		</Dialog>
	)
}

export default AddNotificationModal
