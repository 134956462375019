import React, {useState, useEffect} from 'react'
import { Outlet } from 'react-router-dom'
import Navigation from './Navigation'
import Header from '../../components/Header/Header'

export default function AdminNavigation() {

	const [openSideBar, setOpenSideBar] = useState(window.innerWidth <= 1024 ? false : true)
	
	return (
		<div className="flex flex-row w-full bg-stone-300" style={{height: '100%'}}>
			<Header 
				openSideBar={openSideBar}
			/>
			<div className={`py-3 px-5 bg-white fixed h-full z-20 ${openSideBar ? 'w-[256px]' : 'w-[80px] ease-in duration-300'} rounded-r-xl h-full`}>
				<Navigation 
					openSideBar={openSideBar}
					setOpenSideBar={setOpenSideBar}
				/>
			</div>
			<div className={`${openSideBar ? 'ml-[256px] ' : 'ml-[80px] ease-in duration-300'} w-full pt-[120px] pb-[20px] px-[15px] overflow-auto`} style={{height: '100vh'}}>
				<Outlet />
			</div>
		</div>
	)
}
