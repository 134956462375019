import React, { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { ReactComponent as Close } from '../../../assets/images/icons/close.svg'
import { ReactComponent as CloseBlack } from '../../../assets/images/icons/closeBlack.svg'
import { ReactComponent as Check } from '../../../assets/images/icons/check.svg'
import { ReactComponent as Info } from '../../../assets/images/icons/infoMessage.svg'
import { ReactComponent as AlertRed } from '../../../assets/images/icons/infoMessageRed.svg'
import ActionButton from '../ActionButton'
import { useNavigate, useParams } from 'react-router-dom'
import { dependencyContainer } from '../../store'
import { useMutation } from '@tanstack/react-query'
import { queryClient } from '../../../index'

interface Props {
  setOpenMeasuringPointInfoModal: React.Dispatch<React.SetStateAction<boolean>>;
	setOpenCreateCalculatedMpModal: React.Dispatch<React.SetStateAction<boolean>>;
  openMeasuringPointInfoModal: boolean;
	infoVmpEditRef: any;
	mpName:any;
	formula:any;
	checkboxDataId:any;
	virtualMpNames: any;
	factor: number;
}

const MeasuringPointInfoModal = ({
	openMeasuringPointInfoModal,
	setOpenMeasuringPointInfoModal,
	setOpenCreateCalculatedMpModal,
	infoVmpEditRef,
	mpName,
	formula,
	checkboxDataId,
	virtualMpNames,
	factor,
}: Props) => {
	const [error, setError] = useState('')

	const navigate = useNavigate()
	const { id } = useParams<any>()
	const { subBusinessId } = useParams()

	const createCopiedName = (name: any, names: any) => {
		if (name == null) return
		if (names.length === 0) return name

		if (name.match(new RegExp(' (.[0-9]+.)$')))
			name = name.substring(0, name.lastIndexOf('(') - 1)

		const alreadyNameCopies: any = names.filter((x: any) => x.match(new RegExp(name + ' (.[0-9]+.)$')))

		if (alreadyNameCopies.length === 0) return name + ' (2)'

		let copyMax = 2
		for (const nameCopy of alreadyNameCopies) {
			let copyNumber = nameCopy.substring(nameCopy.lastIndexOf('(') + 1, nameCopy.length - 1)
			copyNumber = parseInt(copyNumber)
			if (copyMax < copyNumber) copyMax = copyNumber
		}

		return name + ' (' + (++copyMax).toString() + ')'
	}

	const createVirtualMeasuringPoint = useMutation({
		mutationFn: () => dependencyContainer.dependency.measuringPointsApi.createVirtualMp(id as string, createCopiedName(mpName, virtualMpNames), formula(), checkboxDataId, factor),
		onSuccess: async () => {
			setOpenCreateCalculatedMpModal(false)
			setOpenMeasuringPointInfoModal(false)
			if(subBusinessId !== undefined){
				navigate(`/businesses/${id}`)
			}
			queryClient.invalidateQueries({ queryKey: ['dashboardData'] })
		},
		onError: (error: any) => {
			setError(error.response?.data?.message ? error.response?.data?.message : 'Internal server error !')
		},
	})

	const closeModalAndResetDataState = () => {
		setOpenMeasuringPointInfoModal(false)
		setError('')
	}

	return (
		<Transition.Root show={openMeasuringPointInfoModal} as={Fragment}>
			<Dialog
				as="div"
				className="relative z-[20]"
				initialFocus={infoVmpEditRef}
				onClose={()=>{
					//no action for clicking outside modal
				}}
			>
				<Transition.Child
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 bg-[#000000] bg-opacity-50 transition-opacity " />
				</Transition.Child>

				<div className="fixed inset-0 z-10 overflow-y-auto">
					<div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							enterTo="opacity-100 translate-y-0 sm:scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 translate-y-0 sm:scale-100"
							leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						>
							<Dialog.Panel
								className="relative transform overflow-hidden rounded-lg
               bg-white text-left shadow-xl transition-all sm:my-8 xl:w-[603px] lg:w-[503px]"
							>
								<div className="p-[15px]">
									<div className=" flex flex-row justify-between items-center pt-[9px]">
										<div className="flex items-center">
											<div className="flex items-center w-5 h-4 mb-[3px]">
												<Info />
											</div>
											<div className=" pl-[7px]">
												<h2 className="text-base font-medium tracking-[0.15px] text-[#154365]">	
												Creation of a new Measuring Point									
												</h2>
											</div>
										</div>
										<div
											className=""
											onClick={()=> {
												setOpenMeasuringPointInfoModal(false)
												setError('')
											}}
										>
											<Close className="text-[#4D6071] cursor-pointer" />
										</div>
									</div>
									<div className="mt-[10px] w-full h-[0.75px] order-1 bg-[#A7ACB3]"></div>
									<div className="mt-[23px] bg-[#F0F0F0] rounded-xl flex items-center flex-wrap p-[13px]">
										<div className="items-center text-[#154365] text-[14px] flex flex-wrap">
											<p>Editing the selection will result in the creation of a new Measuring Point. The original Measuring Point will continue to exist in its current form.</p>
										</div>
									</div>
									{error && (
										<div className="flex justify-start fixed mt-[10px] text-[#ff0000] text-sm">
											<AlertRed  className='mt-[1px]'/>
											<h2  className='ml-[8px]'>{error}</h2>
										</div>
									)}
									<div className="mt-[30px] flex justify-end">
										<div ref={infoVmpEditRef}>
											<ActionButton
												className="bg-[#D5E4F7] text-[#001D32] font-medium py-2.5 pl-[21px] pr-[24px] rounded-full h-[40px]"
												text="Cancel"
												icon={<CloseBlack />}
												onClick={() => closeModalAndResetDataState()}
											/>
										</div>
										<div ref={infoVmpEditRef} className="ml-[20px]">
											<ActionButton
												className="bg-[#154365] text-white font-medium py-2.5 pl-[21px] pr-[24px] rounded-full h-[40px]"
												text="Ok"
												icon={<Check />}
												onClick={() => {
													createVirtualMeasuringPoint.mutate()
													setError('')
												}}
											/>
										</div>
									</div>
								</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition.Root>
	)
}

export default MeasuringPointInfoModal
