import React from 'react'
import { Tooltip } from '@material-tailwind/react'

interface Props {
  data: any
	length: number
	tooltipWidth: number
}

const CommentColumn = ({data, length, tooltipWidth}: Props) => {

	return (
		<div className='h-[full]'>
			{(data?.length) > length ?
				<Tooltip content={data} placeholder=''
					className={`w-[${tooltipWidth}px] bg-[#ebedf0] border-gray-400
				 p-[15px] text-[#4d6071] border font-medium text-center`}>
					<div className="flex w-full h-[full]">
						<div className='flex justify-start break-words text-left'>
							{data?.substr(0,length-1)}
							{data?.length > length ? ' ...' : ''}
						</div>
					</div>
				</Tooltip>
				:
				<div className="flex w-[full] h-[full] items-center break-words">
					<div className='flex justify-start break-words'>
						{data}
					</div>
				</div>
			}
		</div>
	)
}

export default CommentColumn
