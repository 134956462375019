import React, { useState, Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { Select, Option, Input } from '@material-tailwind/react'
import { ReactComponent as Close } from '../../../assets/images/icons/close.svg'
import { ReactComponent as CloseBlack } from '../../../assets/images/icons/closeBlack.svg'
import { ReactComponent as IconClose } from '../../../assets/images/icons/iconClose.svg'
import { ReactComponent as Check } from '../../../assets/images/icons/check.svg'
import { ReactComponent as GroupAdd } from '../../../assets/images/icons/group_add.svg'
import { ReactComponent as AlertRed } from '../../../assets/images/icons/infoMessageRed.svg'
import ActionButton from '../ActionButton'
import { useParams } from 'react-router-dom'
import { dependencyContainer } from '../../store'
import { useMutation } from '@tanstack/react-query'
import {queryClient} from '../../../index'

interface Props {
  setOpenAddUserModal: React.Dispatch<React.SetStateAction<boolean>>;
  openAddUserModal: boolean;
  cancelButtonRef: any;
  userInfoData?: any;
  setUserInfoData: any;
}

const roles = [
	{
		id: 1,
		name: 'admin',
	},
	{
		id: 2,
		name: 'viewer',
	},
]

const AddUserModal = ({
	setOpenAddUserModal,
	openAddUserModal,
	cancelButtonRef,
	userInfoData,
	setUserInfoData,
}: Props) => {
	const [email, setEmail] = useState(userInfoData ? userInfoData.email : '')
	const [error, setError] = useState('')
	const [role, setRole] = useState(userInfoData ? userInfoData.role : '')

	const { id } = useParams<any>()

	const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
		setEmail(event.target.value)
		setError('')
	}

	const closeModalAndResetDataState = () => {
		setOpenAddUserModal(false)
		setUserInfoData()
	}

	const mailValidation = (mail: string) => {
		// eslint-disable-next-line no-useless-escape
		if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(mail)) {
			return false
		}
		else {
			return true
		}
	}

	const addNewUser = useMutation({
		mutationFn: () => dependencyContainer.dependency.usersDataApi.addNewUser(id as string, email, role),
		onSuccess: async () => {
			setOpenAddUserModal(false)
			queryClient.invalidateQueries({ queryKey: ['usersData'] })
			queryClient.invalidateQueries({ queryKey: ['usersDataInvited'] })
		},
		onError: (error: any) => {
			setError(error.response?.data?.message ? error.response?.data?.message : 'Internal server error !')
		},
	})

	const editUserRole = useMutation({
		mutationFn: () => dependencyContainer.dependency.usersDataApi.editUserRole(id as string, userInfoData?.id, role),
		onSuccess: async () => {
			setOpenAddUserModal(false)
			setUserInfoData()
			queryClient.invalidateQueries({ queryKey: ['usersDataInvited'] })
			queryClient.invalidateQueries({ queryKey: ['usersData'] })
		},
		onError: (error: any) => {
			setError(error.response?.data?.message ? error.response?.data?.message : 'Internal server error !')
		},
	})

	const submitForm = async () => {
		if (email === '') {
			setError('Email is required !')
		}
		else if(mailValidation(email)) {
			setError('You are writing invalid email address !')
		}
		else if (role === '') {
			setError('Role is required !')
		}
		else {
			if(userInfoData){
				editUserRole.mutate()
			}else {
				addNewUser.mutate()
			}
		}
	}

	const setRoleOnClick = (role: any) => {
		setRole(role)
		setError('')
	}

	return (
		<Transition.Root show={openAddUserModal} as={Fragment}>
			<Dialog
				as="div"
				className="relative z-[20]"
				initialFocus={cancelButtonRef}
				onClose={()=>{
					//no action for clicking outside modal
				}}
			>
				<Transition.Child
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 bg-[#000000] bg-opacity-75 transition-opacity " />
				</Transition.Child>

				<div className="fixed inset-0 z-10 overflow-y-auto">
					<div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							enterTo="opacity-100 translate-y-0 sm:scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 translate-y-0 sm:scale-100"
							leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						>
							<Dialog.Panel
								className="relative transform overflow-hidden rounded-lg
               bg-white text-left shadow-xl transition-all sm:my-8 xl:w-[603px] h-[538px] lg:w-[503px]"
							>
								<div className="p-[15px]">
									<div className=" flex flex-row justify-between items-center pt-[9px]">
										<div className="flex items-center">
											<div className="flex items-center w-5 h-5">
												<GroupAdd />
											</div>
											<div className="w-[292px] pl-[7px]">
												<h2 className="text-base font-medium tracking-[0.15px] text-[#154365]">
													{userInfoData ? 'Edit user' : 'Add user'}
												</h2>
											</div>
										</div>
										<div
											className=""
											onClick={() => closeModalAndResetDataState()}
										>
											<Close className="text-[#4D6071] cursor-pointer" />
										</div>
									</div>
									<div className="mt-[10px] w-full h-[0.75px] order-1 bg-[#A7ACB3]"></div>
									<div className="mt-[33px] bg-[#F0F0F0] rounded-xl h-[365px]">
										<div className="p-[13px]">
											<div className="w-full relative mb-[20px]">
												<Input
													crossOrigin=''
													tabIndex={userInfoData ? -1 : 1}
													type={userInfoData ?'submit' : 'text'}
													color={(error === 'Email is required !' || error === 'You are writing invalid email address !') ? 'red' : 'blue'}
													size="lg"
													value={email}
													label="E-mail"
													onChange={handleChangeEmail}
													className="text-base text-left"
													icon={userInfoData ?
														null
														:
														<IconClose
															className="cursor-pointer"
															onClick={() => setEmail('')}
														/>
													}
												/>
											</div>
											{(error === 'Email is required !' || error === 'You are writing invalid email address !') && (
												<div className="flex justify-start mb-[10px] text-[#ff0000] text-[14px] mt-[-10px]">
													<AlertRed  className='mt-[1px]'/>
													<h2  className='ml-[8px]'>{error}</h2>
												</div>
											)}
											<div className="w-full relative mb-[1px] selectDivAutoHeight">
												<Select 
													placeholder=''
													label="Role"
													size='lg'
													value={role}
													onChange={setRoleOnClick}
													style={{fontSize: '16px'}}
													animate={{
														mount: { y: 0 },
														unmount: { y: 15 },
													}}
												>
													{roles?.map((role: any) => (
														<Option
															key={role?.id}
															className="text-sm text-[#001F24] tracking-[0.5px] hover:bg-[#004368] hover:bg-opacity-[0.12] cursor-pointer h-[32px] py-[6px] pl-[16px] "
															value={role?.name}
														>
															{role?.name}
														</Option>
													))}
												</Select>
											</div>
											{error === 'Role is required !' && (
												<div className="flex justify-start mb-[10px] text-[#ff0000] text-sm mt-[10px]">
													<AlertRed  className='mt-[1px]'/>
													<h2  className='ml-[8px]'>{error}</h2>
												</div>
											)}
										</div>
									</div>
									{(error !== 'Role is required !' && error !== 'Email is required !' && error !== 'You are writing invalid email address !'  && error !== '') && (
										<div className="flex justify-start fixed mb-[10px] text-[#ff0000] text-sm mt-[10px]">
											<AlertRed  className='ml-[14px] mt-[1px]'/>
											<h2  className='ml-[8px]'>{error}</h2>
										</div>
									)}
									<div className="mt-[30px] flex justify-end">
										<div>
											<ActionButton
												className="bg-[#D5E4F7] text-[#001D32] font-medium py-2.5 pl-[21px] pr-[24px] rounded-full h-[40px]"
												text="Cancel"
												icon={<CloseBlack />}
												onClick={() => closeModalAndResetDataState()}
											/>
										</div>
										<div className="ml-[20px]">
											<ActionButton
												className="bg-[#154365] text-white font-medium py-2.5 pl-[21px] pr-[24px] rounded-full h-[40px]"
												text="Save"
												icon={<Check />}
												onClick={() => {
													submitForm()
												}}
											/>
										</div>
									</div>
								</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition.Root>
	)
}

export default AddUserModal
