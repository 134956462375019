import React, { useEffect, useState } from 'react'
import { Input } from '@material-tailwind/react'
import { ReactComponent as Vector } from '../../../assets/images/icons/vector.svg'
import { ReactComponent as VectorUp } from '../../../assets/images/icons/vectorUp.svg'
import { ReactComponent as CloseWhite } from '../../../assets/images/icons/closeWhite.svg'
import { ReactComponent as Search } from '../../../assets/images/icons/search-icon.svg'
import { Link } from 'react-router-dom'

import withClickOutside from './withClickOutside'

interface Props {
  options?: any;
  placeholder: string;
  onChange: any;
  selectedKey: string;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  //ref: any;
  onClick?: any
}

const SelectComponent = React.forwardRef(
	(
		{ options, placeholder = '', onChange, selectedKey, open, setOpen }: Props,
		ref: any
	) => {
		const [inputValue, setInputValue] = useState('')
		const [icon, setIcon] = useState(true)

		useEffect(() => {
			if (selectedKey) {
				setInputValue(options.find((o: any) => o.id === selectedKey).name)
			}
		}, [selectedKey, options])

		useEffect(() => {
			if (
				!open &&
        options.findIndex((o: any) => o.name === inputValue) === -1
			) {
				if (!inputValue) {
					onChange('')
				} else {
					if (selectedKey) {
						setInputValue(options.find((o: any) => o.id === inputValue).name)
					} else {
						setInputValue('')
					}
				}
			}
		}, [inputValue, onChange, open, options, selectedKey])

		const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
			setInputValue(e.target.value)
		}

		const onItemSelected = (option: any) => {
			onChange !== undefined && onChange(option.id)
			onChange !== undefined && setInputValue(option.name)
			setOpen(false)
		}

		const clearDropdown = () => {
			setInputValue('')
			onChange('')
		}

		const onInputClick = () => {
			setOpen((prevValue) => !prevValue)
		}

		return (
			<div className="dropdown-container" ref={ref}>
				<div className="input-container customLabel2" onClick={onInputClick}>
					{icon && <div className='absolute left-[10px] top-[18px]'>
						<Search className="cursor-pointer" />
					</div>}
					<Input
						crossOrigin=''
						onClick={()=>setIcon(false)}
						type={open ? 'text' : 'submit'}
						value={inputValue}
						placeholder={placeholder}
						onChange={onInputChange}
						color="blue"
						size="lg"
						label="Business name"
						className="inputText text-white cursor-pointer"
					/>
					<div className="input-arrow-container">
						{open ? (
							<VectorUp className="cursor-pointer" />
						) : (
							<Vector className="cursor-pointer" />
						)}
					</div>
					{selectedKey || inputValue ? (
						<div onClick={
							()=>{setIcon(true)
								clearDropdown()} }
						className="input-clear-container">
							<CloseWhite className="text-white cursor-pointer" />
						</div>
					) : null}
				</div>
				<div className={`dropdown text-[#001F24] ${open ? 'visible' : ''}`}>
					{options
						.filter((item: any) => {
							const searchItem = inputValue.toLowerCase()
							const v = item.name.toLowerCase()

							if (!searchItem) return true

							// return v.startsWith(searchItem);
							return v.includes(searchItem)
						})
						.map((opt: any) => {
							return (
								<Link key={opt.id} to={`/businesses/${opt.id}`}>
									<div
										key={opt.id}
										onClick={() => onItemSelected(opt)}
										className="option"
									>
										{opt.name}
									</div>
								</Link>
							)
						})}
				</div>
			</div>
		)
	}
)

export default withClickOutside(SelectComponent)
