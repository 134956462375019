import { businessLoaded, Role } from '../../app/appSlice'
import { Command } from '../../app/store'
import { businessesLoaded } from '../selectBusiness/selectBusinessSlice'

function rightToRole(group: string): Role {
	if (group === 'admins') return 'admin'
	return 'viewer'
}

export const loadAppCommand = (businessId: string): Command => async (dispatch, getState, { dependency }) => {
	try {
		const businessesRights: string[] = ((dependency.keycloak.parsedToken as any).groups)
		const allowedBusinesses = businessesRights.map(right => {
			const parts = right.split('/')
			return {
				business: parts[1],
				role: parts[2]
			}
		})
		const business = allowedBusinesses.filter(b => b.business === businessId)[0]
		if (business === undefined) throw new Error('Provided business is not allowed!')

		dispatch(businessesLoaded(await dependency.businessesRepository.businesses()))
		dispatch(businessLoaded({
			business: businessId,
			role: rightToRole(business.role)
		}))
	} catch (err) {
		// console.log(err)
		window.location.href = '/'

	}
}

