import React,{useState} from 'react'
import { ReactComponent as DragIcon } from '../../../assets/images/icons/dragIcon.svg'

interface Props {
  data: any
}

const ApiKeysOptionsColumn = ({data}: Props) => {

	const [isHovered, setIsHovered] = useState(false)

	return (
		<div
			onMouseEnter={() => setIsHovered(true)}
			onMouseLeave={() => setIsHovered(false)}
			className='flex items-center'
		>
			<DragIcon
				stroke={isHovered ? '#4d6071' : '#c2cad1'}
			/>
		</div>
	)

}

export default ApiKeysOptionsColumn
