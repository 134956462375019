import React from 'react'
import { ReactComponent as LogoLogin } from '../../../assets/images/icons/logoLogin.svg'
import { ReactComponent as InfoWhite } from '../../../assets/images/icons/infoWhite.svg'
import { ReactComponent as Replay } from '../../../assets/images/icons/replay.svg'
import AppKeycloak from '../../Keycloak'

const NoBusinesses = () => {

	const logoutFunc = () => {
		AppKeycloak.getAppKeycloak().logout()
			.then(() => {
				console.log('LOGGED OUT')
				window.location.replace('/')
			})
			.catch((err) => {
				console.log('ERR', err)
			})
	}

	const reloadFunc = () => {
		window.location.reload()
	}

	return (
		<div className="bg-[#004368] h-[100vh] w-[100vw] flex flex-col items-center justify-center">
			<div className="flex flex-col items-center justify-center">
				<LogoLogin />
				<div className="text-white flex tracking-[0.5px] leading-4 mt-[27px]">
					<h2 className="text-xs font-normal mt-[3px] mr-[3px]">aliunid</h2>
					<h1 className="font-medium text-sm">OPERATOR</h1>
				</div>
				<div className="my-2 w-[195px] h-[1px] order-1 bg-[#C2E8FF]"></div>
			</div>
			<div className="flex flex-col w-[350px]">
				<div className="mx-auto mt-[50px]">
					<InfoWhite />
				</div>
				<p className="font-normal mx-auto tracking-[0.5px] text-white mt-[7px] text-[16px]">
          No business assigned.
				</p>
				<p className="font-normal mx-auto flex tracking-[0.5px] text-white mt-[30px] text-[12px]">
          aliunid MONITOR is an invite based solution.
				</p>
				<p className="font-normal flex mx-auto tracking-[0.5px] text-white text-[12px]">
          Please contact your administrator and request an invitation.
				</p>
				<div className="mt-[70px] mx-auto cursor-pointer">
					<div
						className="bg-[#D5E4F7] text-[#1C1B1F] font-medium py-3 px-3.5 w-full rounded-full "
						onClick={() => reloadFunc()}
					>
						<Replay />
					</div>
				</div>
				<div className="text-sm mt-[25px] mx-auto hover:text-[#D5E4F7] text-white font-medium mb-[70px] cursor-pointer"
					onClick={() => logoutFunc()}
				>
          Logout
				</div>
			</div>
		</div>
	)
}

export default NoBusinesses
