import React, { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { ReactComponent as Close } from '../../../assets/images/icons/close.svg'
import { ReactComponent as CloseBlack } from '../../../assets/images/icons/closeBlack.svg'
import { ReactComponent as Info } from '../../../assets/images/icons/info.svg'
import ActionButton from '../ActionButton'

interface Props {
  setOpenDetectingForefoxModal: React.Dispatch<React.SetStateAction<boolean>>;
  openDetectingForefoxModal: boolean;
  detectingForefoxModalRef: any;
}

const DetectingForefoxModal = ({
	setOpenDetectingForefoxModal,
	openDetectingForefoxModal,
	detectingForefoxModalRef,
}: Props) => {

	return (
		<Transition.Root show={openDetectingForefoxModal} as={Fragment}>
			<Dialog
				as="div"
				className="relative z-[20]"
				initialFocus={detectingForefoxModalRef}
				onClose={()=>{
					//no action for clicking outside modal
				}}
			>
				<Transition.Child
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0  transition-opacity " />
				</Transition.Child>

				<div className="fixed inset-0 z-10 overflow-y-auto mt-[55vh]">
					<div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							enterTo="opacity-100 translate-y-0 sm:scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 translate-y-0 sm:scale-100"
							leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						>
							<Dialog.Panel
								className="relative transform overflow-hidden rounded-lg
               bg-white text-left shadow-xl transition-all sm:my-8 xl:w-[503px] lg:w-[403px]"
							>
								<div className="p-[15px]">
									<div className=" flex flex-row justify-between items-center pt-[9px]">
										<div className="flex items-center">
											<div className="flex items-center w-5 h-5 mb-[2px]">
												<Info />
											</div>
											<div className=" pl-[7px]">
												<h2 className="text-base font-medium tracking-[0.15px] text-[#154365]">	
													We have detected that you are using Firefox
												</h2>
											</div>
										</div>
										<div
											className=""
											onClick={()=>setOpenDetectingForefoxModal(false)}
										>
											<Close className="text-[#4D6071] cursor-pointer" />
										</div>
									</div>
									<div className="mt-[10px] w-full h-[0.75px] order-1 bg-[#A7ACB3]"></div>
									<div className="mt-[13px] bg-[#F0F0F0] rounded-xl flex items-center flex-wrap p-[13px]">
										<div className="items-center text-[#154365] text-[14px]">
											In Firefox, the application shows instability in displaying live data. We are working hard to fix this problem.
										</div>
									</div>
									<div className="mt-[20px] flex justify-end">
										<div ref={detectingForefoxModalRef}>
											<ActionButton
												className="bg-[#D5E4F7] text-[#001D32] font-medium py-2.5 pl-[21px] pr-[24px] rounded-full h-[40px]"
												text="Close"
												icon={<CloseBlack />}
												onClick={()=>setOpenDetectingForefoxModal(false)}
											/>
										</div>
									</div>
								</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition.Root>
	)
}

export default DetectingForefoxModal
