import { configureStore, ThunkAction, Action, AnyAction } from '@reduxjs/toolkit'
import counterReducer from '../features/counter/counterSlice'
import selectBusinessSlice from '../features/selectBusiness/selectBusinessSlice'
import appSlice from './appSlice'
import DependencyContainer, { Dependency } from './DependencyContainer'

export const dependencyContainer = new DependencyContainer()

export const loadEnv = (env: any) => {
	dependencyContainer.createDependency(env)
}

export const store = configureStore({
	reducer: {
		app: appSlice,
		selectBusiness: selectBusinessSlice,
		counter: counterReducer,
	},
	devTools: process.env.NODE_ENV !== 'production',
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			thunk: {
				extraArgument: dependencyContainer,
			},
		}),
})

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type Command = ThunkAction<void, RootState, DependencyContainer, AnyAction>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
