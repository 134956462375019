import { ICellRendererParams } from 'ag-grid-community'
import React from 'react'

const VirtualMpFilterFormatter = (params: ICellRendererParams) => {

	const checked = params.value

	if (checked === 'true') {
		return (<div className='forecast-filter'><div style={{ backgroundColor: '#154365', marginRight: '4px' }}></div> Selected</div>)
	} else if (checked === null) {
		return (<div className='forecast-filter'><div style={{ backgroundColor: 'white', marginRight: '4px' }}></div> Not Selected</div>)
	}
}

export default VirtualMpFilterFormatter
