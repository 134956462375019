import React, { useState, useMemo, useCallback, useRef } from 'react'
import { AgGridReact } from 'ag-grid-react'
import CustomHeader from '../Table/CustomHeader'
import { ReactComponent as IconPlus } from '../../../assets/images/icons/icon+.svg'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-alpine.css'
import 'ag-grid-enterprise'
import { GetContextMenuItemsParams, LicenseManager, ValueFormatterParams } from 'ag-grid-enterprise'
import '../../pages/admin/ag-grid-power.css'
import { useAppSelector } from '../../hooks'
import AddQuotaModal from '../Modals/AddQuotaModal'
import ShareMpColumn from '../Table/ShareMpColumn'
import DeleteQuotaModal from '../Modals/DeleteQuotaModal'
import CommentColumn from '../Table/CommentColumn'
import { MeasuringPointModel } from '../../repository/DashboardRepository'

LicenseManager.setLicenseKey(
	'CompanyName=Appmodule AG,LicensedGroup=aliunid grid / power,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=2,AssetReference=AG-036901,SupportServicesEnd=31_January_2024_[v2]_MTcwNjY1OTIwMDAwMA==4c9d5d4b04b142bc6b50ed895e5b5ef3'
)

interface Quota {
	comment: string
	id: string
	month: string
	quota: number
	quotaRate: string
	referenceAmount: number
	referencePeriod: string
}

interface Props {
	quotasData: Quota[]
	mpData: MeasuringPointModel
}

const QuotasTab = ({quotasData, mpData}: Props) => {

	const dateCompareDate = (valueA: any, valueB: any) => {
		return (new Date(valueA.month.replace(' ', ' 1 ')) <= new Date(valueB.month.replace(' ', ' 1 '))) ? 1 : -1
	}
	quotasData = quotasData.sort(dateCompareDate)

	const dateCompare = (valueA: string, valueB: string) => {
		return (new Date(valueA.replace(' ', ' 1 ')) <= new Date(valueB.replace(' ', ' 1 '))) ? 1 : -1
	}

	const [openDeleteQuotaInfoModal, setOpenDeleteQuotaInfoModal] = useState(false)
	const [openAddQuotaModal, setOpenAddQuotaModal] = useState(false)
	const [quotaInfoData, setQuotaInfoData] = useState<any>()

	const quotaModalRef = useRef(null)
	const deleteQuotaInfoRef = useRef(null)

	const editIcon = '<img border="0" width="15" height="15" src="/images/edit_icon.svg"/>'
	const deleteIcon = '<img border="0" width="15" height="15" src="/images/delete_icon.svg"/>'

	const getContextMenuItems = useCallback((params: GetContextMenuItemsParams | any) => {

		const result = []
		if (params.node) {
			result.push(
				{
					name: 'Edit',
					icon: editIcon,
					action: function () {
						setOpenAddQuotaModal(true)
						setQuotaInfoData(params.node.data)
						
					}
				},
				'separator'
			)
			result.push(
				{
					name: 'Delete',
					icon: deleteIcon,
					action: function () {
						setOpenDeleteQuotaInfoModal(true)
						setQuotaInfoData(params.node.data)
					}
				}
			)
		}
		return result
	}, [])

	const { role } = useAppSelector((state) => state.app)

	const headerHeight = 25

	const columnDefs = [
		{
			cellRenderer: (row: any) => {
				if(mpData?.provider === undefined && role === 'admin') {
					return <ShareMpColumn
						data={row.data}
					/>
				}
			}
			,
			field: 'mp',
			filter: false,
			maxWidth: mpData?.provider === undefined && role === 'admin' ? 30 : 5,
			sortable: false,
			onCellClicked: function (params: any) {
				if (params.node.data.name !== '' && (mpData?.provider === undefined && role === 'admin')) {
					params.api.contextMenuFactory.showMenu(
						params.node,
						params.column,
						params.value,
						params.event
					)
				}
			},
			cellStyle: function() {
				if(mpData?.provider === undefined && role === 'admin') {
					return { cursor: 'pointer'}
				}
			},
		},
		{
			field: 'month',
			headerName: 'Month',
			headerComponentParams: { menuIcon: 'calendar_month' },
			cellClass: 'first-cell',
			comparator: dateCompare,
			minWidth: 180,
		},
		{
			field: 'referencePeriod',
			headerName: 'Reference period',
			headerComponentParams: { menuIcon: 'calendar_month' },
			cellClass: 'first-cell',
			comparator: dateCompare,
			minWidth: 180,
		},
		{
			field: 'referenceAmount',
			headerName: 'kWh Reference amount',
			headerComponentParams: { menuIcon: 'flash_on' },
			cellClass: 'flex-right-align',
			type: 'number',
			valueFormatter: (params: ValueFormatterParams) => params.value?.toFixed(1),
			minWidth: 210,
		},
		{
			field: 'quotaRate',
			headerName: 'Quota rate',
			headerComponentParams: { menuIcon: 'percent' },
			cellClass: 'flex-right-align',
			valueGetter: 'data.quotaRate + " %"',
			minWidth: 145,
		},
		{
			field: 'quota',
			headerName: 'kWh Quota',
			headerComponentParams: { menuIcon: 'dynamic_form' },
			cellClass: 'flex-right-align text-[#96CCFF]',
			minWidth: 145,
		},
		{
			field: 'comment',
			headerName: 'Comment',
			headerComponentParams: { menuIcon: 'chat_bubble' },
			cellClass: 'first-cell truncate',
			cellStyle: function() {
				return { wordBreak: 'break-word'}
			},
			cellRenderer: (row: any) => (
				<CommentColumn
					data={row.data.comment}
					length={52}
					tooltipWidth={250}
				/>
			),
			minWidth: 210,
		},
	]

	const defaultColDef = useMemo(
		() => ({
			sortable: true,
			filter: 'agTextColumnFilter',
			floatingFilter: true,
			wrapText: true,
			flex: 1,
			suppressMovable: true,
			suppressMenu: true,
			floatingFilterComponentParams: {
				suppressFilterButton: true,
			},
		}),
		[]
	)

	const onComponentStateChanged = useCallback(() => {
		setTimeout(() => {
			document
				.querySelectorAll('.ag-theme-alpine input[class^=ag-][type=text]')
				.forEach((obj) => {
					obj.setAttribute('placeholder', 'Search')
				})
		}, 50)
		setTimeout(() => {
			document
				.querySelectorAll('.ag-theme-alpine .ag-disabled input[class^=ag-][type=text]')
				.forEach((obj) => {
					obj.setAttribute('placeholder', 'Select')
					obj.removeAttribute('disabled')
				})
		}, 50)
	}, [])

	const components = useMemo(() => {
		return {
			agColumnHeader: CustomHeader,
			unSortIcon: true,
		}
	}, [])

	const floatingFiltersHeight = 30

	const columnTypes = useMemo(() => {
		return {
			number: {
				aggFunc: 'count',
			},
		}
	}, [])

	const getRowStyle = (params: any) => {
		if (typeof params.data !== 'undefined') {
			return { background: 'white' }
		}
	}

	const icons = useMemo(() => {
		return {
			groupExpanded: '<i class="ag-icon ag-icon-small-down" style=""/>',
			groupContracted: '<i class="ag-icon ag-icon-small-right" style=""/>',
		}
	}, [])

	return (
		<div className="bg-[#F0F0F0] p-[13px] rounded-b-xl rounded-r-xl rounded-tl-none">
			<div className={`quotas ag-theme-alpine ${role === 'admin' ? 'h-[calc(100vh-450px)]' : 'h-[calc(100vh-392px)]'}`}>
				<AgGridReact
					headerHeight={headerHeight}
					floatingFiltersHeight={floatingFiltersHeight}
					rowData={quotasData}
					columnDefs={columnDefs}
					defaultColDef={defaultColDef}
					columnTypes={columnTypes}
					groupDisplayType={'groupRows'}
					rowSelection="multiple"
					animateRows={true}
					onComponentStateChanged={onComponentStateChanged}
					groupSelectsChildren={true}
					suppressRowClickSelection={true}
					suppressAggFuncInHeader={true}
					icons={icons}
					components={components}
					pagination={true}
					paginationAutoPageSize={true}
					suppressContextMenu={true}
					getRowStyle={getRowStyle}
					getContextMenuItems={getContextMenuItems}
				/>
			</div>
			<div className="mb-[25px]  w-full h-[1px] order-1 bg-white"></div>
			{(role === 'admin' && mpData?.provider === undefined) &&
				<div className='flex'>
					<div
						onClick={() => setOpenAddQuotaModal(true)}
						className="mt-[15px] flex justify-center align-center px-[18px] py-[10px] rounded-3xl border-[#4D6071] border-[1px] cursor-pointer"
					>
						<IconPlus className="mr-[11px] mt-[3px]" />
						<h2 className="text-sm font-medium text-[#154365]">Add new Quota</h2>
					</div>
				</div>
			}
			{openAddQuotaModal && (
				<AddQuotaModal
					setOpenAddQuotaModal={setOpenAddQuotaModal}
					openAddQuotaModal={openAddQuotaModal}
					quotaModalRef={quotaModalRef}
					quotaInfoData={quotaInfoData}
					setQuotaInfoData={setQuotaInfoData}
					quotasData={quotasData}
				/>
			)}
			{openDeleteQuotaInfoModal && (
				<DeleteQuotaModal
					quotaInfoData={quotaInfoData}
					setQuotaInfoData={setQuotaInfoData}
					setOpenDeleteQuotaInfoModal={setOpenDeleteQuotaInfoModal}
					openDeleteQuotaInfoModal={openDeleteQuotaInfoModal}
					deleteQuotaInfoRef={deleteQuotaInfoRef}
				/>
			)}
		</div>
	)
}
export default QuotasTab
