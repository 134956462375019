import React from 'react'

interface ButtonProps {
  icon?: any;
  text: string;
  // eslint-disable-next-line no-unused-vars
  onClick?(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void;
  className: string | undefined
}

export default function ActionButton(props: ButtonProps) {
	const onClick = React.useCallback(
		(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
			if (props.onClick === undefined) return
			props.onClick(event)
		},
		[props.onClick]
	)

	return (
		<button
			tabIndex={-1}
			onClick={onClick}
			className={props.className}
		>
			<div className="flex justify-center">
				<div className="mt-[3px]">{props.icon}</div>
				<div className="ml-[11px] text-sm">{props.text}</div>
			</div>
		</button>
	)
}
