import React, { useState, Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { ReactComponent as Close } from '../../../assets/images/icons/close.svg'
import { ReactComponent as CloseBlack } from '../../../assets/images/icons/closeBlack.svg'
import { ReactComponent as Copy } from '../../../assets/images/icons/copy.svg'
import { ReactComponent as Key } from '../../../assets/images/icons/key.svg'
import ActionButton from '../ActionButton'
import Tooltip from '@mui/material/Tooltip'

interface Props {
  setOpenPopupCreateAPIKey: React.Dispatch<React.SetStateAction<boolean>>
  setSignalForOpenPopupCreateAPIKey: React.Dispatch<React.SetStateAction<boolean>>
  openPopupCreateAPIKey: boolean
  apiPopupRef: any
	APIKeyDataString: any
	setAPIKeyDataString: any
}

const ApiKeyPopupModal = ({
	openPopupCreateAPIKey,
	setOpenPopupCreateAPIKey,
	apiPopupRef,
	setSignalForOpenPopupCreateAPIKey,
	APIKeyDataString,
	setAPIKeyDataString
}: Props) => {
	const [tooltipText, setTooltipText] = useState('Copy to clipboard')

	const copyData = () => {
		navigator.clipboard
			.writeText(APIKeyDataString?.apiKey)
			.then(() => {
				setTooltipText('Copied !')
				setTimeout(function () {
					setTooltipText('Copy to clipboard')
				}, 3000)
			})
			.catch((err) => {
				console.log(err.message)
			})
	}

	return (
		<Transition.Root show={openPopupCreateAPIKey} as={Fragment}>
			<Dialog
				as="div"
				className="relative z-[20]"
				initialFocus={apiPopupRef}
				onClose={() => {
					//no action for clicking outside modal
				}}
			>
				<Transition.Child
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 bg-[#000000] bg-opacity-75 transition-opacity " />
				</Transition.Child>

				<div className="fixed inset-0 z-10 overflow-y-auto">
					<div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							enterTo="opacity-100 translate-y-0 sm:scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 translate-y-0 sm:scale-100"
							leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						>
							<Dialog.Panel
								className="relative transform overflow-hidden rounded-lg
               bg-white text-left shadow-xl transition-all sm:my-8 xl:w-[603px] lg:w-[503px]"
							>
								<div className="p-[15px]">
									<div className=" flex flex-row justify-between items-center pt-[9px]">
										<div className="flex items-center">
											<div className="flex items-center w-5 h-4 mb-[3px]">
												<Key />
											</div>
											<div className=" pl-[7px]">
												<h2 className="text-base font-medium tracking-[0.15px] text-[#154365]">API key</h2>
											</div>
										</div>
										<div
											className=""
											onClick={() => {
												setOpenPopupCreateAPIKey(false)
												setSignalForOpenPopupCreateAPIKey(false)
												setAPIKeyDataString()
											}}
										>
											<Close className="text-[#4D6071] cursor-pointer" />
										</div>
									</div>
									<div className="mt-[10px] w-full h-[0.75px] order-1 bg-[#A7ACB3]"></div>
									<div className="mt-[23px] bg-[#F0F0F0] rounded-xl flex items-center flex-wrap p-[13px]">
										<div className="items-center text-[#154365] text-[14px] flex flex-wrap">
											<p>Copy and store this key safe - it cannot be recovered!</p>
										</div>
										<div className="bg-white w-full h-[48px] mt-[15px] px-[25px] flex justify-between items-center">
											<div>{APIKeyDataString?.apiKey}</div>
											<Tooltip title={tooltipText}>
												<Copy className="text-[#4D6071] cursor-pointer" onClick={copyData}/>
											</Tooltip>
										</div>
									</div>
									<div className="mt-[30px] flex justify-end">
										<div className="ml-[20px]"  ref={apiPopupRef}>
											<ActionButton
												className="bg-[#D5E4F7] text-[#001D32] font-medium py-2.5 pl-[21px] pr-[24px] rounded-full h-[40px]"
												text="Close"
												icon={<CloseBlack />}
												onClick={() => {
													setSignalForOpenPopupCreateAPIKey(false)
													setOpenPopupCreateAPIKey(false)
													setAPIKeyDataString()
												}}
											/>
										</div>
									</div>
								</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition.Root>
	)
}

export default ApiKeyPopupModal
