import React from 'react'
import { Link } from 'react-router-dom'

interface Props {
  text: string;
  to: string;
  startIcon?: JSX.Element;
  openSideBar: boolean;
}

export default function LinkButton({ text, to, startIcon, openSideBar }: Props) {
	return (
		<Link
			to={to}
			className="block hover:bg-[#96CCFF] bg-[#154365] text-white font-bold py-2 px-4 w-full rounded-full text-left"
		>
			<div className="flex items-center">
				{startIcon ? <div className={`${openSideBar ? 'block w-6 h-[16px] mb-[4px] mr-[8px]' : 'h-[16px] mb-[4px]'}`}>{startIcon}</div> : null}
				<div className="block text-sm">{text}</div>
			</div>
		</Link>
	)
}
