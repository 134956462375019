import React, { useState, Fragment, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { ReactComponent as Close } from '../../../assets/images/icons/close.svg'
import { ReactComponent as Check } from '../../../assets/images/icons/check.svg'
import { ReactComponent as Conflict } from '../../../assets/images/icons/infoMessage.svg'
import ActionButton from '../ActionButton'
import MpCellRenderWithIcon from '../Table/MpCellRenderWithIcon'

interface Props {
	setOpenConflictVmpMpModal: React.Dispatch<React.SetStateAction<boolean>>;
	openConflictVmpMpModal: boolean;
	conflictVmpRef: React.MutableRefObject<null>;
	rowData: any;
	setSignalForOpenCalculatedModal: React.Dispatch<React.SetStateAction<boolean>>;
	setSignalForCancelConflictModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const ConflictVmpModal = ({
	setOpenConflictVmpMpModal,
	openConflictVmpMpModal,
	conflictVmpRef,
	rowData,
	setSignalForOpenCalculatedModal,
	setSignalForCancelConflictModal
}: Props) => {

	const [checkedMps, setCheckedMps] = useState([])

	useEffect(() => {
		setCheckedMps(rowData.filter((el: any) => el.checked))
	}, [])

	const filteredRowData = checkedMps?.filter((data: any) => {
		return data.name !== '' && data.provider
	})
	
	return (
		<Transition.Root show={openConflictVmpMpModal} as={Fragment}>
			<Dialog
				as="div"
				className="relative z-[20]"
				initialFocus={conflictVmpRef}
				onClose={() => {
					//no action for clicking outside modal
				}}
			>
				<Transition.Child
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 bg-[#000000] bg-opacity-75 transition-opacity " />
				</Transition.Child>

				<div className="fixed inset-0 z-10 overflow-y-auto">
					<div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							enterTo="opacity-100 translate-y-0 sm:scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 translate-y-0 sm:scale-100"
							leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						>
							<Dialog.Panel className="relative transform overflow-hidden rounded-lg
               bg-white text-left shadow-xl transition-all sm:my-8 xl:w-[639px] xl:h-[585px] lg:w-[539px] lg:h-[485px]">
								<div className="p-[15px]">
									<div className=" flex flex-row justify-between items-center pt-[9px]">
										<div className="flex items-center">
											<div className="flex mb-[2px] items-center w-5 h-5">
												<Conflict />
											</div>
											<div className="w-[292px] pl-[7px]">
												<h2 className="text-base font-medium tracking-[0.15px] text-[#154365]">
												Conflicting selection
												</h2>
											</div>
										</div>
										<div
											className=""
											onClick={() => {
												setOpenConflictVmpMpModal(false)
												setSignalForCancelConflictModal(true)
											}}
										>
											<Close className="text-[#4D6071] cursor-pointer" />
										</div>
									</div>
									<div className="mt-[10px] w-full h-[0.75px] order-1 bg-[#A7ACB3]"></div>
									<div className="mt-[33px] bg-[#F0F0F0] rounded-xl p-[13px]">
										<div className='text-[14px] text-[#004368] font-normal'>
										Your selection contains measuring points that cannot be used to create measuring point groups. They are removed in order to continue.
										</div>
										<div className='mt-[18px] xl:h-[325px] lg:h-[225px] overflow-auto'>
											{filteredRowData.map((el: any)=> {
												return (
													<div key={el.id} className='bg-[white] pl-[12px] h-[53px] mb-[1px] font-medium text-[12px] text-[#51606F] flex align-middle '>
														<MpCellRenderWithIcon
															data={el}
															length={90}
														/>
													</div>
												)
											})}
										</div> 
									</div>
									<div className="mt-[30px] flex justify-end">
										<div ref={conflictVmpRef}>
											<ActionButton
												className="bg-[#154365] text-white font-medium py-2.5 pl-[21px] pr-[24px] rounded-full h-[40px]"
												text="OK"
												icon={<Check />}
												onClick={() => {
													setOpenConflictVmpMpModal(false)
													setSignalForOpenCalculatedModal(true)
												}}
											/>
										</div>
									</div>
								</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition.Root>
	)
}

export default ConflictVmpModal
