import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'

export type Role = 'admin' | 'viewer' | ''

export interface AppState {
    business: string
    role: Role
}

const initialState: AppState = {
	business: '',
	role: ''
}

export const counterSlice = createSlice({
	name: 'app',
	initialState,
	reducers: {
		businessLoaded: (state, { payload }: PayloadAction<AppState>) => {
			return payload
		},
		businessUnloaded: (state) => {
			return initialState
		}
	},

})

export const { businessLoaded, businessUnloaded } = counterSlice.actions

export default counterSlice.reducer
