import { ICellRendererParams } from 'ag-grid-community'
import React from 'react'

const ForecastFilterFormatter = (params: ICellRendererParams) => {
	const forecast = params.value

	if (forecast === 'VIOLATED') {
		return (<div className='forecast-filter'><div style={{ backgroundColor: '#BA1A1A', marginRight: '4px' }}></div> Violated</div>)
	} else if (forecast === 'ENDANGERED') {
		return (<div className='forecast-filter'><div style={{ backgroundColor: '#FFBB00', marginRight: '4px' }}></div> Endangered</div>)
	} else if (forecast === 'ON_TRACK') {
		return (<div className='forecast-filter'><div style={{ backgroundColor: '#154365', marginRight: '4px' }}></div> On track</div>)
	} else if (forecast === 'QUOTA_NOT_SET') {
		return (<div className='forecast-filter'><div style={{ backgroundColor: '#7DC2FD', marginRight: '4px' }}></div> No limitation</div>)
	}

	return '(No forecast)'
}

export default ForecastFilterFormatter
