import React from 'react'

const GroupCellRenderer = (props: any) => {

	const pad = (num: any, size: number) => {
		if (props.node?.allLeafChildren !== undefined && props.node.allLeafChildren[0]?.data.name === '') {
			return '00'
		} else {
			num = num.toString()
			while (num.length < size) num = '0' + num
			return num
		} 
	}

	return (
		<div className="groupRender">
			<div className="businessName ">{props.value === 'self' ? 'My Business': props.value}</div>
			<div>
				<span className="consumptionCount ">
					<span>{pad(props.node.aggData.consumptionSpent, 2)}</span>
				</span>
				<span className="measuringPoints cursor-pointer">
          Measuring points
				</span>
			</div>
		</div>
	)
}

export default GroupCellRenderer
