import React, { useState } from 'react'
import { Link, useParams, useLocation } from 'react-router-dom'
import { ReactComponent as Logo } from '../../../assets/images/logo.svg'
import { ReactComponent as DashboardIcon } from '../../../assets/images/icons/dashboard.svg'
import { ReactComponent as UserIcon } from '../../../assets/images/icons/users.svg'
import { ReactComponent as SettingsIcon } from '../../../assets/images/icons/nav-settings.svg'
import { ReactComponent as MenuIcon } from '../../../assets/images/icons/icon-button.svg'
import { ReactComponent as NotificationIcon } from '../../../assets/images/icons/notifications.svg'
import { ReactComponent as IconPlus } from '../../../assets/images/icons/icon+.svg'
import LinkButton from '../../components/LinkButton'
import AddNotificationModal from '../../components/Modals/AddNotificationModal '
import { useAppSelector } from '../../hooks'

interface Props {
  setOpenSideBar: React.Dispatch<React.SetStateAction<boolean>>;
  openSideBar: boolean;
}

export default function Navigation({ setOpenSideBar, openSideBar }: Props) {

	const [openAddNotificationModal, setOpenAddNotificationModal] = useState(false)

	const { id } = useParams()
	const { role } = useAppSelector((state) => state.app)
	const location = useLocation()

	const navigation = role === 'admin' ? 
		[
			{ name: 'Dashboard', to: '', icon: <DashboardIcon /> },
			{ name: 'Users', to: 'users', icon: <UserIcon /> },
			{ name: 'Notifications', to: 'notifications', icon: <NotificationIcon /> },
		]
		:
		[
			{ name: 'Dashboard', to: '', icon: <DashboardIcon /> },
			{ name: 'Users', to: 'users', icon: <UserIcon /> },
		]

	return (
		<div>
			<div
				className={`flex flex-row  w-full pb-2 mb-14 items-center border-b border-blue-gray-100
      ${openSideBar ? '' : 'pl-[8px]'}
      `}
			>
				<Logo />
				{openSideBar ? (
					<>
						<div className="flex items-baseline">
							<div className=" font-normal text-[12px] pl-[13px] tracking-wide text-[#004368]">aliunid</div>
							<div className=" text-[14px] pl-[5px] font-medium tracking-wide text-[#004368]">OPERATOR</div>
							<Link
								to={'changelog'}
								className="hover:bg-blue-100 px-1 rounded-md ml-[10px]  mt-[4px]"
							>
								<div className="text-[10px] text-[#004368]">v 1.5</div>
							</Link>
						</div>
						<MenuIcon
							className="ml-auto cursor-pointer"
							onClick={() => setOpenSideBar(!openSideBar)}
						/>
					</>
				) : null}
			</div>
			{!openSideBar ? (
				<MenuIcon
					className="ml-auto cursor-pointer absolute left-[30px] top-[58px]"
					style={{transform:'rotate(180deg)'}}
					onClick={() => setOpenSideBar(!openSideBar)}
				/>
			) : null
			}
			<ul
				className={`${openSideBar ? '' : 'absolute left-[14px] top-[106px]'}`}
			>
				{navigation.map(({ name, to, icon }) => (
					<li className="py-2" key={name}>
						<LinkButton
							text={openSideBar ? name : ''}
							to={to}
							startIcon={icon ? icon : undefined}
							openSideBar={openSideBar}
						/>
					</li>
				))}
				{(location.pathname === `/businesses/${id}/notifications` && role === 'admin') &&
					<li
						onClick={() => setOpenAddNotificationModal(true)}
						className="flex hover:bg-[#e4f1ff] bg-[#CEE5FF] cursor-pointer font-bold py-2 pl-[18px] w-full rounded-full text-left"
					>
						<div className='flex justify-center items-center mr-[16px] w-3.5 h-[18px]'>
							<IconPlus />
						</div>
						{openSideBar && <h2 className="text-sm font-medium text-[#154365]">Add Notification</h2>}
					</li>
				}
			</ul>
			{role === 'admin' &&
				<ul className={`${openSideBar ? 'absolute w-[217px] bottom-[55px]' : 'absolute left-[14px] bottom-[55px]'}`}>
					<li>
						<LinkButton
							text={openSideBar ? 'Settings' : ''}
							to={'settings'}
							startIcon={<SettingsIcon />}
							openSideBar={openSideBar}
						/>
					</li>
				</ul>
			}
			{openAddNotificationModal && (
				<AddNotificationModal
					setOpenAddNotificationModal={setOpenAddNotificationModal}
					openAddNotificationModal={openAddNotificationModal}
				/>
			)}
		</div>
	)
}
