import React, { useState, Fragment } from 'react'
import { useParams } from 'react-router-dom'
import { Select, Option } from '@material-tailwind/react'
import { Dialog, Transition } from '@headlessui/react'
import { ReactComponent as Close } from '../../../assets/images/icons/close.svg'
import { ReactComponent as CloseBlack } from '../../../assets/images/icons/closeBlack.svg'
import { ReactComponent as Check } from '../../../assets/images/icons/check.svg'
import ActionButton from '../ActionButton'
import {ReactComponent as Arrow} from '../../../assets/images/icons/trending_flat.svg'
import { ReactComponent as AlertRed } from '../../../assets/images/icons/infoMessageRed.svg'
import { dependencyContainer } from '../../store'
import { useMutation } from '@tanstack/react-query'
import {queryClient} from '../../../index'

interface Props {
  mpData: any
	setOpenMpShareDialog: React.Dispatch<React.SetStateAction<boolean>>;
  openMpShareDialog: boolean;
  openMpShareDialogRef: any;
	businessesData: any;
}

const MpShareDialog = ({
	mpData,
	setOpenMpShareDialog,
	openMpShareDialog,
	openMpShareDialogRef,
	businessesData
}: Props) => {

	const shared = mpData.businessGroup !== 'self'

	const [businessId, setBusinessId] = useState('')	
	const [error, setError] = useState('')

	const businessIdParam = useParams().id

	const shareMp = useMutation({
		mutationFn: () => dependencyContainer.dependency.measuringPointsApi.shareMeasuringPoint(businessIdParam as string, mpData?.id, businessId),
		onSuccess: async () => {
			setOpenMpShareDialog(false)
			queryClient.invalidateQueries({ queryKey: ['dashboardData'] })
		},
		onError: (error: any) => {
			setError(error.response?.data?.message ? error.response?.data?.message : 'Internal server error !')
		},
	})

	const unshareMp = useMutation({
		mutationFn: () => dependencyContainer.dependency.measuringPointsApi.cancelSharingMeasuringPoint(businessIdParam as string, mpData?.id),
		onSuccess: async () => {
			setOpenMpShareDialog(false)
			queryClient.invalidateQueries({ queryKey: ['dashboardData'] })
		},
		onError: (error: any) => {
			setError(error.response?.data?.message ? error.response?.data?.message : 'Internal server error !')
		},
	})

	const filteredBusinesses = businessesData?.filter((business: any)=> business.businessId !== mpData.businessGroupId)

	const setBusinessOnClick = (business: any) => {
		setBusinessId(business)
		setError('')
	}

	return (
		<Transition.Root show={openMpShareDialog} as={Fragment}>
			<Dialog
				as="div"
				className="relative z-[20]"
				initialFocus={openMpShareDialogRef}
				onClose={()=>{
					//no action for clicking outside modal
				}}
			>
				<Transition.Child
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 bg-[#000000] bg-opacity-75 transition-opacity " />
				</Transition.Child>

				<div className="fixed inset-0 z-10 overflow-y-auto">
					<div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							enterTo="opacity-100 translate-y-0 sm:scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 translate-y-0 sm:scale-100"
							leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						>
							<Dialog.Panel
								className='relative transform overflow-hidden rounded-lg
						 bg-white text-left shadow-xl transition-all sm:my-8 xl:w-[603px] lg:w-[503px]'
							>
								<div className="p-[15px]">
									<div className=" flex flex-row justify-between items-center pt-[9px]">
										<div className="flex items-center">
											{!shared ? 
												<div className="flex items-center w-5 h-5">
													<Arrow />
												</div>
												:
												<div className="flex items-center w-5 h-5 mr-[5px]" style={{transform:'rotate(180deg)'}}>
													<Arrow />
												</div>
											}
											<div className=" pl-[7px]">
												<h2 className="text-base font-medium tracking-[0.15px] text-[#154365]">
													{!shared ? `Move "${mpData?.name}" Measuring Point to...` : `Unshare "${mpData?.name}" Measuring Point`}
												</h2>
											</div>
										</div>
										<div
											className=""
											onClick={()=>setOpenMpShareDialog(false)}
										>
											<Close className="text-[#4D6071] cursor-pointer" />
										</div>
									</div>
									<div className="mt-[10px] w-full h-[0.75px] order-1 bg-[#A7ACB3]"></div>
									{!shared ? <div className="mt-[33px] bg-[#F0F0F0] rounded-xl h-[270px]">
										<div className="p-[13px]">
											<div className="w-full relative mb-[1px] mpShare selectDiv">
												<Select 
													placeholder=''
													label="Business"
													size='lg'
													value={businessId}
													onChange={setBusinessOnClick}
													style={{fontSize: '16px'}}
													animate={{
														mount: { y: 0 },
														unmount: { y: 15 },
													}}
												>
													{filteredBusinesses?.map((business: any) => (
														<Option
															key={business?.businessId}
															className="text-sm text-[#001F24] tracking-[0.5px] hover:bg-[#004368] hover:bg-opacity-[0.12] cursor-pointer h-[32px] py-[6px] pl-[16px] "
															value={business?.businessId}
														>
															{business?.business}
														</Option>
													))}
												</Select>
											</div>
										</div>
										{error === 'Please select business !' && (
											<div className="flex justify-start ml-[15px] text-[#ff0000] text-sm">
												<AlertRed  className='mt-[1px]'/>
												<h2  className='ml-[8px]'>{error}</h2>
											</div>
										)}
									</div> : null}
									{(error !== 'Please select business !' && error !== '') && (
										<div className="flex justify-start fixed ml-[15px] mt-[10px] text-[#ff0000] text-sm">
											<AlertRed  className='mt-[1px]'/>
											<h2  className='ml-[8px]'>{error}</h2>
										</div>
									)}
									<div className="mt-[30px] flex justify-end">
										<div ref={openMpShareDialogRef}>
											<ActionButton
												className="bg-[#D5E4F7] text-[#001D32] font-medium py-2.5 pl-[21px] pr-[24px] rounded-full h-[40px]"
												text="Cancel"
												icon={<CloseBlack />}
												onClick={()=>setOpenMpShareDialog(false)}
											/>
										</div>
										<div ref={openMpShareDialogRef} className="ml-[20px]">
											<ActionButton
												className="bg-[#154365] text-white font-medium py-2.5 pl-[21px] pr-[24px] rounded-full h-[40px]"
												text={!shared ? 'Save' : 'Unshare'}
												icon={<Check />}
												onClick={() => {
													if(!shared) {
														if(businessId === ''){
															setError('Please select business !')
														} else {
															shareMp.mutate()
														}
													}
													else{
														unshareMp.mutate()
													}
												}}
											/>
										</div>
									</div>
								</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition.Root>
	)
}

export default MpShareDialog
