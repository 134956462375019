import { useParams, useNavigate } from 'react-router-dom'
import { AgGridReact } from 'ag-grid-react'
import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react'
import TagsRenderer from '../../components/Table/TagsRenderer'
import CustomHeader from '../../components/Table/CustomHeader'
import { ReactComponent as IconPlus } from '../../../assets/images/icons/addWhite.svg'
import Breadcrumb from '../../components/Breadcrumb/Index'
import { ReactComponent as List } from '../../../assets/images/icons/list.svg'
import { ReactComponent as MpList } from '../../../assets/images/icons/mpList.svg'
import { ReactComponent as Send } from '../../../assets/images/icons/send.svg'
//import { ReactComponent as Unhide } from '../../../assets/images/icons/history.svg'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-alpine.css'
import 'ag-grid-enterprise'
import GroupCellRenderer from '../../components/Table/GroupCellRenderer'
import './ag-grid-power.css'
import AppKeycloak from '../../Keycloak'

import { GetRowIdFunc, GetRowIdParams } from 'ag-grid-community'
import { LicenseManager } from 'ag-grid-enterprise'
import AddBusinessModal from '../../components/Modals/AddBusinessModal'
import AppMQTT from '../../AppMQTT'
import { dependencyContainer } from '../../store'
import { useQuery } from '@tanstack/react-query'
import ShareMpColumn from '../../components/Table/ShareMpColumn'
import MpShareDialog from '../../components/Modals/MpShareDialog'
import { useAppSelector } from '../../hooks'
import { GetContextMenuItemsParams } from 'ag-grid-community'
import ForecastRenderer from '../../components/Table/ForecastRenderer'
import ForecastFilterFormatter from '../../components/Table/ForecastFilterFormatter'
import DeleteMpModal from '../../components/Modals/DeleteMpModal'
import CalculatedMpModal from '../../components/Modals/CalculatedMpModal'
import MpCellRenderWithIcon from '../../components/Table/MpCellRenderWithIcon'
import { AppState } from '../../appSlice'
import AddNotificationModal from '../../components/Modals/AddNotificationModal '
import ConflictVmpModal from '../../components/Modals/ConflictVmpModal'
//import {queryClient} from '../../../index'

LicenseManager.setLicenseKey(
	'CompanyName=Appmodule AG,LicensedGroup=aliunid grid / power,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=2,AssetReference=AG-036901,SupportServicesEnd=31_January_2024_[v2]_MTcwNjY1OTIwMDAwMA==4c9d5d4b04b142bc6b50ed895e5b5ef3'
)

export default function Dashboard() {
	const [openAddBusinessModal, setOpenAddBusinessModal] = useState(false)
	const [openCreateCalculatedMpModal, setOpenCreateCalculatedMpModal] = useState(false)
	const [isConnect, setIsConnect] = useState(false)
	const [openAddNotificationModal, setOpenAddNotificationModal] = useState(false)
	const [signalForCancelConflictModal, setSignalForCancelConflictModal] = useState(false)

	const [rowData, setRowData] = useState<any>([])
	const [openMpShareDialog, setOpenMpShareDialog] = useState(false)
	const [mpData, setMpData] = useState<any>({})
	const [openDeleteMpModal, setOpenDeleteMpModal] = useState(false)
	const [openConflictVmpMpModal, setOpenConflictVmpMpModal] = useState(false)
	const [signalForOpenCalculatedModal, setSignalForOpenCalculatedModal] = useState(false)
	const [businessesDataForShareList, setBusinessesDataForShareList] = useState<any>([])
	const [mqttTopics, setMQTTTOpics] = useState<string[]>([])
	const [siotIdToMPId, setSiotToMPId] = useState<any>({})
	//const [hide, setHide] = useState(false)
	let client = null as any

	const cancelButtonRef = useRef(null)
	const openMpShareDialogRef = useRef(null)
	const deleteMpRef = useRef(null)
	const calculatedMpRef = useRef(null)
	const conflictVmpRef = useRef(null)

	const gridRef = useRef<AgGridReact>(null)
	const navigate = useNavigate()

	const { role }: AppState = useAppSelector((state) => state.app)
	const {id: businessIdParam} = useParams()

	const headerHeight = 25

	const floatingFiltersHeight = 30

	const columnDefs = [
		{
			cellRenderer: (row: any) => (
				<ShareMpColumn
					data={row.data}
				/>
			),
			field: 'mp',
			filter: false,
			maxWidth: 40,
			sortable: false,
			onCellClicked: function (params: any) {
				if (params.node.data.name !== '') {
					params.api.contextMenuFactory.showMenu(
						params.node,
						params.column,
						params.value,
						params.event
					)
				}
			}
		},
		{
			field: 'checkbox',
			filter: false,
			maxWidth: 30,
			sortable: false,
			headerCheckboxSelection: true,
			checkboxSelection: (node: any) => {
				if(node.data?.name !== ''){
					return true
				} else {
					return false
				}
			},
			cellStyle: function() {
				return { display: 'flex', justifyContent: 'center',marginLeft:'3px'}
			},
		},
		{
			field: 'businessGroup',
			rowGroup: true,
			hide: true,
		},
		{
			field: 'name',
			headerName: 'Measuring Point name',
			minWidth: 400,
			cellClass: 'first-cell',
			headerComponentParams: { menuIcon: 'electric_meter' },
			cellRenderer: (row: any) => (
				<MpCellRenderWithIcon
					data={row.data}
					length={100}
				/>
			),
			cellStyle: function() {
				return { wordBreak: 'break-word' }
			},
		},
		{
			field: 'consumptionSpent',
			headerName: 'kW Live',
			type: 'number',
			valueFormatter: (params: any) => {
				if (params.value === 0) return params.value
				else if (params.value === null) return null
				else if (params.value) return params.value?.toFixed(2)
				else return '-'
		
			},
			headerComponentParams: { menuIcon: 'flash_on' },
			cellClass: 'flex-right-align',
			filter: false,
			minWidth: 130,
		},
		{
			field: 'consumptionForecast',
			headerName: 'Forecast',
			filter: 'agSetColumnFilter',
			filterParams: {
				buttons: ['reset', 'apply'],
				suppressSelectAll: true,
				suppressBlanks: true,
				cellHeight: 48,
				suppressMiniFilter: true,
				cellRenderer: ForecastFilterFormatter
			},
			minWidth: 185,
			//maxWidth: 185,
			headerComponentParams: { menuIcon: 'apps' },
			cellStyle: function() {
				return { display: 'flex', justifyContent: 'center'}
			},
			cellRenderer: (row: any) => (
				<ForecastRenderer
					data={row.data}
				/>
			),
		},
		{
			field: 'quota',
			headerName: 'kWh Quota',
			headerComponentParams: { menuIcon: 'dynamic_form' },
			cellClass: 'text-[#96CCFF] flex-right-align',
			minWidth: 160,
		},
		{
			field: 'quotaUsed',
			headerName: 'of Quota used',
			headerComponentParams: { menuIcon: 'percent' },
			cellClass: 'flex-right-align',
			minWidth: 170,
		},
		{
			field: 'used',
			headerName: 'kWh used',
			headerComponentParams: { menuIcon: 'flash_on' },
			cellClass: 'flex-right-align',
			minWidth: 150,
		},
		{
			field: 'tags',
			filter: 'agSetColumnFilter',
			filterParams: {
				buttons: ['reset', 'apply'],
				suppressSelectAll: true,
				cellHeight: 48,
				suppressMiniFilter: true,
			},
			cellRenderer: TagsRenderer,
			headerComponentParams: { menuIcon: 'style' },
			autoHeight: true,
			wrapText: true,
			cellStyle: { justifyContent: 'center' },
			minWidth: 240,
			maxWidth: 280,
		},
	]

	const groupDefaultExpanded = 1

	const rowStyle = { cursor: 'pointer' }

	const defaultColDef = useMemo(
		() => ({
			sortable: true,
			filter: 'agTextColumnFilter',
			floatingFilter: true,
			wrapText: true,
			suppressMovable: true,
			flex: 1,
			suppressMenu: true,
			floatingFilterComponentParams: {
				suppressFilterButton: true,
			},
		}),
		[]
	)

	const groupRowRendererParams = useMemo(() => {
		return {
			innerRenderer: GroupCellRenderer,
			checkbox: true,
			suppressCount: true,
		}
	}, [])

	const columnTypes = useMemo(() => {
		return {
			number: {
				aggFunc: 'count',
			},
		}
	}, [])

	const onFirstDataRendered = useCallback(() => {
		setTimeout(() => {
			document
				.querySelectorAll('.ag-theme-alpine input[class^=ag-][type=text]')
				.forEach((obj) => {
					obj.setAttribute('placeholder', 'Search')
				})
		}, 5)
		setTimeout(() => {
			document
				.querySelectorAll(
					'.ag-theme-alpine .ag-disabled input[class^=ag-][type=text]'
				)
				.forEach((obj) => {
					obj.setAttribute('placeholder', 'Select')
					obj.removeAttribute('disabled')
				})
		}, 10)
	}, [])

	const getRowHeight = (params: any) => (params.node.group ? 92 : 50)

	const components = useMemo(() => {
		return {
			agColumnHeader: CustomHeader,
		}
	}, [])

	useQuery({
		queryKey: ['dashboardData',businessIdParam,openCreateCalculatedMpModal,openAddNotificationModal,openConflictVmpMpModal],
		queryFn:() =>  dependencyContainer.dependency.dashboardApi.dashboardData(businessIdParam as string),
		onSuccess: (data) => {
			// data.map((el: any) => {
			// 	if(el.hide) {
			// 		setHide(true)
			// 	}
			// })
			//here logic for hidden mps(set rowData only with hide: false)
			//const filteredData = data.filter((mp: any) => mp.hide === false || mp.hide === undefined)
			//console.log(filteredData)
			setRowData(data as any)
		}
	})

	useQuery({
		queryKey: ['dashboardDataNoMap',businessIdParam],
		queryFn:() =>  dependencyContainer.dependency.measuringPointsApi.dashboardWithoutMap(businessIdParam as string),
		onSuccess: (data) => {
			setBusinessesDataForShareList(data)
		}
	})

	// const setHideMp = useMutation({
	// 	mutationFn: () => dependencyContainer.dependency.measuringPointsApi.setHideMeasuringPoint(mpData?.businessGroupId, mpData?.id, mpData?.name),
	// 	onSuccess: async () => {
	// 		queryClient.invalidateQueries({ queryKey: ['dashboardData'] })
	// 	}
	// })

	// const setShowAllMps = useMutation({
	// 	mutationFn: () => dependencyContainer.dependency.measuringPointsApi.setShowAllMeasuringPoints(businessIdParam as string),
	// 	onSuccess: async () => {
	// 		queryClient.invalidateQueries({ queryKey: ['dashboardData'] })
	// 	}
	// })

	const getSelectedRowData = () => {
		const selectedData = gridRef.current?.api.getSelectedRows()
		selectedData?.forEach((selectedEl: any)=> {
			rowData.forEach((element: any) => {
				if(element.id === selectedEl.id){
					element.checked = true
				}
			})
		})
		return rowData
	}

	useEffect(() => {
		if(gridRef.current !== null && gridRef.current?.api !== undefined){
			if(!openCreateCalculatedMpModal){
				gridRef.current?.api.forEachNode((node) => {
					node.setSelected(false)
				})
			}}
	}, [openCreateCalculatedMpModal, gridRef])

	useEffect(() => {
		if(gridRef.current !== null && gridRef.current?.api !== undefined){
			if(!openConflictVmpMpModal && !openCreateCalculatedMpModal && signalForCancelConflictModal){
				gridRef.current?.api.forEachNode((node) => {
					node.setSelected(false)
				})
				setSignalForCancelConflictModal(false)
			}}
	}, [openConflictVmpMpModal, gridRef])
	
	useEffect(() => {
		if(gridRef.current !== null && gridRef.current?.api !== undefined){
			if(!openAddNotificationModal){
				gridRef.current?.api.forEachNode((node) => {
					node.setSelected(false)
				})
			}}
	}, [openAddNotificationModal, gridRef])

	const checkVirtualMpConflicts = () => {
		setMpData({})
		const filtered = getSelectedRowData().filter((el: any) => el.checked && el.provider)
		if(filtered.length !== 0) {
			setOpenConflictVmpMpModal(true)
		} else {
			setOpenCreateCalculatedMpModal(true)
		}
	}

	useEffect(() => {
		if(signalForOpenCalculatedModal){
			setOpenCreateCalculatedMpModal(true)
		}
	}, [signalForOpenCalculatedModal])
	
	useEffect(() => {
		if (businessIdParam === undefined) return
		let mqtt: AppMQTT | undefined = undefined
		try {
			mqtt = new AppMQTT(dependencyContainer.dependency.wssUrl, AppKeycloak.getAppKeycloak())
			client = mqtt.connect()
				.on('connect', () => {
					setIsConnect(true)
					mqttTopics.forEach(topic => client.subscribe(topic))
				})
				.on('error', () => {
					setIsConnect(false)
					//console.error(e)
				})
				.on('timeout', (isWithError: any) => {
					setIsConnect(false)
					console.error(isWithError)
					// TODO: something more?
				})
				.on('message', (_topic: any, message: any) => {
					// `s2/aliunid/${bId}/dat/em/${siotId}`
					const parts = _topic.split('/')
					const data = JSON.parse(message.toString()).value.sys.kW
					updateRow({
						id: siotIdToMPId[parts[4]],
						consumptionSpent: data,
					})
				})
		} catch (e) {
			console.error(e)
		}
		return () => {
			if (mqtt !== undefined)
				mqtt.disconnect()
		}
	}, [businessIdParam, rowData])

	// const deInitMQTT = () => {
	// 	if (client) {
	// 		client.end()
	// 		console.log('DISCONNECTED from broker')
	// 	}
	// }

	const subToAll = () => {
		if (!rowData) return
		const temp = siotIdToMPId || {}
		for (let i = 0; i < rowData.length; i++) {
			//const bId: any = rowData[i].businessGroupId
			const siotId: any = rowData[i].siotId !== '' ? rowData[i].siotId : null
			const mpId: any = rowData[i].id

			const topic = `s2/aliunid/${businessIdParam}/adm/${siotId}`

			temp[siotId] = mpId

			if (client && isConnect) {
				client.subscribe(topic)
			} else {
				mqttTopics.push(topic)
				setMQTTTOpics(mqttTopics)
			}
			setSiotToMPId(temp)
		}
	}

	useEffect(() => {
		return subToAll()
	}, [rowData])

	const bc = [
		{
			name: 'Business Overview',
			link: '',
		},
	]

	const icons = useMemo(() => {
		return {
			groupExpanded: '<i class="ag-icon ag-icon-small-down" style=""/>',
			groupContracted: '<i class="ag-icon ag-icon-small-right" style=""/>',
		}
	}, [])

	const getRowStyle = (params: any) => {
		const data = params.data
		if(data === null || data === undefined) return
		if(data.provider !== null && data.provider !== undefined && data.provider.linked) {
			return { background: '#ebebeb' }
		} else {
			return { background: 'white' }
		}
	}

	const parentRowClicked = (row: any) => {
		if (typeof row.data === 'undefined') {
			const businessFiltered: any = rowData?.filter(
				(data: any) => data.businessGroup === row.node.key
			)
			if (businessFiltered[0].businessGroup === 'self') {
				navigate('self')
			} else navigate(`${businessFiltered[0].businessGroupId}`)

		}
	}

	const arrowIcon = '<img border="0" width="15" height="15" src="/images/trending_flat.svg"/>'
	const searchIcon = '<img border="0" width="15" height="15" src="/images/icon.svg"/>'
	const deleteIcon = '<img border="0" width="15" height="15" src="/images/delete_icon.svg"/>'
	//const hideIcon = '<img border="0" width="15" height="15" src="/images/mpShared.svg"/>'
	const reverseIcon = `<div style="transform:rotate(180deg); margin-right: 8px">${arrowIcon}</div>`
	//const editIcon = '<img border="0" width="15" height="15" src="/images/edit_icon.svg"/>'

	const businessExists = (businessName: string) => {
		return rowData?.some(function(el: any) {
			return el.businessGroup !== businessName
		}) 
	}

	const getContextMenuItems = useCallback((params: GetContextMenuItemsParams) => {
	
		const data = params.node?.data
		const shared = data.businessGroup !== 'self'
		const hasActiveProvider = data.provider !== null && data.provider !== undefined && data.provider.linked

		const result = []
		if (params.node) {
			// if(data.virtual)
			// 	result.push(
			// 		{
			// 			name: 'Edit',
			// 			icon: editIcon,
			// 			action: function () {
			// 				setOpenCreateCalculatedMpModal(true)
			// 			}
			// 		},
			// 		'separator',
			// 	)
			if(!hasActiveProvider && businessExists('self'))
				result.push(
					{
						name: shared ? 'Unshare' : 'Move to',
						icon: shared ? reverseIcon : arrowIcon,
						action: function () {
							setOpenMpShareDialog(true)
						}
					},
					'separator',
				)
			if (!shared && !hasActiveProvider && role === 'admin')
				result.push(
					{
						name: 'Delete',
						icon: deleteIcon,
						action: function () {
							setOpenDeleteMpModal(true)
						}
					},
					'separator',
				)
			result.push(
				{
					name: 'Details',
					icon: searchIcon,
					action: function () {
						if (typeof params.node?.data !== 'undefined' && params.node.data.name !== '') {
							navigate(`self/measuring-points/${params.node.data.id}`)
						} else {
							setMpData(params.node?.data)
						}
					}
				},
				'separator',
			)
			// if(role === 'admin')
			// 	result.push(
			// 		{
			// 			name: 'Hide',
			// 			icon: hideIcon,
			// 			action: function () {
			// 				//backend call to hide mp
			// 				//setHideMp.mutate()
			// 			}
			// 		},
			// 	)
		}
		return result
	},[rowData])

	const cellClicked = (row: any) => {
		if (typeof row.data !== 'undefined' && row.data.name !== '' && row.column.colId !== 'mp' && row.column.colId !== 'checkbox') {
			navigate(`self/measuring-points/${row.data.id}`)
		} else {
			setMpData(row.data)
		}

	}

	/* WEBSOCKET LIVE UPDATE ******************************/

	const getRowId = useMemo<GetRowIdFunc>(() => {
		return (params: GetRowIdParams) => {
			return params.data.id
		}
	}, [])

	const updateRow = useCallback((message: any) => {
		try {
			const rowNode = gridRef.current!.api.getRowNode(message.id)!
			rowNode.setDataValue('consumptionSpent', message.consumptionSpent)
		} catch (e) {
			// console.log(e)
		}
	}, [])

	/*************************************** */

	return (
		<div className="w-full pl-[25px]">
			<div className='relative'>
				<div className='flex absolute right-[0px] bottom-[12px]'>
					{role === 'admin' &&
					<div
						onClick={() => setOpenAddBusinessModal(true)}
						className="mt-[15px] hover:bg-[#96CCFF] flex justify-center align-center px-[18px] py-[10px] rounded-3xl bg-[#154365] cursor-pointer"
					>
						<IconPlus className="mr-[11px] mt-[3px]" />
						<h2 className="text-sm font-medium text-white">Add new Business</h2>
					</div>
					}
				</div>
				<Breadcrumb links={bc} icon={<List />} />
			</div>
			<div className="rounded-t-xl mt-[23px]">
				<div className={`dashboard ag-theme-alpine ${role === 'admin' ? 'h-[calc(100vh-300px)]' : 'h-[calc(100vh-242px)]'}`}>
					<AgGridReact
						ref={gridRef}
						headerHeight={headerHeight}
						floatingFiltersHeight={floatingFiltersHeight}
						rowData={rowData}
						columnDefs={columnDefs}
						defaultColDef={defaultColDef}
						columnTypes={columnTypes}
						groupDisplayType={'groupRows'}
						rowSelection="multiple"
						animateRows={true}
						onFirstDataRendered={onFirstDataRendered}
						groupSelectsChildren={true}
						suppressRowClickSelection={true}
						suppressAggFuncInHeader={true}
						groupRowRendererParams={groupRowRendererParams}
						groupRowsSticky={true}
						getRowHeight={getRowHeight}
						icons={icons}
						components={components}
						groupDefaultExpanded={groupDefaultExpanded}
						onCellClicked={cellClicked}
						onRowClicked={parentRowClicked}
						rowStyle={rowStyle}
						getRowId={getRowId}
						suppressContextMenu={true}
						getRowStyle={getRowStyle}
						getContextMenuItems={getContextMenuItems}
					/>
				</div>
			</div>
			{role === 'admin' &&
			<div className='flex'>
				<div
					onClick={() => {
						checkVirtualMpConflicts()
					}}
					className="mt-[15px] flex justify-center align-center px-[18px] py-[10px] rounded-3xl border-[#4D6071] border-[1px] cursor-pointer"
				>
					<MpList className="mr-[11px] mt-[2px]" />
					<h2 className="text-sm font-medium text-[#154365]">Create calculated Measuring Point</h2>
				</div>
				<div
					onClick={() => setOpenAddNotificationModal(true)}
					className="ml-[13px] mt-[15px] flex justify-center align-center px-[18px] py-[10px] rounded-3xl border-[#4D6071] border-[1px] cursor-pointer"
				>
					<Send className="mr-[11px] mt-[3px]" />
					<h2 className="text-sm font-medium text-[#154365]">Create Notification</h2>
				</div>
				{/* {hide &&
				<div
					// onClick={() => setShowAllMps()}
					className="ml-[13px] mt-[15px] flex justify-center align-center px-[18px] py-[10px] rounded-3xl border-[#4D6071] border-[1px] cursor-pointer"
				>
					<Unhide className="mr-[11px] mt-[3px]" />
					<h2 className="text-sm font-medium text-[#154365]">Show all Measuring Points</h2>
				</div>
				} */}
			</div>

			}
			{openAddBusinessModal && (
				<AddBusinessModal
					setOpenAddBusinessModal={setOpenAddBusinessModal}
					openAddBusinessModal={openAddBusinessModal}
					cancelButtonRef={cancelButtonRef}
				/>
			)}
			{openMpShareDialog && (
				<MpShareDialog
					mpData={mpData}
					setOpenMpShareDialog={setOpenMpShareDialog}
					openMpShareDialog={openMpShareDialog}
					openMpShareDialogRef={openMpShareDialogRef}
					businessesData={businessesDataForShareList}
				/>
			)}
			{openDeleteMpModal && (
				<DeleteMpModal
					mpData={mpData}
					setOpenDeleteMpModal={setOpenDeleteMpModal}
					openDeleteMpModal={openDeleteMpModal}
					deleteMpRef={deleteMpRef}
				/>
			)}
			{(openCreateCalculatedMpModal || signalForOpenCalculatedModal) && (
				<CalculatedMpModal
					rowData={getSelectedRowData()}
					mpData={mpData}
					setOpenCreateCalculatedMpModal={setOpenCreateCalculatedMpModal}
					openCreateCalculatedMpModal={openCreateCalculatedMpModal}
					calculatedMpRef={calculatedMpRef}
					setSignalForOpenCalculatedModal={setSignalForOpenCalculatedModal}
				/>
			)}
			{openAddNotificationModal && (
				<AddNotificationModal
					rowData={getSelectedRowData()}
					setOpenAddNotificationModal={setOpenAddNotificationModal}
					openAddNotificationModal={openAddNotificationModal}
				/>
			)}
			{openConflictVmpMpModal && (
				<ConflictVmpModal
					rowData={rowData}
					setOpenConflictVmpMpModal={setOpenConflictVmpMpModal}
					openConflictVmpMpModal={openConflictVmpMpModal}
					conflictVmpRef={conflictVmpRef}
					setSignalForOpenCalculatedModal={setSignalForOpenCalculatedModal}
					setSignalForCancelConflictModal={setSignalForCancelConflictModal}
				/>
			)}
		</div>
	)
}
