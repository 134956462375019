import React, { useState } from 'react'
import BusinessDataTab from './BusinessDataTab'
import { ReactComponent as Info } from '../../../assets/images/icons/info.svg'
import { MeasuringPointModel } from '../../repository/DashboardRepository'
import { OneBusinessModel } from '../../repository/BusinessRepository'

interface Props {
	businessData: MeasuringPointModel[]
	oneBusinessData: OneBusinessModel
	openCreateCalculatedMpModal: boolean
	openAddNotificationModal: boolean
	setOpenCreateCalculatedMpModal: React.Dispatch<React.SetStateAction<boolean>>
	setOpenAddNotificationModal: React.Dispatch<React.SetStateAction<boolean>>
	openConflictVmpMpModal: boolean
	setOpenConflictVmpMpModal: React.Dispatch<React.SetStateAction<boolean>>
	//hide: boolean
}

const BusinessTabs = (
	{ businessData, 
		oneBusinessData,
		openCreateCalculatedMpModal,
		openAddNotificationModal,
		setOpenCreateCalculatedMpModal,
		setOpenAddNotificationModal,
		openConflictVmpMpModal,
		setOpenConflictVmpMpModal,
		//hide
	}: Props) => {
	const [openBusinessTab, setOpenBusinessTab] = useState<number>(1)

	return (
		<div className="flex flex-wrap">
			<div className="w-full">
				<div>
					<div className=" flex flex-row justify-between items-center">
						<div className="flex items-center">
							<div className="flex items-center">
								<Info className=" text-[#004368]" />
							</div>
							<div className="pl-[10px]">
								<h2 className="text-base leading-6 font-medium tracking-[0.15px] mt-[2px] text-[#154365]">
                          Details
								</h2>
							</div>
						</div>
					</div>
					<div className="mt-[10px] w-full h-[1px] bg-stone-200 "></div>
				</div>
				<ul
					className="flex mb-0 list-none flex-wrap flex-row justify-between "
					role="tablist"
				>
					{/* li elements */}
				</ul>
				<div className="relative flex flex-col min-w-0 break-words bg-white w-full rounded">
					<div className="flex-auto">
						<div className="tab-content tab-space ">
							<div
								className={openBusinessTab === 1 ? 'block' : 'hidden'}
								id="link1"
							>
								<BusinessDataTab
									businessData={businessData}
									oneBusinessData={oneBusinessData}
									openCreateCalculatedMpModal={openCreateCalculatedMpModal}
									setOpenCreateCalculatedMpModal={setOpenCreateCalculatedMpModal}
									openAddNotificationModal={openAddNotificationModal}
									setOpenAddNotificationModal={setOpenAddNotificationModal}
									openConflictVmpMpModal={openConflictVmpMpModal}
									setOpenConflictVmpMpModal={setOpenConflictVmpMpModal}
									//hide={hide}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
export default BusinessTabs
