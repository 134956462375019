import AppKeycloak from '../Keycloak'
import { MPHistoryDataModel } from './MPHistoryDataRepository'

import axios from 'axios'
export default class GetMPHistoryDataRepository {
	async historyData(businessId: string, subBusinessId: string, mpId: string, from: number, to: number, interval?: number): Promise<MPHistoryDataModel[]> {
		const keycloak = AppKeycloak.getAppKeycloak()
		const settings: any = JSON.parse(localStorage.getItem('settings') || '')

		let url = `${settings.WEBPORTAL_BACKEND_URL}/businesses/${businessId}/sub-businesses/${subBusinessId}/measuring-points/${mpId}/range?timestamp=true&field=sys.kW&interval=${interval} seconds&from=${from}&to=${to}&timezone=Europe/Zurich`
		if(subBusinessId === 'self') 
			url = `${settings.WEBPORTAL_BACKEND_URL}/businesses/${businessId}/measuring-points/${mpId}/range?timestamp=true&field=sys.kW&interval=${interval} seconds&from=${from}&to=${to}&timezone=Europe/Zurich`
		const data = await axios.get(url, {
			headers: {
				Authorization: `Bearer ${keycloak.token}`,
			},
		})

		return data.data.data.map((d: any) => {
			return {
				time: Math.round(d.time / 1000) * 1000,
				value: d.value,
			}
		})
	}
}
