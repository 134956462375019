import React, { useState, useRef } from 'react'

export default function withClickOutside(WrappedComponent: any) {
	const Component = (props: any) => {
		const [open, setOpen] = useState(false)

		const ref: any | undefined = useRef()

		return (
			<WrappedComponent open={open} setOpen={setOpen} ref={ref} {...props} />
		)
	}

	return Component
}
