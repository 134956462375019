import React, { useState } from 'react'
import { ReactComponent as EditIconInactive } from '../../../assets/images/icons/edit-inactive.svg'
import { ReactComponent as RemoveIconInactive } from '../../../assets/images/icons/closeInactive.svg'

interface Props {
  data: any
  setUserInfoData: any;
  setOpenAddUserModal: any;
  userInfoData?: any;
	setOpenRemoveUserModal: any
}

const UsersActionRenderer = ({
	data, 
	setUserInfoData, 
	setOpenAddUserModal,
	setOpenRemoveUserModal
}: Props) => {

	const [isHoveredEdit, setValueEdit] = useState(false)
	const [isHoveredRemove, setValueRemove] = useState(false)

	const userEditClick = () => {
		setOpenAddUserModal(true)
		setUserInfoData(data)
	}

	const openRemoveUserModal = () => {
		setOpenRemoveUserModal(true)
		setUserInfoData(data)
	}

	const icons = <div className='flex'>
		{data?.business === undefined ? 
			<div className='pr-[25px]'>
				<EditIconInactive 
					stroke={isHoveredEdit ? '#006397' : '#959ca3'}
					onMouseEnter={() => setValueEdit(true)}
					onMouseLeave={() => setValueEdit(false)}
					onClick={()=>userEditClick()}
				/>
			</div>
			:
			<div className='w-[20px] pr-[25px] h-[20px]'></div>
		}
		<div className='mt-[2px]'>
			<RemoveIconInactive 
				stroke={isHoveredRemove ? '#006397' : '#959ca3'}
				onMouseEnter={() => setValueRemove(true)}
				onMouseLeave={() => setValueRemove(false)}
				onClick={()=>openRemoveUserModal()}
			/>
		</div>
	</div>

	return (
		<div className='table-action-holder'>
			{icons}
		</div>
	)

}

export default UsersActionRenderer
