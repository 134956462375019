import React from 'react'
import { useNavigate } from 'react-router-dom'

import { ReactComponent as SettingsIcon } from '../../assets/images/icons/settings.svg'

import { useAppDispatch } from '../../app/hooks'
import { changeBusinessCommand } from './changeBusinessCommand'

export default function ChangeBusiness() {
	const dispatch = useAppDispatch()
	const navigate = useNavigate()

	const changeBusiness = React.useCallback(() => {
		dispatch(changeBusinessCommand())
		navigate('/businesses')
	}, [dispatch, navigate])

	return (
		<div className="flex pl-[10px] items-center h-[45px] hover:bg-[#DEE3EB] rounded-t-md" onClick={() => changeBusiness()}>
			<div className=''>
				<SettingsIcon />
			</div>
			<h2 className="pl-[10px] pt-[3px]">Change Business</h2>
		</div>
	)
}
