import React from 'react'

const TagsRenderer = (props: any) => {
	if (props.value === undefined) return '-'
		
	const tags = props.value.map((val: string) =>
		<li key={val}><div>{val}</div></li>
	)

	return (
		<div className='tags-holder'>
			<ul>{tags}</ul>
		</div>
	)

}

export default TagsRenderer
