import React, { useState, Fragment, useMemo, useCallback, useRef, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { Input } from '@material-tailwind/react'
import { ReactComponent as Close } from '../../../assets/images/icons/close.svg'
import { ReactComponent as CloseBlack } from '../../../assets/images/icons/closeBlack.svg'
import { ReactComponent as IconClose } from '../../../assets/images/icons/iconClose.svg'
import { ReactComponent as Check } from '../../../assets/images/icons/check.svg'
import { ReactComponent as MpList } from '../../../assets/images/icons/mpList.svg'
import { ReactComponent as Edit } from '../../../assets/images/icons/editNotification.svg'
import { ReactComponent as AlertRed } from '../../../assets/images/icons/infoMessageRed.svg'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-alpine.css'
import 'ag-grid-enterprise'
import '../../pages/admin/ag-grid-power.css'
import { AgGridReact } from 'ag-grid-react'
import CustomHeader from '../Table/CustomHeader'
import { LicenseManager } from 'ag-grid-enterprise'
import ActionButton from '../ActionButton'
import { useParams, useNavigate } from 'react-router-dom'
import { dependencyContainer } from '../../store'
import { useMutation } from '@tanstack/react-query'
import { queryClient } from '../../../index'
import MpCellRenderWithIcon from '../Table/MpCellRenderWithIcon'
import VirtualMpFilterFormatter from '../Table/VirtualMpFilterFormatter'
import VirtualMpFormatter from '../Table/VirtualMpFormatter'
import TagsRenderer from '../Table/TagsRenderer'
import MeasuringPointInfoModal from './MeasuringPointInfoModal'
import { useQuery } from '@tanstack/react-query'
import RecipientsWarningModal from './RecipientsWarningModal'

LicenseManager.setLicenseKey(
	'CompanyName=Appmodule AG,LicensedGroup=aliunid grid / power,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=2,AssetReference=AG-036901,SupportServicesEnd=31_January_2024_[v2]_MTcwNjY1OTIwMDAwMA==4c9d5d4b04b142bc6b50ed895e5b5ef3'
)

interface Props {
	setOpenCreateCalculatedMpModal: React.Dispatch<React.SetStateAction<boolean>>;
	openCreateCalculatedMpModal: boolean;
	calculatedMpRef: React.MutableRefObject<null>;
	setSignalForOpenCalculatedModal?: any;
	rowData?: any;
	mpData?: any;

}

const CalculatedMpModal = ({
	setOpenCreateCalculatedMpModal,
	openCreateCalculatedMpModal,
	calculatedMpRef,
	rowData,
	mpData,
	setSignalForOpenCalculatedModal
}: Props) => {

	const [mpName, setMpName] = useState(Object.keys(mpData)?.length !== 0 ? mpData.name : '')
	const [factor, setFactor] = useState(Object.keys(mpData)?.length !== 0 ? mpData.calculationFactor : 1)
	const [error, setError] = useState('')
	const [checkboxDataId, setCheckboxDataId] = useState<any>([])
	const [checkboxData, setCheckboxData] = useState<any>([])
	const [dataWithChecked, setDataWithChecked] = useState<any>([])
	const [openMeasuringPointInfoModal, setOpenMeasuringPointInfoModal] = useState(false)
	const [rowDataForEdit, setRowDataForEdit] = useState([])
	const [openRecipientsWarningModal, setOpenRecipientsWarningModal] = useState(false)
	const [virtualMpNames, setVirtualMpNames] = useState<any>([])

	const { id } = useParams<any>()
	const gridRefVmp = useRef<AgGridReact>(null)
	const infoVmpEditRef = useRef(null)
	const { subBusinessId, mpId } = useParams()

	const navigate = useNavigate()

	const isEdited = Object.keys(mpData)?.length !== 0

	if(rowData === undefined){
		useQuery({
			queryKey: ['dashboardData', id],
			queryFn:() =>  dependencyContainer.dependency.dashboardApi.dashboardData(id as string),
			onSuccess: (data) => {
				setVirtualMpNames(data.filter((el: any)=> el.name !== '').map(((mp: any) => mp.name)))
				const filteredVmpData = mpData?.involvedPoints 
				const filteredMpData = data.filter((mp)=> filteredVmpData?.indexOf(mp.id) > -1)

				filteredMpData.forEach((el: any)=> {
					el.checked = true
				})
				data = data.filter((el: any) => !el.provider)

				setRowDataForEdit(data as any)
			}
		})
	}

	useEffect(() => {
		if(rowData !== undefined) {
			rowData = rowData.filter((el: any) => !el.provider)
			setDataWithChecked(rowData.sort((a: any) => a.checked === true ? -1 : 1))
		}
	}, [])
	
	useEffect(() => {
		if(rowData === undefined) {
			setDataWithChecked(rowDataForEdit?.sort((a: any) => a.checked === true ? -1 : 1))
		}
	}, [rowDataForEdit])

	const columnDefs = [
		{
			field: 'checked',
			headerName: '',
			maxWidth: 68,
			sortable: false,
			headerCheckboxSelection: true,
			checkboxSelection: (node: any) => {
				if (node.data?.name !== '') {
					return true
				} else {
					return false
				}
			},
			cellStyle: function () {
				return { display: 'flex', justifyContent: 'center', marginLeft: '8px' }
			},
			filter: 'agSetColumnFilter',
			filterParams: {
				buttons: ['reset', 'apply'],
				suppressSelectAll: true,
				suppressBlanks: true,
				cellHeight: 48,
				suppressMiniFilter: true,
				cellRenderer: VirtualMpFilterFormatter
			},
			cellRenderer: (row: any) => (
				<VirtualMpFormatter
					data={row.data}
				/>
			),
		},
		{
			field: 'name',
			headerName: 'Sum of:',
			//minWidth: 370,
			headerComponentParams: { menuIcon: 'sum' },
			cellClass: 'first-cell',
			cellRenderer: (row: any) => (
				<MpCellRenderWithIcon
					data={row.data}
					length={90}
				/>
			),
			cellStyle: function() {
				return { wordBreak: 'break-word' }
			},
		},
		{
			field: 'tags',
			filter: 'agSetColumnFilter',
			filterParams: {
				buttons: ['reset', 'apply'],
				suppressSelectAll: true,
				cellHeight: 48,
				suppressMiniFilter: true,
			},
			cellRenderer: TagsRenderer,
			headerComponentParams: { menuIcon: 'style' },
			autoHeight: true,
			wrapText: true,
			cellStyle: { justifyContent: 'center' },
		},
	]

	const headerHeight = 25
	const floatingFiltersHeight = 30

	const defaultColDef = useMemo(
		() => ({
			sortable: true,
			filter: 'agTextColumnFilter',
			floatingFilter: true,
			flex: 1,
			suppressMovable: true,
			wrapText: true,
			suppressMenu: true,
			floatingFilterComponentParams: {
				suppressFilterButton: true,
			},
		}),
		[]
	)

	const columnTypes = useMemo(() => {
		return {
			number: {
				aggFunc: 'count',
			},
		}
	}, [])

	const onFirstDataRendered = useCallback(() => {
		setTimeout(() => {
			document
				.querySelectorAll('.ag-theme-alpine input[class^=ag-][type=text]')
				.forEach((obj) => {
					obj.setAttribute('placeholder', 'Search')
				})
		}, 50)
		setTimeout(() => {
			document
				.querySelectorAll(
					'.ag-theme-alpine .ag-disabled input[class^=ag-][type=text]'
				)
				.forEach((obj) => {
					obj.setAttribute('placeholder', 'Select')
					obj.removeAttribute('disabled')
				})
		}, 100)
		gridRefVmp.current?.api.forEachNode((node) =>
			node.setSelected(node.data && node.data.checked)
		)
	}, [])

	const onRowSelected = useCallback((event: any) => {

		if (event.data) {
			setCheckboxDataId((oldData: any) => {
				const filtered = oldData.filter((item: any) => item === event.data.id)

				if (filtered.length === 0) {
					return [...oldData, event.data.id]
				} else {
					return oldData.filter((item: any) => item !== event.data.id)
				}
			})

			setCheckboxData((oldData: any) => {
				const filtered = oldData.filter((item: any) => item.id === event.data.id)

				if (filtered.length === 0) {
					return [...oldData, event.data]
				} else {
					return oldData.filter((item: any) => item.id !== event.data.id)
				}
			})

		}
	}, [])

	const getRowStyle = (params: any) => {
		const data = params.data
		if (data === null || data === undefined) return
		if (data.provider !== null && data.provider !== undefined && data.provider.linked) {
			return { background: '#ebebeb' }
		} else {
			return { background: 'white' }
		}
	}

	const rowHeight = 50

	const components = useMemo(() => {
		return {
			agColumnHeader: CustomHeader,
		}
	}, [])

	const handleChangeVmpName = (event: React.ChangeEvent<HTMLInputElement>) => {
		setMpName(event.target.value)
		setError('')
	}

	const handleChangeFactor = (event: React.ChangeEvent<HTMLInputElement>) => {
		setFactor(parseFloat(event.target.value))
	}

	const filteredRowData = dataWithChecked?.filter((data: any) => {
		return data.name !== ''
	})

	//formula for create virtualMp

	const createFormulaLine = (unit: string): string => {
		let result = '['
		for (let i = 0; i < checkboxData.length; i++) {
			result += `_${checkboxData[i].siotId.replaceAll('-', '_')}.sys['${unit}'], `
		}
		result = result.substring(0, result.length - 2)
		result += '], "('
		for (let i = 0; i < checkboxData.length; i++) {
			result += `_${checkboxData[i].siotId.replaceAll('-', '_')}.sys['${unit}'] + `
		}
		result = result.substring(0, result.length - 3)
		result += `)*${factor}")`
		return result
	}

	const formula = (): string => {
		let result = '# make sure all important object fields exist'
		for (let i = 0; i < checkboxData.length; i++) {
			const siotId = '_' + checkboxData[i].siotId.replaceAll('-', '_')
			result += `\n${siotId}.sys = ${siotId}.sys != undefined ? ${siotId}.sys : {}`
		}
		result += '\n\n# calculate result\nresult = {\nsys: {\n'
		result += `\t"kWh+": nullIfOperandsNull(${createFormulaLine('kWh+')},\n`
		result += `\t"kW": nullIfOperandsNull(${createFormulaLine('kW')}`
		result += '\n\t}\n};'

		return result
	}

	//formula for create virtualMp

	// const createFormulaLine = (unit: string, phase: string): string => {
	// 	let result = '['
	// 	for (let i = 0; i < checkboxData.length; i++) {
	// 		result += `${checkboxData[i].siotId}.${phase}['${unit}'], `
	// 	}
	// 	result = result.substring(0, result.length - 2)
	// 	result += '], "'
	// 	for (let i = 0; i < checkboxData.length; i++) {
	// 		result += `${checkboxData[i].siotId}.${phase}['${unit}'] + `
	// 	}
	// 	result = result.substring(0, result.length - 3)
	// 	result += '")'
	// 	return result
	// }

	// const formula = (): string => {

	// 	const sysArray = ['kWh+', 'kWh-', 'kvarh+', 'kvarh-', 'kW', 'kVA', 'kvar']
	// 	const lArray = ['kWh+', 'kWh-', 'kW', 'kVA', 'kvar']

	// 	interface AssocArr {
	// 		[key: string]: string;
	// 	}
	// 	const lsArray: AssocArr = { 'l1': 'R', 'l2': 'S', 'l3': 'T' }

	// 	let result = '# make sure all important object fields exist'
	// 	for (let i = 0; i < checkboxData.length; i++) {
	// 		const siotId = checkboxData[i].siotId
	// 		result += `\n${siotId}.sys = ${siotId}.sys != undefined ? ${siotId}.sys : {}`
	// 		result += `\n${siotId}.l1 = ${siotId}.l1 != undefined ? ${siotId}.l1 : {}`
	// 		result += `\n${siotId}.l2 = ${siotId}.l2 != undefined ? ${siotId}.l2 : {}`
	// 		result += `\n${siotId}.l3 = ${siotId}.l3 != undefined ? ${siotId}.l3 : {}\n`
	// 	}
	// 	result += '\n# calculate result'
	// 	result += '\nresult = {'
	// 	result += '\n\tsys: {'
	// 	result += '\n\t\t"Hz": null,\n'
	// 	for (const val of sysArray) {
	// 		result += `\t\t"${val}": nullIfOperandsNull(${createFormulaLine(val, 'sys')},\n`
	// 	}
	// 	result = result.substring(0, result.length - 2)
	// 	for (const key in lsArray) {
	// 		result += '\n\t},'
	// 		result += `\n\t${key}: {`
	// 		result += `\n\t\t"phase": "${lsArray[key]}",`
	// 		result += '\n\t\t"V": null,'
	// 		result += '\n\t\t"A": null,'
	// 		for (const val of lArray) {
	// 			result += `\n\t\t"${val}": nullIfOperandsNull(${createFormulaLine(val, key)},`
	// 		}
	// 		result = result.substring(0, result.length - 1)
	// 	}

	// 	result += '\n\t}'
	// 	result += '\n};'

	// 	return result
	// }

	//console.log(formula())

	const createVirtualMeasuringPoint = useMutation({
		mutationFn: () => dependencyContainer.dependency.measuringPointsApi.createVirtualMp(id as string, mpName, formula(), checkboxDataId, factor),
		onSuccess: async () => {
			setOpenCreateCalculatedMpModal(false)
			if(subBusinessId !== undefined){
				navigate(`/businesses/${id}`)
			}
			queryClient.invalidateQueries({ queryKey: ['dashboardData'] })
		},
		onError: (error: any) => {
			setError(error.response?.data?.message ? error.response?.data?.message : 'Internal server error !')

		},
	})

	const submitForm = async () => {
		if (mpName === '') {
			setError('Measuring Point name is required !')
		}
		else if (checkboxData?.length === 0) {
			setOpenRecipientsWarningModal(true)
		}
		else {
			createVirtualMeasuringPoint.mutate()
			if(mpId === undefined){
				setSignalForOpenCalculatedModal(false)
			}
		}
	}

	useEffect(() => {
		if(checkboxData.length !== 0) {
			setError('')
		}
	}, [checkboxData])

	const ifEdited = () => {
		if (checkboxData?.length === 0) {
			setOpenRecipientsWarningModal(true)
		}
		else {
			setOpenMeasuringPointInfoModal(true)
			if(mpId === undefined){
				setSignalForOpenCalculatedModal(false)
			}
		}
	}

	return (
		<Transition.Root show={openCreateCalculatedMpModal} as={Fragment}>
			<Dialog
				as="div"
				className="relative z-[20]"
				initialFocus={calculatedMpRef}
				onClose={() => {
					//no action for clicking outside modal
				}}
			>
				<Transition.Child
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 bg-[#000000] bg-opacity-75 transition-opacity " />
				</Transition.Child>

				<div className="fixed inset-0 z-10 overflow-y-auto">
					<div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							enterTo="opacity-100 translate-y-0 sm:scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 translate-y-0 sm:scale-100"
							leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						>
							<Dialog.Panel className=" ml-auto transform rounded-l-lg
               bg-white text-left shadow-xl transition-all w-[calc(100vw-38vw)] h-screen overflow-auto">
								<div className="p-[15px]">
									<div className=" flex flex-row justify-between items-center pt-[9px]">
										<div className="flex items-center">
											<div className="flex mb-[2px] items-center w-5 h-5">
												{isEdited ? <Edit /> : <MpList />}
											</div>
											<div className="w-[292px] pl-[7px]">
												<h2 className="text-base font-medium tracking-[0.15px] text-[#154365]">
													{isEdited ? 'Edit Measuring Point selection' : 'Create calculated Measuring Point'}
												</h2>
											</div>
										</div>
										<div
											className=""
											onClick={() => {
												setOpenCreateCalculatedMpModal(false)
												if(mpId === undefined){
													setSignalForOpenCalculatedModal(false)
												}
											}}
										>
											<Close className="text-[#4D6071] cursor-pointer" />
										</div>
									</div>
									<div className="mt-[10px] w-full h-[0.75px] order-1 bg-[#A7ACB3]"></div>
									<div className="mt-[33px] bg-[#F0F0F0] rounded-xl">
										<div className="p-[13px]">
											{isEdited ?
												<div className="items-center text-[#154365] text-[14px] flex flex-wrap">
													<p>Select or deselect Measuring Points to change your selection.</p>
												</div>
												:
												<>
													<div className="w-full relative mb-[15px]">
														<Input
															crossOrigin=''
															type={'text'}
															color={error ? 'red' : 'blue'}
															size="lg"
															value={mpName || ''}
															label="Measuring Point name"
															required
															onChange={handleChangeVmpName}
															className="text-base"
															icon={
																<IconClose
																	className="cursor-pointer"
																	onClick={() => setMpName('')}
																/>
															}
														/>
														{error === 'Measuring Point name is required !' && (
															<div className="flex justify-start mt-[10px] text-[#ff0000] text-sm">
																<AlertRed  className='mt-[1px]'/>
																<h2  className='ml-[8px]'>{error}</h2>
															</div>
														)}
													</div>
													<div className="w-full relative">
														<Input
															crossOrigin=''
															type='number'
															size="lg"
															value={factor}
															label="Factor to be applied"
															onChange={handleChangeFactor}
															className="text-base"
															icon={
																<IconClose
																	className="cursor-pointer"
																	onClick={() => setFactor(1)}
																/>
															}
														/>
													</div>
												</>
											}
										</div>
										<div className='vmp ag-theme-alpine h-[calc(100vh-340px)] p-[13px]'>
											<AgGridReact
												ref={gridRefVmp}
												headerHeight={headerHeight}
												floatingFiltersHeight={floatingFiltersHeight}
												rowData={filteredRowData}
												columnDefs={columnDefs}
												defaultColDef={defaultColDef}
												columnTypes={columnTypes}
												groupDisplayType={'groupRows'}
												rowSelection="multiple"
												animateRows={true}
												onFirstDataRendered={onFirstDataRendered}
												groupSelectsChildren={true}
												suppressRowClickSelection={true}
												suppressAggFuncInHeader={true}
												rowHeight={rowHeight}
												components={components}
												pagination={true}
												paginationAutoPageSize={true}
												suppressContextMenu={true}
												getRowStyle={getRowStyle}
												onRowSelected={onRowSelected}
											/>
											<div className="mb-[25px] w-full h-[1px] order-1 bg-white"></div>
											{(error !== 'Measuring Point name is required !' &&
											error !== '') && (
												<div className="flex justify-start mt-[10px] text-[#ff0000] text-sm">
													<AlertRed  className='mt-[1px]'/>
													<h2  className='ml-[8px]'>{error}</h2>
												</div>
											)}
										</div>
									</div>
									<div className="mt-[30px] flex justify-end">
										<div ref={calculatedMpRef}>
											<ActionButton
												className="bg-[#D5E4F7] text-[#001D32] font-medium py-2.5 pl-[21px] pr-[24px] rounded-full h-[40px]"
												text="Cancel"
												icon={<CloseBlack />}
												onClick={() => {
													setOpenCreateCalculatedMpModal(false)
													if(mpId === undefined){
														setSignalForOpenCalculatedModal(false)
													}
												}}
											/>
										</div>
										<div ref={calculatedMpRef} className="ml-[20px]">
											<ActionButton
												className="bg-[#154365] text-white font-medium py-2.5 pl-[21px] pr-[24px] rounded-full h-[40px]"
												text={isEdited ? 'Save' : 'Create'}
												icon={<Check />}
												onClick={() => {
													isEdited ? ifEdited() : submitForm()
												}}
											/>
										</div>
									</div>
									{openMeasuringPointInfoModal && (
										<MeasuringPointInfoModal
											setOpenMeasuringPointInfoModal={setOpenMeasuringPointInfoModal}
											openMeasuringPointInfoModal={openMeasuringPointInfoModal}
											infoVmpEditRef={infoVmpEditRef}
											setOpenCreateCalculatedMpModal={setOpenCreateCalculatedMpModal}
											mpName={mpName}
											formula={formula}
											checkboxDataId={checkboxDataId}
											virtualMpNames={virtualMpNames}
											factor={factor}
										/>
									)}
									{openRecipientsWarningModal && (
										<RecipientsWarningModal
											setOpenRecipientsWarningModal={setOpenRecipientsWarningModal}
											openRecipientsWarningModal={openRecipientsWarningModal}
										/>
									)}
								</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition.Root>
	)
}

export default CalculatedMpModal
