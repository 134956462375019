import * as React from 'react'
import Dialog from '@mui/material/Dialog'
import { ReactComponent as Close } from '../../../assets/images/icons/close.svg'
import { ReactComponent as Check } from '../../../assets/images/icons/checkBlue.svg'
import { ReactComponent as Info } from '../../../assets/images/icons/infoMessage.svg'
import { ReactComponent as MpIcon } from '../../../assets/images/icons/mpNotShared.svg'
import { ReactComponent as Blue } from '../../../assets/images/icons/blue.svg'
import ActionButton from '../ActionButton'

interface Props {
  setOpenRecipientsWarningModal: React.Dispatch<React.SetStateAction<boolean>>;
  openRecipientsWarningModal: boolean;
}

const RecipientsWarningModal = ({
	setOpenRecipientsWarningModal,
	openRecipientsWarningModal,
}: Props) => {

	return (
		<Dialog
			open={openRecipientsWarningModal}
			onClose={()=>setOpenRecipientsWarningModal(true)}
			className="relative z-[20] bg-[#000000] bg-opacity-0 transition-opacity"
		>
			<div className="fixed inset-0 z-10 overflow-y-auto">
				<div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
					<div className="relative transform overflow-hidden rounded-lg
               bg-white text-left shadow-xl transition-all sm:my-8 xl:w-[603px] lg:w-[503px]">
						<div className="p-[15px]">
							<div className=" flex flex-row justify-between items-center pt-[9px]">
								<div className="flex items-center">
									<div className="flex items-center w-5 h-5 mb-[2px]">
										<Info />
									</div>
									<div className=" pl-[7px]">
										<h2 className="text-base font-medium tracking-[0.15px] text-[#154365]">	
										Warning: No recipients selected
										</h2>
									</div>
								</div>
								<div
									className=""
									onClick={()=>setOpenRecipientsWarningModal(false)}
								>
									<Close className="text-[#4D6071] cursor-pointer" />
								</div>
							</div>
							<div className="mt-[10px] w-full h-[0.75px] order-1 bg-[#A7ACB3]"></div>
							<div className="mt-[13px] bg-[#F0F0F0] rounded-xl flex p-[13px]">
								<div className='flex w-[100px] bg-white justify-around items-center'>
									<div className='relative'>
										<Blue />
										<Check className='absolute top-[2px]'/>
									</div>
									<div className=''>
										<MpIcon />
									</div>
								</div>
								<div className="items-center text-[#154365] text-[14px] ml-[10px]">
								Please select at least one measuring point as recipient from the list by clicking on the checkbox.
								</div>
							</div>
							<div className="mt-[20px] flex justify-end">
								<div>
									<ActionButton
										className="bg-[#154365] text-white font-medium py-2.5 pl-[21px] pr-[24px] rounded-full h-[40px]"
										text="Ok"
										icon={<Check />}
										onClick={()=>setOpenRecipientsWarningModal(false)}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Dialog>
	)
}

export default RecipientsWarningModal
