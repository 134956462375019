import AppKeycloak from './Keycloak'
import mqtt from 'precompiled-mqtt'

export default class AppMQTT {

	private client!: mqtt.Client
	private tag: string

	constructor(private readonly url: string, private readonly keycloak: AppKeycloak) {
		this.tag = this.createTag()
		this.log('CREATED')
	}

	private log(message: string) {
		console.log(`[${this.tag}]`, message)
	}

	connect(): mqtt.Client {
		this.client = mqtt.connect(this.url, {
			username: 'power_access_token',
			password: this.keycloak.token,
			connectTimeout: 10000,
			clientId: 'mqttjs_' + Math.random().toString(16).substr(2, 8),
		})
			.on('connect', () => {
				this.log('MQTT CONNECTED')
			})
			.on('end', () => {
				this.log('MQTT END')
			})
			.on('disconnect', () => {
				this.log('MQTT DISCONNECT')
			})
			.on('reconnect', () => {
				this.log('MQTT RECONNECT')
			})
			.on('error', async (e) => {
				if (e.message === 'Connection refused: Not authorized') {
					this.client.end()
					const isRefreshed = await this.keycloak.refreshToken()
					if(!isRefreshed)
						throw new Error('Refresh token failed!')
					setTimeout(() => {
						this.connect()
					}, 1000)
				}
				this.log('MQTT ERROR ' + e)
			})
		// .on('message', (t, d) => {
		// 	console.log(t, d)
		// })
		return this.client
	}

	disconnect() {
		this.client.end()
	}

	private createTag() {
		const length = 5
		let result = ''
		const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
		const charactersLength = characters.length
		for (let i = 0; i < length; i++) {
			result += characters.charAt(Math.floor(Math.random() * charactersLength))
		}
		return result
	}
}
