import React from 'react'

interface Props {
  data: any

}

const VirtualMpFormatter = ({data}: Props) => {

	return (
		<div className='flex items-center'>
			<div className='ml-[5px]'>
				{data?.checked ? '' : null}
			</div>
		</div>
	)
}

export default VirtualMpFormatter
