import React from 'react'

interface Props {
  data: any

}

const EmailCellRenderer = ({data}: Props) => {

	const adminEmail = window.localStorage.getItem('admin-email') || ''

	return (
		<div className='items-center'>
			<div className='flex ml-[5px]'>
				<div>
					{data?.email} 
				</div>
				<div className='text-[11px] ml-[10px]'>
					{adminEmail !== '' && data?.email === adminEmail && '( ME )'}
				</div>
			</div>
		</div>
	)

}

export default EmailCellRenderer
