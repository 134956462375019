import React from 'react'
import { createBrowserRouter, Outlet, RouterProvider } from 'react-router-dom'

import { useAppSelector } from '../app/hooks'
import AdminNavigation from '../app/pages/admin/AdminNavigation'
import Dashboard from '../app/pages/admin/Dashboard'
import Users from '../app/pages/admin/Users'
import AppLoader from '../features/appLoader/AppLoader'
import Businesses from '../app/pages/Businesses'
import AppKeycloak, { KeycloakStore } from '../app/Keycloak'
import Notifications from '../app/pages/admin/Notifications'
import { Changelog } from '../app/pages/Changelog'
import Details from '../app/pages/admin/Details/Details'
import MeasuringPoint from '../app/pages/admin/Details/MeasuringPoint'
import Notification from '../app/pages/admin/Details/Notification'
import Settings from '../app/pages/admin/Settings'
import APIKeyDetails from '../app/pages/admin/Details/APIKeyDetails'

const adminRoutes = [
	{
		index: true,
		element: <Dashboard />,
	},
	{
		path: 'notifications',
		element: <Outlet />,
		children: [
			{
				index: true,
				element: <Notifications />,
			},
			{
				path: ':notificationId',
				element: <Notification />,
			},
		]
	},
	{
		path: 'users',
		element: <Users />,
	},
	{
		path: 'settings',
		element: <Outlet />,
		children: [
			{
				index: true,
				element: <Settings />,
			},
			{
				path: 'APIKey/:APIKeyId',
				element: <APIKeyDetails />,
			},
		],
	},
	{
		path: 'changelog',
		element: <Changelog />,
	},
	{
		path: ':subBusinessId',
		element: <Outlet />,
		children: [
			{
				index: true,
				element: <Details />,
			},
			{
				path: 'measuring-points/:mpId',
				element: <MeasuringPoint />,
			},
		],
	},
]

const viewerRoutes = [
	{
		index: true,
		element: <Dashboard />,
	},
	// {
	// 	path: 'notifications',
	// 	element: <Outlet />,
	// 	children: [
	// 		{
	// 			index: true,
	// 			element: <Notifications />,
	// 		},
	// 		{
	// 			path: ':notificationId',
	// 			element: <Notification />,
	// 		},
	// 	]
	// },
	{
		path: 'users',
		element: <Users />,
	},
	{
		path: 'settings',
		element: <Outlet />,
		children: [
			{
				index: true,
				element: <Settings />,
			},
			{
				path: 'APIKey/:APIKeyId',
				element: <APIKeyDetails />,
			},
		],
	},
	{
		path: 'changelog',
		element: <Changelog />,
	},
	{
		path: ':subBusinessId',
		element: <Outlet />,
		children: [
			{
				index: true,
				element: <Details />,
			},
			{
				path: 'measuring-points/:mpId',
				element: <MeasuringPoint />,
			},
		],
	},
]

// const technicianRoutes = [
// 	{
// 		index: true,
// 		element: <Dashboard />,
// 	},
// 	{
// 		path: 'notifications',
// 		element: <Outlet />,
// 		children: [
// 			{
// 				index: true,
// 				element: <Notifications />,
// 			},
// 			{
// 				path: ':notificationId',
// 				element: <Changelog />,
// 			},
// 		]
// 	},
// 	{
// 		path: 'users',
// 		element: <Users />,
// 	},
// 	{
// 		path: 'changelog',
// 		element: <Changelog />,
// 	},
// 	{
// 		path: ':subBusinessId',
// 		element: <Outlet />,
// 		children: [
// 			{
// 				index: true,
// 				element: <Details />,
// 			},
// 			{
// 				path: 'measuring-points/:mpId',
// 				element: <MeasuringPoint />,
// 			},
// 		],
// 	},
// ]

// [
//   {
//     index: true,
//     element: <ViewerDashboard />,
//   },
//   {
//     path: "users",
//     element: <ViewerUsers />,
//   },
//   {
//     path: "changelog",
//     element: <Changelog />,
//   },
// ]

// interface Props {
//   keycloakStore: KeycloakStore
// }

export default function AppRouter() {
	const { role } = useAppSelector((state) => state.app)

	const router = React.useMemo(() => {
		const adminApp = {
			element: <AdminNavigation />,
			children: [...adminRoutes],
		}
		const viewerApp = {
			// element: <ViewerNavigation />,
			element: <AdminNavigation />,
			children: [...viewerRoutes],
		}
		// const technicianApp = {
		// 	// element: <ViewerNavigation />,
		// 	element: <AdminNavigation />,
		// 	children: [...technicianRoutes],
		// }
		return createBrowserRouter([
			{
				path: '/',
				element: <AppLoader keycloakStore={AppKeycloak.getAppKeycloak().store()} />,
				children: [
					{
						index: true,
						element: <Businesses />,
					},
					{
						path: 'businesses',
						element: <Outlet />,
						children: [
							{
								index: true,
								element: <Businesses />,
							},
						],
					},
					{
						path: 'businesses/:id',
						element: <Outlet />,
						children: [role === 'admin' ? adminApp : {}, role === 'viewer' ? viewerApp : {}],
					},
					{
						path: 'businesses/:id/:param',
						element: <></>,
					},
					{
						path: 'businesses/:id/:param/measuring-points/:param',
						element: <></>,
					},
					{
						path: 'businesses/:id/notifications/:notificationId',
						element: <></>,
					},
					{
						path: 'businesses/:id/settings/APIKey/:APIKeyId',
						element: <></>,
					},
				],
			},
		])
	}, [role])

	return <RouterProvider router={router} />
}
