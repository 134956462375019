import React from 'react'
import { AgGridReact } from 'ag-grid-react'
import {useState,useEffect,useMemo,useCallback,useRef} from 'react'
import { useParams} from 'react-router-dom'
import GroupCellRenderer from '../../components/Table/GroupCellRenderer'
import UsersActionRenderer from '../../components/Table/UsersActionRenderer'
import CustomHeader from '../../components/Table/CustomHeader'
import { ReactComponent as IconPlus } from '../../../assets/images/icons/icon+.svg'
import { ReactComponent as Group } from '../../../assets/images/icons/group.svg'
import Breadcrumb from '../../components/Breadcrumb/Index'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-alpine.css'
import 'ag-grid-enterprise'
import './ag-grid-power.css'
import { LicenseManager } from 'ag-grid-enterprise'
import AddUserModal from '../../components/Modals/AddUserModal'
import RemoveUserModal from '../../components/Modals/RemoveUserModal'
import { useAppSelector } from '../../hooks'
import { useQuery } from '@tanstack/react-query'
import { dependencyContainer } from '../../store'
import EmailCellRenderer from '../../components/Table/EmailCellRendered'

LicenseManager.setLicenseKey(
	'CompanyName=Appmodule AG,LicensedGroup=aliunid grid / power,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=2,AssetReference=AG-036901,SupportServicesEnd=31_January_2024_[v2]_MTcwNjY1OTIwMDAwMA==4c9d5d4b04b142bc6b50ed895e5b5ef3'
)

export default function Users() {
	const [openAddUserModal, setOpenAddUserModal] = useState(false)
	const [openRemoveUserModal, setOpenRemoveUserModal] = useState(false)
	const [userInfoData, setUserInfoData] = useState()

	const cancelButtonRef = useRef(null)
	const cancelButtonRemoveRef = useRef(null)
	///const cancelButtonRefInfoUser = useRef(null);

	//const gridRef = useRef()
	const businessIdParam = useParams().id

	const [rowData, setRowData] = useState(null)
	const [invitationsRowData, setInvitationsRowData] = useState(null)

	const [allDataUsers, setAllDataUsers] = useState([])

	const { role } = useAppSelector((state) => state.app)

	const adminEmail = window.localStorage.getItem('admin-email') || ''
	
	const headerHeight = 25

	const floatingFiltersHeight = 30

	const columnDefs = [
		// {
		//   field: "nameOfuser",
		//   headerName: "Name of user",
		//   headerComponentParams: { menuIcon: "group" },
		//   cellClass: "first-cell",
		// },
		{
			field: 'email',
			headerName: 'E-mail',
			headerComponentParams: { menuIcon: 'mail' },
			cellClass: 'first-cell',
			cellRenderer: (row: any) => (
				<EmailCellRenderer
					data={row.data}
				/>
			),
		},
		{
			field: 'role',
			headerName: 'Role',
			headerComponentParams: { menuIcon: 'role' },
			cellClass: 'first-cell',
			filter: 'agSetColumnFilter',
			filterParams: {
				buttons: ['reset', 'apply'],
				suppressSelectAll: true,
				cellHeight: 48,
				suppressMiniFilter: true,
			},
		},
		{
			field: 'invited',
			headerName: 'Status',
			headerComponentParams: { menuIcon: 'invited' },
			cellClass: 'invited-cell',
			filter: 'agSetColumnFilter',
			filterParams: {
				buttons: ['reset', 'apply'],
				suppressSelectAll: true,
				cellHeight: 48,
				suppressMiniFilter: true,
			},
		},
		{
			cellRenderer: (row: any) => (
				(role === 'admin' && row.data?.email !== adminEmail) &&
				<UsersActionRenderer
					setOpenAddUserModal={setOpenAddUserModal}
					setUserInfoData={setUserInfoData}
					data={row.data}
					setOpenRemoveUserModal={setOpenRemoveUserModal}
				/>
			),
			filter: false,
			maxWidth: 70,
			sortable: false,
		},
	]

	const groupDefaultExpanded = 1

	const defaultColDef = useMemo(
		() => ({
			sortable: true,
			filter: 'agTextColumnFilter',
			floatingFilter: true,
			flex: 1,
			suppressMovable: true,
			suppressMenu: true,
			floatingFilterComponentParams: {
				suppressFilterButton: true,
			},
		}),
		[]
	)

	const groupRowRendererParams = useMemo(() => {
		return {
			innerRenderer: GroupCellRenderer,
			// checkbox: true,
			suppressCount: true,
		}
	}, [])

	const columnTypes = useMemo(() => {
		return {
			number: {
				aggFunc: 'count',
			},
		}
	}, [])

	// const cellClickedListener = useCallback((e: any) => {
	// 	console.log('cellClicked', e)
	// }, [])

	const onFirstDataRendered = useCallback(() => {
		setTimeout(() => {
			document
				.querySelectorAll('.ag-theme-alpine input[class^=ag-][type=text]')
				.forEach((obj) => {
					obj.setAttribute('placeholder', 'Search')
				})
		}, 5)
		setTimeout(() => {
			document
				.querySelectorAll(
					'.ag-theme-alpine .ag-disabled input[class^=ag-][type=text]'
				)
				.forEach((obj) => {
					obj.setAttribute('placeholder', 'Select')
					obj.removeAttribute('disabled')
				})
		}, 10)
	}, [])

	const getRowHeight = (params: any) => (params.node.group ? 72 : 50)

	const components = useMemo(() => {
		return {
			agColumnHeader: CustomHeader,
		}
	}, [])

	if((role === 'admin')){
		useQuery({
			queryKey: ['usersData',businessIdParam],
			queryFn:() => dependencyContainer.dependency.usersDataApi.invitedUsers(businessIdParam as string),
			onSuccess: (data) => {
				setInvitationsRowData(data as any)
			}
		})
		useQuery({
			queryKey: ['usersDataInvited',businessIdParam],
			queryFn:() => dependencyContainer.dependency.usersDataApi.users(businessIdParam as string),
			onSuccess: (data) => {
				setRowData(data as any)
			}
		})
	}else {
		useQuery({
			queryKey: ['usersData',businessIdParam],
			queryFn:() => dependencyContainer.dependency.usersDataApi.users(businessIdParam as string),
			onSuccess: (data) => {
				setRowData(data as any)
			}
		})
	}

	useEffect(() => {
		if(rowData !== null){
			if(invitationsRowData !== null ){
				(invitationsRowData as any).forEach((data: any)=> data.invited = 'Invited')
				setAllDataUsers((rowData as any).concat(invitationsRowData))
			}else {
				setAllDataUsers(rowData)
			}
			
		}
	}, [rowData,invitationsRowData])

	const bc = [
		{
			name: 'Users',
		},
	]

	const icons = useMemo(() => {
		return {
			groupExpanded: '<i class="ag-icon ag-icon-small-down" style=""/>',
			groupContracted: '<i class="ag-icon ag-icon-small-right" style=""/>',
		}
	}, [])

	const getRowStyle = (params: any) => {
		if (params.data.business !== undefined) {
			return { background: '#ebebeb' }
		} else {
			return { background: 'white' }
		}
	}

	return (
		<div className="w-full pl-[25px]">
			{/* <Header title="Dashboard (ADMIN)" /> */}
			<Breadcrumb links={bc} icon={<Group />} />
			<div className="bg-[#F0F0F0] rounded-t-xl mt-[23px] ">
				<div className={`users ag-theme-alpine ${role === 'admin' ? 'h-[calc(100vh-310px)]' : 'h-[calc(100vh-242px)]'}`}>
					<AgGridReact
						// onCellClicked={cellClickedListener}
						headerHeight={headerHeight}
						floatingFiltersHeight={floatingFiltersHeight}
						rowData={allDataUsers}
						columnDefs={columnDefs}
						defaultColDef={defaultColDef}
						columnTypes={columnTypes}
						groupDisplayType={'groupRows'}
						rowSelection="multiple"
						animateRows={true}
						onFirstDataRendered={onFirstDataRendered}
						groupSelectsChildren={true}
						suppressRowClickSelection={true}
						suppressAggFuncInHeader={true}
						groupRowRendererParams={groupRowRendererParams}
						getRowHeight={getRowHeight}
						icons={icons}
						components={components}
						groupDefaultExpanded={groupDefaultExpanded}
						pagination={true}
						paginationAutoPageSize={true}
						suppressContextMenu={true}
						getRowStyle={getRowStyle}
						//rowStyle={rowStyle}
					/>
				</div>
				<div className="mb-[25px] w-full h-[1px] order-1 bg-white"></div>
				{role === 'admin' && 
				<div className='flex'>
					<div
						onClick={() => setOpenAddUserModal(true)}
						className="mt-[15px] flex justify-center align-center px-[18px] py-[10px] rounded-3xl border-[#4D6071] border-[1px] cursor-pointer"
					>
						<IconPlus className="mr-[11px] mt-[3px]" />
						<h2 className="text-sm font-medium text-[#154365]">Add new User</h2>
					</div>
				</div>
				}
			</div>
			{openAddUserModal && (
				<AddUserModal
					setOpenAddUserModal={setOpenAddUserModal}
					openAddUserModal={openAddUserModal}
					cancelButtonRef={cancelButtonRef}
					userInfoData={userInfoData}
					setUserInfoData={setUserInfoData}
				/>
			)}
			{openRemoveUserModal && (
				<RemoveUserModal
					setOpenRemoveUserModal={setOpenRemoveUserModal}
					openRemoveUserModal={openRemoveUserModal}
					cancelButtonRemoveRef={cancelButtonRemoveRef}
					userInfoData={userInfoData}
					setUserInfoData={setUserInfoData}
				/>
			)}
		</div>
	)
}
