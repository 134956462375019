import AppKeycloak from '../Keycloak'
import UsersRepository, { UserModel } from './UserModelRepository'

import axios from 'axios'
export default class GetUsersRepository implements UsersRepository 
{

	async users(id?: string): Promise<UserModel[]> {
		const settings: any = JSON.parse(localStorage.getItem('settings') || '')

		const keycloak = AppKeycloak.getAppKeycloak()
		const data = await axios.get(`${settings.WEBPORTAL_BACKEND_URL}/businesses/${id}/users`, {
			headers: {
				Authorization: `Bearer ${keycloak.token}`,
			},
		})

		return data.data
	}

	async addNewUser(id?: string, email?: string, role?: string): Promise<UserModel>  {
		const settings: any = JSON.parse(localStorage.getItem('settings') || '')
		const keycloak = AppKeycloak.getAppKeycloak()

		const body = {
			'email': email,
			'role': role,
			
		}
		const call = await axios.post(`${settings.WEBPORTAL_BACKEND_URL}/businesses/${id}/users/invite-user`,body,{
			headers: {
				Authorization: `Bearer ${keycloak.token}`,
			},
		})
		return call as any
	}

	async editUserRole(id?: string,userId?:string, role?: string): Promise<any>  {
		const settings: any = JSON.parse(localStorage.getItem('settings') || '')
		const keycloak = AppKeycloak.getAppKeycloak()

		const body = {
			'newRole': role,
			
		}
		const call = await axios.post(`${settings.WEBPORTAL_BACKEND_URL}/businesses/${id}/users/${userId}/change-role`,body,{
			headers: {
				Authorization: `Bearer ${keycloak.token}`,
			},
		})
		return call
	}

	async removeUser(id?: string, userId?: string): Promise<any>  {
		const settings: any = JSON.parse(localStorage.getItem('settings') || '')
		const keycloak = AppKeycloak.getAppKeycloak()

		const body = {}

		const call = await axios.post(`${settings.WEBPORTAL_BACKEND_URL}/businesses/${id}/users/${userId}/remove-user`,body,{
			headers: {
				Authorization: `Bearer ${keycloak.token}`,
			},
		})
		return call
	}

	async invitedUsers(id?: string): Promise<UserModel[]> {
		const settings: any = JSON.parse(localStorage.getItem('settings') || '')

		const keycloak = AppKeycloak.getAppKeycloak()
		const data = await axios.get(`${settings.WEBPORTAL_BACKEND_URL}/businesses/${id}/users/invitations`, {
			headers: {
				Authorization: `Bearer ${keycloak.token}`,
			},
		})

		return data.data
	}

	async deleteInvitedUser(id?: string, invitationId?: string): Promise<any>  {
		const settings: any = JSON.parse(localStorage.getItem('settings') || '')
		const keycloak = AppKeycloak.getAppKeycloak()

		const call = await axios.delete(`${settings.WEBPORTAL_BACKEND_URL}/businesses/${id}/users/invitations/${invitationId}`,{
			headers: {
				Authorization: `Bearer ${keycloak.token}`,
			},
		})
		return call
	}
}
