import { ICellRendererParams } from 'ag-grid-community'
import React from 'react'

const ValidityFilterFormatter = (params: ICellRendererParams) => {

	const validity = params.value

	if (validity === 'true') {
		return (<div className='forecast-filter'><div style={{ backgroundColor: '#7DC2FD', marginRight: '4px' }}></div> Valid</div>)
	} else if (validity === 'false') {
		return (<div className='forecast-filter'><div style={{ backgroundColor: '#BA1A1A', marginRight: '4px' }}></div> Expired</div>)
	}

	return '(No validity)'
}

export default ValidityFilterFormatter
