import React from 'react'

const ForecastRenderer = (props: any) => {

	let cellValue = ''
	let bgColor = ''
	let color = ''

	const quotaForecast = props.data.standing !== undefined ? (props.data.standing.length !== 0 ? props.data.standing[0]?.quota.status : 'QUOTA_NOT_SET') : null

	const forecast: string = props.data.consumptionForecast ? props.data.consumptionForecast : quotaForecast

	if (forecast === 'VIOLATED') {
		cellValue = 'Violated'
		bgColor = '#BA1A1A'
		color = '#ffffff'
	} else if (forecast === 'ENDANGERED') {
		cellValue = 'Endangered'
		bgColor = '#FFBB00'
	} else if (forecast === 'ON_TRACK') {
		cellValue = 'On track'
		bgColor = '#154365'
		color = '#ffffff'
	} else if (forecast === 'QUOTA_NOT_SET') {
		cellValue = 'No limitation'
		bgColor = '#7DC2FD'
	}

	return (
		<div className='forecast-holder'>
			<div className='forecast' style={{ backgroundColor: bgColor, color: color}}>
				<span>{cellValue}</span>
			</div>
		</div>
	)
}

export default ForecastRenderer
