import React  from 'react'
import { useNavigate } from 'react-router-dom'
import ProviderMessage from '../ProviderMessage/ProviderMessage'

interface BC {
  links: Inner[];
  icon?: any;
  id?: string;
	mpData?: any
}
interface Inner {
  name: string;
  link?: string;
  icon?: any;
	path?: string
}

export default function Breadcrumb(props: BC) {
	const navigate = useNavigate()

	return (
		<div className="text-[#154365]">
			<h2 className="flex border-b items-center border-stone-200 border-solid pb-2">
				<div className="mr-[7px] mt-[-2px]">{props.icon}</div>
				{props.links.map(({ name, link, path }) => {
					if (!link)
						return (
							<div className="font-medium text-base" key={name}>
								{name}
							</div>
						)
					else
						return (
							<div key={name} className="flex w-[100%]">
								<div className='flex'>
									<div
										className="font-medium hover:text-[#96CCFF] text-base cursor-pointer"
										onClick={() => navigate(`${path}`)}
										key={name}
									>
										{name}&nbsp;
									</div>
									<div>
										/
									</div>
									<div className="font-medium text-base mr-[3px]">
                  &nbsp;{link}&nbsp;
									</div>
								</div>
								<div className='ml-[auto]'>
									<ProviderMessage
										mpData={props.mpData}
									/>
								</div>
							</div>
						)
				})}
			</h2>
		</div>
	)
}
