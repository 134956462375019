import AppKeycloak from '../Keycloak'
import QuotasRepository, {MeasuringPointQuotasModel} from './QuotasRepository'
import axios from 'axios'

export default class APIQuotasRepository implements QuotasRepository 
{

	async mpQuotas(id?: string, mpId?: string): Promise<MeasuringPointQuotasModel[]> {
		const settings: any = JSON.parse(localStorage.getItem('settings') || '')
		const keycloak = AppKeycloak.getAppKeycloak()

		const data = await axios.get(`${settings.WEBPORTAL_BACKEND_URL}/businesses/${id}/measuring-points/${mpId}/quotas`, {
			headers: {
				Authorization: `Bearer ${keycloak.token}`,
			},
		})

		return this.mapToViewModel(data.data)
	}

	async createQuota(id?: string, name?: string, comment?: string, mpId?: string, 
		amount?: number, startOfThePeriod?: number, endOfThePeriod?: number, 
		referencePeriodStart?: number, referencePeriodEnd?: number, referenceAmount?: number): Promise<MeasuringPointQuotasModel>  {
		const settings: any = JSON.parse(localStorage.getItem('settings') || '')
		const keycloak = AppKeycloak.getAppKeycloak()

		const body = {
			'name': name,
			'comment': comment,
			'measuringPoint': mpId,
			'amount': amount,
			'startOfThePeriod': startOfThePeriod,
			'endOfThePeriod': endOfThePeriod,
			'referencePeriodStart': referencePeriodStart,
			'referencePeriodEnd': referencePeriodEnd,
			'referenceAmount': referenceAmount
			
		}
		const call = await axios.post(`${settings.WEBPORTAL_BACKEND_URL}/businesses/${id}/quotas`,body,{
			headers: {
				Authorization: `Bearer ${keycloak.token}`,
			},
		})
		return call as any
	}

	async changeQuota(id?: string, quotaId?: string, name?: string, comment?: string, 
		amount?: number, startOfThePeriod?: number, endOfThePeriod?: number, 
		referencePeriodStart?: number, referencePeriodEnd?: number, referenceAmount?: number): Promise<MeasuringPointQuotasModel>  {
		const settings: any = JSON.parse(localStorage.getItem('settings') || '')
		const keycloak = AppKeycloak.getAppKeycloak()

		const body = {
			'name': name,
			'comment': comment,
			'amount': amount,
			'startOfThePeriod': startOfThePeriod,
			'endOfThePeriod': endOfThePeriod,
			'referencePeriodStart': referencePeriodStart,
			'referencePeriodEnd': referencePeriodEnd,
			'referenceAmount': referenceAmount
			
		}
		const call = await axios.post(`${settings.WEBPORTAL_BACKEND_URL}/businesses/${id}/quotas/${quotaId}/change`,body,{
			headers: {
				Authorization: `Bearer ${keycloak.token}`,
			},
		})
		return call as any
	}

	async deleteQuota(id?: string, quotaId?: string): Promise<any>  {
		const settings: any = JSON.parse(localStorage.getItem('settings') || '')
		const keycloak = AppKeycloak.getAppKeycloak()

		const call = await axios.delete(`${settings.WEBPORTAL_BACKEND_URL}/businesses/${id}/quotas/${quotaId}`,{
			headers: {
				Authorization: `Bearer ${keycloak.token}`,
			},
		})
		return call
	}

	months = (month: number) =>{
		if (month === 0) return 'January'
		if (month === 1) return 'February'
		if (month === 2) return 'March'
		if (month === 3) return 'April'
		if (month === 4) return 'May'
		if (month === 5) return 'June'
		if (month === 6) return 'July'
		if (month === 7) return 'August'
		if (month === 8) return 'September'
		if (month === 9) return 'October'
		if (month === 10) return 'November'
		if (month === 11) return 'December'
	}

	getMonth = (timestamp: number) => {
		const date = new Date(timestamp)
		return this.months(date.getMonth()) + ' ' + date.getFullYear() 
	}

	mapToViewModel = ( data: any) => {
		return data.map((el: any) => {
			const qoutaCalculation = el.quota?.amount !== undefined && el.quota?.reference?.amount !== undefined ? 
				(el.quota?.amount * 100 / el.quota?.reference?.amount)?.toFixed(2)
				: 
				''
			return {
				month: this.getMonth(el.startOfThePeriod),
				referencePeriod: this.getMonth(el.quota?.reference?.startOfThePeriod),
				referenceAmount: el.quota?.reference?.amount,
				quotaRate: qoutaCalculation,
				quota: el.quota?.amount,
				id: el.quota?.id,
				comment: el.quota?.comment  ? el.quota?.comment : '',
			}
		})
	}

}
