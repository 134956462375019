import AppKeycloak from '../Keycloak'
import BusinessRepository, { OneBusinessModel } from './BusinessRepository'

// import { useBusinessQuery } from "../query/useBusinessQuery"

import axios from 'axios'
export default class APIBusinessRepository implements BusinessRepository {

	async business(id?: string): Promise<OneBusinessModel> {
		const settings: any = JSON.parse(localStorage.getItem('settings') || '')
		const keycloak = AppKeycloak.getAppKeycloak()

		const data = await axios.get(`${settings.WEBPORTAL_BACKEND_URL}/businesses/${id}`, {
			headers: {
				Authorization: `Bearer ${keycloak.token}`,
			},
		})

		return data.data
	}

	async editBusiness(id?: string, name?: string, ceoName?: string, address?: string, city?: string,  zip?: string, accountNumber?: string): Promise<OneBusinessModel>  {
		const settings: any = JSON.parse(localStorage.getItem('settings') || '')
		const keycloak = AppKeycloak.getAppKeycloak()

		const body = {
			'name': name,
			'ceoName': ceoName,
			'address': address,
			'city': city,
			'zip': zip,
			'accountNumber': accountNumber,
		}
		const call = await axios.post(`${settings.WEBPORTAL_BACKEND_URL}/businesses/${id}/change-info`,body,{
			headers: {
				Authorization: `Bearer ${keycloak.token}`,
			},
		})
		return call as any
	}

	async addNewBusiness(id?: string, name?: string, ceoName?: string, address?: string, city?: string,  zip?: string, accountNumber?: string): Promise<OneBusinessModel>  {
		const settings: any = JSON.parse(localStorage.getItem('settings') || '')
		const keycloak = AppKeycloak.getAppKeycloak()

		const body = {
			'name': name,
			'ceoName': ceoName,
			'address': address,
			'city': city,
			'zip': zip,
			'accountNumber': accountNumber,
		}
		const call = await axios.post(`${settings.WEBPORTAL_BACKEND_URL}/businesses/${id}/create-business`,body,{
			headers: {
				Authorization: `Bearer ${keycloak.token}`,
			},
		})
		return call as any
	}
}
