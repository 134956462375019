import React, { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { ReactComponent as Close } from '../../../assets/images/icons/close.svg'
import { ReactComponent as CloseBlack } from '../../../assets/images/icons/closeBlack.svg'
import { ReactComponent as Check } from '../../../assets/images/icons/check.svg'
import { ReactComponent as Info } from '../../../assets/images/icons/infoMessage.svg'
import ActionButton from '../ActionButton'

interface Props {
  setOpenRotateConfirmationModal: React.Dispatch<React.SetStateAction<boolean>>;
  setSignalForOpenAddAPIKeyModal: React.Dispatch<React.SetStateAction<boolean>>;
  setAPIKeyData: any;
  setRotate: React.Dispatch<React.SetStateAction<boolean>>;
  openRotateConfirmationModal: boolean;
  rotateConfirmationRef: any;
}

const RotateConfirmationModal = ({
	openRotateConfirmationModal,
	setOpenRotateConfirmationModal,
	setSignalForOpenAddAPIKeyModal,
	rotateConfirmationRef,
	setRotate,
	setAPIKeyData,
}: Props) => {

	return (
		<Transition.Root show={openRotateConfirmationModal} as={Fragment}>
			<Dialog
				as="div"
				className="relative z-[20]"
				initialFocus={rotateConfirmationRef}
				onClose={()=>{
					//no action for clicking outside modal
				}}
			>
				<Transition.Child
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 bg-[#000000] bg-opacity-75 transition-opacity " />
				</Transition.Child>

				<div className="fixed inset-0 z-10 overflow-y-auto">
					<div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							enterTo="opacity-100 translate-y-0 sm:scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 translate-y-0 sm:scale-100"
							leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						>
							<Dialog.Panel
								className="relative transform overflow-hidden rounded-lg
               bg-white text-left shadow-xl transition-all sm:my-8 xl:w-[603px] lg:w-[503px]"
							>
								<div className="p-[15px]">
									<div className=" flex flex-row justify-between items-center pt-[9px]">
										<div className="flex items-center">
											<div className="flex items-center w-5 h-4 mb-[3px]">
												<Info />
											</div>
											<div className=" pl-[7px]">
												<h2 className="text-base font-medium tracking-[0.15px] text-[#154365]">	
													Are you sure you want to rotate this API key?										
												</h2>
											</div>
										</div>
										<div
											className=""
											onClick={()=>{
												setOpenRotateConfirmationModal(false)
												setAPIKeyData()
											}}
										>
											<Close className="text-[#4D6071] cursor-pointer" />
										</div>
									</div>
									<div className="mt-[10px] w-full h-[0.75px] order-1 bg-[#A7ACB3]"></div>
									<div className="mt-[23px] bg-[#F0F0F0] rounded-xl flex items-center flex-wrap p-[13px]">
										<div className="items-center text-[#154365] text-[14px] flex flex-wrap">
											<p className="pb-[20px]">Warning: This action will overwrite the current API key with a new one. Please make sure to update your connected applications or systems with the new API key.</p>
											<p>(Note: The new key takes effect immediately, the old one remains valid for another 24 hours to ensure a seamless transition.)</p>
										</div>
									</div>
									<div className="mt-[30px] flex justify-end">
										<div ref={rotateConfirmationRef}>
											<ActionButton
												className="bg-[#D5E4F7] text-[#001D32] font-medium py-2.5 pl-[21px] pr-[24px] rounded-full h-[40px]"
												text="No"
												icon={<CloseBlack />}
												onClick={()=>{
													setOpenRotateConfirmationModal(false)
													setAPIKeyData()
												}}
											/>
										</div>
										<div ref={rotateConfirmationRef} className="ml-[20px]">
											<ActionButton
												className="bg-[#154365] text-white font-medium py-2.5 pl-[21px] pr-[24px] rounded-full h-[40px]"
												text="Yes"
												icon={<Check />}
												onClick={()=>{
													setOpenRotateConfirmationModal(false)
													setSignalForOpenAddAPIKeyModal(true)
													setRotate(true)
												}}
											/>
										</div>
									</div>
								</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition.Root>
	)
}

export default RotateConfirmationModal
