import React from 'react'
import { useState, useEffect, useRef, useMemo, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { ReactComponent as Badge1 } from '../../../assets/images/icons/badge1.svg'
import { ReactComponent as Style2 } from '../../../assets/images/icons/style2.svg'
import { ReactComponent as FlashOn } from '../../../assets/images/icons/flash_on1.svg'
import { ReactComponent as DinamicForm } from '../../../assets/images/icons/dynamic_form1.svg'
import { ReactComponent as Percent } from '../../../assets/images/icons/percent1.svg'
import { ReactComponent as Forecast } from '../../../assets/images/icons/apps1.svg'
import { ReactComponent as ChartIcon } from '../../../assets/images/icons/charticon.svg'
import { ReactComponent as Calculate } from '../../../assets/images/icons/calculate.svg'
import { ReactComponent as Edit } from '../../../assets/images/icons/edit1.svg'
import { ReactComponent as AlertRed } from '../../../assets/images/icons/infoMessageRed.svg'
import { ReactComponent as Factor } from '../../../assets/images/icons/factor.svg'

import { Select, Option } from '@material-tailwind/react'
import { useAppSelector } from '../../hooks'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-alpine.css'
import 'ag-grid-enterprise'
import '../../pages/admin/ag-grid-power.css'
import { LicenseManager } from 'ag-grid-enterprise'
import { AgGridReact } from 'ag-grid-react'
import * as am5 from '@amcharts/amcharts5'
import * as am5xy from '@amcharts/amcharts5/xy'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'
import * as am5plugins_exporting from '@amcharts/amcharts5/plugins/exporting'
import { Input } from '@material-tailwind/react'
import { TimeUnit } from '@amcharts/amcharts5/.internal/core/util/Time'
import './customCss.css'
import { useQuery } from '@tanstack/react-query'
import { dependencyContainer } from '../../store'
import CheckIcon from '../CheckIcon'
import CloseIcon from '../CloseIcon'
import ForecastRenderer from '../../components/Table/ForecastRenderer'
import EditIcon from '../EditIcon'
import { useMutation } from '@tanstack/react-query'
import CustomHeader from '../Table/CustomHeader'
import CalculatedMpModal from '../Modals/CalculatedMpModal'
import ChangingInputModal from '../Modals/ChangingInputModal'
import dayjs, { Dayjs } from 'dayjs'
import TextField from '@mui/material/TextField'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import ThemeProvider from '@mui/system/ThemeProvider/ThemeProvider'
import { createTheme } from '@mui/material/styles'
import MpCellRenderWithIcon from '../Table/MpCellRenderWithIcon'
import TagsRenderer from '../Table/TagsRenderer'

const theme = createTheme({
	palette: {
		primary: {
			main: '#2196f3',
		},
		secondary: {
			main: '#f44336',
		},
	},
})

LicenseManager.setLicenseKey(
	'CompanyName=Appmodule AG,LicensedGroup=aliunid grid / power,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=2,AssetReference=AG-036901,SupportServicesEnd=31_January_2024_[v2]_MTcwNjY1OTIwMDAwMA==4c9d5d4b04b142bc6b50ed895e5b5ef3'
)

const resolutionValues = [
	{
		id: 1,
		name: 'Auto',
	},
	{
		id: 2,
		name: '10 seconds',
	},
	{
		id: 3,
		name: '30 seconds',
	},
	{
		id: 4,
		name: '1 minute',
	},
	{
		id: 5,
		name: '5 minutes',
	},
	{
		id: 6,
		name: '10 minutes',
	},
	{
		id: 7,
		name: '15 minutes',
	},
	{
		id: 8,
		name: '30 minutes',
	},
	{
		id: 9,
		name: '1 hour',
	},
	{
		id: 10,
		name: '1 day',
	},
]

interface Props {
	mpData: any
	kwLive: number
}

export default function DataTab({ mpData, kwLive }: Props) {

	const [mpHistoryData, setMPHistoryData] = useState([])
	const [resolution, setResolution] = useState(resolutionValues[0].name)
	const [averageValue, setAverageValue] = useState((0.0).toFixed(2))
	const [maxValue, setMaxValue] = useState((0.0).toFixed(2))
	const [minValue, setMinValue] = useState((0.0).toFixed(2))
	const [errorData, setErrorData] = useState(false)
	const [errorNoData, setErrorNoData] = useState(false)
	const [inputNameActive, setInputNameActive] = useState(false)
	const [mpName, setMpName] = useState('')
	const [error, setError] = useState('')
	const [openCreateCalculatedMpModal, setOpenCreateCalculatedMpModal] = useState(false)
	const [showChangingInputModal, setShowChangingInputModal] = useState(false)
	const [rowDataVmp, setRowDataVmp] = useState([])

	const [dateFrom, setDateFrom] = useState<Dayjs | null>(dayjs().add(-1, 'day'))
	const [dateTo, setDateTo] = useState<Dayjs | null>(dayjs())

	const { business } = useAppSelector((state) => state.app)
	const { mpId: id, subBusinessId } = useParams()

	const inputNameRef = useRef<any>()
	const calculatedMpRef = useRef(null)
	const changingInputRef = useRef(null)

	const { role } = useAppSelector((state) => state.app)

	useEffect(() => {
		if(mpData !== undefined){
			setMpName(mpData.name ? mpData.name : '')
		}
	}, [mpData])

	const editMpName = useMutation({
		mutationFn: () => dependencyContainer.dependency.measuringPointsApi.changeNameMeasuringPoint(business, mpData?.id, mpName),
		onSuccess: async () => {
			window.location.reload()
		},
		onError: (error: any) => {
			setError(error.response?.data?.message ? error.response?.data?.message : 'Internal server error !')
		},
	})

	useQuery({
		queryKey: ['involvedMps', business, mpData],
		queryFn:() =>  dependencyContainer.dependency.dashboardApi.dashboardData(business as string),
		enabled: !!mpData,
		onSuccess: (data) => {
			if(Object.keys(mpData)?.length !== 0){
				const filteredNotificationData = mpData?.involvedPoints
				const filteredMpData = data.filter((mp)=> filteredNotificationData?.indexOf(mp.id) > -1)
				setRowDataVmp(filteredMpData as any)
			}
		}
	})

	const handleChangeMpName = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setMpName(event.target.value)
	}

	const saveNewName = () => {
		editMpName.mutate()
		setInputNameActive(false)
	}

	const resetName = () => {
		setMpName(mpData.name ? mpData.name : '')
		setInputNameActive(true)
	}

	const changeDateFrom = (newValue: any) => {
		if(newValue !== null){
			setDateFrom(newValue)
			setErrorNoData(false)
			setErrorData(false)
		}
	}

	const changeDateTo = (newValue: any) => {
		if(newValue !== null){
			setDateTo(newValue)
			setErrorNoData(false)
			setErrorData(false)
		}
	}

	const setResolutionOnClick = (value: any) => {
		setResolution(value)
	}

	const resolutionMap = (resolution: any) => {
		if (resolution === 'Auto') return 0
		if (resolution === '10 seconds') return 10
		if (resolution === '30 seconds') return 30
		if (resolution === '1 minute') return 60
		if (resolution === '5 minutes') return 300
		if (resolution === '10 minutes') return 600
		if (resolution === '15 minutes') return 900
		if (resolution === '30 minutes') return 1800
		if (resolution === '1 hour') return 3600
		if (resolution === '1 day') return 86400
	}

	const countForChart = (resolution: string) => {
		if (
			resolution === 'Auto' ||
			resolution === '10 seconds' ||
			resolution === '30 seconds'
		) {
			return 'second'
		} else if (
			resolution === '1 minute' ||
			resolution === '5 minutes' ||
			resolution === '10 minutes' ||
			resolution === '15 minutes' ||
			resolution === '30 minutes'
		) {
			return 'minute'
		} else if (resolution === '1 hour') {
			return 'hour'
		} else if (resolution === '1 day') {
			return 'day'
		}
	}

	useEffect(() => {
		const sysArr = [] as any
		if (typeof mpHistoryData !== 'undefined') {
			if (
				Object.keys(mpHistoryData).length !== 0 &&
				Array.isArray(mpHistoryData)
			) {
				mpHistoryData.map((el) => {
					return sysArr.push(el['value'])
				})
				setAverageValue(
					(sysArr.reduce((a: any, b: any) => a + b, 0) / sysArr.length).toFixed(2)
				)
				setMaxValue(Math.max(...sysArr).toFixed(2))
				setMinValue(Math.min(...sysArr).toFixed(2))
			}
		}
	}, [mpHistoryData])
	
	const from = new Date(dateFrom as any).getTime()
	const to = new Date(dateTo as any).getTime()
	
	let interval = Math.round((to - from) / 3000 / 1000)
	if(interval < 10) interval = 10

	const resolutionMapForChart = (resolution: any) => {
		if (resolution === 'Auto') return interval
		if (resolution === '10 seconds') return 10
		if (resolution === '30 seconds') return 30
		if (resolution === '1 minute') return 1
		if (resolution === '5 minutes') return 5
		if (resolution === '10 minutes') return 10
		if (resolution === '15 minutes') return 15
		if (resolution === '30 minutes') return 30
		if (resolution === '1 hour') return 1
		if (resolution === '1 day') return 1
	}

	if (typeof resolution !== 'undefined' && resolution !== 'Auto') {
		if ((resolutionMap(resolution) as number) >= interval / 2) {
			interval = resolutionMap(resolution) as number
		} 
	}

	useEffect(() => {
		if ((resolutionMap(resolution) as number) < interval / 2) {
			setResolution('Auto')
		}
	}, [resolution])
	
	useEffect(() => {
		if (dateFrom === dateTo || (dateFrom as any) > (dateTo as any)) {
			setErrorData(true)
		}
	}, [dateFrom, dateTo, resolution])
	
	useQuery({
		queryKey: ['historyData',dateFrom, dateTo, resolution],
		queryFn:() => dependencyContainer.dependency.mpHistoryDataApi.historyData(business, subBusinessId as string, id as string, from, to, interval),
		onSuccess: (data) => {
			setMPHistoryData(data as any)
			if(data.length === 0) {
				setErrorNoData(true)
			} 
			else {
				setErrorNoData(false)
				setErrorData(false)
			}
		},
		onError: () => {
			setErrorData(true)
		},
	})

	// const modifyForExport = (data: any) => {
	// 	data.forEach((el: any) => {
	// 		const date: any = new Date(el.time)
	// 		el.time = date.getHours() + ':' + date.getMinutes() + ', '+ date.toDateString()
	// 		el.value = el.value.toFixed(3) 
	// 	})
	// 	return data
	// }

	useEffect(() => {
		const root = am5.Root.new('chartdiv')

		root.setThemes([am5themes_Animated.new(root)])

		const chart = root.container.children.push(
			am5xy.XYChart.new(root, {
				panX: false,
				panY: false,
				wheelX: 'panX',
				wheelY: 'zoomX',
				pinchZoomX: true,
			})
		)

		const cursor = chart.set(
			'cursor',
			am5xy.XYCursor.new(root, {
				behavior: 'zoomX',
			})
		)
		cursor.lineY.set('visible', false)
		cursor.lineX.setAll({
			stroke: am5.color('#004368'),
		})

		// Create axes
		// https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
		const xAxis = chart.xAxes.push(
			am5xy.DateAxis.new(root, {
				//baseInterval: { timeUnit:'minute', count: 1 },
				baseInterval: {
					timeUnit: countForChart(resolution) as TimeUnit,
					count: resolutionMapForChart(resolution) as number,
				},

				renderer: am5xy.AxisRendererX.new(root, {}),
				tooltip: am5.Tooltip.new(root, {}),
			})
		)

		const yAxis = chart.yAxes.push(
			am5xy.ValueAxis.new(root, {
				renderer: am5xy.AxisRendererY.new(root, {}),
			})
		)

		yAxis.axisHeader.set('paddingRight', 100)

		yAxis.children.unshift(
			am5.Label.new(root, {
				// rotation: -90,
				text: 'kW',
				fontWeight: '500',
				y: -35,
				x: 30
				// centerX: am5.p50
			})
		)

		// Add series
		// https://www.amcharts.com/docs/v5/charts/xy-chart/series/
		const series = chart.series.push(
			am5xy.LineSeries.new(root, {
				xAxis: xAxis,
				yAxis: yAxis,
				valueYField: 'value',
				valueXField: 'time',
				stroke: am5.color('#004368'),
				height: 50,
				tooltip: am5.Tooltip.new(root, {
					labelText: '[fontSize:13px #154365 bold]{valueY} kW[/]',
					paddingTop:20,
					paddingBottom:20,
					paddingLeft: 30,
					paddingRight: 30,
				}),
			})
		)

		series.data.setAll(mpHistoryData)

		series.strokes.template.setAll({
			strokeWidth: 3,
		})

		series.setAll({
			fill: am5.color('#F0F0F0'),
		})

		chart.set(
			'scrollbarX',
			am5.Scrollbar.new(root, { orientation: 'horizontal' })
		)

		const scrollbarX: any = chart.get('scrollbarX')

		scrollbarX.get('background').setAll({
			fill: am5.color('#CEE5FF'),
			maxHeight: 6,
			dy: 3
		})

		scrollbarX.thumb.setAll({
			fill: am5.color('#004368'),
			height: 6,
			//fillOpacity: 0.1,
		})

		scrollbarX.startGrip.setAll({
			background: am5.Circle.new(root, {
				fill: am5.color(0x004368),
				centerX: -20,
				centerY: -15,
			}),
		})

		scrollbarX.endGrip.setAll({
			background: am5.Circle.new(root, {
				fill: am5.color(0x004368),
				centerX: -10,
				centerY: -15,
			}),
		})

		am5plugins_exporting.Exporting.new(root, {
			menu: am5plugins_exporting.ExportingMenu.new(root, {}),
			dataSource: mpHistoryData,
			numericFields: ['value'],
			numberFormat: '###,###.000',
			dateFields: ['time'],
			dateFormat: 'hh:mm dd.MM.yyyy',
			dataFields: {
				time: 'Time and Date',
				value: 'Value (kW)',
			},
			dataFieldsOrder: ['time', 'value'],
		})

		return () => {
			root.dispose()
		}
	}, [mpHistoryData, resolution, from, to])

	//agGrid
	const columnDefs = [
		{
			field: 'name',
			headerName: 'Sum of:',
			//minWidth: 370,
			headerComponentParams: { menuIcon: 'sum' },
			cellClass: 'first-cell',
			cellRenderer: (row: any) => (
				<MpCellRenderWithIcon
					data={row.data}
					length={100}
				/>
			),
			cellStyle: function() {
				return { wordBreak: 'break-word' }
			},
		},
		{
			field: 'tags',
			filter: 'agSetColumnFilter',
			filterParams: {
				buttons: ['reset', 'apply'],
				suppressSelectAll: true,
				cellHeight: 48,
				suppressMiniFilter: true,
			},
			cellRenderer: TagsRenderer,
			headerComponentParams: { menuIcon: 'style' },
			autoHeight: true,
			wrapText: true,
			cellStyle: { justifyContent: 'center' },
		},
	]

	const headerHeight = 25
	const floatingFiltersHeight = 30
	const rowHeight = 50

	const defaultColDef = useMemo(
		() => ({
			sortable: true,
			filter: 'agTextColumnFilter',
			floatingFilter: true,
			flex: 1,
			suppressMovable: true,
			wrapText: true,
			suppressMenu: true,
			floatingFilterComponentParams: {
				suppressFilterButton: true,
			},
		}),
		[]
	)

	const columnTypes = useMemo(() => {
		return {
			number: {
				aggFunc: 'count',
			},
		}
	}, [])

	const onFirstDataRendered = useCallback(() => {
		setTimeout(() => {
			document
				.querySelectorAll('.ag-theme-alpine input[class^=ag-][type=text]')
				.forEach((obj) => {
					obj.setAttribute('placeholder', 'Search')
				})
		}, 5)
		setTimeout(() => {
			document
				.querySelectorAll(
					'.ag-theme-alpine .ag-disabled input[class^=ag-][type=text]'
				)
				.forEach((obj) => {
					obj.setAttribute('placeholder', 'Select')
					obj.removeAttribute('disabled')
				})
		}, 10)
	}, [])

	const components = useMemo(() => {
		return {
			agColumnHeader: CustomHeader,
		}
	}, [])

	const getRowStyle = (params: any) => {
		if (params.data.business !== undefined) {
			return { background: '#ebebeb' }
		} else {
			return { background: 'white' }
		}
	}

	const qoutaCalculation = mpData.standing !== undefined && mpData.standing.length !== 0 ? (mpData.standing[0].consumption.used * 100 / mpData.standing[0].quota.amount)?.toFixed(2) : 'n/a'
	const kwhQuota = mpData.standing !== undefined && mpData.standing.length !== 0 ? mpData.standing[0].quota.amount : 'n/a'
	const kwhUsed = mpData.standing !== undefined && mpData.standing.length !== 0 ? mpData.standing[0].consumption.used : '-'

	useEffect(() => {
		function handleClickOutside(event: any) {
			if (mpData.name !== undefined && mpName !== '' && !inputNameRef.current.contains(event.target) && (mpName !== mpData.name)) {
				setShowChangingInputModal(true)
			}
		}
		
		document.addEventListener('mousedown', handleClickOutside)
		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [mpName,mpData])
	
	return (
		<div>
			<div className="bg-[#F0F0F0] p-[13px] text-[#004368] rounded-tr-xl">				
				<div className="grid grid-cols-2 gap-4  mb-[3px] ">
					<div className="bg-white rounded-xl p-[10px] min-h-[107px]">
						<div className="flex-col">
							<div className="flex mt-[13.5px]">
								<div className="items-center w-5 h-5">
									<Badge1 className="w-4 h-4 text-[#004368]" />
								</div>
								<div>
									<h2 className="leading-6 font-medium tracking-[0.15px] mt-[-2px] ml-[8px] text-[11px]">
										Measuring Point Name
									</h2>
								</div>
							</div>
							<div className="mt-[2px] w-full h-[1px] order-1 bg-[#96CCFF]"></div>
							<div className="mt-[-5px] w-full text-[#004368]">
								<Input
									crossOrigin=''
									ref={inputNameRef}
									id='name'
									tabIndex={-1}
									style={{color: '#004368', fontWeight: 500}}
									onClick={() => {
										setInputNameActive(true)
									}}
									value={mpName || ''}
									readOnly={role === 'admin' && mpData?.provider === undefined ? false : true}
									onChange={handleChangeMpName}
									variant="static"
									onBlur={()=>{
										setTimeout(function(){
											setInputNameActive(false)
										}, 150)
									}}
									icon={
										role === 'admin' && mpData?.provider === undefined ? (inputNameActive ? (
											<div className="flex ml-[-16px] mt-[3px]">
												<CheckIcon
													onClick={() => saveNewName()}
													className='cursor-pointer top-[-5px] w-[30px] bg-[white] p-[6px] h-[30px] absolute right-[18px]'
												/>
												<CloseIcon
													className='cursor-pointer h-[13px] w-[15px] bg-[white] mt-[1px]'
													onClick={() => resetName()}
												/>
											</div>
										) : (
											<EditIcon
												className='cursor-pointer mt-[3px] w-[50px] absolute right-[-10px] z-[20] bg-[white] h-[15px]'
												onClick={() => {
													setInputNameActive(true)
													inputNameRef.current?.children[1].focus()
												}}
											/>
										))
											:
											null
									}
								/>
								{error && (
									<div className="flex justify-start mt-[10px] text-[#ff0000] text-sm">
										<AlertRed  className='mt-[1px]'/>
										<h2  className='ml-[8px]'>{error}</h2>
									</div>
								)}
							</div>
						</div>
					</div>
					<div className=" bg-white rounded-xl p-[10px] min-h-[107px]">
						<div className="flex-col">
							<div className="flex mt-[13.5px]">
								<div className="items-center w-5 h-5">
									<Style2 className="w-4 h-4 text-[#004368]" />
								</div>
								<div>
									<h2 className="leading-6 font-medium tracking-[0.15px] mt-[-2px] ml-[8px] text-[11px]">
										Tags
									</h2>
								</div>
							</div>
							<div className="mt-[2px] w-full h-[1px] order-1 bg-[#96CCFF]"></div>
							<div className="flex flex-wrap mt-[7px]">
								{'-'}
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="bg-[#F0F0F0] p-[13px] rounded-b-xl text-[#004368]">
				<div className="grid grid-cols-2 gap-4 mb-[15px]">
					<div className="bg-white rounded-xl p-[10px] h-[107px]">
						<div className="flex-col ">
							<div className="flex mt-[13.5px]">
								<div className="items-center w-5 h-5">
									<FlashOn className="w-4 h-4 text-[#004368]" />
								</div>
								<div>
									<h2 className="leading-6 font-medium tracking-[0.15px] mt-[-2px] ml-[5px] text-[11px]">
										kW Live:
									</h2>
								</div>
							</div>
							<div className="mt-[2px] w-full h-[1px] order-1 bg-[#96CCFF]"></div>
							<h2 className="font-medium leading-6 tracking-[0.15px] mt-[7px]">
								{kwLive === -1 ? '-' : kwLive}
							</h2>
						</div>
					</div>
					{mpData?.involvedPoints ?
						<div className="bg-white rounded-xl p-[10px] h-[107px] ">
							<div className="flex-col ">
								<div className="flex mt-[13.5px]">
									<div className="items-center w-5 h-5 pb-[5px]">
										<Factor className="w-6 h-6 text-[#004368] relative bottom-[3px] left-[-5px]" />
									</div>
									<div>
										<h2 className="leading-6 font-medium tracking-[0.15px] mt-[-2px] ml-[5px] text-[11px]">
										Factor:
										</h2>
									</div>
								</div>
								<div className="mt-[2px] w-full h-[1px] order-1 bg-[#96CCFF]"></div>
								<h2 className="font-medium leading-6 tracking-[0.15px] mt-[7px]">
									{mpData?.calculationFactor > 0 ?  `+ ${mpData?.calculationFactor}` : `${mpData?.calculationFactor}`}
								</h2>
							</div>
						</div> 
						:
						<div className="bg-white rounded-xl p-[10px] h-[107px]">
							<div className="flex-col">
								<div className="flex mt-[13.5px]">
									<div className="items-center w-5 h-5">
										<Forecast className="w-[11px] h-4 text-[#004368]" />
									</div>
									<div>
										<h2 className="leading-6 font-medium tracking-[0.15px] mt-[-2px] ml-[8px] text-[11px]">
										Forecast:
										</h2>
									</div>
								</div>
								<div className="mt-[2px] w-full h-[1px] order-1 bg-[#96CCFF]"></div>
								<h2 className="forecast font-medium leading-6 tracking-[0.15px] mt-[9px]">
									<ForecastRenderer
										data={mpData}
									/>
								</h2>
							</div>
						</div>
					}
				</div>
				<div className={`grid ${mpData?.involvedPoints ? 'grid-cols-4' : 'grid-cols-3'} gap-4 mb-[15px]`}>
					<div className="bg-white rounded-xl p-[10px] h-[107px]">
						<div className="flex-col ">
							<div className="flex mt-[13.5px]">
								<div className="items-center w-5 h-5">
									<DinamicForm className="w-4 h-4 text-[#004368]" />
								</div>
								<div>
									<h2 className="leading-6 font-medium tracking-[0.15px] mt-[-2px] ml-[8px] text-[11px]">
									kWh Quota:
									</h2>
								</div>
							</div>
							<div className="mt-[2px] w-full h-[1px] order-1 bg-[#96CCFF]"></div>
							<h2 className="font-medium leading-6 tracking-[0.15px] mt-[7px]">
								{kwhQuota}
							</h2>
						</div>
					</div>
					<div className="bg-white rounded-xl p-[10px] h-[107px]">
						<div className="flex-col">
							<div className="flex mt-[13.5px]">
								<div className="items-center w-5 h-5">
									<Percent className="w-[13px] h-4 text-[#004368]" />
								</div>
								<div>
									<h2 className="leading-6 font-medium tracking-[0.15px] mt-[-2px] ml-[8px] text-[11px]">
									of Quota used:
									</h2>
								</div>
							</div>
							<div className="mt-[2px] w-full h-[1px] order-1 bg-[#96CCFF]"></div>
							<h2 className="font-medium leading-6 tracking-[0.15px] mt-[7px]">
								{qoutaCalculation}
							</h2>
						</div>
					</div>
					<div className="bg-white rounded-xl p-[10px] h-[107px]">
						<div className="flex-col">
							<div className="flex mt-[13.5px]">
								<div className="items-center w-5 h-5">
									<FlashOn className="w-4 h-4 text-[#004368]" />
								</div>
								<div>
									<h2 className="leading-6 font-medium tracking-[0.15px] mt-[-2px] ml-[6px] text-[11px]">
									kWh used:
									</h2>
								</div>
							</div>
							<div className="mt-[2px] w-full h-[1px] order-1 bg-[#96CCFF]"></div>
							<h2 className="font-medium leading-6 tracking-[0.15px] mt-[7px]">
								{kwhUsed}
							</h2>
						</div>
					</div>
					{mpData?.involvedPoints && 
						<div className="bg-white rounded-xl p-[10px] h-[107px]">
							<div className="flex-col">
								<div className="flex mt-[13.5px]">
									<div className="items-center w-5 h-5">
										<Forecast className="w-[11px] h-4 text-[#004368]" />
									</div>
									<div>
										<h2 className="leading-6 font-medium tracking-[0.15px] mt-[-2px] ml-[8px] text-[11px]">
										Forecast:
										</h2>
									</div>
								</div>
								<div className="mt-[2px] w-full h-[1px] order-1 bg-[#96CCFF]"></div>
								<h2 className="forecast font-medium leading-6 tracking-[0.15px] mt-[9px]">
									<ForecastRenderer
										data={mpData}
									/>
								</h2>
							</div>
						</div>
					}
				</div>
				{mpData?.involvedPoints &&
					<>
						<div className=" flex flex-row justify-between items-center pt-[22px]">
							<div className="flex items-center">
								<div className="flex items-center w-5 h-5">
									<Calculate className="w-4 h-4 text-[#004368]" />
								</div>
								<div className="pl-[9px]">
									<h2 className="text-base text-[#004368] font-medium leading-6 tracking-[0.15px] mt-[2px] text-[11px]">
							Calculation
									</h2>
								</div>
							</div>
						</div>
						<div className="mt-[10px] w-full h-[1px] bg-[#96CCFF] mb-[26px]"></div>
						<div className='relative ag-theme-alpine min-h-[270px] h-[calc(100vh-620px)]'>
							<AgGridReact
								headerHeight={headerHeight}
								floatingFiltersHeight={floatingFiltersHeight}
								rowData={rowDataVmp}
								columnDefs={columnDefs}
								defaultColDef={defaultColDef}
								columnTypes={columnTypes}
								groupDisplayType={'groupRows'}
								rowSelection="multiple"
								animateRows={true}
								onFirstDataRendered={onFirstDataRendered}
								groupSelectsChildren={true}
								suppressRowClickSelection={true}
								suppressAggFuncInHeader={true}
								rowHeight={rowHeight}
								components={components}
								pagination={true}
								paginationAutoPageSize={true}
								suppressContextMenu={true}
								getRowStyle={getRowStyle}
							/>
							{role === 'admin' &&
								<button onClick={() => {
									setOpenCreateCalculatedMpModal(true)
								}}
								className='bg-[#96CCFF] rounded-[12px] shadow-xl shadow-[#b3b3b3] absolute right-[20px] bottom-[70px]'>
									<div className='flex p-[11px]'>
										<Edit className='w-[18px] h-[18px]'/>
									</div>
								</button>
							}
							<div className="mb-[25px] w-full h-[1px] order-1 bg-white"></div>
						</div>
					</>
				}
				<div className=" flex flex-row justify-between items-center pt-[22px]">
					<div className="flex items-center">
						<div className="flex items-center w-5 h-5">
							<ChartIcon className="w-4 h-4 text-[#004368]" />
						</div>
						<div className="pl-[9px]">
							<h2 className="text-base text-[#004368] font-medium leading-6 tracking-[0.15px] mt-[2px] text-[11px]">
							Power Usage
							</h2>
						</div>
					</div>
				</div>
				<div className="mt-[10px] w-full h-[1px] bg-[#96CCFF] mb-[26px]"></div>
				<div className='bg-white rounded-xl pt-[18px]'>
					<div className="flex  mb-[3px] ml-[25px]">
						<div className="w-[223px] customLabel1">
							<ThemeProvider theme={theme}>
								<LocalizationProvider dateAdapter={AdapterDayjs}>
									<DateTimePicker
										label="Start date"
										inputFormat="DD.MM.YYYY HH:mm"
										ampm={false}
										value={dateFrom}
										onChange={changeDateFrom}
										renderInput={(params) => <TextField {...params}
											sx={{'& .MuiOutlinedInput-root':{'& > fieldset': {border: '1px solid #B0BEC5', height:'61px', borderRadius:'6px'}}}}
										/>}
										PopperProps={{
											placement: 'bottom-start',
										}}
										PaperProps={{
											sx: {marginTop: '3px'}
										}}
									/>
								</LocalizationProvider>
							</ThemeProvider>
						</div>
						<div className=" ml-[25px] w-[223px] min-w-[200px] customLabel1">
							<ThemeProvider theme={theme}>
								<LocalizationProvider dateAdapter={AdapterDayjs}>
									<DateTimePicker
										label="End date"
										inputFormat="DD.MM.YYYY HH:mm"
										ampm={false}
										value={dateTo}
										onChange={changeDateTo}
										renderInput={(params) => <TextField {...params}
											sx={{'& .MuiOutlinedInput-root':{'& > fieldset': {border: '1px solid #B0BEC5', height:'61px', borderRadius:'6px'}}}}
										/>}
										PopperProps={{
											placement: 'bottom-start',
										}}
										PaperProps={{
											sx: {marginTop: '3px'}
										}}
									/>
								</LocalizationProvider>
							</ThemeProvider>
						</div>
						<div className=" ml-[25px] w-[223px] min-w-[160px] mr-[25px]">
							<div className="w-full h-[60px] mb-[1px] selectDiv customLabelResolution">
								<Select 
									placeholder=''
									label="Resolution"
									size='lg'
									value={resolution}
									style={{fontSize: '16px', height: '57px', paddingTop: '15px'}}
									onChange={setResolutionOnClick}
								>
									{resolutionValues?.map((value: any) => (
										<Option
											key={value?.id}
											className="text-sm text-[#001F24] tracking-[0.5px] hover:bg-[#004368] hover:bg-opacity-[0.12] cursor-pointer h-[32px] py-[6px] pl-[16px] "
											value={value?.name}
										>
											{value?.name}
										</Option>
									))}
								</Select>
							</div>
						</div>
					</div>
					<div className='relative'>
						<div
							id="chartdiv"
							style={{ width: '100%', height: '300px', fontSize: '10px' }}
						></div>
						<div className="flex text-[#004368] font-medium text-[13px] justify-end mt-[15px] pb-[20px] pr-[18px]">
							<div className="ml-[15px]">Min: {minValue}</div>
							<div className="ml-[15px]">Avr: {averageValue}</div>
							<div className="ml-[15px]">Max: {maxValue}</div>
						</div>
						{errorData && (
							<div className="absolute rounded-xl text-[#ff0000] font-medium w-[150px] h-[60px] left-[45%] bottom-[40%] bg-[#F0F0F0] flex items-center justify-center">
								<div className="">Date Error !</div>
							</div>
						)}
						{errorNoData && (
							<div className="absolute rounded-xl text-[#ff0000] font-medium w-[150px] h-[60px] left-[45%] bottom-[40%] bg-[#F0F0F0] flex items-center justify-center">
								<div className="">No Data !</div>
							</div>
						)}
					</div>
				</div>
			</div>
			{openCreateCalculatedMpModal && (
				<CalculatedMpModal
					//rowData={rowData}
					mpData={mpData}
					setOpenCreateCalculatedMpModal={setOpenCreateCalculatedMpModal}
					openCreateCalculatedMpModal={openCreateCalculatedMpModal}
					calculatedMpRef={calculatedMpRef}
				/>
			)}
			{showChangingInputModal && (
				<ChangingInputModal
					setShowChangingInputModal={setShowChangingInputModal}
					showChangingInputModal={showChangingInputModal}
					changingInputRef={changingInputRef}
					resetInput={resetName}
					setInputActive={setInputNameActive}
					focusRef={inputNameRef}
				/>
			)}
		</div>
	)
}
