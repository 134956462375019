import React, { useState, useEffect } from 'react'
import ReactMarkdown from 'react-markdown'
import ChangelogMarkdown from '../../changelog.md'
import './markdown.css'

export const Changelog = (): JSX.Element => {
	const [changelogText, setChangelogText] = useState<any>('')

	useEffect(() => {
		fetch(ChangelogMarkdown).then(res => res.text()).then(text => setChangelogText(text))
	})

	return (
		<div>
			{/* eslint-disable-next-line react/no-children-prop */}
			<ReactMarkdown children={changelogText} className='markdown'/>
		</div>
	)
}
