import React, { useState } from 'react'
import { ReactComponent as BrandingWatermark } from '../../../assets/images/icons/branding_watermark.svg'
import { ReactComponent as BrandingWatermark1 } from '../../../assets/images/icons/branding_watermark1.svg'
import { ReactComponent as DynamicForm } from '../../../assets/images/icons/dynamic_form.svg'
import { ReactComponent as DynamicForm1 } from '../../../assets/images/icons/dynamic_form1.svg'
import DataTab from './DataTab'
import QuotasTab1 from './QuotasTab1'
import TehnicalTab from './TehnicalTab'
import QuotasTab from './QuotasTab'

interface Props {
  mpData: any
  kwLive: number
	quotasData: any
}

const MpTabs = ({ mpData, kwLive, quotasData }: Props) => {
	const [openTab, setOpenTab] = useState<number>(1)

	return (
		<div className="flex flex-wrap">
			<div className="w-full">
				<ul
					className="flex mb-0 list-none flex-wrap flex-row "
					role="tablist"
				>
					<li className="w-[24%] h-[93px]">
						<a
							tabIndex={-1}
							className={
								'flex flex-col pl-[13px] pr-[13px] text-xs h-[93px] leading-normal ' +
                (openTab === 1 ? 'text-[#154365] bg-[#F0F0F0] rounded-t-2xl border-l-2 border-r-2 border-t-2 border-[#F0F0F0]' : 'text-[#72777F] bg-white rounded-t-2xl border-l-2 border-r-2 border-t-2 border-[#F0F0F0]')
							}
							onClick={(e) => {
								e.preventDefault()
								setOpenTab(1)
							}}
							data-toggle="tab"
							href="#link1"
							role="tablist"
						>
							<div className="flex pt-[31.5px]">
								<div>
									{openTab === 1 ? (
										<BrandingWatermark />
									) : (
										<BrandingWatermark1 />
									)}
								</div>
								<div className="pl-[13px] font-[11px] mt-[-2px] mb-[13.5px]">
                  Data
								</div>
							</div>
							<div
								className={`w-full h-[1px] order-1 
                    ${openTab === 1 ? 'bg-[#51606F]' : 'bg-[#72777F]'}
                  `}
							></div>
						</a>
					</li>
					<li className="w-[24%] h-[93px] ml-[15px]">
						<a
							tabIndex={-1}
							className={
								'flex flex-col pl-[13px] pr-[13px] text-xs h-[93px] leading-normal ' +
                  (openTab === 2 ? 'text-[#154365] bg-[#F0F0F0] rounded-t-2xl border-l-2 border-r-2 border-t-2 border-[#F0F0F0]' : 'text-[#72777F] bg-white rounded-t-2xl border-l-2 border-r-2 border-t-2 border-[#F0F0F0]')
							}
							onClick={(e) => {
								e.preventDefault()
								setOpenTab(2)
							}}
							data-toggle="tab"
							href="#link2"
							role="tablist"
						>
							<div className="flex pt-[31.5px]">
								<div>
									{openTab === 2 ? <DynamicForm1 /> : <DynamicForm />}
								</div>
								<div className="pl-[13px] font-[11px] mt-[-2px] mb-[13.5px]">
                    Quotas
								</div>
							</div>
							<div
								className={`w-full h-[1px] order-1 
                    ${openTab === 2 ? 'bg-[#51606F]' : 'bg-[#72777F]'}
                  `}
							></div>
						</a>
					</li>
					{/* <li className="w-[24%] h-[93px]">
              <a
              tabIndex={-1}
                className={
                  "flex flex-col pl-[13px] pr-[13px] text-xs h-[93px] leading-normal " +
                  (openTab === 3 ? 'text-[#154365] bg-[#F0F0F0] rounded-t-2xl border-l-2 border-r-2 border-t-2 border-[#F0F0F0]' : 'text-[#72777F] bg-white rounded-t-2xl border-l-2 border-r-2 border-t-2 border-[#F0F0F0]')
                }
                onClick={(e) => {
                  e.preventDefault();
                  setOpenTab(3);
                }}
                data-toggle="tab"
                href="#link3"
                role="tablist"
              >
                <div className="flex pt-[31.5px]">
                  <div>{openTab === 3 ? <FactCheck /> : <FactCheck1 />}</div>
                  <div className="pl-[13px] font-[11px] mt-[-2px] mb-[13.5px]">
                    Quotas
                  </div>
                </div>
                <div
                  className={`w-full h-[1px] order-1 
                    ${openTab === 3 ? "bg-[#004368]" : "bg-[#72787E]"}
                  `}
                ></div>
              </a>
            </li>
            <li className="w-[24%] h-[93px]">
              <a
              tabIndex={-1}
                className={
                  "flex flex-col pl-[13px] pr-[13px] text-xs h-[93px] leading-normal " +
                  (openTab === 4 ? 'text-[#154365] bg-[#F0F0F0] rounded-t-2xl border-l-2 border-r-2 border-t-2 border-[#F0F0F0]' : 'text-[#72777F] bg-white rounded-t-2xl border-l-2 border-r-2 border-t-2 border-[#F0F0F0]')
                }
                onClick={(e) => {
                  e.preventDefault();
                  setOpenTab(4);
                }}
                data-toggle="tab"
                href="#link4"
                role="tablist"
              >
                <div className="flex pt-[31.5px]">
                  <div>
                    <div>{openTab === 4 ? <AddChart /> : <AddChart1 />}</div>
                  </div>
                  <div className="pl-[13px] font-[11px] mt-[-2px] mb-[13.5px]">
                    Tehnical
                  </div>
                </div>
                <div
                  className={`w-full h-[1px] order-1 
                    ${openTab === 4 ? "bg-[#004368]" : "bg-[#72787E]"}
                  `}
                ></div>
              </a>
            </li> */}
				</ul>
				<div className="relative flex flex-col min-w-0 break-words bg-white w-full rounded">
					<div className="flex-auto">
						<div className="tab-content tab-space ">
							<div className={openTab === 1 ? 'block' : 'hidden'} id="link1">
								<DataTab mpData={mpData} kwLive={kwLive} />
							</div>
							<div className={openTab === 2 ? 'block' : 'hidden'} id="link2">
								<QuotasTab quotasData={quotasData} mpData={mpData}/>
							</div>
							<div className={openTab === 3 ? 'block' : 'hidden'} id="link3">
								<QuotasTab1 />
							</div>
							<div className={openTab === 4 ? 'block' : 'hidden'} id="link4">
								<TehnicalTab />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
export default MpTabs
