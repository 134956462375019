import React, { useState } from 'react'
import { ReactComponent as SettingsIcon } from '../../../assets/images/icons/bre-settings.svg'
import Breadcrumb from '../../components/Breadcrumb/Index'
import { useAppSelector } from '../../hooks'
import { dependencyContainer } from '../../store'
import { useQuery } from '@tanstack/react-query'
import SettingsTabs from '../../components/SettingsTabs/SettingsTabs'
//import { APIKey } from '../../repository/APIKeysRepository'

const Settings = () => {
	const [apiKeysData, setApiKeysData] = useState<any>([])

	const { business } = useAppSelector((state) => state.app)
	
	useQuery({
		queryKey: ['apiKeysData', business],
		queryFn:() => dependencyContainer.dependency.apiKeysDataApi.apiKeys(business),
		onSuccess: (data) => {
			setApiKeysData(data)
		},
		onError: (e) => {
			console.log(e)
		},
	})

	const bc = [
		{
			name: 'Settings',
		},
	]

	return (
		<div className="overflow-auto pl-[25px]">
			<div className='relative'>
				<Breadcrumb links={bc} icon={<SettingsIcon />} />
			</div>
			<div className="p-[15px] bg-white w-[100%] rounded-xl mt-[23px]">
				<SettingsTabs
					apiKeys={apiKeysData}
				/>
			</div>
		</div>
	)
}

export default Settings
