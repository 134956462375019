import React, { useState, useEffect, useLayoutEffect, useRef } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { loadBusinessesCommand } from '../../features/selectBusiness/selectBusinessCommands'
import { useAppDispatch, useAppSelector } from '../hooks'
import { ReactComponent as LogoLogin } from '../../assets/images/icons/logoLogin.svg'
import SelectComponent from '../components/Select/SelectComponent'
import NoBusinesses from './admin/NoBusinesses'
import '../components/Select/styles.css'
import DetectingForefoxModal from '../components/Modals/DetectingFirefoxModal'

export default function Businesses() {

	const [selectedOption, setSelectedOption] = useState()
	const [openDetectingForefoxModal, setOpenDetectingForefoxModal] = useState(false)
	const [loading, setLoading] = useState(true)
	
	const { businesses } = useAppSelector((state) => state.selectBusiness)
	const detectingForefoxModalRef = useRef(null)
	
	const dispatch = useAppDispatch()
	const navigate = useNavigate()
	const location = useLocation()

	useEffect(() => {
		dispatch(loadBusinessesCommand())
	}, [dispatch])

	useLayoutEffect(() => {
		if (businesses.length === 1 && location.pathname === '/' && loading === false) {
			navigate(`/businesses/${businesses[0].id}`)
		}
	}, [businesses])

	const loader = () => {
		setTimeout(() => {
			setLoading(false)
		}, 2000)
	}

	useEffect(() => {
		loader()
		if(navigator.userAgent.toLowerCase().indexOf('firefox') > -1){
			setOpenDetectingForefoxModal(true)
		}
	}, [])
		
	return (
		<div>
			{loading && location.pathname === '/' ?
				<div role="status" className='bg-[#004368] flex flex-col justify-center items-center h-[100vh] w-[100vw]'>
					<div className="flex flex-col items-center justify-center">
						<div className='rotate flex'>
							<LogoLogin />
						</div>
						<div className="text-white flex tracking-[0.5px] leading-4 mt-[27px]">
							<h2 className="text-xs font-normal mt-[3px] mr-[3px]">aliunid</h2>
							<h1 className="font-medium text-sm">OPERATOR</h1>
						</div>
						<div className="my-2 w-[195px] h-[1px] order-1 bg-[#C2E8FF]"></div>
					</div>
				</div>
				:
				businesses.length !== 0 ? 
					<div className="bg-[#004368] h-[100vh] w-[100vw] flex flex-col items-center justify-center">
						<div className="flex flex-col items-center justify-center">
							<LogoLogin />
							<div className="text-white flex tracking-[0.5px] leading-4 mt-[27px]">
								<h2 className="text-xs font-normal mt-[3px] mr-[3px]">aliunid</h2>
								<h1 className="font-medium text-sm">OPERATOR</h1>
							</div>
							<div className="my-2 w-[195px] h-[1px] order-1 bg-[#C2E8FF]"></div>
						</div>
						<div>
							<p className="font-normal text-base tracking-[0.5px] text-white mt-[52px]">
           Select the business you wish to continue with
							</p>
						</div>
						<div className="flex mt-[56px] mb-[190px] text-base w-[380px]">
							<div className="business-select ">
								<SelectComponent
									options={businesses}
									onChange={(item: any) => setSelectedOption(item)}
									selectedKey={selectedOption}
								/>
							</div>
						</div>
					</div>
					:
					<NoBusinesses />
			}
			{(openDetectingForefoxModal && location.pathname === '/') && (
				<DetectingForefoxModal
					setOpenDetectingForefoxModal={setOpenDetectingForefoxModal}
					openDetectingForefoxModal={openDetectingForefoxModal}
					detectingForefoxModalRef={detectingForefoxModalRef}
				/>
			)}
		</div>
	)
}
