import AppKeycloak from './Keycloak'
import BusinessesRepository from './repository/BusinessesRepository'
import BusinessRepository from './repository/BusinessRepository'
import SubBusinessRepository from './repository/SubBusinessRepository'
import DashboardRepository from './repository/DashboardRepository'
import APIBusinessesRepository from './repository/APIBusinessesRepository'
import GetMeasuringPointsRepository from './repository/APIMeasuringPointsRepository'
import APIDashboardRepository from './repository/APIDashboardRepository'
import APISubBusinessRepository from './repository/APISubBusinessRepository'
import APIBusinessRepository from './repository/APIBusinessRepository'
import MeasuringPointsRepository from './repository/MeasuringPointsRepository'
import GetMPHistoryDataRepository from './repository/APIMPHistoryDataRepository'
import MPHistoryDataRepository from './repository/MPHistoryDataRepository'
import GetUsersRepository from './repository/APIUsersRepository'
import UserModelRepository from './repository/UserModelRepository'
import APIQuotasRepository from './repository/APIQuotasRepository'
import QuotasRepository from './repository/QuotasRepository'
import APINotificationsRepository from './repository/APINotificationsRepository '
import NotificationsRepository from './repository/NotificationsRepository'
import APIKeysRepository from './repository/APIKeysRepository'
import APIAPIKeysRepository from './repository/APIAPIKeysRepository'

export interface Dependency {
	wssUrl: string
  keycloak: AppKeycloak
  businessesRepository: BusinessesRepository
	dashboardApi: DashboardRepository
	businessApi: BusinessRepository
	subBusinessApi: SubBusinessRepository
	measuringPointsApi: MeasuringPointsRepository
	mpHistoryDataApi: MPHistoryDataRepository
	usersDataApi: UserModelRepository
	quotasDataApi: QuotasRepository
	notificationsDataApi: NotificationsRepository
	apiKeysDataApi: APIKeysRepository
}

export default class DependencyContainer {

	private _dependency!: Dependency

	createDependency(env: any): void {

		const keycloak = AppKeycloak.createAppKeycloak('/keycloak.json')
		const baseUrl = env.WEBPORTAL_BACKEND_URL

		this._dependency = {
			wssUrl: env.WEBPORTAL_SIOT_WSS_URL,
			keycloak,
			businessesRepository: new APIBusinessesRepository(keycloak),
			dashboardApi: new APIDashboardRepository(keycloak, baseUrl),
			businessApi: new APIBusinessRepository(),
			subBusinessApi: new APISubBusinessRepository(),
			measuringPointsApi: new GetMeasuringPointsRepository(),
			mpHistoryDataApi: new GetMPHistoryDataRepository(),
			usersDataApi: new GetUsersRepository(),
			quotasDataApi: new APIQuotasRepository(),
			notificationsDataApi: new APINotificationsRepository(),
			apiKeysDataApi: new APIAPIKeysRepository()
		}
		//console.log(this._dependency)
	}
	get dependency(): Dependency {
		return this._dependency
	}
}
