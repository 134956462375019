import React from 'react'
import { ReactComponent as MpNotShared } from '../../../assets/images/icons/mpNotShared.svg'
import { ReactComponent as MpShared } from '../../../assets/images/icons/mpShared.svg'
import { Tooltip } from '@material-tailwind/react'

interface Props {
  data: any
	length: number

}

const MpCellRenderWithIcon = ({data, length}: Props) => {

	let providerText = null
	if(data.provider !== null && data.provider !== undefined) {
		providerText = `Provider: ${data?.provider?.businessName}`
		if(!data.provider.linked)
			providerText = `Last Provider: ${data?.provider?.businessName}`
	}
	const shared = (data.provider !== null && data.provider !== undefined && data.provider.linked) || data.businessGroup !== 'self'

	return (
		<div className='flex items-center ml-[-3px]'>
			{ data?.name !== '' && 
		<div className='mt-[2px] w-[24px] h-[28px] rounded bg-[#ECF1F9] p-[5px] flex align-center'>
			{shared ?
				<MpShared 
					fill={'#154365'}
				/>
				:
				<MpNotShared 
					fill={'#154365'}
				/>
			}
		</div>
			}
			<div className='flex flex-col ml-[18px]'>
				<div>
					{(data?.name?.length) > length ?
						<Tooltip content={data?.name} placeholder=''
							className='w-[250px] bg-[#ebedf0] border-gray-400
				 p-[15px] text-[#4d6071] border font-medium text-center'>
							<div className="flex w-full h-[full]">
								<div className='flex justify-start break-words text-left'>
									{data?.name?.substr(0,length-1)}
									{data?.name?.length > length ? ' ...' : ''}
								</div>
							</div>
						</Tooltip>
						:
						<div className='flex justify-start break-words'>
							{data?.name}
						</div>
					}
				</div>
				<div className='text-[9px] font-normal'>
					{providerText}
				</div>
			</div>
		</div>
	)

}

export default MpCellRenderWithIcon
