import AppKeycloak from '../Keycloak'
import BusinessesRepository, { BusinessModel } from './BusinessesRepository'

// import { useBusinessQuery } from "../query/useBusinessQuery"

import axios from 'axios'
export default class GetBusinessesRepository implements BusinessesRepository {
	// eslint-disable-next-line no-unused-vars
	constructor(private readonly keycloak: AppKeycloak) { }

	async businesses(): Promise<BusinessModel[]> {
		const settings: any = JSON.parse(localStorage.getItem('settings') || '')
		const data = await axios.get(`${settings.WEBPORTAL_BACKEND_URL}/available-businesses`, {
			headers: {
				Authorization: `Bearer ${this.keycloak.token}`,
			},
		})

		return data.data.map((d: any) => this.mapToViewModel(d))
	}

	mapToViewModel = (dto: any) => {
		return {
			name: dto.name,
			id: dto.id,
			role: dto.role,
		}
	}
}
