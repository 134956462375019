import React, { useState, Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { Input } from '@material-tailwind/react'
import { ReactComponent as AddBusiness } from '../../../assets/images/icons/add_business.svg'
import { ReactComponent as Close } from '../../../assets/images/icons/close.svg'
import { ReactComponent as CloseBlack } from '../../../assets/images/icons/closeBlack.svg'
import { ReactComponent as IconClose } from '../../../assets/images/icons/iconClose.svg'
import { ReactComponent as Check } from '../../../assets/images/icons/check.svg'
import { ReactComponent as AlertRed } from '../../../assets/images/icons/infoMessageRed.svg'
import ActionButton from '../ActionButton'
import { useParams } from 'react-router-dom'
import { dependencyContainer } from '../../store'
import { useMutation } from '@tanstack/react-query'
import {queryClient} from '../../../index'

interface Props {
  setOpenAddBusinessModal: React.Dispatch<React.SetStateAction<boolean>>;
  openAddBusinessModal: boolean;
  cancelButtonRef: React.MutableRefObject<null>;
}

const AddBusinessModal = ({
	setOpenAddBusinessModal,
	openAddBusinessModal,
	cancelButtonRef,
}: Props) => {
	const [name, setName] = useState('')
	const [ceoName, setCeoName] = useState('')
	const [address, setAddress] = useState('')
	const [city, setCity] = useState('')
	const [zip, setZip] = useState('')
	const [accountNumber, setAccountNumber] = useState('')
	const [error, setError] = useState<any>('')

	const { id } = useParams<any>()

	const handleChangeBusinessName = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setName(event.target.value)
		setError('')
	}
	const handleChangeCeoName = (event: React.ChangeEvent<HTMLInputElement>) => {
		setCeoName(event.target.value)
	}
	const handleChangeZip = (event: React.ChangeEvent<HTMLInputElement>) => {
		setZip(event.target.value)
	}
	const handleChangeCity = (event: React.ChangeEvent<HTMLInputElement>) => {
		setCity(event.target.value)
	}
	const handleChangeAddress = (event: React.ChangeEvent<HTMLInputElement>) => {
		setAddress(event.target.value)
	}
	const handleChangeBusinessAccountNumber = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setAccountNumber(event.target.value)
	}

	const addBusiness = useMutation({
		mutationFn: () => dependencyContainer.dependency.businessApi.addNewBusiness(id, name, ceoName, address, city, zip, accountNumber),
		onSuccess: async () => {
			setOpenAddBusinessModal(false)
			queryClient.invalidateQueries({ queryKey: ['dashboardData'] })
		},
		onError: (error: any) => {
			setError(error.response?.data?.message ? error.response?.data?.message : 'Internal server error !')
		},
	})

	const submitForm = async () => {
		if (name === '') {
			setError('Enter Business name !')
		}
		else {
			addBusiness.mutate()
		}
	}
	
	return (
		<Transition.Root show={openAddBusinessModal} as={Fragment}>
			<Dialog
				as="div"
				className="relative z-[20]"
				initialFocus={cancelButtonRef}
				onClose={()=>{
					//no action for clicking outside modal
				}}
			>
				<Transition.Child
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 bg-[#000000] bg-opacity-75 transition-opacity " />
				</Transition.Child>

				<div className="fixed inset-0 z-10 overflow-y-auto">
					<div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							enterTo="opacity-100 translate-y-0 sm:scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 translate-y-0 sm:scale-100"
							leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						>
							<Dialog.Panel className="relative transform overflow-hidden rounded-lg
               bg-white text-left shadow-xl transition-all sm:my-8 xl:w-[603px] xl:h-[600px] lg:w-[503px] lg:h-[600px]">
								<div className="p-[15px]">
									<div className=" flex flex-row justify-between items-center pt-[9px]">
										<div className="flex items-center">
											<div className="flex items-center w-5 h-5">
												<AddBusiness />
											</div>
											<div className="w-[292px] pl-[7px]">
												<h2 className="text-base font-medium tracking-[0.15px] text-[#154365]">
                          Add new business
												</h2>
											</div>
										</div>
										<div
											className=""
											onClick={() => setOpenAddBusinessModal(false)}
										>
											<Close className="text-[#4D6071] cursor-pointer" />
										</div>
									</div>
									<div className="mt-[10px] w-full h-[0.75px] order-1 bg-[#A7ACB3]"></div>
									<div className="mt-[33px] bg-[#F0F0F0] rounded-xl h-[425px]">
										<div className="p-[13px]">
											<div className="w-full relative mb-[20px] ">
												<Input
													crossOrigin=''
													type={'text'}
													color={error ? 'red' : 'blue'}
													size="lg"
													value={name}
													label="Business name"
													required
													onChange={handleChangeBusinessName}
													className="text-base"
													icon={
														<IconClose
															className="cursor-pointer"
															onClick={() => setName('')}
														/>
													}
												/>
												{error === 'Enter Business name !' && (
													<div className="flex justify-start mt-[10px] text-[#ff0000] text-sm">
														<AlertRed  className='mt-[1px]'/>
														<h2  className='ml-[8px]'>{error}</h2>
													</div>
												)}
											</div>
											<div className="w-full relative mb-[20px]">
												<Input
													crossOrigin=''
													type={'text'}
													color="blue"
													size="lg"
													value={ceoName}
													label="CEO name"
													onChange={handleChangeCeoName}
													className="text-base"
													icon={
														<IconClose
															className="cursor-pointer"
															onClick={() => setCeoName('')}
														/>
													}
												/>
											</div>
											<div className="w-full relative mb-[20px]">
												<Input
													crossOrigin=''
													type={'text'}
													color="blue"
													size="lg"
													value={zip}
													label="ZIP"
													onChange={handleChangeZip}
													className="text-base"
													icon={
														<IconClose
															className="cursor-pointer"
															onClick={() => setZip('')}
														/>
													}
												/>
											</div>
											<div className="w-full relative mb-[20px]">
												<Input
													crossOrigin=''
													type={'text'}
													color="blue"
													size="lg"
													value={city}
													label="City"
													onChange={handleChangeCity}
													className="text-base"
													icon={
														<IconClose
															className="cursor-pointer"
															onClick={() => setCity('')}
														/>
													}
												/>
											</div>
											<div className="w-full relative mb-[20px]">
												<Input
													crossOrigin=''
													type={'text'}
													color="blue"
													size="lg"
													value={address}
													label="Address"
													onChange={handleChangeAddress}
													className="text-base"
													icon={
														<IconClose
															className="cursor-pointer"
															onClick={() => setAddress('')}
														/>
													}
												/>
											</div>
											<div className="w-full relative mb-[8px]">
												<Input
													crossOrigin=''
													type={'text'}
													color="blue"
													size="lg"
													value={accountNumber}
													label="Business account number"
													onChange={handleChangeBusinessAccountNumber}
													className="text-base"
													icon={
														<IconClose
															className="cursor-pointer"
															onClick={() => setAccountNumber('')}
														/>
													}
												/>
											</div>
										</div>
									</div>
									{(error !== 'Enter Business name !' && error !== '') && (
										<div className="flex justify-start fixed ml-[15px] mt-[10px] text-[#ff0000] text-sm">
											<AlertRed  className='mt-[1px]'/>
											<h2  className='ml-[8px]'>{error}</h2>
										</div>
									)}
									<div className="mt-[30px] flex justify-end">
										<div>
											<ActionButton
												className="bg-[#D5E4F7] text-[#001D32] font-medium py-2.5 pl-[21px] pr-[24px] rounded-full h-[40px]"
												text="Cancel"
												icon={<CloseBlack />}
												onClick={() => setOpenAddBusinessModal(false)}
											/>
										</div>
										<div className="ml-[20px]">
											<ActionButton
												className="bg-[#154365] text-white font-medium py-2.5 pl-[21px] pr-[24px] rounded-full h-[40px]"
												text="Save"
												icon={<Check />}
												onClick={() => submitForm()}
											/>
										</div>
									</div>
								</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition.Root>
	)
}

export default AddBusinessModal
