import React, { useState, useMemo, useCallback, useRef, useEffect } from 'react'
import { AgGridReact } from 'ag-grid-react'
import { useNavigate } from 'react-router-dom'
import CustomHeader from '../Table/CustomHeader'
import { ReactComponent as IconPlus } from '../../../assets/images/icons/icon_add_white.svg'
import { ReactComponent as Book } from '../../../assets/images/icons/book.svg'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-alpine.css'
import 'ag-grid-enterprise'
import { GetContextMenuItemsParams, LicenseManager } from 'ag-grid-enterprise'
import '../../pages/admin/ag-grid-power.css'
import { useAppSelector } from '../../hooks'
import ValidityFilterFormatter from '../Table/ValidityFilterFormatter'
import ValidityRenderer from '../Table/ValidityRenderer'
import AddAPIKeyModal from '../Modals/AddAPIKeyModal'
import { APIKey } from '../../repository/APIKeysRepository'
import ApiKeyPopupModal from '../Modals/ApiKeyPopupModal'
import DeleteAPIKeyModal from '../Modals/DeleteAPIKeyModal'
import RotateConfirmationModal from '../Modals/RotateConfirmationModal'
import ApiKeysOptionsColumn from '../Table/ApiKeysOptionsColumn'
import Link from '@mui/material/Link'

LicenseManager.setLicenseKey(
	'CompanyName=Appmodule AG,LicensedGroup=aliunid grid / power,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=2,AssetReference=AG-036901,SupportServicesEnd=31_January_2024_[v2]_MTcwNjY1OTIwMDAwMA==4c9d5d4b04b142bc6b50ed895e5b5ef3'
)

interface Props {
  apiKeys: APIKey[]
}

const APIKeysTab = ({ apiKeys }: Props) => {
	const [openAddAPIKeyModal, setOpenAddAPIKeyModal] = useState(false)
	const [openPopupCreateAPIKey, setOpenPopupCreateAPIKey] = useState(false)
	const [openRotateConfirmationModal, setOpenRotateConfirmationModal] = useState(false)
	const [signalForOpenPopupCreateAPIKey, setSignalForOpenPopupCreateAPIKey] = useState(false)
	const [signalForOpenAddAPIKeyModal, setSignalForOpenAddAPIKeyModal] = useState(false)
	const [APIKeyData, setAPIKeyData] = useState()
	const [APIKeyDataString, setAPIKeyDataString] = useState()
	const [rotate, setRotate] = useState(false)
	const [openDeleteAPIKeyModal, setOpenDeleteAPIKeyModal] = useState(false)

	const navigate = useNavigate()

	const gridRef = useRef<AgGridReact>(null)

	const cancelButtonRef = useRef(null)
	const apiPopupRef = useRef(null)
	const deleteAPIKeyRef = useRef(null)
	const rotateConfirmationRef = useRef(null)

	const cellClicked = (row: any) => {
		if (typeof row.data !== 'undefined' && row.column.colId !== 'mp' && row.column.colId !== 'checkbox') {
			navigate(`APIKey/${row.data.id}`)
		} 
	}

	const editIcon = '<img border="0" width="15" height="15" src="/images/edit_icon.svg"/>'
	const replaceIcon = '<img border="0" width="15" height="15" src="/images/replace.svg"/>'
	const deleteIcon = '<img border="0" width="15" height="15" src="/images/delete_icon.svg"/>'
	const detailsIcon = '<img border="0" width="15" height="15" src="/images/magnifying_glass.svg"/>'

	const getContextMenuItems = useCallback((params: GetContextMenuItemsParams | any) => {
		const result = []
		if (params.node) {
			result.push(
				{
					name: 'Edit',
					icon: editIcon,
					action: function () {
						setAPIKeyData(params.node.data)
						setOpenAddAPIKeyModal(true)
					},
				},
				'separator'
			)
			result.push(
				{
					name: 'Rotate',
					icon: replaceIcon,
					action: function () {
						setAPIKeyData(params.node.data)
						setOpenRotateConfirmationModal(true)
					},
				},
				'separator'
			)
			result.push(
				{
					name: 'Delete',
					icon: deleteIcon,
					action: function () {
						setAPIKeyData(params.node.data)
						setOpenDeleteAPIKeyModal(true)
					},
				},
				'separator'
			)
			result.push({
				name: 'Details',
				icon: detailsIcon,
				action: function () {
					if (typeof params.node.data !== 'undefined') {
						navigate(`APIKey/${params.node.data.id}`)
					}
				},
			})
		}
		return result
	}, [])

	const { role } = useAppSelector((state) => state.app)

	const headerHeight = 25

	const rowStyle = { cursor: 'pointer' }

	const columnDefs = [
		{
			cellRenderer: (row: any) => {
				if (role === 'admin') {
					return <ApiKeysOptionsColumn data={row.data} />
				}
			},
			field: 'mp',
			filter: false,
			maxWidth: role === 'admin' ? 30 : 5,
			sortable: false,
			onCellClicked: function (params: any) {
				if (params.node.data.name !== '' && role === 'admin') {
					params.api.contextMenuFactory.showMenu(
						params.node,
						params.column,
						params.value,
						params.event
					)
				}
			},
			cellStyle: function () {
				if (role === 'admin') {
					return { cursor: 'pointer' }
				}
			},
		},
		{
			field: 'title',
			headerName: 'Title',
			headerComponentParams: { menuIcon: 'title' },
			cellClass: 'first-cell',
			minWidth: 180,
			cellStyle: function() {
				return { wordBreak: 'break-word' }
			},
		},
		{
			field: 'id',
			headerName: 'ID',
			headerComponentParams: { menuIcon: 'fingerprint' },
			cellClass: 'first-cell',
			minWidth: 180,
		},
		{
			field: 'validity',
			headerName: 'Validity',
			filter: 'agSetColumnFilter',
			filterParams: {
				buttons: ['reset', 'apply'],
				suppressSelectAll: true,
				suppressBlanks: true,
				cellHeight: 48,
				suppressMiniFilter: true,
				cellRenderer: ValidityFilterFormatter,
			},
			minWidth: 210,
			headerComponentParams: { menuIcon: 'balance' },
			cellStyle: function () {
				return { display: 'flex', justifyContent: 'center' }
			},
			cellRenderer: (row: any) => <ValidityRenderer data={row.data} />,
		},
		{
			field: 'expirationDate',
			headerName: 'Expiration Date',
			headerComponentParams: { menuIcon: 'event' },
			cellClass: 'flex-right-align',

			minWidth: 200,
			maxWidth: 200,
			valueFormatter: (params: any) => {
				if(params.value !== undefined){
					const date = new Date(params.value)
					return date.getDate().toString().padStart(2, '0') + '.' + (date.getMonth() + 1).toString().padStart(2, '0') + '.' + date.getFullYear()
				} else {
					if(!params.data?.validity) {
						return '-'
					} else {
						return 'Does not expire'
					}
				}
				
			},
		},
	]

	const defaultColDef = useMemo(
		() => ({
			sortable: true,
			filter: 'agTextColumnFilter',
			floatingFilter: true,
			wrapText: true,
			flex: 1,
			suppressMovable: true,
			suppressMenu: true,
			floatingFilterComponentParams: {
				suppressFilterButton: true,
			},
		}),
		[]
	)

	const onComponentStateChanged = useCallback(() => {
		setTimeout(() => {
			document.querySelectorAll('.ag-theme-alpine input[class^=ag-][type=text]').forEach((obj) => {
				obj.setAttribute('placeholder', 'Search')
			})
		}, 50)
		setTimeout(() => {
			document.querySelectorAll('.ag-theme-alpine .ag-disabled input[class^=ag-][type=text]').forEach((obj) => {
				obj.setAttribute('placeholder', 'Select')
				obj.removeAttribute('disabled')
			})
		}, 50)
	}, [])

	const components = useMemo(() => {
		return {
			agColumnHeader: CustomHeader,
			unSortIcon: true,
		}
	}, [])

	const floatingFiltersHeight = 30

	const columnTypes = useMemo(() => {
		return {
			number: {
				aggFunc: 'count',
			},
		}
	}, [])

	const getRowStyle = (params: any) => {
		if (typeof params.data !== 'undefined') {
			return { background: 'white' }
		}
	}

	const icons = useMemo(() => {
		return {
			groupExpanded: '<i class="ag-icon ag-icon-small-down" style=""/>',
			groupContracted: '<i class="ag-icon ag-icon-small-right" style=""/>',
		}
	}, [])

	useEffect(() => {
		if (signalForOpenPopupCreateAPIKey) {
			setTimeout(() => {
				setOpenPopupCreateAPIKey(true)
			}, 1000)
			
		}
	}, [signalForOpenPopupCreateAPIKey])

	useEffect(() => {
		if (signalForOpenAddAPIKeyModal) {
			setTimeout(() => {
				setOpenAddAPIKeyModal(true)
			}, 1000)
			
		}
	}, [signalForOpenAddAPIKeyModal])

	return (
		<div>
			<div className="relative bg-[#F1F1F1] h-[54px] rounded-tr-xl">
				<div className="flex absolute right-[14px] bottom-[0px]">
					{role === 'admin' && (
						<div
							onClick={() => setOpenAddAPIKeyModal(true)}
							className="mt-[15px] hover:bg-[#96CCFF] flex justify-center align-center px-[18px] py-[10px] rounded-3xl bg-[#154365] cursor-pointer"
						>
							<IconPlus className="mr-[11px] mt-[3px]" />
							<h2 className="text-sm font-medium text-white">Add API key</h2>
						</div>
					)}
				</div>
			</div>
			<div className="bg-[#F0F0F0] p-[13px] rounded-b-xl rounded-r-xl rounded-tl-none rounded-tr-none">
				<div className={`quotas ag-theme-alpine ${role === 'admin' ? 'h-[calc(100vh-510px)]' : 'h-[calc(100vh-452px)]'}`}>
					<AgGridReact
						ref={gridRef}
						headerHeight={headerHeight}
						floatingFiltersHeight={floatingFiltersHeight}
						rowData={apiKeys}
						columnDefs={columnDefs}
						defaultColDef={defaultColDef}
						columnTypes={columnTypes}
						groupDisplayType={'groupRows'}
						rowSelection="multiple"
						animateRows={true}
						onComponentStateChanged={onComponentStateChanged}
						groupSelectsChildren={true}
						suppressRowClickSelection={true}
						suppressAggFuncInHeader={true}
						icons={icons}
						components={components}
						pagination={true}
						paginationAutoPageSize={true}
						suppressContextMenu={true}
						getRowStyle={getRowStyle}
						getContextMenuItems={getContextMenuItems}
						onCellClicked={cellClicked}
						rowStyle={rowStyle}
					/>
				</div>
				<div className="mb-[25px]  w-full h-[1px] order-1 bg-white"></div>
				
				<Link sx={{display: 'flex', width: '204px'}} href='https://github.com/aliunidAG/API_documentation' underline="none" target="_blank" rel="noopener">
					<div className="flex justify-center align-center px-[18px] py-[10px] rounded-3xl border-[#4D6071] border-[1px] cursor-pointer">
						<Book className="mr-[11px] mt-[-2px]" />
						<h2 className="text-sm font-medium text-[#154365]">View Documentation</h2>
					</div>
				</Link>
				
				{(openAddAPIKeyModal || signalForOpenAddAPIKeyModal) && (
					<AddAPIKeyModal
						setOpenAddAPIKeyModal={setOpenAddAPIKeyModal}
						openAddAPIKeyModal={openAddAPIKeyModal}
						cancelButtonRef={cancelButtonRef}
						APIKeyData={APIKeyData}
						setAPIKeyData={setAPIKeyData}
						setRotate={setRotate}
						rotate={rotate}
						setSignalForOpenPopupCreateAPIKey={setSignalForOpenPopupCreateAPIKey}
						setSignalForOpenAddAPIKeyModal={setSignalForOpenAddAPIKeyModal}
						setAPIKeyDataString={setAPIKeyDataString}
					/>
				)}
				{openPopupCreateAPIKey && signalForOpenPopupCreateAPIKey && (
					<ApiKeyPopupModal
						setOpenPopupCreateAPIKey={setOpenPopupCreateAPIKey}
						openPopupCreateAPIKey={openPopupCreateAPIKey}
						apiPopupRef={apiPopupRef}
						setSignalForOpenPopupCreateAPIKey={setSignalForOpenPopupCreateAPIKey}
						APIKeyDataString={APIKeyDataString}
						setAPIKeyDataString={setAPIKeyDataString}
					/>
				)}
				{openDeleteAPIKeyModal && (
					<DeleteAPIKeyModal
						APIKeyData={APIKeyData}
						setOpenDeleteAPIKeyModal={setOpenDeleteAPIKeyModal}
						openDeleteAPIKeyModal={openDeleteAPIKeyModal}
						deleteAPIKeyRef={deleteAPIKeyRef}
						setAPIKeyData={setAPIKeyData}
					/>
				)}
				{openRotateConfirmationModal && (
					<RotateConfirmationModal
						openRotateConfirmationModal={openRotateConfirmationModal}
						setOpenRotateConfirmationModal={setOpenRotateConfirmationModal}
						rotateConfirmationRef={rotateConfirmationRef}
						setSignalForOpenAddAPIKeyModal={setSignalForOpenAddAPIKeyModal}
						setRotate={setRotate}
						setAPIKeyData={setAPIKeyData}
					/>
				)}
			</div>
		</div>
	)
}
export default APIKeysTab
