import AppKeycloak from '../Keycloak'
import APIKeysRepository, { APIKey, ApiKeyId } from './APIKeysRepository'

import axios from 'axios'
export default class APIAPIKeysRepository implements APIKeysRepository {

	async apiKeys(id: string): Promise<APIKey[]> {
		const settings: any = JSON.parse(localStorage.getItem('settings') || '')
		const keycloak = AppKeycloak.getAppKeycloak()

		const data = await axios.get(`${settings.WEBPORTAL_BACKEND_URL}/businesses/${id}/api-keys`, {
			headers: {
				Authorization: `Bearer ${keycloak.token}`,
			},
		})

		return data.data
	}

	async addApiKey(id: string, title: string, expirationDate?: string): Promise<ApiKeyId> {
		const settings: any = JSON.parse(localStorage.getItem('settings') || '')
		const keycloak = AppKeycloak.getAppKeycloak()

		const body = {
			'title': title,
			'expirationDate': expirationDate,
		}
		const data = await axios.post(`${settings.WEBPORTAL_BACKEND_URL}/businesses/${id}/api-keys`, body,{
			headers: {
				Authorization: `Bearer ${keycloak.token}`,
			},
		})

		return data.data
	}

	async editApiKeyName(id: string, apiKeyId: string, title: string): Promise<void> {
		const settings: any = JSON.parse(localStorage.getItem('settings') || '')
		const keycloak = AppKeycloak.getAppKeycloak()

		const body = {
			'title': title,
		}
		await axios.post(`${settings.WEBPORTAL_BACKEND_URL}/businesses/${id}/api-keys/${apiKeyId}/change-title`, body,{
			headers: {
				Authorization: `Bearer ${keycloak.token}`,
			},
		})
	}

	async rotateApiKey(id: string, apiKeyId: string, expirationDate?: string): Promise<ApiKeyId> {
		const settings: any = JSON.parse(localStorage.getItem('settings') || '')
		const keycloak = AppKeycloak.getAppKeycloak()

		const body = {
			'expirationDate': expirationDate,
		}
		const data = await axios.post(`${settings.WEBPORTAL_BACKEND_URL}/businesses/${id}/api-keys/${apiKeyId}/rotate-api-key`, body,{
			headers: {
				Authorization: `Bearer ${keycloak.token}`,
			},
		})

		return data.data
	}

	async deleteApiKey(businessId: string, apiKeyId: string): Promise<void>  {
		const settings: any = JSON.parse(localStorage.getItem('settings') || '')
		const keycloak = AppKeycloak.getAppKeycloak()

		await axios.delete(`${settings.WEBPORTAL_BACKEND_URL}/businesses/${businessId}/api-keys//${apiKeyId}`,{
			headers: {
				Authorization: `Bearer ${keycloak.token}`,
			},
		})
	}

	async apiKey(id: string, apiKeyId: string): Promise<APIKey> {
		const settings: any = JSON.parse(localStorage.getItem('settings') || '')
		const keycloak = AppKeycloak.getAppKeycloak()

		const data = await axios.get(`${settings.WEBPORTAL_BACKEND_URL}/businesses/${id}/api-keys/${apiKeyId}`, {
			headers: {
				Authorization: `Bearer ${keycloak.token}`,
			},
		})

		return data.data
	}
}
