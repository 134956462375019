import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { ReactComponent as List } from '../../../../assets/images/icons/list.svg'
import Breadcrumb from '../../../components/Breadcrumb/Index'
import BusinessTabs from '../../../components/BusinessTabs/BusinessTabs'
import { useAppSelector } from '../../../hooks'
import { useQuery } from '@tanstack/react-query'
import { dependencyContainer } from '../../../store'
import { MeasuringPointModel } from '../../../repository/DashboardRepository'
import { OneBusinessModel } from '../../../repository/BusinessRepository'

const Details = () => {

	const [businessData, setBusinessData] = useState<MeasuringPointModel[]>([])
	const [oneBusinessData, setOneBusinessData] = useState<OneBusinessModel | any>()
	const [openCreateCalculatedMpModal, setOpenCreateCalculatedMpModal] = useState(false)
	const [openAddNotificationModal, setOpenAddNotificationModal] = useState(false)
	const [openConflictVmpMpModal, setOpenConflictVmpMpModal] = useState(false)
	//const [hide, setHide] = useState(false)

	const { business } = useAppSelector((state) => state.app)

	const { subBusinessId: id } = useParams()

	useQuery({
		queryKey: ['businessData',business, id],
		queryFn:() => {
			if(id !== 'self') {
				return dependencyContainer.dependency.subBusinessApi.subBusiness(business, id as string)
			} else {
				return dependencyContainer.dependency.businessApi.business(business, id as string)
			}
		} ,
		onSuccess: (data) => {
			setOneBusinessData(data)
		}
	})

	useQuery({
		queryKey: ['measuringPointsData',business, id, openCreateCalculatedMpModal, openAddNotificationModal, openConflictVmpMpModal],
		queryFn:() =>  dependencyContainer.dependency.dashboardApi.dashboardData(business as string),
		onSuccess: (data: any) => {
			let filteredData = data.filter((el: any) => el.businessGroupId === id)
			if(filteredData.length === 0)
				filteredData = data.filter((el: any) => el.businessGroupId === business)
				//here logic for hidden mps(set businessData only with hide: false)
			//const filteredHideShowData = filteredData.filter((mp: any) => mp.hide === false || mp.hide === undefined)
			//console.log(filteredData)
			setBusinessData(filteredData)
			// filteredData?.map((el: any) => {
			// 	if(el.hide) {
			// 		setHide(true)
			// 	}
			// })
		}
	})

	const mapBusinessName = (breadcrumbLink: string) => {
		if (breadcrumbLink  === 'self'){
			return 'My Business'
		} else {
			if(breadcrumbLink === oneBusinessData?.id) {
				return oneBusinessData?.name
			}
		}
		
	}

	const bc = [
		{
			name: 'Business Overview',
			link: mapBusinessName(id as string),
			path: `/businesses/${business}`
		},
	]

	return (
		<div className="overflow-auto pl-[25px]">
			<Breadcrumb links={bc} icon={<List />} />
			<div className="p-[15px] bg-white w-[100%] rounded-xl mt-[23px]">
				<BusinessTabs
					businessData={businessData}
					oneBusinessData={oneBusinessData}
					openCreateCalculatedMpModal={openCreateCalculatedMpModal}
					setOpenCreateCalculatedMpModal={setOpenCreateCalculatedMpModal}
					openAddNotificationModal={openAddNotificationModal}
					setOpenAddNotificationModal={setOpenAddNotificationModal}
					openConflictVmpMpModal={openConflictVmpMpModal}
					setOpenConflictVmpMpModal={setOpenConflictVmpMpModal}
					//hide={hide}
				/>
			</div>
		</div>
	)
}

export default Details
