import React from 'react'
import { ReactComponent as RotationIcon } from '../../../assets/images/icons/rotation_table.svg'
import { ReactComponent as EditIcon } from '../../../assets/images/icons/edit_table.svg'
import { ReactComponent as CreationIcon } from '../../../assets/images/icons/creation_table.svg'
import { ReactComponent as DeleteIcon } from '../../../assets/images/icons/creation_delete.svg'

interface Props {
  data: any
}

const APIKeyIconColumn = ({data}: Props) => {

	return (
		<div
			className='flex items-center'
		>
			{data == 'API_KEY_ROTATED' && <RotationIcon />}
			{data == 'API_KEY_AUTHENTICATOR_TITLE_CHANGED' && <EditIcon />}
			{data == 'API_KEY_CREATED' && <CreationIcon />}
			{data == 'API_KEY_DELETED' && <DeleteIcon />}
			{data == 'EXPIRED_API_KEYS_DELETED' && <DeleteIcon />}
		</div>
	)

}

export default APIKeyIconColumn
