import { AgGridReact } from 'ag-grid-react'
import React, { useCallback, useMemo, useRef, useState} from 'react'
import { ReactComponent as Notification } from '../../../assets/images/icons/notificationsBlue.svg'
import { ReactComponent as Clock } from '../../../assets/images/icons/clock.svg'
import { ReactComponent as History } from '../../../assets/images/icons/history.svg'
import { ReactComponent as Send } from '../../../assets/images/icons/send.svg'
import Breadcrumb from '../../components/Breadcrumb/Index'
import CustomHeader from '../../components/Table/CustomHeader'
import ShareMpColumn from '../../components/Table/ShareMpColumn'
import { useAppSelector } from '../../hooks'
import { GetContextMenuItemsParams, LicenseManager } from 'ag-grid-enterprise'
import DeleteNotificationModal from '../../components/Modals/DeleteNotificationModal'
import AddNotificationModal from '../../components/Modals/AddNotificationModal '
import { useParams, useNavigate } from 'react-router-dom'
import { dependencyContainer } from '../../store'
import { useQuery } from '@tanstack/react-query'
import CommentColumn from '../../components/Table/CommentColumn'

LicenseManager.setLicenseKey(
	'CompanyName=Appmodule AG,LicensedGroup=aliunid grid / power,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=2,AssetReference=AG-036901,SupportServicesEnd=31_January_2024_[v2]_MTcwNjY1OTIwMDAwMA==4c9d5d4b04b142bc6b50ed895e5b5ef3'
)

const Notifications = () => {
	const [openDeleteNotificationModal, setOpenDeleteNotificationModal] = useState(false)
	const [notificationData, setNotificationData] = useState<any>()
	const [openAddNotificationModal, setOpenAddNotificationModal] = useState(false)
	const [replicate, setReplicate] = useState(false)
	const [rowDataScheduled, setRowDataScheduled] = useState<any>([])
	const [rowDataHistory, setRowDataHistory] = useState<any>([])

	const { role } = useAppSelector((state) => state.app)
	const notificationRef = useRef(null)
	const businessIdParam = useParams().id
	const navigate = useNavigate()

	const dateCompareDesc = (valueA: any, valueB: any) => {
		return valueA.scheduledAt <= valueB.scheduledAt ? 1 : -1
	}

	const dateCompareAsc = (valueA: any, valueB: any) => {
		return valueA.scheduledAt >= valueB.scheduledAt ? 1 : -1
	}

	useQuery({
		queryKey: ['notificationsData',businessIdParam,],
		queryFn:() =>  dependencyContainer.dependency.notificationsDataApi.notifications(businessIdParam as string),
		onSuccess: (data) => {
			setRowDataScheduled(data.filter((el => el.status === 'SCHEDULED')).sort(dateCompareAsc))
			setRowDataHistory(data.filter((el => el.status === 'HISTORY')).sort(dateCompareDesc))
		}
	})

	const headerHeight = 25
	const rowHeight = 84
	const floatingFiltersHeight = 30

	const columnDefs = [
		{
			cellRenderer: (row: any) => {
				return <ShareMpColumn
					data={row.data}
				/>
			},
			field: 'mp',
			filter: false,
			maxWidth: 35,
			sortable: false,
			onCellClicked: function (params: any) {
				if (params.node.data.name !== '') {
					params.api.contextMenuFactory.showMenu(
						params.node,
						params.column,
						params.value,
						params.event
					)
				}
			},
			cellStyle: function() {
				return { cursor: 'pointer'}
			},
		},
		{
			field: 'title',
			headerName: 'Title',
			headerComponentParams: { menuIcon: 'title' },
			cellClass: 'first-cell',
			minWidth: 140,
			cellStyle: function() {
				return { wordBreak: 'break-word' }
			},
		},
		{
			field: 'scheduledAt',
			headerName: 'Date',
			headerComponentParams: { menuIcon: 'date' },
			cellClass: 'flex-right-align',
			minWidth: 140,
			maxWidth: 140,
			valueFormatter: (params: any) => {
				const date = new Date(params.value)
				return date.getDate().toString().padStart(2, '0') + '.' + (date.getMonth() + 1).toString().padStart(2, '0') + '.' + date.getFullYear() 
			}
		},
		{
			field: 'scheduledAt',
			headerName: 'Time',
			headerComponentParams: { menuIcon: 'time' },
			cellClass: 'flex-right-align mr-[5px]',
			minWidth: 140,
			maxWidth: 140,
			valueFormatter: (params: any) => {
				const time = new Date(params.value)
				return time.getHours().toString().padStart(2, '0') + ':' + time.getMinutes().toString().padStart(2, '0') 
			}
		},
		{
			field: 'body',
			headerName: 'Message',
			headerComponentParams: { menuIcon: 'message' },
			cellClass: 'first-cell',
			cellStyle: function() {
				return { wordBreak: 'break-word' }
			},
			cellRenderer: (row: any) => (
				<CommentColumn
					data={row.data.body}
					length={70}
					tooltipWidth={250}
				/>
			),
			minWidth: 170,
		},
	]

	const defaultColDef = useMemo(
		() => ({
			sortable: true,
			filter: 'agTextColumnFilter',
			floatingFilter: true,
			wrapText: true,
			flex: 1,
			suppressMovable: true,
			suppressMenu: true,
			floatingFilterComponentParams: {
				suppressFilterButton: true,
			},
		}),
		[]
	)

	const columnTypes = useMemo(() => {
		return {
			number: {
				aggFunc: 'count',
			},
		}
	}, [])

	const onFirstDataRendered = useCallback(() => {
		setTimeout(() => {
			document
				.querySelectorAll('.ag-theme-alpine input[class^=ag-][type=text]')
				.forEach((obj) => {
					obj.setAttribute('placeholder', 'Search')
				})
		}, 50)
		setTimeout(() => {
			document
				.querySelectorAll('.ag-theme-alpine .ag-disabled input[class^=ag-][type=text]')
				.forEach((obj) => {
					obj.setAttribute('placeholder', 'Select')
					obj.removeAttribute('disabled')
				})
		}, 50)
	}, [])

	const icons = useMemo(() => {
		return {
			groupExpanded: '<i class="ag-icon ag-icon-small-down" style=""/>',
			groupContracted: '<i class="ag-icon ag-icon-small-right" style=""/>',
		}
	}, [])

	const components = useMemo(() => {
		return {
			agColumnHeader: CustomHeader,
			unSortIcon: true,
		}
	}, [])

	const getRowStyle = (params: any) => {
		if (typeof params.data !== 'undefined') {
			return { background: 'white' }
		}
	}

	const rowStyle = { cursor: 'pointer' }

	const getRowStyleHystory = (params: any) => {
		if (typeof params.data !== 'undefined') {
			return { background: '#ebebeb' }
		}
	}

	const editIcon = '<img border="0" width="15" height="15" src="/images/edit_icon.svg"/>'
	const replicateIcon = '<img border="0" width="15" height="15" src="/images/replace.svg"/>'
	const deleteIcon = '<img border="0" width="15" height="15" src="/images/delete_icon.svg"/>'
	const searchIcon = '<img border="0" width="15" height="15" src="/images/icon.svg"/>'

	const getContextMenuItems = useCallback((params: GetContextMenuItemsParams) => {

		const result = []
		if (params.node) {
			if(params.node.data?.status === 'SCHEDULED')
				result.push(
					{
						name: 'Edit',
						icon: editIcon,
						action: function () {
							setNotificationData(params.node?.data)
							setOpenAddNotificationModal(true)
							setReplicate(false)
						}
					},
					'separator'
				)
			result.push(
				{
					name: 'Replicate',
					icon: replicateIcon,
					action: function () {
						setNotificationData(params.node?.data)
						setOpenAddNotificationModal(true)
						setReplicate(true)
					}
				},
				'separator'
			)
			result.push(
				{
					name: 'Delete',
					icon: deleteIcon,
					action: function () {
						setNotificationData(params.node?.data)
						setOpenDeleteNotificationModal(true)
					}
				},
				'separator',
			)
			result.push(
				{
					name: 'Details',
					icon: searchIcon,
					action: function () {
						if (typeof params.node?.data !== 'undefined' && params.node.data?.status === 'HISTORY') {
							navigate(`${params.node?.data.adminNotificationId}`)
						} 
						else if(typeof params.node?.data !== 'undefined' && params.node?.data?.status === 'SCHEDULED') {
							setNotificationData(params.node?.data)
							setOpenAddNotificationModal(true)
							setReplicate(false)
						}
					}
				}
			)
		}
		return result
	}, [])

	const cellClicked = (row: any) => {
		if (typeof row.data !== 'undefined' && row.data.title !== '' && row.column.colId !== 'mp') {
			if(row.data.status === 'SCHEDULED') {
				setNotificationData(row.data)
				setOpenAddNotificationModal(true)
				setReplicate(false)
			} else {
				navigate(`${row.data.adminNotificationId}`)
			}
		} 
	}

	const bc = [
		{
			name: 'Notifications',
			link: '',
		},
	]

	return (
		<div className="w-full pl-[25px]">
			<Breadcrumb links={bc} icon={<Notification />} />
			<div className='grid grid-cols-2 gap-4  mt-[23px]'>
				<div className="bg-white p-[15px] rounded-xl">
					<div>
						<div className=" flex flex-row justify-between items-center">
							<div className="flex items-center">
								<div className="flex items-center">
									<Clock className=" text-[#004368]" />
								</div>
								<div className="pl-[10px]">
									<h2 className="text-base leading-6 font-medium tracking-[0.15px] mt-[2px] text-[#154365]">
									Scheduled Notification
									</h2>
								</div>
							</div>
						</div>
						<div className="mt-[10px] w-full h-[1px] bg-stone-200  "></div>
					</div>
					<div className="bg-[#F0F0F0] rounded-xl mt-[23px] p-[13px]">
						<div className={`notifications ag-theme-alpine ${role === 'admin' ? 'h-[calc(100vh-400px)]' : 'h-[calc(100vh-332px)]'}`}>
							<AgGridReact
								rowHeight={rowHeight}
								headerHeight={headerHeight}
								floatingFiltersHeight={floatingFiltersHeight}
								rowData={rowDataScheduled}
								columnDefs={columnDefs}
								defaultColDef={defaultColDef}
								columnTypes={columnTypes}
								groupDisplayType={'groupRows'}
								rowSelection="multiple"
								animateRows={true}
								onFirstDataRendered={onFirstDataRendered}
								groupSelectsChildren={true}
								suppressRowClickSelection={true}
								suppressAggFuncInHeader={true}
								icons={icons}
								components={components}
								pagination={true}
								paginationAutoPageSize={true}
								suppressContextMenu={true}
								getRowStyle={getRowStyle}
								getContextMenuItems={getContextMenuItems}
								onCellClicked={cellClicked}
								rowStyle={rowStyle}
							/>
						</div>
						<div className="w-full h-[1px] order-1 bg-white"></div>
					</div>
					{role === 'admin' &&
					<div className='flex'>
						<div
							onClick={() => {
								setReplicate(false)
								setOpenAddNotificationModal(true)
							}}
							className="ml-[13px] mt-[15px] flex justify-center align-center px-[18px] py-[10px] rounded-3xl border-[#4D6071] border-[1px] cursor-pointer"
						>
							<Send className="mr-[11px] mt-[3px]" />
							<h2 className="text-sm font-medium text-[#154365]">Create Notification</h2>
						</div>
					</div>}
				</div>
				<div className="bg-white p-[15px] rounded-xl">
					<div>
						<div className=" flex flex-row justify-between items-center">
							<div className="flex items-center">
								<div className="flex items-center">
									<History className=" text-[#004368]" />
								</div>
								<div className="pl-[10px]">
									<h2 className="text-base leading-6 font-medium tracking-[0.15px] mt-[2px] text-[#154365]">
									Notification History
									</h2>
								</div>
							</div>
						</div>
						<div className="mt-[10px] w-full h-[1px] bg-stone-200  "></div>
					</div>
					<div className="bg-[#F0F0F0] rounded-xl mt-[23px] p-[13px]">
						<div className={`notifications ag-theme-alpine ${role === 'admin' ? 'h-[calc(100vh-400px)]' : 'h-[calc(100vh-332px)]'}`}>
							<AgGridReact
								rowHeight={rowHeight}
								headerHeight={headerHeight}
								floatingFiltersHeight={floatingFiltersHeight}
								rowData={rowDataHistory}
								columnDefs={columnDefs}
								defaultColDef={defaultColDef}
								columnTypes={columnTypes}
								groupDisplayType={'groupRows'}
								rowSelection="multiple"
								animateRows={true}
								onFirstDataRendered={onFirstDataRendered}
								groupSelectsChildren={true}
								suppressRowClickSelection={true}
								suppressAggFuncInHeader={true}
								icons={icons}
								components={components}
								pagination={true}
								paginationAutoPageSize={true}
								suppressContextMenu={true}
								getRowStyle={getRowStyleHystory}
								getContextMenuItems={getContextMenuItems}
								onCellClicked={cellClicked}
								rowStyle={rowStyle}
							/>
						</div>
						<div className="w-full h-[1px] order-1 bg-white"></div>
					</div>
				</div>
			</div>
			{openDeleteNotificationModal && (
				<DeleteNotificationModal
					notificationData={notificationData}
					setNotificationData={setNotificationData}
					setOpenDeleteNotificationModal={setOpenDeleteNotificationModal}
					openDeleteNotificationModal={openDeleteNotificationModal}
					notificationRef={notificationRef}
				/>
			)}
			{openAddNotificationModal && (
				<AddNotificationModal
					setOpenAddNotificationModal={setOpenAddNotificationModal}
					openAddNotificationModal={openAddNotificationModal}
					notificationData={notificationData}
					setNotificationData={setNotificationData}
					replicate={replicate}
				/>
			)}
		</div>
	)
}
export default Notifications
