import React from 'react'

const ValidityRenderer = (props: any) => {

	let cellValue = ''
	let bgColor = ''
	let color = ''

	const validity = props.data?.validity

	if (validity) {
		cellValue = 'Valid'
		bgColor = '#7DC2FD'
	} else {
		cellValue = 'Expired'
		bgColor = '#BA1A1A'
		color = '#ffffff'
	}

	return (
		<div className='forecast-holder'>
			<div className='forecast' style={{ backgroundColor: bgColor, color: color}}>
				<span>{cellValue}</span>
			</div>
		</div>
	)
}

export default ValidityRenderer
