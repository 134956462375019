import React,{useState} from 'react'
import { ReactComponent as DragIcon } from '../../../assets/images/icons/dragIcon.svg'

interface Props {
  data: any
}

const ShareMpColumn = ({data}: Props) => {

	const [isHovered, setIsHovered] = useState(false)

	return (
		<div
			onMouseEnter={() => setIsHovered(true)}
			onMouseLeave={() => setIsHovered(false)}
			className='flex items-center'
		>
			{(data?.name || data?.comment || data?.month || data?.title) && <DragIcon
				stroke={isHovered ? '#4d6071' : '#c2cad1'}
				//onClick={()=> openContextMenu()}
			/>}
		</div>
	)

}

export default ShareMpColumn
