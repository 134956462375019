import AppKeycloak from '../Keycloak'
import { MeasuringPointModel } from './DashboardRepository'
import axios from 'axios'
export default class GetMeasuringPointsRepository {

	async dashboardWithoutMap(id?: string): Promise<MeasuringPointModel[]> {
		if (id == null) return []
		const settings: any = JSON.parse(localStorage.getItem('settings') || '')

		const keycloak = AppKeycloak.getAppKeycloak()
		try {
			const data = await axios.get(
				`${settings.WEBPORTAL_BACKEND_URL}/businesses/${id}/dashboard`,
				{
					headers: {
						Authorization: `Bearer ${keycloak.token}`,
					},
				}
			)

			return data.data
		} catch (error) {
			console.log(error)
			return []
		}
	}
	async measuringPoints(id?: string): Promise<MeasuringPointModel[]> {
		// return this.dashboard(id)
		if (id == null) return []
		const settings: any = JSON.parse(localStorage.getItem('settings') || '')

		const keycloak = AppKeycloak.getAppKeycloak()
		try {
			const response = await axios.get<any[]>(`${settings.WEBPORTAL_BACKEND_URL}/businesses/${id}/measuring-points`, {
				headers: { Authorization: `Bearer ${keycloak.token}` }
			})
			return response.data.map(mp => ({ ...mp, businessGroupId: 'self',consumptionSpent: undefined }))
		} catch (error) {
			console.log(error)
			return []
		}
	}

	async measuringPoint(rootBusinessId: string, subBusinessId: string, mpId: string): Promise<MeasuringPointModel> {
		//if (mpId == null) return []
		const settings: any = JSON.parse(localStorage.getItem('settings') || '')

		const keycloak = AppKeycloak.getAppKeycloak()

		let url = `${settings.WEBPORTAL_BACKEND_URL}/businesses/${rootBusinessId}/sub-businesses/${subBusinessId}/measuring-points/${mpId}`
		if(subBusinessId === 'self')
			url = `${settings.WEBPORTAL_BACKEND_URL}/businesses/${rootBusinessId}/measuring-points/${mpId}`
		const data = await axios.get(url, { headers: { Authorization: `Bearer ${keycloak.token}` } })

		return data.data
	}

	async shareMeasuringPoint(rootBusinessId: string, mpId: string, targetBusinessId: string): Promise<MeasuringPointModel>  {
		const settings: any = JSON.parse(localStorage.getItem('settings') || '')
		const keycloak = AppKeycloak.getAppKeycloak()

		const body = {
			'targetBusiness': targetBusinessId,
			
		}
		const call = await axios.post(`${settings.WEBPORTAL_BACKEND_URL}/businesses/${rootBusinessId}/measuring-points/${mpId}/share`,body,{
			headers: {
				Authorization: `Bearer ${keycloak.token}`,
			},
		})
		return call as any
	}

	async cancelSharingMeasuringPoint(rootBusinessId: string, mpId: string): Promise<MeasuringPointModel>  {
		const settings: any = JSON.parse(localStorage.getItem('settings') || '')
		const keycloak = AppKeycloak.getAppKeycloak()

		const body = {}
		const call = await axios.post(`${settings.WEBPORTAL_BACKEND_URL}/businesses/${rootBusinessId}/measuring-points/${mpId}/cancel-sharing`,body,{
			headers: {
				Authorization: `Bearer ${keycloak.token}`,
			},
		})
		return call as any
	}

	async changeNameMeasuringPoint(rootBusinessId: string, mpId: string, name: string): Promise<MeasuringPointModel>  {
		const settings: any = JSON.parse(localStorage.getItem('settings') || '')
		const keycloak = AppKeycloak.getAppKeycloak()

		const body = {
			'name': name,
			
		}
		const call = await axios.post(`${settings.WEBPORTAL_BACKEND_URL}/businesses/${rootBusinessId}/measuring-points/${mpId}/change-name`, body, {
			headers: {
				Authorization: `Bearer ${keycloak.token}`,
			},
		})
		return call as any
	}

	async deleteMeasuringPoint(rootBusinessId: string, mpId: string): Promise<void>  {
		const settings: any = JSON.parse(localStorage.getItem('settings') || '')
		const keycloak = AppKeycloak.getAppKeycloak()

		const call = await axios.post(`${settings.WEBPORTAL_BACKEND_URL}/businesses/${rootBusinessId}/measuring-points/${mpId}/delete`, {}, {
			headers: {
				Authorization: `Bearer ${keycloak.token}`,
			},
		})
		return call as any
	}

	async measuringPointsQuotas(id?: string): Promise<MeasuringPointModel[]> {
		// return this.dashboard(id)
		if (id == null) return []
		const settings: any = JSON.parse(localStorage.getItem('settings') || '')

		const keycloak = AppKeycloak.getAppKeycloak()
		try {
			const response = await axios.get<any[]>(`${settings.WEBPORTAL_BACKEND_URL}/businesses/${id}/measuring-points`, {
				headers: { Authorization: `Bearer ${keycloak.token}` }
			})
			return response.data.map(mp => ({ ...mp, businessGroupId: 'self',consumptionSpent: undefined }))
		} catch (error) {
			console.log(error)
			return []
		}
	}

	async createVirtualMp(id: string, name: string,  formula: string, mp: string[], factor: number): Promise<void> {
		const settings: any = JSON.parse(localStorage.getItem('settings') || '')
		const keycloak = AppKeycloak.getAppKeycloak()

		const body = {
			'name': name,
			'formulae': formula,
			'measuringPoints': mp,
			'calculationFactor': factor,
		}
		await axios.post(`${settings.WEBPORTAL_BACKEND_URL}/businesses/${id}/measuring-points/create-virtual-measuring-point`, body,{
			headers: {
				Authorization: `Bearer ${keycloak.token}`,
			},
		})
	}

}
