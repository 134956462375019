import React, { useState, Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { Input } from '@material-tailwind/react'
import { ReactComponent as Close } from '../../../assets/images/icons/close.svg'
import { ReactComponent as Check } from '../../../assets/images/icons/check.svg'
import { ReactComponent as CloseBlack } from '../../../assets/images/icons/closeBlack.svg'
import { ReactComponent as IconClose } from '../../../assets/images/icons/iconClose.svg'
import { ReactComponent as APIKeyAdd } from '../../../assets/images/icons/add_API.svg'
import { ReactComponent as APIKeyEdit } from '../../../assets/images/icons/editNotification.svg'
import { ReactComponent as AlertRed } from '../../../assets/images/icons/infoMessageRed.svg'
import { ReactComponent as Rotate } from '../../../assets/images/icons/replace.svg'
import { ReactComponent as RotateWhite } from '../../../assets/images/icons/replaceWhite1.svg'
import ActionButton from '../ActionButton'
import { useParams } from 'react-router-dom'
import { dependencyContainer } from '../../store'
import { useMutation } from '@tanstack/react-query'
import { queryClient } from '../../../index'
import { ReactComponent as IconPlus } from '../../../assets/images/icons/icon_add_white.svg'
import ThemeProvider from '@mui/system/ThemeProvider/ThemeProvider'
import { createTheme } from '@mui/material/styles'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

import Stack from '@mui/material/Stack'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import dayjs, { Dayjs } from 'dayjs'
import TextField from '@mui/material/TextField'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'

const theme = createTheme({
	palette: {
		primary: {
			main: '#2196f3',
		},
		secondary: {
			main: '#154365',
		},
	},
})

interface Props {
  setOpenAddAPIKeyModal: React.Dispatch<React.SetStateAction<boolean>>
  setSignalForOpenPopupCreateAPIKey: React.Dispatch<React.SetStateAction<boolean>>
  setSignalForOpenAddAPIKeyModal: React.Dispatch<React.SetStateAction<boolean>>
  openAddAPIKeyModal: boolean
  cancelButtonRef: any
  APIKeyData?: any
  setAPIKeyData: any
  rotate: boolean
  setRotate: React.Dispatch<React.SetStateAction<boolean>>
	setAPIKeyDataString: any
}

const AddAPIKeyModal = ({
	setOpenAddAPIKeyModal,
	openAddAPIKeyModal,
	cancelButtonRef,
	APIKeyData,
	setAPIKeyData,
	rotate,
	setRotate,
	setSignalForOpenPopupCreateAPIKey,
	setSignalForOpenAddAPIKeyModal,
	setAPIKeyDataString
}: Props) => {
	const [title, setTitle] = useState(APIKeyData ? APIKeyData.title : '')
	const [date, setDate] = useState<Dayjs | null>(APIKeyData ?  (rotate && APIKeyData?.expirationDate !== undefined ? dayjs(APIKeyData?.expirationDate) : dayjs().add(1, 'day')) : dayjs().add(1, 'day'))
	const [error, setError] = useState('')
	const [checked, setChecked] = useState(false)

	const handleChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
		setChecked(event.target.checked)
		if(error === 'Please choose a future date !') {
			setError('')
		}
	}

	const handleChangeDate = (newValue: any) => {
		if (newValue !== null) setDate(newValue)
		if(error === 'Please choose a future date !') {
			setError('')
		}
	}

	const { id } = useParams<any>()

	const handleChangeTitle = (event: React.ChangeEvent<HTMLInputElement>) => {
		setTitle(event.target.value)
		setError('')
	}

	const closeModalAndResetDataState = () => {
		setOpenAddAPIKeyModal(false)
		setAPIKeyData()
		setRotate(false)
		setSignalForOpenAddAPIKeyModal(false)
	}

	const addApiKey = useMutation({
		mutationFn: () => {
			if(!checked) {
				return dependencyContainer.dependency.apiKeysDataApi.addApiKey(id as string, title, date as any)
			}
			else {
				return dependencyContainer.dependency.apiKeysDataApi.addApiKey(id as string, title)
			}
		},
		onSuccess: async (data) => {
			setOpenAddAPIKeyModal(false)
			setSignalForOpenPopupCreateAPIKey(true)
			setAPIKeyData()
			setAPIKeyDataString(data)
			queryClient.invalidateQueries({ queryKey: ['apiKeysData'] })
		},
		onError: (error: any) => {
			setError(error.response?.data?.message ? error.response?.data?.message : 'Internal server error !')
		},
	})

	const editApiKey = useMutation({
		mutationFn: () => dependencyContainer.dependency.apiKeysDataApi.editApiKeyName(id as string, APIKeyData?.id, title),
		onSuccess: async () => {
			setOpenAddAPIKeyModal(false)
			setAPIKeyData()
			queryClient.invalidateQueries({ queryKey: ['apiKeysData'] })
		},
		onError: (error: any) => {
			setError(error.response?.data?.message ? error.response?.data?.message : 'Internal server error !')
		},
	})

	const rotateApiKey = useMutation({
		mutationFn: () => {
			if(!checked) {
				return dependencyContainer.dependency.apiKeysDataApi.rotateApiKey(id as string, APIKeyData?.id, date as any)
			}
			else {
				return dependencyContainer.dependency.apiKeysDataApi.rotateApiKey(id as string, APIKeyData?.id)
			}
		},
		onSuccess: async (data) => {
			setOpenAddAPIKeyModal(false)
			setSignalForOpenPopupCreateAPIKey(true)
			setSignalForOpenAddAPIKeyModal(false)
			setRotate(false)
			setAPIKeyData()
			setAPIKeyDataString(data)
			queryClient.invalidateQueries({ queryKey: ['apiKeysData'] })
		},
		onError: (error: any) => {
			setError(error.response?.data?.message ? error.response?.data?.message : 'Internal server error !')
		},
	})

	const submitForm = async () => {
		if (title === '') {
			setError('Title is required !')
		} else {
			if (APIKeyData) {
				editApiKey.mutate()
			} else {
				if (dayjs().isAfter(date) && !checked) {
					setError('Please choose a future date !')
				} else {
					addApiKey.mutate()
				}
			}
		}
	}

	const submitRotateForm = async () => {
		if (dayjs().isAfter(date)  && !checked) {
			setError('Please choose a future date !')
		} else {
			rotateApiKey.mutate()
		}
	}

	return (
		<Transition.Root show={openAddAPIKeyModal} as={Fragment}>
			<Dialog
				as="div"
				className="relative z-[20]"
				initialFocus={cancelButtonRef}
				onClose={() => {
					//no action for clicking outside modal
				}}
			>
				<Transition.Child
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 bg-[#000000] bg-opacity-75 transition-opacity " />
				</Transition.Child>

				<div className="fixed inset-0 z-10 overflow-y-auto">
					<div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							enterTo="opacity-100 translate-y-0 sm:scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 translate-y-0 sm:scale-100"
							leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						>
							<Dialog.Panel
								className="relative transform overflow-hidden rounded-lg
               bg-white text-left shadow-xl transition-all sm:my-8 xl:w-[603px] lg:w-[503px]"
							>
								<div className="p-[15px]">
									<div className=" flex flex-row justify-between items-center pt-[9px]">
										<div className="flex items-center">
											<div className="flex items-center w-5 h-5">{APIKeyData ? rotate ? <Rotate /> : <APIKeyEdit /> : <APIKeyAdd />}</div>
											<div className="w-[292px] pl-[7px]">
												<h2 className="text-base font-medium tracking-[0.15px] text-[#154365]">
													{APIKeyData ? (rotate ? 'Rotate API key' : 'Edit API key') : 'Add API key'}
												</h2>
											</div>
										</div>
										<div
											className=""
											onClick={() => {
												closeModalAndResetDataState()
												setSignalForOpenAddAPIKeyModal(false)
											}}
										>
											<Close className="text-[#4D6071] cursor-pointer" />
										</div>
									</div>
									<div className="mt-[10px] w-full h-[0.75px] order-1 bg-[#A7ACB3]"></div>
									<div className={`${APIKeyData ? !rotate ? 'h-[70px]' : 'h-[135px]' : 'h-[200px]'} mt-[33px] bg-[#F0F0F0] rounded-xl`}>
										<div className="p-[13px]">
											{!rotate && (
												<div className={`w-full relative ${APIKeyData ? '' : 'mb-[20px]'}`}>
													<Input
														crossOrigin=''
														required
														type={'text'}
														color={error === 'Title is required !' ? 'red' : 'blue'}
														size="lg"
														value={title}
														label="Title"
														onChange={handleChangeTitle}
														className="text-base text-left"
														icon={<IconClose className="cursor-pointer" onClick={() => setTitle('')} />}
													/>
												</div>
											)}
											{!rotate && error === 'Title is required !' && (
												<div className={`flex justify-start mb-[10px] ${APIKeyData ? !rotate ? ' pt-[30px]' : 'pt-[20px]' : ''} text-[#ff0000] text-[14px] mt-[-10px]`}>
													<AlertRed className="mt-[1px]" />
													<h2 className="ml-[8px]">{error}</h2>
												</div>
											)}
											{APIKeyData === undefined && (
												<>
													<div className="w-full relative">
														<ThemeProvider theme={theme}>
															<LocalizationProvider dateAdapter={AdapterDayjs}>
																<Stack spacing={3}>
																	<DatePicker
																		disabled={checked}
																		label="Expiration date"
																		inputFormat="DD.MM.YYYY"
																		value={date}
																		disablePast
																		minDate={new Date().setDate(new Date().getDate()+1)}
																		onChange={handleChangeDate}
																		renderInput={(params) => (
																			<TextField
																				{...params}
																				sx= {
																					{ '& .MuiOutlinedInput-root': { '& > fieldset': { border: '1px solid #B0BEC5', borderRadius: '6px' } },
																						'& .MuiInputBase-root': { height: '45px',} }
																				}
																			/>
																		)}
																		PopperProps={{
																			placement: 'bottom-start',
																		}}
																		PaperProps={{
																			sx: { marginTop: '-5px' },
																		}}
																	/>
																</Stack>
															</LocalizationProvider>
														</ThemeProvider>
													</div>
													<div className="bg-[#F0F0F0]">
														<div className="pt-[13px] text-[#455A64]">
															<ThemeProvider theme={theme}>
																<FormControlLabel control={
																	<Checkbox
																		checked={checked}
																		color="secondary"
																		onChange={handleChangeCheckbox}
																		inputProps={{ 'aria-label': 'controlled' }}
																	/>
																} label="Does not expire" />
															</ThemeProvider>
														</div>
													</div>
												</>
											)}
											{rotate && (
												<>
													<div className="w-full relative">
														<ThemeProvider theme={theme}>
															<LocalizationProvider dateAdapter={AdapterDayjs}>
																<Stack spacing={3}>
																	<DatePicker
																		disabled={checked}
																		label="Expiration date"
																		inputFormat="DD.MM.YYYY"
																		value={date}
																		disablePast
																		minDate={new Date().setDate(new Date().getDate()+1)}
																		onChange={handleChangeDate}
																		renderInput={(params) => (
																			<TextField
																				{...params}
																				sx= {
																					{ '& .MuiOutlinedInput-root': { '& > fieldset': { border: '1px solid #B0BEC5', borderRadius: '6px' } },
																						'& .MuiInputBase-root': { height: '45px',} }
																				}
																			/>
																		)}
																		PopperProps={{
																			placement: 'bottom-start',
																		}}
																		PaperProps={{
																			sx: { marginTop: '-5px' },
																		}}
																	/>
																</Stack>
															</LocalizationProvider>
														</ThemeProvider>
													</div>
													<div className="bg-[#F0F0F0]">
														<div className="pt-[13px] text-[#455A64]">
															<ThemeProvider theme={theme}>
																<FormControlLabel control={
																	<Checkbox
																		checked={checked}
																		color="secondary"
																		onChange={handleChangeCheckbox}
																		inputProps={{ 'aria-label': 'controlled' }}
																	/>
																} label="Does not expire" />
															</ThemeProvider>
														</div>
													</div>
												</>
											)}
										</div>
									</div>
									{error !== 'Title is required !' && error !== '' && (
										<div className="flex justify-start fixed text-[#ff0000] text-sm mt-[10px]">
											<AlertRed className="ml-[14px] mt-[1px]" />
											<h2 className="ml-[8px]">{error}</h2>
										</div>
									)}
									<div className="mt-[40px] flex justify-end">
										<div>
											<ActionButton
												className="bg-[#D5E4F7] text-[#001D32] font-medium py-2.5 pl-[21px] pr-[24px] rounded-full h-[40px]"
												text="Cancel"
												icon={<CloseBlack />}
												onClick={() => closeModalAndResetDataState()}
											/>
										</div>
										<div className="ml-[20px]">
											<ActionButton
												className="bg-[#154365] text-white font-medium py-2.5 pl-[21px] pr-[24px] rounded-full h-[40px]"
												text={APIKeyData ? (rotate ? 'Rotate' : 'Save') : 'Create'}
												icon={APIKeyData ? rotate ? <RotateWhite /> : <Check /> : <IconPlus />}
												onClick={() => {
													!rotate ? submitForm() : submitRotateForm()
												}}
											/>
										</div>
									</div>
								</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition.Root>
	)
}

export default AddAPIKeyModal
