import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { ReactComponent as List } from '../../../../assets/images/icons/list.svg'
import Breadcrumb from '../../../components/Breadcrumb/Index'
import MpTabs from '../../../components/DetailsTabs/MpTabs'
import { useAppSelector } from '../../../hooks'
import AppKeycloak from '../../../Keycloak'
import AppMQTT from '../../../AppMQTT'
import { dependencyContainer } from '../../../store'
import { useQuery } from '@tanstack/react-query'

const MeasuringPoint = () => {
	const [breadcrumbLink, setBreadcrumbLink] = useState<any>()
	const [mpData, setMpData] = useState<any>({})
	const [quotasData, setQuotasData] = useState<any>([])
	const [kwLive, setKwLive] = useState<number>(-1)
	const [isConnect, setIsConnect] = useState(false)

	let client = null as any

	const { business } = useAppSelector((state) => state.app)
	const { subBusinessId, mpId: id } = useParams()

	useQuery({
		queryKey: ['mpData', business, subBusinessId, id],
		queryFn:() => dependencyContainer.dependency.measuringPointsApi.measuringPoint(business, subBusinessId as string, id as string),
		onSuccess: (data) => {
			setMpData(data)
		}
	})

	useQuery({
		queryKey: ['quotasData', business, id],
		queryFn:() => dependencyContainer.dependency.quotasDataApi.mpQuotas(business, id as string),
		onSuccess: (data) => {
			setQuotasData(data)
		}
	})

	useEffect(() => {
		setBreadcrumbLink(mpData)
	}, [mpData])

	useEffect(() => {
		if (id === undefined) return
		if (Object.keys(mpData).length === 0) return
		let mqtt: AppMQTT | undefined = undefined
		try {
			mqtt = new AppMQTT(dependencyContainer.dependency.wssUrl,AppKeycloak.getAppKeycloak())
			client = mqtt
				.connect()
				.on('connect', () => {
					setIsConnect(true)
					client.subscribe(`s2/aliunid/${business}/adm/${mpData.siotId}`)
				})
				.on('error', (e: any) => {
					setIsConnect(false)
					console.error(e)
				})
				.on('timeout', (isWithError: any) => {
					setIsConnect(false)
					console.error(isWithError)
					// TODO: something more?
				})
				.on('message', (_topic: any, message: any) => {
					const data = JSON.parse(message.toString()).value.sys.kW
					setKwLive(data.toFixed(3))
				})
		} catch (e) {
			console.error(e)
		}
		return () => {
			if (mqtt !== undefined)
				mqtt.disconnect()
		}
	}, [mpData, id])

	const bc = [
		{
			name: 'Business Overview',
			link: breadcrumbLink !== undefined ? breadcrumbLink.name : '',
			path: `/businesses/${business}`
		},
	]

	return (
		<div className="overflow-auto pl-[25px]">
			<div className='relative'>
				<Breadcrumb links={bc} icon={<List />} mpData={mpData}/>
			</div>
			<div className="p-[15px] bg-white w-[100%] rounded-xl mt-[23px]">
				<MpTabs 
					mpData={mpData} 
					kwLive={kwLive} 
					quotasData={quotasData}
				/>
			</div>
		</div>
	)
}

export default MeasuringPoint
