// require('dotenv').config()
import './index.css'
import { ThemeProvider } from '@material-tailwind/react'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'

import App from './App'
import { dependencyContainer, loadEnv, store } from './app/store'
import reportWebVitals from './reportWebVitals'
import AppKeycloak, { KeycloakStore } from './app/Keycloak'
import AppRouter from './router/AppRouter'
import { loadAppCommand } from './features/appLoader/appLoaderCommands'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

import 'typeface-roboto'

// import { createStore } from 'redux'

export const queryClient = new QueryClient()

fetch('/settings.json')
	.then(async (res) => {
		const settings = await res.json()
		localStorage.setItem('settings', JSON.stringify(settings))
		return settings
	})
	.then((env) => loadEnv(env))
	.then(() => {
		return new Promise<KeycloakStore>((resolve, reject) => {
			const keycloak = AppKeycloak.getAppKeycloak()
			keycloak
				.start()
				.then(() => {
					resolve(keycloak.store())
				})
				.catch((e) => {
					reject(e)
				})
		})
	})
	.then((keycloakStore) => {
		const parts = window.location.pathname
			.split('/businesses')
			.filter((p) => p !== '')
			.filter((p) => p !== '/')

		if (parts.length >= 1) {
			const businessId = parts[0].substring(1, parts[0].length).split('/')[0]
			store.dispatch(loadAppCommand(businessId))
		}

		// store.dispatch(loadAppCommand())

		return keycloakStore
	})
	.then((env) => {
		ReactDOM.createRoot(document.getElementById('root')!).render(
			<QueryClientProvider client={queryClient}>
				<React.StrictMode>
					<Provider store={store}>
						<ThemeProvider>
							<AppRouter /* keycloakStore={keycloakStore} */ />
						</ThemeProvider>
					</Provider>
				</React.StrictMode>
			</QueryClientProvider>
		)
	})

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
