import { businessLoaded, Role } from '../../app/appSlice'
import { Command } from '../../app/store'
import { businessesLoaded } from './selectBusinessSlice'

function groupToRole(group: string): Role {
	if (group === 'admins') return 'admin'
	return 'viewer'
}

export const loadBusinessesCommand = (): Command => async (dispatch, getState, { dependency }) => {
	try {
		dispatch(businessesLoaded(await dependency.businessesRepository.businesses()))
	} catch (err) {
		console.log(err)
	}
}
export const selectBusinessCommand = (businessId: string): Command => async (dispatch, getState, { dependency }) => {
	try {
		const tokenBusinesses: string[] = ((dependency.keycloak.parsedToken as any).groups)
		const allowedBusinesses = tokenBusinesses.map(tokenPart => {
			const parts = tokenPart.split('/')
			return {
				business: parts[1],
				role: parts[2]
			}
		})
		const business = allowedBusinesses.filter(b => b.business === businessId)
		if (business === undefined) throw new Error('Provided business is not allowed!')
		dispatch(businessLoaded({
			business: businessId,
			role: groupToRole(business[0].role)
		}))
	} catch (err) {
		console.log(err)
	}
}

