import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { ReactComponent as SettingsIcon } from '../../../../assets/images/icons/bre-settings.svg'
import Breadcrumb from '../../../components/Breadcrumb/Index'
import { useAppSelector } from '../../../hooks'
import { dependencyContainer } from '../../../store'
import { useQuery } from '@tanstack/react-query'
import APIKeyDetailsTabs from '../../../components/SettingsTabs/APIKeyDetailsTabs'

const APIKeyDetails = () => {
	const [APIKeyData, setAPIKeyData] = useState<any>()

	const { business } = useAppSelector((state) => state.app)
	const { APIKeyId } = useParams()

	useQuery({
		queryKey: ['apiKeysData', business, APIKeyId],
		queryFn:() => dependencyContainer.dependency.apiKeysDataApi.apiKey(business, APIKeyId as string),
		onSuccess: (data) => {
			setAPIKeyData(data)
		}
	})

	const bc = [
		{
			name: 'Settings',
			link: APIKeyData !== undefined ? APIKeyData.title : '',
			path: `/businesses/${business}/settings`
		},
	]

	return (
		<div className="overflow-auto pl-[25px]">
			<div className='relative'>
				<Breadcrumb links={bc} icon={<SettingsIcon />} mpData={APIKeyData}/>
			</div>
			<div className="p-[15px] bg-white w-[100%] rounded-xl mt-[23px]">
				<APIKeyDetailsTabs
					APIKeyData={APIKeyData} 
				/>
			</div>
		</div>
	)
}

export default APIKeyDetails
